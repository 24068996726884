import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {Grid} from 'react-bootstrap-icons';
import DialogHeading from '../../../components/DialogHeading';

const systemDetailsMapping = [
  {key: 'system_ID', label: 'System ID'},
  {key: 'installation_date', label: 'Installation Date'},
  {key: 'power_on_date', label: 'Power On Date'},
  {key: 'system_size', label: 'System Size(kWp)'},
  {key: 'product_name', label: 'Product'},
  {key: 'product_category_name', label: 'Category'},
  {key: 'sku_name', label: 'SKU'},
];

const inverterDetailsMapping = [
  {key: 'GatewayId', label: 'Gateway ID'},
  {key: 'deviceType', label: 'Device Type'},
  {key: 'device_serial_number', label: 'Device serial number'},
  //   {key: 'etl_column', label: 'ETL column'},
  {key: 'inverter_ID', label: 'Inverter ID'},
  {key: 'inverter_name', label: 'Inverter Name'},
  {key: 'objectType', label: 'Type'},
];

const AddGatewayDialog = ({setOpenDialog, handleAddSystem, systems}) => {
  const [selectedGatewayIndex, setSelectedGatewayIndex] = useState();
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open
      handleClose={() => setOpenDialog(false)}
    >
      <DialogHeading
        title={'Add System'}
        handleClose={() => setOpenDialog(false)}
      />

      <DialogContent>
        {systems && systems.length > 0 ? (
          <FormControl fullWidth sx={{mt: 1}}>
            <InputLabel>Gateway</InputLabel>
            <Select
              value={selectedGatewayIndex}
              label="Gateway"
              onChange={e => setSelectedGatewayIndex(e.target.value)}
            >
              {systems &&
                systems.length > 0 &&
                systems.map((item, index) => (
                  <MenuItem value={index}>{item.system_ID}</MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <Typography sx={{mt: 2}}>No systems available</Typography>
        )}

        {selectedGatewayIndex > -1 && (
          <Box sx={{mt: 1}}>
            {systemDetailsMapping.map(item => {
              return (
                <Typography>
                  {item.label + ': ' + systems[selectedGatewayIndex][item.key]}
                </Typography>
              );
            })}

            {systems[selectedGatewayIndex] &&
            systems[selectedGatewayIndex].gateway &&
            systems[selectedGatewayIndex].gateway.length > 0 ? (
              <>
                <Typography sx={{mt: 2}}>Gateway Data:</Typography>
                {systems[selectedGatewayIndex].gateway.map(gateway => (
                  <Typography>
                    {gateway.gateway_name + ' - ' + gateway.gateway_ID}
                  </Typography>
                ))}
              </>
            ) : (
              <Typography sx={{mt: 2}}>No gateway data available</Typography>
            )}

            {systems[selectedGatewayIndex] &&
              systems[selectedGatewayIndex].inverter &&
              systems[selectedGatewayIndex].inverter.length > 0 && (
                <>
                  {systems[selectedGatewayIndex].inverter.length > 0 ? (
                    systems[selectedGatewayIndex].inverter.map(
                      (inverter, index) => (
                        <Box sx={{mt: 1}}>
                          <Typography sx={{mt: 2}}>{`Inverter ${
                            index + 1
                          } Data:`}</Typography>
                          {inverterDetailsMapping.map(
                            mapItem =>
                              inverter[mapItem.key] && (
                                <Typography>
                                  {mapItem.label +
                                    ' - ' +
                                    inverter[mapItem.key]}
                                </Typography>
                              )
                          )}
                        </Box>
                      )
                    )
                  ) : (
                    <Typography sx={{mt: 2}}>
                      No inverter data available
                    </Typography>
                  )}
                </>
              )}
          </Box>
        )}
        {/* <Grid item xs={12}>
            {selectedGatewayIndex}
          </Grid> */}
        {/* </Grid> */}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={
            selectedGatewayIndex < 0 || (systems && systems.length === 0)
          }
          onClick={() =>
            handleAddSystem(systems[selectedGatewayIndex].system_ID)
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGatewayDialog;
