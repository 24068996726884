import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import React, {useEffect, useState} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import appService, {appServiceTSL} from '../../../core/service/app.service';
import ILead from '.././__types__/lead.interface';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallIcon from '@mui/icons-material/Call';

import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Map from '../../Projects/Map';
import envConfig from '../../../config/envConfig';
import IUser from '../../../core/__types__/Users.interface';
import LoadingButton from '@mui/lab/LoadingButton';
import {useSnackbar} from 'notistack';
import IOrganisation from '.././__types__/organisation.interface';
import LinearProgressWithLabel from '../../../components/LinearProgressBar';

const useStyles = makeStyles(theme => ({
  linkDecorationReset: {
    textDecoration: 'none',
    // @ts-ignore
    color: theme.palette.secondary.main,
  },
}));

function TSLLead() {
  const {leadId} = useParams<{leadId: string}>();

  const [leadData, setLeadData] = useState<null | ILead>(null);

  const [installers, setInstallers] = useState<any>(null);

  const [installer, setInstaller] = useState<any>('');

  const [assigning, setAssigning] = useState<boolean>(false);
  const styles = useStyles();
  const history = useHistory();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    (async () => {
      const leadData = await appService.getLeadById(leadId);

      setLeadData(leadData.message);

      const {tsl_project_ID} = leadData.message;

      // get users
      const users: any[] = [];
      const usersres = await appServiceTSL.getUsers(1);
      users.push(...usersres.results);
      const userpages = Math.ceil(usersres.count / 10);

      const userpromises = [];

      for (let i = 2; i <= userpages; i++) {
        userpromises.push(appServiceTSL.getUsers(i));
      }

      const y = await Promise.all(userpromises);

      y.forEach(response => {
        users.push(...response.results);
      });

      setInstallers(users);

      if (tsl_project_ID) {
        const tslProjectData = await appServiceTSL.getProjectById(
          tsl_project_ID
        );

        const {owner} = tslProjectData;

        console.log(tslProjectData);
        const assignedUser = users.find(user => user.id === owner.id);

        console.log(assignedUser);

        setInstaller(assignedUser);
      }
    })();
  }, []);

  const breadcrumbs = [
    <Link className={styles.linkDecorationReset} to={`/leadmanagement`}>
      <b>Leads</b>
    </Link>,
    <Typography key="2" sx={{cursor: 'pointer'}}>
      {leadId}
    </Typography>,
  ];

  const handleInstallerSelect = (e: any, value: any) => {
    console.log(value);
    setInstaller(value);
  };

  const handleAssignLead = async () => {
    try {
      if (leadData !== null) {
        setAssigning(true);
        const createProjectBody = {
          ...leadData,
          name: leadData.fullname,
          client_name: leadData.fullname,
          client_email_id: leadData.email,
          client_phone: leadData.phone_number,
          quota_type: 'LARGE',
          owner_id: installer.id,
        };

        const response = await appServiceTSL.createProject(createProjectBody);

        if (response.status >= 500) {
          enqueueSnackbar(`Project is not created.`, {
            variant: 'error',
          });
          setAssigning(false);
        } else {
          const tsl_project_ID = response.id;
          appService.updateLead(leadId, {tsl_project_ID});

          setLeadData({...leadData, tsl_project_ID});
          const projectId = response.id;
          const updateResponse = await appServiceTSL.updateProject(projectId, {
            owner: installer.id,
          });

          enqueueSnackbar(`Project is successfully assigned.`, {
            variant: 'success',
          });
          setAssigning(false);
        }
      }
    } catch (error) {
      enqueueSnackbar(`Project is successfully assigned.`, {
        variant: 'success',
      });
      setAssigning(false);
    }
  };

  return (
    <Box>
      <Breadcrumbs separator="›" aria-label="breadcrumb ">
        {breadcrumbs}
      </Breadcrumbs>
      {leadData ? (
        <Grid container>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Grid item>
              <Typography variant="h6"> {leadData.lead_ID} </Typography>{' '}
            </Grid>
          </Grid>

          <Grid
            item
            container
            p={1}
            pt={3}
            sx={{borderTop: '2px solid white'}}
            mt={1}
          >
            <Grid item container mb={2}>
              {/*installer  */}
              <Grid item container xs={12} mt={2} md={6} alignItems="center">
                <Grid item xs={6} md={4}>
                  <Typography>Installer :</Typography>
                </Grid>

                <Grid item xs>
                  <Autocomplete
                    onChange={handleInstallerSelect}
                    disablePortal
                    id="combo-box-demo"
                    sx={{width: 300}}
                    value={installer}
                    options={installers ? installers : []}
                    getOptionLabel={installer => installer.first_name || ''}
                    disabled={Boolean(leadData?.tsl_project_ID)}
                    renderInput={params => (
                      <TextField {...params} label="Installer" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*Project start */}

            {/*cutomer details*/}
            <Grid
              item
              container
              flexDirection="column"
              // pl={3}
              rowSpacing={1.5}
              my={2}
              xs={12}
              md={6}
            >
              <Grid item container>
                <AccountCircleIcon />
                <Typography ml={2} color={'secondary'}>
                  {`${leadData.salutation ? leadData.salutation : ''} ${
                    leadData.first_name
                  } ${leadData.last_name}`}
                </Typography>
              </Grid>

              <Grid item container>
                <CallIcon />
                <Typography ml={2} color={'secondary'}>
                  {leadData.phone_number}
                </Typography>
              </Grid>
              <Grid item container>
                <EmailIcon />
                <Typography ml={2} color={'secondary'}>
                  {leadData.email}
                </Typography>
              </Grid>
              <Grid item container>
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item xs>
                  <Typography ml={2}>{leadData.address}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*Geo map*/}
            <Grid item xs={12} md={6} height={250} my={2}>
              <Map
                address={leadData.address}
                latlng={{lat: leadData.latitude, lng: leadData.longitude}}
                minheight={'250px'}
              />
            </Grid>

            {installer && (
              <LoadingButton
                loading={assigning}
                variant="contained"
                color="secondary"
                onClick={handleAssignLead}
                disabled={Boolean(leadData?.tsl_project_ID)}
              >
                Confirm Assignment
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Box>
  );
}

export default TSLLead;
