import {Box, CircularProgress, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';

import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import IDesign from '../__types__/design.interface';
import appService from '../../../core/service/app.service';
import MiniDesignCard from '../MiniDesignCard';

function Designs() {
  const [value, setValue] = useState('0');

  const [myDesigns, setMyDesigns] = useState<null | IDesign[]>(null);

  const [sentDesigns, setSentDesigns] = useState<null | IDesign[]>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    appService
      .getDesignProjectsInstaller()
      .then(res => {
        console.log(res);

        setMyDesigns(res.message);
      })
      .catch(err => {
        console.log(err);
      });

    appService
      .getDesignProjectsSentInstaller()
      .then(res => {
        console.log(res);

        setSentDesigns(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <Grid>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="My Designs" value="0" />
          <Tab label="Ordered Designs" value="1" />
        </TabList>

        <TabPanel value="0">
          <Grid container spacing={3} pt={1}>
            {myDesigns ? (
              myDesigns.map(design => (
                <Grid item xs={12} sm={6} md={4} xl={4}>
                  <MiniDesignCard designData={design} view="grid" />
                </Grid>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={300}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value="1">
          <Grid container spacing={3} pt={1}>
            {sentDesigns ? (
              sentDesigns.map(design => (
                <Grid item xs={12} sm={6} md={4} xl={4}>
                  <MiniDesignCard designData={design} view="grid" />
                </Grid>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={300}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Grid>
        </TabPanel>
      </TabContext>
    </Grid>
  );
}

export default Designs;
