import {Grid} from '@mui/material';
import {Box} from '@mui/system';
import {DataGrid} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react';
import Headings from '../../components/Headings';
import appService from '../../core/service/app.service';

const SystemAlert = () => {
  const [systemAlertData, setSystemAlertData] = useState<any>();

  useEffect(() => {
    appService
      .getSystemAlert()
      .then(res => setSystemAlertData(res.message))
      .catch(err => console.log(err));
  }, []);

  const tableColumns = [
    {field: 'id', headerName: 'Alert ID', flex: 1},
    {field: 'alert_type', headerName: 'Alert Type', flex: 1},
    {field: 'gateway_id', headerName: 'GateWay ID', flex: 1},
    {field: 'description', headerName: 'Description', flex: 2},
    {field: 'subject', headerName: 'Subject', flex: 2},
  ];

  const getFormattedRows = (systemAlertData: any) => {
    if (systemAlertData) {
      const rows = systemAlertData.map((alerts: any) => ({
        ID: alerts.ID,
        id: alerts.sys_alert_ID,
        gateway_id: alerts.gateway_ID,
        alert_type: alerts.alert_type,
        name: alerts.gateway_name,
        subject: alerts.subject,
        description: alerts.description,
      }));

      return rows;
    }
  };

  return (
    <Grid container>
      <Headings heading={'Alert'} />

      {systemAlertData && (
        <Box sx={{height: '70vh', width: '100%', mt: 2}}>
          <DataGrid
            getRowId={(systemAlertData: any) => systemAlertData.ID}
            rows={systemAlertData ? getFormattedRows(systemAlertData) : []}
            columns={tableColumns}
          />
        </Box>
      )}
    </Grid>
  );
};

export default SystemAlert;
