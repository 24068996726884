import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import BackDropCirularProgress from '../../components/BackDropCircularProgress';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Formik} from 'formik';
import React, {useState, useRef} from 'react';
import Map, {Ilatlng} from '../../components/Map';
import MapAutocomplete from '../../components/MapAutocomplete';

import * as Yup from 'yup';
import appService from '../../core/service/app.service';

import envConfig from '../../config/envConfig';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../core/redux/store';
import {useSelector} from 'react-redux';
import {GoogleApiWrapper} from 'google-maps-react';
import LoadingButton from '@mui/lab/LoadingButton';
import {useSnackbar} from 'notistack';
import DialogHeading from '../../components/DialogHeading';

const useStyles = makeStyles(theme => ({
  dialog: {
    // the dropdown is next to the dialog root, not inside
    '& + .pac-container': {
      //@ts-ignore
      zIndex: theme.zIndex.modal + 1,
    },
  },
}));

function CreateLead(props: any) {
  const classes = useStyles();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const history = useHistory();
  const geocoder = new props.google.maps.Geocoder();
  const [addressString, setAddressString] = useState<string>('');
  const [leadDialogOpen, setLeadDialogOpen] = React.useState(false);
  const [creatingLead, setCreatingLead] = useState<boolean>(false);

  const addressSelectedRef = useRef<boolean>(false);

  const [addressLatLng, setAddressLatLng] = useState<Ilatlng>({
    lat: null,
    lng: null,
  });

  const [center, setCenter] = useState<Ilatlng>({
    lat: null,
    lng: null,
  });

  const updateAddressFromAutocomplete = (addressObject: any) => {
    if (typeof addressObject !== 'string') {
      const latlng = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      };
      setAddressString(addressObject.formatted_address);

      setAddressLatLng(latlng);

      setCenter(latlng);
    }
  };

  const resetContent = () => {
    console.log('called reset');
    setAddressString('');
    setAddressLatLng({lat: null, lng: null});
  };
  const handleCreate = async (values: any) => {
    try {
      setCreatingLead(true);

      const data = {
        import: false,

        leads: {
          latitude: addressLatLng.lat,
          longitude: addressLatLng.lng,
          address: addressString,
          gmaps_address_string: addressString,

          ...values,
        },
      };

      console.log(data);
      const res = await appService.createLead(data);
      console.log(res);
      setCreatingLead(false);
      setLeadDialogOpen(false);
      setAddressString('');
      setAddressLatLng({lat: null, lng: null});

      props.handleClose();
      history.push('/leadmanagement');
      enqueueSnackbar(`Lead Created Succesfully`, {
        variant: 'success',
      });
      props.getLeads();
    } catch (error) {
      setCreatingLead(false);
      enqueueSnackbar(`Operation Failed`, {
        variant: 'error',
      });
    }
  };

  const handleMapClick = (coords: any, setFieldValue: any) => {
    setAddressLatLng(coords);

    geocoder
      .geocode({location: coords})
      .then((response: any) => {
        // setAddressData(response.results[0]);
        const address = response.results[0].formatted_address;
        setAddressString(address);

        setFieldValue('address', address);
      })
      .catch((e: any) => console.log('Geocoder failed due to: ' + e));
  };
  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={() => {
        resetContent();
        props.handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <BackDropCirularProgress open={leadDialogOpen} />

      <DialogHeading
        title="Create Lead"
        handleClose={() => {
          props.handleClose();
          resetContent();
        }}
      />
      <DialogContent>
        {/* <CardContent sx={{p: 6}}> */}

        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            salutation: 'Mr.',
            phone_number: '',
            email: '',
            address: '',
            lead_source: 'Direct',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Email must be formatted correctly.')
              .max(255)
              .required('Email is required.'),

            first_name: Yup.string().required('First name is required.'),
            last_name: Yup.string().required('Last name is required'),
            salutation: Yup.string().required('Salutation is required.'),
            lead_source: Yup.string().required('Lead source is required.'),
            phone_number: Yup.string().required('Phone number is required.'),
            address: Yup.string().required('Address  is required.'),
          })}
          onSubmit={values => {
            console.log(values);
            handleCreate(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid
                  sx={{backgroundColor: 'none', mt: 0.3}}
                  xs={12}
                  container
                  alignItems="center"
                  spacing={2.5}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    flexDirection="row"
                    sx={{backgroundColor: 'none'}}
                    alignItems={'flex-start'}
                    spacing={2}
                  >
                    <Grid item xs={2} sm={1.5} md={1.1}>
                      <Typography sx={{fontSize: '80%'}}>
                        Salutation*
                      </Typography>

                      <FormControl fullWidth>
                        <Select
                          onChange={handleChange}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          autoWidth
                          sx={{width: '100%', backgroundColor: '#E8EDF2'}}
                          name="salutation"
                          size="small"
                          defaultValue={values.salutation}
                          fullWidth
                          margin="none"
                        >
                          <MenuItem value={'Mr.'}>Mr.</MenuItem>
                          <MenuItem value={'Ms.'}>Ms.</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2.5} md={2.9}>
                      <Typography sx={{fontSize: '80%'}}>
                        First Name*
                      </Typography>
                      <TextField
                        error={Boolean(errors.first_name)}
                        helperText={errors.first_name}
                        // inputProps={{backgroundColor: 'white'}}
                        //placeholder="First Name"
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // label="Required"
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        sx={{backgroundColor: '#E8EDF2'}}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{fontSize: '80%'}}>Last Name*</Typography>
                      <TextField
                        error={Boolean(errors.last_name)}
                        helperText={errors.last_name}
                        //placeholder="Last Name"
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{backgroundColor: '#E8EDF2'}}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      //width="80%"
                      sx={{backgroundColor: 'none'}}
                      alignItems={'center'}
                    >
                      <Typography sx={{fontSize: '80%'}}>
                        Enter Location*
                      </Typography>

                      {/* @ts-ignore */}
                      <MapAutocomplete
                        addressString={addressString}
                        onChange={e => {
                          setAddressString(e.target.value);
                          // handleChange(e);
                          if (addressSelectedRef.current) {
                            addressSelectedRef.current = false;
                            setFieldValue('address', '');
                          }
                        }}
                        updateAddressFromAutocomplete={(data: any) => {
                          addressSelectedRef.current = true;
                          setFieldValue('address', data.formatted_address);

                          updateAddressFromAutocomplete(data);
                        }}
                        name="address"
                        size="small"
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    alignItems="center"
                    //justifyContent={'center'}
                    sx={{backgroundColor: 'none', mt: -6}}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{backgroundColor: 'none'}}
                      spacing={2}
                    >
                      {/* <Grid item container xs={12} >*/}
                      <Grid item xs={6}>
                        <Typography sx={{fontSize: '80%'}}>
                          Phone Number*
                        </Typography>
                        <TextField
                          error={Boolean(errors.phone_number)}
                          helperText={errors.phone_number}
                          //placeholder="Phone Number "
                          name="phone_number"
                          size="small"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          fullWidth
                          sx={{backgroundColor: '#E8EDF2'}}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{fontSize: '80%'}}>Email*</Typography>
                        <TextField
                          error={Boolean(errors.email)}
                          helperText={errors.email}
                          //placeholder="Email"
                          size="small"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{shrink: false}}
                          sx={{backgroundColor: '#E8EDF2'}}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      sx={{paddingTop: '18px'}}
                    >
                      <Grid item xs={6}>
                        <Typography sx={{fontSize: '80%'}}>
                          Lead Source*
                        </Typography>
                        <FormControl
                          fullWidth
                          error={Boolean(errors.lead_source)}
                        >
                          <Select
                            placeholder="Lead Source"
                            onChange={handleChange}
                            labelId="demo-simple-select-label"
                            size="small"
                            id="demo-simple-select"
                            name="lead_source"
                            sx={{backgroundColor: '#E8EDF2'}}
                            defaultValue={values.lead_source}
                          >
                            <MenuItem value={'Direct'}>Direct</MenuItem>
                            <MenuItem value={'Referral'}>Referral</MenuItem>
                            <MenuItem value={'Other offline medium'}>
                              Other offline medium
                            </MenuItem>
                            <MenuItem value={'Facebook'}>Facebook</MenuItem>
                            <MenuItem value={'Twitter'}>Twitter</MenuItem>

                            <MenuItem value={'Other social medium'}>
                              Other social medium
                            </MenuItem>
                          </Select>
                          {errors.lead_source && (
                            <FormHelperText>
                              {errors.lead_source}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{paddingTop: '18px', paddingBottom: '18px'}}
                    >
                      <Grid item xs={4}>
                        <LoadingButton
                          loading={creatingLead}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{
                            //
                            width: '100%',
                            '& .MuiLoadingButton-loadingIndicator': {
                              position: 'initial',
                              mx: 0.5,
                            },
                          }}
                          loadingPosition="start"
                        >
                          Create{' '}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={4}
                    sx={{backgroundColor: 'none'}}
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs={11} sx={{minWidth: '100%'}} width="100%">
                      <Map
                        latlng={addressLatLng}
                        handleClick={cords => {
                          handleMapClick(cords, setFieldValue);
                        }}
                        center={center}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>

        {/* </CardContent> */}
        {/* {{creatingLead} && <CircularProgress />} */}
      </DialogContent>
    </Dialog>
  );
}

const CreateLeadContainer = GoogleApiWrapper({
  apiKey: String(process.env.REACT_APP_GMAPS_KEY),
  //@ts-ignore
  options: {mapTypeId: 'satellite', componentRestrictions: {country: ['US']}},
})(CreateLead);

export default CreateLeadContainer;
