import react, {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import appService from '../../core/service/app.service';
import {RootState} from '../../core/redux/store';
import {useSelector} from 'react-redux';
import {IActionOutcome, IOutcomes} from './__types__/ActionOutcome';
import {Button, Grid, IconButton, MenuItem, Select} from '@mui/material';
import DraggableList from './DraggableList';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteDialogOrg from '../Organisation/DeleteDialogOrg';
import AddAction from './AddAction';
import {Box} from '@mui/system';
import {IEvent} from './__types__/Event';
import CirularProgress from '../../components/CirularProgress';

const items1 = [
  {
    id: '1',
    content: 'item 1',
  },
  {
    id: '2',
    content: 'item 2',
  },
  {
    id: '3',
    content: 'item 3',
  },
  {
    id: '4',
    content: 'item 4',
  },
];

function Actions() {
  const user = useSelector((state: RootState) => state.appState.user);

  const [actions, setActions] = useState<null | IOutcomes[]>(null);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [addActionOpen, setAddActionOpen] = useState(false);

  const [deleteWF, setDeleteWF] = useState<null | string>(null);

  const [events, setEvents] = useState<IEvent[] | null>(null);

  const [event, setEvent] = useState<null | string>(null);

  useEffect(() => {
    const orgId = user?.arka?.orgId;

    if (!orgId) return;
    appService
      .getWorkFlows()
      .then(res => {
        console.log(res);

        let result: IActionOutcome[] = res.message;

        result = result.filter(x => x.outcome !== null && x.action !== null);
        const actionIds: any[] = [
          ...new Set(result.map(item => item.actions_ID)),
        ];

        const y: IOutcomes[] = [];
        actionIds.forEach((action_ID: any) => {
          let outcomes = result.filter(
            action => action.actions_ID === action_ID
          );

          const mappedOutcomes: any[] = outcomes
            .map(outcome => ({
              ID: outcome.ID,
              outcomes_ID: outcome.outcomes_ID,
              subject: outcome.outcome?.subject,
              sequence: outcome.sequence,
              org_ID: outcome.org_ID,
              system: outcome.outcome.system,
            }))
            .sort((a, b) => a.sequence - b.sequence);
          y.push({
            action_ID,
            name: outcomes.length > 0 ? outcomes[0].action?.subject : '',

            event: {
              event_ID: outcomes[0].event_action?.event_ID,
              name: outcomes[0].event_action?.event?.name,
            },

            //@ts-ignore
            outcomes: mappedOutcomes,
          });
        });
        console.log(y);

        setActions(y);
      })
      .catch(err => {
        console.log(err);
      });

    appService
      .getEvents()
      .then(res => {
        console.log(res);

        setEvents(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const changeEventWorkflowMapping = (eventId: string, actionId: string) => {
    appService
      .changeEventWorkflowMapping({eventId, actionId})
      .then(res => {
        console.log(res);
      })
      .catch(err => console.log(err));
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any, action_ID: string) => {
    // dropped outside the list

    if (!result.destination) {
      return;
    }
    const action = actions?.find(action => action.action_ID === action_ID);

    console.log(result, action_ID, action);
    if (!action) return;
    const tempOutcomes = reorder(
      [...action.outcomes],
      result.source.index,
      result.destination.index
    );
    action.outcomes = tempOutcomes;
    //@ts-ignore
    setActions([...actions]);

    console.log(actions);

    const body = {
      source: {
        actionId: result.source.droppableId,
        sequence: result.source.index + 1,
      },
      destination: {
        actionId: result.destination.droppableId,
        sequence: result.destination.index + 1,
      },
      draggableId: result.draggableId,
    };

    console.log(body);

    appService
      .outcomeSequenceChange(body)
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };

  const addNewAction = (newActionOutcome: IOutcomes) => {
    if (!actions) setActions([{...newActionOutcome}]);
    else setActions([...actions, {...newActionOutcome}]);

    const data = newActionOutcome.outcomes.map(outcome => ({
      outcomes_ID: outcome.outcomes_ID,
      actions_ID: newActionOutcome.action_ID,
      org_ID: outcome.org_ID,
      sequence: outcome.sequence,
    }));

    appService
      .createWorkflow(data)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
    //@ts-ignore
    console.log([...actions, {...newActionOutcome}]);
  };

  const deleteAction = () => {
    if (!actions) return;

    const tempWFs = actions.filter(action => action.action_ID !== deleteWF);
    setActions(tempWFs);
    setDeleteOpen(false);

    appService
      .deleteWorkflow(deleteWF)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Grid container spacing={2}>
      {events && actions ? (
        <>
          <AddAction
            open={addActionOpen}
            handleClose={() => {
              setAddActionOpen(false);
            }}
            onAdd={addNewAction}
          />
          <DeleteDialogOrg
            open={deleteOpen}
            handleClose={() => setDeleteOpen(false)}
            handleDeleteOrgId={deleteAction}
            info={'Are you sure you want to delete this Workflow?'}
          />

          <Grid item xs={12}>
            {' '}
            <Button
              onClick={() => {
                setAddActionOpen(true);
              }}
              variant={'contained'}
            >
              {' '}
              Add New WorkFlow{' '}
            </Button>
          </Grid>
          {actions?.map(action => {
            return (
              <Grid item xs={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon titleAccess="expand workflow" />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Grid container alignItems={'center'} spacing={1}>
                      <Grid item container xs={11} spacing={0.5}>
                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={3}>
                            {' '}
                            <Typography>Action:</Typography>
                          </Grid>

                          <Grid item xs={9}>
                            {' '}
                            <Typography>{action.name}</Typography>
                          </Grid>
                        </Grid>

                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={3}>
                            {' '}
                            <Typography>Event:</Typography>
                          </Grid>

                          <Grid item xs={9}>
                            {' '}
                            <Select
                              value={action.event?.event_ID}
                              onChange={e => {
                                e.stopPropagation();
                                setEvent(e.target.value);

                                const act = actions.find(
                                  item => item.action_ID === action.action_ID
                                );

                                //@ts-ignore

                                act.event.event_ID = e.target.value;

                                setActions([...actions]);

                                changeEventWorkflowMapping(
                                  e.target.value,
                                  action.action_ID
                                );
                              }}
                              size="small"
                              fullWidth
                              // sx={{minWidth: 150}}
                            >
                              {events.map(event => (
                                <MenuItem
                                  value={event.event_ID}
                                  onClick={e => e.stopPropagation()}
                                >
                                  {event.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            setDeleteWF(action.action_ID);
                            setDeleteOpen(true);
                          }}
                          title="delete workflow"
                        >
                          {' '}
                          <DeleteOutlineIcon />{' '}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{height: 400, overflow: 'auto'}}>
                    <DraggableList
                      items={action.outcomes}
                      onDragEnd={onDragEnd}
                      actionID={action.action_ID}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </>
      ) : (
        <CirularProgress />
      )}
    </Grid>
  );
}

export default Actions;
