import {Box} from '@mui/material';
import React, {useState} from 'react';
import appService from '../../../core/service/app.service';
import CreateQuoteDialog from './CreateQuote';
import OrderPaymentInstallments from './OrderPaymentInstallments';
import {useSnackbar} from 'notistack';
import {getUser} from '../../../core/redux/initialState';

const createPageMapping = {
  productDetails: 'Product Details',
  installmentDetails: 'Payment Details',
};
const formModes = {
  pv: 'Conventional PV',
  pg: 'Gazebo',
};
const CreateProposal = ({
  productCategoryData,
  projectId,
  handleCloseDialog,
  updateBackdropLoading,
  paymentTemplateId,
  existingQuotes,
}) => {
  const [currentPage, setCurrentPage] = useState(
    createPageMapping.productDetails
  );
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [proposalData, setProposalData] = useState();

  const [totalQuoteCost, setTotalQuoteCost] = useState();
  const [isPreliminary, setIsPreliminary] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState();
  const [selectedFormMode, setSelectedFormMode] = useState();

  const handleMoveToInstallments = (
    data,
    uploadFiles,
    isPreliminary,
    formMode
  ) => {
    console.log(data);
    let total = 0;
    if (formMode === formModes.pg) {
      console.log(
        data.reduce((sum, item) => {
          let totalAfterDiscountAndTax =
            item.amount +
            (item.tax ? item.tax * item.amount : 0) -
            (item.discount ? item.discount * item.amount : 0);
          return sum + totalAfterDiscountAndTax;
        }, 0)
      );
      total = data.reduce((sum, item) => {
        let totalAfterDiscountAndTax =
          item.amount +
          (item.tax ? item.tax * item.amount : 0) -
          (item.discount ? item.discount * item.amount : 0);
        return sum + totalAfterDiscountAndTax;
      }, 0);
    } else {
      total = data.totalCost;
    }
    setTotalQuoteCost(total);
    setProposalData(data);
    setCurrentPage(createPageMapping.installmentDetails);
    if (uploadFiles) {
      setUploadedFiles(uploadFiles);
    }
    setIsPreliminary(isPreliminary);
    setSelectedFormMode(formMode);
  };

  const handleMoveBackToProposal = () => {
    setCurrentPage(createPageMapping.productDetails);
  };

  const handleCreateProposal = async data => {
    updateBackdropLoading();

    try {
      console.log({data, proposalData});
      // setLoading(true);
      // enqueueSnackbar('Creating Quote');
      const formData = new FormData();

      formData.append('projectId', projectId);
      formData.append('isPreliminary', isPreliminary);
      formData.append('data', JSON.stringify(proposalData));
      formData.append('installments', JSON.stringify(data));
      if (selectedFormMode === formModes.pv) {
        formData.append('is_tsl_response', true);
        formData.append('tsl_token', getUser().tsl.token);
      } else {
        if (uploadedFiles) {
          formData.append('file', uploadedFiles);
          formData.append('is_tsl_response', false);
        }
      }
      console.log({formData});

      const createOrderData = await appService.createQuote(formData);
      // updateBackdropLoading();
      // setCurrentPage(createPageMapping.productDetails);
      // if (createOrderData.success) {
      //   //     setForms([]);
      //   //     setUploadFiles([]);
      //   //     setFiles([]);
      //   //     setFilesSrc([]);
      //   enqueueSnackbar('Quote Created Successfully', {variant: 'success'});
      // } else {
      //   updateBackdropLoading();

      //   enqueueSnackbar('Error occurred while creating quote', {
      //     variant: 'error',
      //   });
      // }
      handleCloseDialog(true);
    } catch (err) {
      handleCloseDialog(true);
      console.log(err);
      updateBackdropLoading();
      enqueueSnackbar('Error occurred while updating quote', {
        variant: 'error',
      });
    }
    // console.log({proposalData, data});
  };

  return (
    <Box sx={{p: 2}}>


      {currentPage === createPageMapping.productDetails ? (
        <CreateQuoteDialog
          //   columnMapping={columnMapping}
          projectId={projectId}
          handleMoveToInstallments={handleMoveToInstallments}
          formModes={formModes}
          existingQuotes={existingQuotes}
        />
      ) : // <ProductDetails productCategoryData={productCategoryData}
      currentPage === createPageMapping.installmentDetails ? (
        <OrderPaymentInstallments
          handleMoveBackToProposal={handleMoveBackToProposal}
          handleCreateProposal={handleCreateProposal}
          totalQuoteCost={totalQuoteCost}
          paymentTemplateId={paymentTemplateId}
        />
      ) : (
        <div>Invalid Page</div>
      )}
    </Box>
  );
};

export default CreateProposal;
