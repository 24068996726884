import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect, useState} from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ToggleViewButton from '../../ActivityOverview/ToggleViewButton';
import organisationService from '../../../core/service/organisation.service';
import {DateTime} from 'luxon';
import appService from '../../../core/service/app.service';
import {useSelector} from 'react-redux';
import {selectDashboardDataAdapter} from '../../../core/redux/AppSlice';
import {all} from 'bluebird';
import {set} from 'date-fns';
import {ConnectedGroupsHelper} from '../../../ganttsource/codebase/sources/dhtmlxgantt';
import DatePicker from '../../../components/DatePicker/DatePicker';
const Toolbar = props => {
  console.log(props);
  const zoomlevel = ['Hours', 'Days', 'Months', 'Years'];
  //Date Range Filter
  const dateRanges = {
    day: 'Today',
    week: 'This week',
    month: 'This month',
    quarter: 'This quarter',
    year: 'This year',
    custom: 'Custom',
  };

  //Task Gantt color coding data
  const statusTypes = {
    toDo: 'To do',

    inProgress: 'In-progress',

    complete: 'Complete',
  };

  const taskStatu = {
    all: 'All',
    toDo: 'To do',

    inProgress: 'In-progress',

    complete: 'Complete',
  };

  const maxAvatars = 4;
  const [selectedzoom, setSelectedZoom] = useState('Days');
  const [zoomscale, setZoomScale] = useState(1);

  const [allActivityOwners, setAllActivityOwners] = useState();
  const [users, setUsers] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [task, setTask] = useState(props.task);
  const [dateRange, setDateRange] = useState(dateRanges.month);
  const [startDate, setStartDate] = useState(
    DateTime.fromISO('2022-10-31T10:00:00')
  );
  const [endDate, setEndDate] = useState(DateTime.now());
  const [myTasks, setMyTasks] = useState(true);
  const user = useSelector(state => state.appState.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [taskStatus, setTaskStatus] = useState(taskStatu.all);

  const handleColor = (status, endDate) => {
    const now = new Date().toISOString();

    if (status === statusTypes.complete) {
      return '#00C853';
    } else if (now > endDate) {
      return '#FF0000';
    } else if (status === statusTypes.toDo) {
      return '#ffc35e';
    } else if (status === statusTypes.inProgress) {
      return '#409eff';
    } else {
      return '#ffc35e';
    }
  };

  useEffect(() => {
    setTask(props.task);
  }, [props]);

  const get360Users = () => {
    // setLoading(true);

    organisationService
      .getOrgUsers()
      .then(res => {
        // setLoading(false);
        console.log(res);
        setUsers(res.message);
      })
      .catch(err => {
        // setLoading(false);
        console.log(err);
      });
  };

  const handleGetAllUsers = async () => {
    // ~~~TSL~~~
    // let users = [];
    // try {
    //   let data = await getTslUsers();
    //   users = [...users, ...data.results];
    //   while (data.next) {
    //     data = await getTslUsers(data.next);
    //     users = [...users, ...data.results];
    //   }
    //   setUsers(users);
    // } catch (err) {}

    get360Users();
  };

  useEffect(() => {
    handleGetAllUsers();
  }, []);

  const generateApiUrlWithFilters = (
    start,
    end,
    projectId,
    status,
    selectuser
  ) => {
    let ownerFilters = '';
    let statusFilters = '';

    if (selectuser && selectuser.length > 0) {
      selectuser.map(item => (ownerFilters += '&owner_ID=' + item.user_ID));
    }

    let queryString = props.projectId
      ? `/api/task/projectwise/all?startDate=${start.toUTC()}&endDate=${end.toUTC()}&project_ID=${
          props.projectId
        }`
      : `/api/task/projectwise/all?startDate=${start.toUTC()}&endDate=${end.toUTC()}`;

    if (selectuser && selectuser.length > 0) {
      selectuser.map(item => (ownerFilters += '&owner_ID=' + item.user_ID));
      queryString = queryString + ownerFilters;
    }
    if (status && status !== 'All') {
      statusFilters += '&status=' + status;

      queryString = queryString + statusFilters;
    }

    return queryString;
  };

  const getActivitiesData = (urlFlag, data) => {
    // enqueueSnackbar('Fetching activity data');
    // setLoading(true);
    const projectId = props.projectId;
    appService
      .getTasks(data)
      .then(res => {
        console.log(res);
        let tasks = res.message.rows;
        console.log(tasks);
        let datas = [];
        tasks.map((ts, index) => {
          const activities = ts.activities;
          if (index === 0) ts.open = true;
          else ts.open = false;
          ts.parent = null;
          ts.id = ts.project_ID;
          activities.map(act => {
            act.open = false;
            act.open = false;
            act.id = act.activity_ID;
            act.parent =
              act.parent && (act.parent !== 0 || act.parent !== '0')
                ? act.parent
                : ts.project_ID;
          });
          activities.push(ts);

          activities.map(data => {
            data.color = handleColor(data.status, data.end_date);

            const startdate = new Date(data.start_date);
            const enddate = new Date(data.end_date);
            data.owner_name = users.filter(
              us => us.user_ID === data.owner_ID
            )[0]
              ? users.filter(us => us.user_ID === data.owner_ID)[0].fullName
              : 'NA';

            data.text = data.name;

            data.start_date =
              startdate.getFullYear() +
              '-' +
              Number(startdate.getMonth() + 1) +
              '-' +
              startdate.getDate();

            if (data.end_date) {
              data.end_date =
                enddate.getFullYear() +
                '-' +
                Number(enddate.getMonth() + 1) +
                '-' +
                enddate.getDate();
            }
          });
          datas = [...datas, ...activities];

          // ts.color = handleColor(ts.status, ts.end_date);
        });
        // res.message.rows[0].id = projectId;
        // tasks.push(res.message.rows[0]);

        //@ts-ignore
        // tasks = tasks.sort((a, b) => a.sequence - b.sequence);
        // tasks.map((d, index) => {
        //   const startdate = new Date(d.start_date);
        //   const enddate = new Date(d.end_date);
        //   d.text = d.name;
        //   d.start_date =
        //     startdate.getFullYear() +
        //     '-' +
        //     Number(startdate.getMonth() + 1) +
        //     '-' +
        //     startdate.getDate();

        //   if (d.end_date) {
        //     d.end_date =
        //       enddate.getFullYear() +
        //       '-' +
        //       Number(enddate.getMonth() + 1) +
        //       '-' +
        //       enddate.getDate();
        //   }
        // });
        console.log(datas);

        props.setLoading(false);

        props.setTaskDatas(datas);
        // props.setSelectedTask(tasks);
        // enqueueSnackbar('Success', {variant: 'success'});
      })
      .catch(err => {
        console.log(err);
        // setLoading(false);
      });
  };

  const getActivitiesDatas = (urlFlag, data) => {
    // enqueueSnackbar('Fetching activity data');

    // setLoading(true);
    const projectId = props.projectId;
    appService
      .getTasks(data)
      .then(res => {
        console.log(res);
        let tasks = res.message.rows;
        console.log(tasks);
        let datas = [];
        tasks.map((ts, index) => {
          const activities = ts.activities;
          if (index === 0) ts.open = true;
          else ts.open = false;
          ts.parent = null;
          ts.id = ts.project_ID;
          activities.map(act => {
            act.open = false;
            act.open = false;
            act.id = act.activity_ID;
            // act.parent =
            // act.parent && (act.parent !== 0 || act.parent !== '0')
            //   ? act.parent
            //   : ts.project_ID;
          });
          // activities.push(ts);

          console.log(activities);

          activities.map(data => {
            data.color = handleColor(data.status, data.end_date);

            const startdate = new Date(data.start_date);
            const enddate = new Date(data.end_date);
            data.owner_name = users.filter(
              us => us.user_ID === data.owner_ID
            )[0]
              ? users.filter(us => us.user_ID === data.owner_ID)[0].fullName
              : 'NA';

            data.text = data.name;

            data.start_date =
              startdate.getFullYear() +
              '-' +
              Number(startdate.getMonth() + 1) +
              '-' +
              startdate.getDate();

            if (data.end_date) {
              data.end_date =
                enddate.getFullYear() +
                '-' +
                Number(enddate.getMonth() + 1) +
                '-' +
                enddate.getDate();
            }
            data.readonly = data.id === data.project_ID ? true : false;
          });
          datas = [...datas, ...activities];

          // ts.color = handleColor(ts.status, ts.end_date);
        });
        // res.message.rows[0].id = projectId;
        // tasks.push(res.message.rows[0]);

        //@ts-ignore
        // tasks = tasks.sort((a, b) => a.sequence - b.sequence);
        // tasks.map((d, index) => {
        //   const startdate = new Date(d.start_date);
        //   const enddate = new Date(d.end_date);
        //   d.text = d.name;
        //   d.start_date =
        //     startdate.getFullYear() +
        //     '-' +
        //     Number(startdate.getMonth() + 1) +
        //     '-' +
        //     startdate.getDate();

        //   if (d.end_date) {
        //     d.end_date =
        //       enddate.getFullYear() +
        //       '-' +
        //       Number(enddate.getMonth() + 1) +
        //       '-' +
        //       enddate.getDate();
        //   }
        // });
        console.log(datas);
        props.setLoading(false);

        props.setTaskDatas(datas);
        // props.setSelectedTask(tasks);
        // enqueueSnackbar('Success', {variant: 'success'});
      })
      .catch(err => {
        console.log(err);
        // setLoading(false);
      });
  };

  useEffect(() => {
    // if (startDate && endDate && users)
    // getActivitiesData(false, generateApiUrlWithFilters(startDate, endDate));
  }, [selectedUsers, users]);

  const handleDateRangeChange = e => {
    console.log(e);
    setDateRange(e);

    let start;
    let end = DateTime.now();
    console.log(e);

    if (e === dateRanges.day) {
      start = DateTime.now().startOf('day');
    } else if (e === dateRanges.week) {
      start = DateTime.now().startOf('week');
    } else if (e === dateRanges.month) {
      start = DateTime.now().startOf('month');
    } else if (e === dateRanges.quarter) {
      start = DateTime.now().startOf('quarter');
    } else if (e === dateRanges.year) {
      start = DateTime.now().startOf('year');
    } else if (e === dateRanges.custom) {
      //handle soon
    }

    setStartDate(start);
    setEndDate(end);
    console.log(start);
    console.log(end);

    // getActivitiesData(false, generateApiUrlWithFilters(start, end));
  };

  const handleStatusChange = e => {
    setTaskStatus(e.target.value);
    const status = e.target.value;
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };
  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleGo = () => {
    console.log(startDate);
    console.log('go');
    props.projectId
      ? getActivitiesDatas(
          false,
          generateApiUrlWithFilters(
            startDate,
            endDate,
            props.projectId,
            taskStatus,
            selectedUsers
          )
        )
      : getActivitiesData(
          false,
          generateApiUrlWithFilters(
            startDate,
            endDate,
            props.projectId,
            taskStatus,
            selectedUsers
          )
        );
    props.setLoading(true);
  };

  useEffect(() => {
    console.log(task);
    let ownerIds = [];
    if (task && task.length > 0 && users && users.length > 0) {
      task.map(item => {
        ownerIds.push(item.owner_ID);
      });
      console.log({ownerIds});
      let uniqueOwnerIds = [...new Set(ownerIds)];
      console.log({uniqueOwnerIds});

      const relevantUsers = [];

      if (uniqueOwnerIds.length > 0) {
        uniqueOwnerIds.map(uOwner => {
          let temp = users.find(usr => usr.user_ID === uOwner);
          if (temp) relevantUsers.push(temp);
        });
      }
      console.log(relevantUsers);
      setAllActivityOwners(relevantUsers);
    }
  }, [task, users]);

  const handleZoomChange = e => {
    if (props.onZoomChange) {
      props.onZoomChange(e.target.value);
    }
  };
  const handleZoomIn = () => {
    console.log(zoomscale);
    if (zoomscale < zoomlevel.length - 1) {
      setZoomScale(zoomscale + 1);
    }
    if (props.onZoomChange && zoomscale < zoomlevel.length - 1) {
      props.onZoomChange(zoomlevel[zoomscale + 1]);
    }
  };
  const handleZoomOut = () => {
    console.log(zoomscale);
    if (zoomscale >= 1) {
      setZoomScale(zoomscale - 1);
    }
    if (props.onZoomChange && zoomscale >= 1) {
      props.onZoomChange(zoomlevel[zoomscale - 1]);
    }
  };
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMyTasks = () => {
    console.log('my task');
    let modifiedUser = {
      ...user.arka,
      user_ID: user.arka.user_Id,
    };
    selectUser(modifiedUser);
    setMyTasks(!myTasks);
  };
  // const handleShowMyTasks = () => {
  //   let modifiedUser = {
  //     ...user.arka,
  //     user_ID: user.arka.user_Id,
  //   };
  //   selectUser(modifiedUser);

  //   setMyTasks(!myTasks);
  // };

  const selectUser = user => {
    if (users.length > 0) {
      console.log(selectedUsers);

      let match = selectedUsers.find(item => item.user_ID === user.user_ID);
      console.log(selectedUsers);
      console.log(match);
      if (match) {
        setSelectedUsers(
          selectedUsers.filter(item => item.user_ID !== user.user_ID)
        );
      } else {
        // @ts-ignore
        setSelectedUsers([...selectedUsers, user]);
      }
    }
  };

  const resetFilters = () => {
    props.setLoading(true);

    setSelectedUsers([]);
    const start = DateTime.fromISO('2022-10-31T10:00:00');

    const end = DateTime.now();
    setDateRange(dateRanges.month);
    handleDateRangeChange(dateRanges.month);
    setTaskStatus(taskStatu.all);
    setMyTasks(true);

    props.projectId
      ? getActivitiesDatas(
          false,
          generateApiUrlWithFilters(start, end, props.projectId)
        )
      : getActivitiesData(false, generateApiUrlWithFilters(start, end));

    // getActivitiesData(false, generateApiUrlWithFilters(start, end));
  };

  console.log();

  return (
    <Box className="tool-bar">
      <Grid container xs={12}>
        {/* user filters and data filter*/}
        <Grid container alignItems={'center'} xs={8}>
          <Grid item sx={{mr: 1}}>
            <Typography variant="subtitle1">Tasks</Typography>
          </Grid>
          {/* User Filters with Avatar */}
          <Grid>
            {/* The users filter with avatars */}
            {allActivityOwners && (
              <Grid item sx={{mr: 1}}>
                <Popover
                  id={id}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  size="small"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <FormGroup sx={{px: 2, py: 1}}>
                    {console.log(allActivityOwners)}
                    {allActivityOwners.map((item, index) => {
                      if (index >= maxAvatars) {
                        return (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={() => selectUser(item)}
                                  checked={selectedUsers.find(
                                    su => su.user_ID === item.user_ID
                                  )}
                                />
                              }
                              label={item.fullName}
                            />
                          </>
                        );
                      }
                    })}
                  </FormGroup>
                </Popover>
                <AvatarGroup
                  disabled
                  max={maxAvatars}
                  onClick={e => {
                    setAnchorEl(e.currentTarget);
                    console.log('selectRestOfUsers()');
                  }}
                >
                  {allActivityOwners &&
                    allActivityOwners.length > 0 &&
                    allActivityOwners.map(item => {
                      const fullName = item.fullName;
                      const nameParts = fullName.split(' ');
                      let initials =
                        nameParts && nameParts[0][0]
                          ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
                          : nameParts[1][0] +
                            nameParts[nameParts.length - 1][0];

                      return (
                        <Tooltip title={fullName}>
                          <Avatar
                            sx={{
                              fontWeight: '0.8rem',
                              bgcolor: '#1c3366',
                              zIndex: 0,
                              transition: 'all 0.5s, ease-in-out',
                              cursor: 'pointer',
                              border: selectedUsers.find(
                                su => su.user_ID === item.user_ID
                              )
                                ? '2px solid red !important'
                                : '',
                              '&:hover': {
                                zIndex: 1,
                                top: -8,
                                border: '2px solid red',
                              },
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              selectUser(item);

                              console.log(item);
                            }}
                          >
                            <Typography variant={'body1'} color="white">
                              {initials}
                            </Typography>
                          </Avatar>
                        </Tooltip>
                      );
                    })}
                </AvatarGroup>{' '}
              </Grid>
            )}
          </Grid>
          {users && (
            <Grid item>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                sx={{height: 1, mr: 1}}
              >
                {myTasks ? (
                  <Button
                    color="secondary"
                    size="medium"
                    variant="contained"
                    onClick={showMyTasks}
                  >
                    My Tasks
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    size="medium"
                    variant="contained"
                    onClick={showMyTasks}
                  >
                    All Tasks
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {/* Date range select */}
          <Grid item sx={{mr: 1}}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
              <Select
                value={dateRange}
                label="Date Range"
                onChange={e => handleDateRangeChange(e.target.value)}
                size="small"
                sx={{background: 'white'}}
              >
                {Object.keys(dateRanges).map(item => (
                  <MenuItem value={dateRanges[item]}>
                    {dateRanges[item]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{' '}
          </Grid>

          {/* Status filter */}
          <Grid item sx={{mr: 1}}>
            <FormControl>
              <InputLabel id="demo-simple-select-label" sx={{minWidth: '50px'}}>
                Task Status
              </InputLabel>
              <Select
                value={taskStatus}
                label="Task Status  "
                onChange={handleStatusChange}
                size="small"
                fullWidth
                sx={{
                  background: 'white',
                  minWidth: '5.5rem',
                }}
              >
                {Object.keys(taskStatu).map(item => (
                  <MenuItem value={taskStatu[item]}>{taskStatu[item]}</MenuItem>
                ))}
              </Select>
            </FormControl>{' '}
          </Grid>
          {/* Go Button */}
          <Grid item sx={{mr: 1}}>
            <Button
              color="secondary"
              size="medium"
              variant="contained"
              onClick={handleGo}
            >
              Go
            </Button>
          </Grid>

          {/* Clear All button */}
          <Grid item sx={{mr: 1}}>
            <Button sx={{color: 'black'}} onClick={resetFilters}>
              Clear All
            </Button>
          </Grid>
        </Grid>
        {/* zoom and view filters */}
        <Grid container xs={4} justifyContent={'right'}>
          {/* Zoom In Zoom out Filters */}
          {props.ganttView && (
            <Grid>
              <IconButton
                onClick={handleZoomOut}
                sx={{border: '1px solid grey ', borderRadius: 0}}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                onClick={handleZoomIn}
                sx={{border: '1px solid grey ', borderRadius: 0}}
              >
                <ZoomOutIcon />
              </IconButton>
            </Grid>
          )}
          {/* 
          <Grid
            item
            sx={{height: '50px'}}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            {' '}
            <ToggleViewButton
              ganttView={props.ganttView}
              setGanttView={props.setGanttView}
            />
          </Grid> */}
        </Grid>
        {dateRange === dateRanges.custom && (
          <Grid container sx={{ml: 2}} xs={6} justifyContent={'right'}>
            <Grid item>
              <DatePicker
                label={'From'}
                date={startDate}
                before={endDate}
                setDate={handleStartDateChange}
              />
            </Grid>
            <Grid item sx={{ml: 1}}>
              <DatePicker
                label="To"
                date={endDate}
                after={startDate}
                setDate={handleEndDateChange}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Toolbar;
