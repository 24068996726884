/* eslint-disable react-hooks/exhaustive-deps */
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import React, {FC, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {
  TextField,
  OutlinedTextFieldProps,
  TextFieldProps,
  BaseTextFieldProps,
} from '@mui/material';

type IMapAutocomplete = {
  addressString: string;
  setAddressString?: any;
  updateAddressFromAutocomplete: any;
  google: any;
} & TextFieldProps;

function MapAutocomplete({
  google,
  updateAddressFromAutocomplete,
  addressString,
  ...rest
}: IMapAutocomplete) {
  let autocomplete: any;

  useEffect(() => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        types: ['geocode'],
        componentRestrictions: {
          country: ['us', 'in'],
        },
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      updateAddressFromAutocomplete(autocomplete.getPlace());
    });
  }, []);

  return (
    <Grid container style={{width: '100%'}}>
      <TextField
        id="autocomplete"
        variant="outlined"
        color="primary"
        value={addressString ? addressString : ''}
        fullWidth
        size="small"
        sx={{backgroundColor: '#E8EDF2'}}
        {...rest}
      />
    </Grid>
  );
}

const WrappedContainer = GoogleApiWrapper({
  apiKey: String(process.env.REACT_APP_GMAPS_KEY),
  //@ts-ignore
  options: {mapTypeId: 'satellite', componentRestrictions: {country: ['US']}},
})(MapAutocomplete);

export default WrappedContainer;
