import React, {useEffect, useState} from 'react';
import appService from '../../core/service/app.service';
import {IAction} from './__types__/Action';
import {IOutcome} from './__types__/Outcome';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button, Grid, MenuItem, Select, Typography} from '@mui/material';
import OutcomesTable from './OutcomesTable';
import DraggableList from './DraggableList';
import {IFactOutcome, IOutcomes} from './__types__/ActionOutcome';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';

interface IAddAction {
  open: boolean;
  handleClose: any;

  onAdd: any;
}

function AddAction({open, handleClose, onAdd}: IAddAction) {
  const [actions, setActions] = useState<IAction[]>([]);

  const [action, setAction] = useState<IAction | null>();

  const [outcomes, setOutcomes] = useState<IOutcome[]>([]);

  const [openOutcomes, setOpenOutcomes] = useState(false);

  const [selectedOutcomes, setSelectedOutcomes] = useState<IFactOutcome[]>([]);
  const user = useSelector((state: RootState) => state.appState.user);

  useEffect(() => {
    appService
      .getActions()
      .then(res => {
        console.log(res);
        setActions(res.message);
      })
      .catch(err => {
        console.log(err);
      });

    appService
      .getOutcomes()
      .then(res => {
        console.log(res);
        setOutcomes(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any, action_ID: string) => {
    // dropped outside the list

    if (!result.destination) {
      return;
    }

    const tempOutcomes = reorder(
      [...selectedOutcomes],
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setSelectedOutcomes(tempOutcomes);
  };

  const handleActionSelect = (e: any) => {
    const actionId = e.target.value;

    console.log(actionId);

    const action = actions.find(action => action.actions_ID === actionId);

    setAction(action);
  };

  const handleOutcomeSelect = (outcomeIds: string[]) => {
    const orgId = user?.arka?.orgId;

    const x: IFactOutcome[] = outcomes
      .filter(outcome => outcomeIds.includes(outcome.outcomes_ID))
      .map((outcome, index) => ({
        ...outcome,
        sequence: index + 1,
        org_ID: orgId,
      }));

    setOpenOutcomes(false);
    console.log(x);
    setSelectedOutcomes(x);
  };

  const clear = () => {
    setSelectedOutcomes([]);
    setAction(null);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Action</DialogTitle>
      <DialogContent>
        <OutcomesTable
          outcomes={outcomes}
          open={openOutcomes}
          handleClose={() => {
            setOpenOutcomes(false);
          }}
          handleOutcomeSelect={handleOutcomeSelect}
        />
        <Grid container flexDirection={'column'}>
          <Grid item xs={12}>
            <Typography> Action</Typography>{' '}
          </Grid>

          <Grid item xs={5}>
            <Select onChange={handleActionSelect} fullWidth>
              {actions.map(action => (
                <MenuItem value={action.actions_ID}>{action.subject}</MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item my={1}>
            <Button
              onClick={() => {
                setOpenOutcomes(true);
              }}
              variant="contained"
              disabled={action === null}
            >
              Add Outcomes
            </Button>
          </Grid>

          <Grid item>
            <DraggableList
              items={selectedOutcomes}
              onDragEnd={onDragEnd}
              actionID={action?.actions_ID || ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            clear();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleClose();
            clear();

            if (!action) return;

            const newActionOutcome: IOutcomes = {
              action_ID: action?.actions_ID,
              name: action?.subject,
              outcomes: selectedOutcomes,
            };

            onAdd(newActionOutcome);
          }}
          autoFocus
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAction;
