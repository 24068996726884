import React from 'react';
import {DialogTitle, IconButton} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
const DialogHeading = ({title, handleClose}: any) => {
  return (
    <DialogTitle>
      {title}
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: -3,
        }}
      >
        <CloseRoundedIcon sx={{color: '#1c3366', fontSize: 'inherit'}} />
      </IconButton>
    </DialogTitle>
  );
};

export default DialogHeading;
