import {Button, Grid, ListItem, ListItemText} from '@mui/material';
import React, {useEffect, useState} from 'react';
import appService from '../../core/service/app.service';
import {useSnackbar} from 'notistack';
import Headings from '../../components/Headings';
import {v4 as uuidv4} from 'uuid';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import {DeleteOutline, DragIndicator} from '@mui/icons-material';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Card, IconButton, TextField, Typography} from '@mui/material';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const propertyNameMapping = {
  name: 'name',
  description: 'description',
  duration: 'duration_in_days',
};

const Tasks = ({
  Id,
  stageId,
  stageCategory,
  tasks,
  stages,
  setStages,
  onActivityDelete,
}) => {
  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const addNewTask = () => {
    let stage = stages[stageCategory].find(stage => stage.Id === Id);

    stage.activities = [
      ...stage.activities,
      {Id: uuidv4(), name: '', description: '', duration_in_days: 0},
    ];
    setStages({...stages});

    console.log({...stages});
  };

  const onDragEnd = result => {
    // dropped outside the list

    if (!result.destination) {
      return;
    }

    const items = reorder(
      [...tasks],
      result.source.index,
      result.destination.index
    );

    console.log(result, items);

    const stage = stages[stageCategory].find(
      stage => stage.stage_ID === stageId
    );

    stage.activities = items;
    setStages({...stages});
  };

  const handleUpdateName = (e, propertyName, indexValue) => {
    const tempTasks = tasks.map((item, index) =>
      index === indexValue ? {...item, [propertyName]: e.target.value} : item
    );

    const stage = stages[stageCategory].find(
      stage => stage.stage_ID === stageId
    );

    stage.activities = tempTasks;

    setStages({...stages});
    console.log({...stages});
  };

  const handleDeleteItem = Id => {
    console.log(Id, tasks);
    let oldTask = tasks.find((item, innerIndex) => item.Id === Id);
    let newTasks = tasks.filter((item, innerIndex) => item.Id !== Id);

    const stage = stages[stageCategory].find(
      stage => stage.stage_ID === stageId
    );
    stage.activities = newTasks;

    setStages({...stages});

    if (oldTask?.activity_ID) {
      onActivityDelete(oldTask?.activity_ID);
    }
  };

  return (
    <Grid container sx={{width: 1}}>
      <BackDropCircularProgress open={loading} />

      <Grid item sx={{m: 'auto', mr: 0}}>
        <Button
          onClick={addNewTask}
          size="small"
          variant="contained"
          sx={{ml: 1}}
        >
          Add Task
        </Button>
      </Grid>

      <Grid container sx={{width: 1}}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {/* @ts-ignore */}
                {tasks.map((item, index) => (
                  <Draggable
                    draggableId={String(item.activity_ID)}
                    index={index}
                    key={item.Id}
                  >
                    {(provided, snapshot) => {
                      return (
                        <ListItem
                          sx={{minwidth: 200, py: 0, pl: 0}}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {' '}
                          <ListItemText>
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent="flex-start"
                              alignItems={'center'}
                            >
                              <Grid item container xs={10} alignItems="center">
                                <Grid item xs={1} sx={{mr: 1}}>
                                  <Typography>{`Task no: ${
                                    index + 1
                                  }`}</Typography>
                                </Grid>

                                <Grid item xs={3} sx={{mr: 1}}>
                                  <TextField
                                    label="Name"
                                    value={item[propertyNameMapping.name]}
                                    size="small"
                                    onChange={e =>
                                      handleUpdateName(
                                        e,
                                        propertyNameMapping.name,
                                        index
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={2} sx={{mr: 1}}>
                                  <TextField
                                    label="Duration in days"
                                    value={item[propertyNameMapping.duration]}
                                    size="small"
                                    type="number"
                                    onChange={e =>
                                      handleUpdateName(
                                        e,
                                        propertyNameMapping.duration,
                                        index
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={5} sx={{mr: 1}}>
                                  <TextField
                                    label="Description"
                                    value={
                                      item[propertyNameMapping.description]
                                        ? item[propertyNameMapping.description]
                                        : ''
                                    }
                                    size="small"
                                    onChange={e =>
                                      handleUpdateName(
                                        e,
                                        propertyNameMapping.description,
                                        index
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={2}
                                alignItems="center"
                                justifyContent={'space-between'}
                              >
                                <Grid item>
                                  <IconButton
                                    onClick={() => handleDeleteItem(item.Id)}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <IconButton size="small">
                                    <DragIndicator />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </ListItemText>
                        </ListItem>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>

      {/* <Grid container sx={{width: 1}}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={String(Id)} style={{padding: 0}}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasks &&
                  tasks.length > 0 &&
                  tasks.map((item, index) => (
                    <Draggable draggableId={item.Id} index={index}>
                      <Grid item xs={12} key={item.Id} sx={{py: 1}}>
                        <Card sx={{width: 1}}>
                          {(provided, snapshot) => (
                            <Grid
                              container
                              sx={{width: 1}}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                           
                            </Grid>
                          )}
                        </Card>
                      </Grid>{' '}
                    </Draggable>
                  ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>  */}
    </Grid>
  );
};

export default Tasks;
