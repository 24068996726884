import {Redirect, Route, RouteProps} from 'react-router-dom';

export type ProtectedRouteProps = {
  isAllowed: boolean;
  redirectPath: string;
} & RouteProps;

export default function ProtectedRoute({
  isAllowed,
  redirectPath,
  ...routeProps
}: ProtectedRouteProps) {
  if (isAllowed) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{pathname: redirectPath}} />;
  }
}
