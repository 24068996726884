import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import BackDropCircularProgress from '../../../components/BackDropCircularProgress';
import {appServiceTSL} from '../../../core/service/app.service';
import DesignAccordion from './DesignAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomNoRowsOverlay from '../../../components/NoRowsOverlay';

const CreateProposalFromDesign = ({
  designs,
  handleMoveToInstallments,
  formMode,
  existingQuotes,
}) => {
  const [designReportItems, setDesignReportItems] = useState();
  const [loading, setLoading] = useState(false);

  // const [designsWithoutProposal, setDesignsWithoutProposal] = useState([]);

  const getDesignReportsData = async () => {
    try {
      if (designs && designs.length > 0) {
        setLoading(true);
        let designReports = [];
        console.log({designs, existingQuotes});
        for (let i = 0; i < designs.length; i++) {
          if (
            (existingQuotes &&
              existingQuotes.filter(
                item => parseInt(item.design_ID) === designs[i].id
              ).length === 0) ||
            !existingQuotes
          ) {
            const designData = await appServiceTSL.getDesignReportDataById(
              designs[i].id
            );
            console.log({designData});
            if (designData.status !== 500) {
              let tempBomItems = [];
              designData.components &&
                designData.components.components.map(item => {
                  if (
                    designData.components[item] &&
                    designData.components[item].length > 0
                  ) {
                    designData.components[item].map(comp =>
                      tempBomItems.push({
                        component: item,
                        make: comp[0],
                        quantity: comp[1],
                      })
                    );
                  }
                });

              designReports.push({
                systemSize: designData.system_metrics
                  ? parseFloat(
                      designData.system_metrics['Module DC Nameplate'].split(
                        ' '
                      )[0]
                    )
                  : 0,
                totalCost: designData.cost_after_insentive
                  ? parseFloat(
                      designData.cost_after_insentive.replace(/\,/g, '')
                    )
                  : 0,
                annualEnergyProduction: designData.system_metrics[
                  'Average Monthly Production'
                ]
                  ? parseFloat(
                      designData.system_metrics[
                        'Average Monthly Production'
                      ].replace(/\,/g, '') * 12
                    ).toFixed(2)
                  : 0,
                annualSavings:
                  designData.financial_data &&
                  designData.financial_data.average_monthly_saving
                    ? designData.financial_data.average_monthly_saving * 12
                    : 0,
                reference_id: designData.reference_id
                  ? designData.reference_id
                  : '',
                proposalLink: designData.reference_id
                  ? `https://360.thesolarlabs.com/webProposal/${designData.reference_id}`
                  : '',
                //details for document download

                templateName:
                  designData.report_defaults_data &&
                  designData.report_defaults_data.template_name,
                isLandscape:
                  designData.report_defaults_data &&
                  designData.report_defaults_data.report_type === 'landscape',

                bomItems: tempBomItems,
                ...designs[i],
              });
            }
          }
        }

        setDesignReportItems(designReports);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (designReportItems && existingQuotes)
  //     setDesignsWithoutProposal(
  //       designReportItems.filter(
  //         report =>
  //           existingQuotes.filter(eq => eq.id === report.design_ID).length === 0
  //       )
  //     );
  // }, [existingQuotes, designReportItems]);

  useEffect(() => {
    getDesignReportsData();
  }, [designs]);

  // const updateDesignId = value => {
  //   if (value < 999 || value > 10000000) {
  //     setDesignIdError('Invalid Design Id');
  //   } else {
  //     setDesignIdError(null);
  //   }
  //   getDesignData(value);
  //   setDesignId(value);
  // };

  return (
    <Box sx={{width: 1}}>
      <BackDropCircularProgress open={loading} />
      {designReportItems && designReportItems.length > 0 ? (
        designReportItems.map((item, index) => {
          return (
            <DesignAccordion
              key={index}
              data={item}
              handleMoveToInstallments={handleMoveToInstallments}
              formMode={formMode}
            />
          );
        })
      ) : (
        <CustomNoRowsOverlay text={'No TSL designs available'} />
      )}
    </Box>
  );
};

export default CreateProposalFromDesign;
