import React, {useEffect, useState} from 'react';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {loginRequest} from '../../config/authConfig';

import Grid from '@mui/material/Grid';

import LinearProgress from '@mui/material/LinearProgress';
import {useHistory} from 'react-router';
import loginStyle from './login.style';
import arkaLogo from '../../assets/images/logo.svg';
import {
  Alert,
  AlertColor,
  Button,
  Card,
  formGroupClasses,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ArkaLogo from '../../assets/images/logo.png';
import * as Yup from 'yup';
import {Formik} from 'formik';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {Theme} from '@mui/system';
import {Link} from 'react-router-dom';
import {RootState} from '../../core/redux/store';
import {useDispatch, useSelector} from 'react-redux';
import {setUser} from '../../core/redux/AppSlice';
import CircularProgress from '@mui/material/CircularProgress';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import appService from '../../core/service/app.service';
import envConfig from '../../config/envConfig';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  link: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '0.8rem ',
  },
}));

const ForgotPassword: React.FC<any> = () => {
  const history = useHistory();
  const classes = useStyle();

  const [loading, setLoading] = useState<boolean>(false);

  const [OTPSent, setOTPSent] = useState<boolean>(false);

  const [resendOTP, setResendOTP] = useState<boolean>(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');

  const [alert, setAlert] = useState<null | {
    type: AlertColor;
    message: string;
  }>(null);

  const handlereqOTP = (values: any) => {
    const {email} = values;
    setLoading(true);
    const body = {
      email,
      appId: String(envConfig.env.APPID),
      orgId: String(envConfig.env.ORGID),
    };
    appService
      .sendOTP(body)
      .then(res => {
        console.log(res);

        setOTPSent(true);
        setLoading(false);
        setForgotPasswordEmail(email);
      })
      .catch(err => {
        console.log(err);

        setAlert({type: 'error', message: err || 'Unknown error occured!'});
        setLoading(false);
      });
  };

  const handleupdatePassword = (values: any) => {
    const {password, otp} = values;
    setLoading(true);
    const body = {
      email: forgotPasswordEmail,
      otp: otp,
      appId: String(envConfig.env.APPID),
      orgId: String(envConfig.env.ORGID),

      newPassword: password,
    };
    appService
      .updatePassword(body)
      .then(res => {
        console.log(res);
        setLoading(false);
        setOTPSent(true);
        history.push('/login');
      })
      .catch(err => {
        console.log(err);

        setAlert({type: 'error', message: err || 'Unknown error occured!'});
        setLoading(false);
      });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={11} sm={6} md={6} lg={4}>
        <Card>
          {!OTPSent && (
            <Grid
              container
              justifyContent={'center'}
              flexDirection="column"
              alignItems={'center'}
              px={2}
              py={5}
            >
              <Grid item xs={12} my={1}>
                <img src={ArkaLogo} alt="arkalogo" height="60px" />
              </Grid>

              <Grid item xs={12}>
                <Typography>Please enter your registered email</Typography>
              </Grid>

              <Grid item xs={12} width="100%">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Email must be formatted correctly.')
                      .max(255)
                      .required('Email is required.'),
                  })}
                  onSubmit={handlereqOTP}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                  }) => {
                    return (
                      <form noValidate onSubmit={handleSubmit}>
                        <Grid
                          item
                          xs={12}
                          container
                          flexDirection="column"
                          justifyContent="center"
                          alignItems={'center'}
                        >
                          <Grid item xs={9} width="100%">
                            <TextField
                              error={Boolean(touched.email && errors.email)}
                              fullWidth
                              helperText={touched.email && errors.email}
                              label="Email"
                              margin="normal"
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="email"
                              variant="outlined"
                            />
                          </Grid>

                          {alert && (
                            <Grid item xs={9} width="100%" my={1}>
                              <Alert
                                severity={alert.type}
                                onClose={() => {
                                  setAlert(null);
                                }}
                              >
                                {alert.message}
                              </Alert>
                            </Grid>
                          )}

                          <Grid item xs={6} my={1} width="100%">
                            <LoadingButton
                              loading={loading}
                              variant="contained"
                              color="secondary"
                              type="submit"
                              fullWidth
                            >
                              {' '}
                              Request OTP
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          )}

          {OTPSent && (
            <Grid
              container
              justifyContent={'center'}
              flexDirection="column"
              alignItems={'center'}
              px={2}
              py={5}
            >
              <Grid item xs={12} my={1}>
                <img src={ArkaLogo} alt="arkalogo" height="60px" />
              </Grid>

              <Grid item xs={12}>
                <Typography> Enter OTP and New password</Typography>
              </Grid>

              <Grid item xs={12} width="100%">
                <Formik
                  initialValues={{
                    otp: '',
                    password: '',
                    passwordConfirm: '',
                    visible1: false,
                    visible2: false,
                  }}
                  validationSchema={Yup.object().shape({
                    otp: Yup.number(),
                    password: Yup.string()
                      .required('Please Enter your password')
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        'Minimum 8 characters long. Must contain atleast 1 upper case alphabet, 1 lower case and 1 special character'
                      ),
                    passwordConfirm: Yup.string()
                      .required('Confirm Password is required')
                      .oneOf(
                        [Yup.ref('password')],
                        'Confirm Password should match'
                      ),
                  })}
                  onSubmit={handleupdatePassword}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <form noValidate onSubmit={handleSubmit}>
                        <Grid
                          item
                          xs={12}
                          container
                          flexDirection="column"
                          justifyContent="center"
                          alignItems={'center'}
                        >
                          <Grid item xs={9} width="100%">
                            <TextField
                              sx={{mt: 1}}
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              fullWidth
                              helperText={touched.password && errors.password}
                              label="Password"
                              margin="normal"
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type={values.visible1 ? 'text' : 'password'}
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setFieldValue(
                                          'visible1',
                                          !values.visible1,
                                          false
                                        )
                                      }
                                    >
                                      {values.visible1 ? (
                                        <VisibilityIcon color="primary" />
                                      ) : (
                                        <VisibilityOffIcon color="primary" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={9} width="100%">
                            <TextField
                              sx={{mt: 1}}
                              error={Boolean(
                                touched.passwordConfirm &&
                                  errors.passwordConfirm
                              )}
                              fullWidth
                              //autoFocus
                              helperText={
                                touched.passwordConfirm &&
                                errors.passwordConfirm
                              }
                              label="Confirm Password"
                              margin="normal"
                              name="passwordConfirm"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type={values.visible2 ? 'text' : 'password'}
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setFieldValue(
                                          'visible2',
                                          !values.visible2,
                                          false
                                        )
                                      }
                                    >
                                      {values.visible2 ? (
                                        <VisibilityIcon color="primary" />
                                      ) : (
                                        <VisibilityOffIcon color="primary" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </Grid>

                          <Grid item xs={9} width="100%">
                            <TextField
                              error={Boolean(touched.otp && errors.otp)}
                              fullWidth
                              helperText={touched.otp && errors.otp}
                              label="OTP"
                              margin="normal"
                              name="otp"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            width="100%"
                            alignItems={'baseline'}
                            container
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2">
                                {' '}
                                Didn't receive OTP?{' '}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <LoadingButton
                                loading={resendOTP}
                                color="secondary"
                                loadingPosition="end"
                                fullWidth
                                sx={{
                                  textTransform: 'none',
                                  p: 0,
                                  minWidth: 0,
                                  fontWeight: 400,
                                }}
                                onClick={() => {
                                  const body = {
                                    user: {
                                      email: forgotPasswordEmail,
                                    },
                                  };
                                  setResendOTP(true);
                                  appService
                                    .sendOTP(body)
                                    .then(res => {
                                      console.log(res);
                                      setResendOTP(false);

                                      setAlert({
                                        type: 'info',
                                        message:
                                          'OTP is sent to registered mail ID',
                                      });
                                    })
                                    .catch(err => {
                                      console.log(err);
                                      setResendOTP(false);
                                      setOTPSent(false);
                                      setAlert({
                                        type: 'error',
                                        message:
                                          err || 'Unknown error occured!',
                                      });
                                    });
                                }}
                              >
                                {' '}
                                Resend
                              </LoadingButton>
                            </Grid>
                          </Grid>

                          {alert && (
                            <Grid item xs={9} width="100%" my={1}>
                              <Alert
                                severity={alert.type}
                                onClose={() => {
                                  setAlert(null);
                                }}
                              >
                                {alert.message}
                              </Alert>
                            </Grid>
                          )}
                          <Grid item xs={3} my={1} width="100%" mt={2}>
                            <LoadingButton
                              loading={loading}
                              variant="contained"
                              color="secondary"
                              type="submit"
                              fullWidth
                              loadingPosition="start"
                            >
                              {' '}
                              Submit
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
