import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import appService, { appServiceTSL } from '../../../core/service/app.service';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Popover,
  Typography,
} from '@mui/material';

import SliderDrawer from '../SliderDrawer';
import CreateQuote from '../CreateQuote';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CirularProgress from '../../../components/CirularProgress';
import CustomNoRowsOverlay from '../../../components/NoRowsOverlay';
import { useSnackbar } from 'notistack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import envConfig from '../../../config/envConfig';
import { currencyFormatter, numericFormatter } from '../../../core/service/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/redux/store';
import PaymentHistory from './PaymentHistory';
import { DateTime } from 'luxon';
import { getUser } from '../../../core/redux/initialState';

interface IQuotes {
  quoteData: any;
  updateBackdropLoading: any;
  getProposalData: any;
  projectId: string;
}

const Input = styled('input')({
  display: 'none',
});

const columnMapping = {
  product_category_name: 'Product Category',
  product_name: 'Product',
  sku_name: 'SKU',
  system_size: 'System Size (kWp)',
  amount: 'Total Cost',
  discount: 'Discount %',
  tax: 'Tax %',
  annual_energy_production: 'Annual Energy Production (kWh)',
  coverage: 'Energy Offset %',
  estimated_annual_savings: 'Estimated Annual Savings',
  created_at: 'Created At',
};
const tslColumnMapping = {
  component: 'Component',
  make: 'Make',
  quantity: 'Quantity'
};

const designProposalColumns: any = {
  design_ID: 'Design ID',
  system_size: 'System Size (kWp)',
  amount: 'Total Cost',
  annual_energy_production: 'Annual Energy Production (kWh)',
  estimated_annual_savings: 'Estimated Annual Savings',
  created_at: 'Created At',
}

function Quotes({ quoteData, updateBackdropLoading, getProposalData, projectId }: IQuotes) {
  console.log(quoteData);

  const [designs, setDesigns] = useState([]);


  const [openCreateQuoteDialog, setOpenCreateQuoteDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const closeDialog = () => {
    setOpenCreateQuoteDialog(false);
  };

  const myOrg = useSelector((state: RootState) => state.appState.org);

  const placeOrder = async (priceQuoteId: string) => {
    enqueueSnackbar('Creating an order');
    try {
      const placeOrderResult = await appService.createOrderOnQuote({
        price_quote_ID: priceQuoteId,
      });
      enqueueSnackbar('Order placed', { variant: 'success' });
      getProposalData()
    } catch (err) {
      enqueueSnackbar('Error occurred while creating order', {
        variant: 'error',
      });
      console.log('Error occurred while creating order', err);
    }
  };

  const handleDownload = (file: string | undefined) => {
    if (!file) return;
    fetch(`${envConfig.env.DOCUMENTSERVICE_URI}?name=${file}`)
      .then(resp => resp.text())

      .then(resp => {
        console.log(resp);
        window.location.assign(resp);
      })

      .catch(err => console.log(err));
  };

  const handleResendProposal = async (priceQuoteId: string) => {
    enqueueSnackbar('Resending proposal data');

    try {
      const result = await appService.resendProposal({ priceQuoteId })
      enqueueSnackbar('Proposal has been sent', { variant: 'success' });
      getProposalData();

    } catch (error) {
      console.log("Error occurred")
      enqueueSnackbar('Unable to resend proposal', { variant: 'error' });
    }
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getDesigns = async () => {
    if (!projectId) return;

    try {
      const tslProjectID = await appService.getTSLProject(projectId);

      const tslProject = await appServiceTSL.getProjectById(
        tslProjectID.message.tsl_project_ID
      );
      console.log({ tslProject });
      setDesigns(tslProject?.designs || []);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDesigns()
  }, [])

  const handleSendUpdatedProfile = async (priceQuoteId: string, designId: string) => {
    try {

      const designData = await appServiceTSL.getDesignReportDataById(
        designId
      );
      let designReport

      if (designData.status !== 500 || !designData) {
        let tempBomItems: any = [];
        designData.components &&
          designData.components.components.map((item: any) => {
            if (
              designData.components[item] &&
              designData.components[item].length > 0
            ) {
              designData.components[item].map((comp: any) =>
                tempBomItems.push({
                  component: item,
                  make: comp[0],
                  quantity: comp[1],
                })
              );
            }
          });

        const currentDesignObject: any = designs.filter((item: any) => item.id === parseInt(designId))[0]
        console.log({ currentDesignObject, designs, designId })
        designReport = {
          systemSize: designData.system_metrics
            ? parseFloat(
              designData.system_metrics['Module DC Nameplate'].split(
                ' '
              )[0]
            )
            : 0,
          totalCost: designData.cost_after_insentive
            ? parseFloat(
              designData.cost_after_insentive.replace(/\,/g, '')
            )
            : 0,
          annualEnergyProduction: designData.system_metrics[
            'Average Monthly Production'
          ]
            // @ts-ignore
            ? parseFloat(designData.system_metrics['Average Monthly Production'].replace(/\,/g, '') * 12).toFixed(2)
            : 0,
          annualSavings:
            designData.financial_data &&
              designData.financial_data.average_monthly_saving
              ? designData.financial_data.average_monthly_saving * 12
              : 0,
          reference_id: designData.reference_id
            ? designData.reference_id
            : '',
          proposalLink: designData.reference_id
            ? `https://360.thesolarlabs.com/webProposal/${designData.reference_id}`
            : '',
          bomItems: tempBomItems,
          //details for document download

          templateName:
            designData.report_defaults_data &&
            designData.report_defaults_data.template_name,
          isLandscape:
            designData.report_defaults_data &&
            designData.report_defaults_data.report_type === 'landscape',

          ...currentDesignObject
        }
      }
      console.log({ designReport })
      getProposalData();
      const result = appService.updateAndSendProposal({ priceQuoteId, data: designReport, tsl_token: getUser()?.tsl.token })
    } catch (error) {
      console.log(error)
    }
    // updateAndSendProposal

  }

  const open = Boolean(anchorEl);


  return (
    <Box>

      <Grid container flexDirection={'column'}>

        <Grid item container xs={12} sx={{ mt: 2 }}>
          {console.log(quoteData)}
          {quoteData && (quoteData.designQuotes || quoteData.normalQuotes) ? (
            quoteData.designQuotes.length === 0 && quoteData.normalQuotes.length === 0 ? (
              <CustomNoRowsOverlay text={'No Proposals Available'} />
            ) : (
              <>
                {quoteData.designQuotes.length > 0 && <Typography>Design Quotes</Typography>}
                {quoteData.designQuotes.length > 0 && quoteData.designQuotes.map((item: any) => {

                  let requiredColumnMapping = tslColumnMapping
                  return (
                    <Grid item container xs={12}>

                      <Accordion elevation={2} sx={{ width: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid container xs={12} justifyContent="space-between" alignItems={'center'}>
                            <Grid item >
                              <Grid container sx={{ width: 1 }} alignItems='center'>
                                <Typography>{item.price_quote_ID}</Typography>
                                {item.is_preliminary && <Chip color='primary' sx={{ ml: 2 }} label='Preliminary' />}

                                {item.rejected && (
                                  <Grid item sx={{ ml: 2 }}>
                                    <Chip color='error' label='Rejected' />
                                  </Grid>
                                )}
                                {item.is_accepted && (
                                  <Grid item sx={{ ml: 2 }}>
                                    <Chip color='success' label='Accepted' />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container sx={{ width: 1 }} alignItems='center'>

                                {item.is_preliminary && item.design_reference_ID && item.is_accepted ?
                                  <Button variant='contained' color='primary' size='small' onClick={() => handleSendUpdatedProfile(item.price_quote_ID, item.design_ID)}>Send Updated Proposal</Button>
                                  : item.is_preliminary && item.design_reference_ID && !item.is_accepted ?
                                    <Button variant='contained' color='primary' size='small' onClick={() => handleResendProposal(item.price_quote_ID)}>Resend Proposal</Button>
                                    : null

                                }

                                {item.file_display_name && (
                                  <Grid item>

                                    <IconButton
                                      onClick={() => handleDownload(item.file_name)}
                                    // onMouseEnter={handlePopoverOpen}
                                    // onMouseClose={handlePopoverClose}
                                    >
                                      <FileDownloadIcon />
                                    </IconButton>
                                    <Popover
                                      id="mouse-over-popover"
                                      sx={{
                                        pointerEvents: 'none',
                                      }}
                                      open={open}
                                      anchorEl={anchorEl}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      onClose={handlePopoverClose}
                                      disableRestoreFocus
                                    >
                                      <Typography sx={{ p: 1 }}>I use Popover.</Typography>
                                    </Popover>
                                    {/* </Typography> */}
                                  </Grid>
                                )}


                                {/* : item.order_ID ? (
                                <Grid item sx={{ ml: 2 }}>
                                  <Typography>{item.order_ID}</Typography>
                                </Grid>
                              ) : (
                                <Grid item sx={{ ml: 2 }}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => placeOrder(item.price_quote_ID)}
                                  >
                                    Place Order
                                  </Button>
                                </Grid>
                              )} */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item container xs={12} sx={{ my: 1 }}>
                            {Object.keys(designProposalColumns).map((key: string) => <Grid item xs={6}><Typography>{`${designProposalColumns[key]} - ${key === 'created_at' ? DateTime.fromISO(item[key])
                              .toFormat('dd/MM/yyyy')
                              .toString() : key === 'amount' ? numericFormatter(item[key], myOrg?.currency) : item[key]}`}</Typography></Grid>)}
                          </Grid>
                          <Grid item container xs={12}>
                            <TableContainer>
                              <Table size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    {Object.keys(requiredColumnMapping).map(item => (
                                      // @ts-ignore
                                      <TableCell>{requiredColumnMapping[item]}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {item.lineItems && item.lineItems.length > 0 ? (
                                    item.lineItems.map((document: any) => (
                                      <TableRow
                                        hover
                                        key={document.Id}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        {Object.keys(requiredColumnMapping).map(item =>
                                          item === 'created_at' ? (
                                            <TableCell>
                                              {new Date(
                                                document[item]
                                              ).toLocaleDateString()}
                                            </TableCell>
                                          ) : item === 'rejected' ? (
                                            <TableCell>
                                              {document[item] ? 'Yes' : 'No'}
                                            </TableCell>
                                          ) : item === 'discount' ||
                                            item === 'tax' ||
                                            item === 'coverage' ? (
                                            <TableCell>
                                              {document[item] * 100}
                                            </TableCell>
                                          ) : item === 'amount' ||
                                            item ===
                                            'estimated_annual_savings' ? (
                                            <TableCell>
                                              {currencyFormatter(
                                                document[item],
                                                myOrg?.currency
                                              )}
                                            </TableCell>
                                          ) : (
                                            <TableCell>
                                              {document[item]}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    ))
                                  ) : (
                                    <Grid
                                      container
                                      sx={{ width: 1 }}
                                      justifyContent="center"
                                    >
                                      <CustomNoRowsOverlay text="No Line Items Available!" />
                                    </Grid>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <Grid item xs={12}>
                              <PaymentHistory
                                getProposalData={getProposalData}
                                projectId={projectId}
                                paymentInstallmentData={[item]}
                              />
                            </Grid>
                          </Grid>

                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
                {quoteData.normalQuotes.length > 0 && <Typography>Normal Quotes</Typography>}
                {quoteData.normalQuotes.length > 0 && quoteData.normalQuotes.map((item: any) => {

                  let requiredColumnMapping = columnMapping
                  return (
                    <Grid item container xs={12}>
                      <Accordion elevation={2} sx={{ width: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid container xs={12} justifyContent="space-between" alignItems={'center'}>
                            <Grid item >
                              <Grid container sx={{ width: 1 }} alignItems='center'>
                                <Typography>{item.price_quote_ID}</Typography>
                                {item.is_preliminary && <Chip color='primary' sx={{ ml: 2 }} label='Preliminary' />}
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container sx={{ width: 1 }} alignItems='center'>

                                {item.file_display_name && (
                                  <Grid item>

                                    <IconButton
                                      onClick={() => handleDownload(item.file_name)}
                                    // onMouseEnter={handlePopoverOpen}
                                    // onMouseClose={handlePopoverClose}
                                    >
                                      <FileDownloadIcon />
                                    </IconButton>
                                    <Popover
                                      id="mouse-over-popover"
                                      sx={{
                                        pointerEvents: 'none',
                                      }}
                                      open={open}
                                      anchorEl={anchorEl}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      onClose={handlePopoverClose}
                                      disableRestoreFocus
                                    >
                                      <Typography sx={{ p: 1 }}>I use Popover.</Typography>
                                    </Popover>
                                    {/* </Typography> */}
                                  </Grid>
                                )}

                                {/* {item.rejected ? (
                                <Grid item sx={{ ml: 2 }}>
                                  <Chip color='error' label='Rejected' />
                                </Grid>
                              ) : item.order_ID ? (
                                <Grid item sx={{ ml: 2 }}>
                                  <Typography>{item.order_ID}</Typography>
                                </Grid>
                              ) : (
                                <Grid item sx={{ ml: 2 }}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => placeOrder(item.price_quote_ID)}
                                  >
                                    Place Order
                                  </Button>
                                </Grid>
                              )} */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item container xs={12}>
                            <TableContainer>
                              <Table size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    {Object.keys(requiredColumnMapping).map(item => (
                                      // @ts-ignore
                                      <TableCell>{requiredColumnMapping[item]}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {item.lineItems && item.lineItems.length > 0 ? (
                                    item.lineItems.map((document: any) => (
                                      <TableRow
                                        hover
                                        key={document.Id}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        {Object.keys(requiredColumnMapping).map(item =>
                                          item === 'created_at' ? (
                                            <TableCell>
                                              {new Date(
                                                document[item]
                                              ).toLocaleDateString()}
                                            </TableCell>
                                          ) : item === 'rejected' ? (
                                            <TableCell>
                                              {document[item] ? 'Yes' : 'No'}
                                            </TableCell>
                                          ) : item === 'discount' ||
                                            item === 'tax' ||
                                            item === 'coverage' ? (
                                            <TableCell>
                                              {document[item] * 100}
                                            </TableCell>
                                          ) : item === 'amount' ||
                                            item ===
                                            'estimated_annual_savings' ? (
                                            <TableCell>
                                              {currencyFormatter(
                                                document[item],
                                                myOrg?.currency
                                              )}
                                            </TableCell>
                                          ) : (
                                            <TableCell>
                                              {document[item]}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    ))
                                  ) : (
                                    <Grid
                                      container
                                      sx={{ width: 1 }}
                                      justifyContent="center"
                                    >
                                      <CustomNoRowsOverlay text="No Line Items Available!" />
                                    </Grid>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <Grid item xs={12}>
                              <PaymentHistory
                                getProposalData={getProposalData}
                                projectId={projectId}
                                paymentInstallmentData={[item]}
                              />
                            </Grid>
                          </Grid>

                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
              </>
            )
          ) : (
            <CustomNoRowsOverlay text={'No Proposals Available'} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Quotes;