import React, {FC, useEffect, useRef, useState} from 'react';
import {Map, GoogleApiWrapper, IProvidedProps, Marker} from 'google-maps-react';

import {Box} from '@mui/system';
import {AnySchema} from 'yup';

interface IMap extends IProvidedProps {
  address: string | undefined;
  latlng: Ilatlng;
  minheight: string | undefined;
  handleClick?: (arg1: Ilatlng) => void;
}

interface Ilatlng {
  lat: number | null | string | undefined;
  lng: number | null | string | undefined;
}
const mapStyles = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const MapContainer: FC<IMap> = props => {
  const [position, setPosition] = useState<Ilatlng | null>(null);
  const mapRef = useRef<any>();

  useEffect(() => {
    if (props.latlng.lat && props.latlng.lng) {
      setPosition(props.latlng);
      return;
    }

    if (props.address) {
      const geocoder = new props.google.maps.Geocoder();

      geocoder.geocode(
        {address: props.address},
        (results: any, status: any) => {
          if (status === props.google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();

            setPosition({lat: latitude, lng: longitude});
          }
        }
      );
    }
  }, [props]);

  const handleMapClick = (mapProps: AnySchema, map: any, clickEvent: any) => {
    if (props.handleClick) {
      props.handleClick({
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      });
    }
  };
  //@ts-nocheck
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: props.minheight,
        height: '100%',
        position: 'relative',
        border: '6px solid white',
      }}
    >
      {' '}
      <Map
        ref={mapRef}
        /** @ts-ignore */

        zoom={20}
        mapType="satellite"
        google={props.google}
        style={mapStyles}
        /** @ts-ignore */
        onClick={handleMapClick}
        center={
          position || {
            lat: 42.39,
            lng: -72.52,
          }
        }
      >
        {position ? (
          <Marker
            /** @ts-ignore */
            position={position}
          />
        ) : null}
      </Map>
    </Box>
  );
};

export default GoogleApiWrapper((props: any) => ({
  apiKey: String(process.env.REACT_APP_GMAPS_KEY),

  options: {mapTypeId: 'satellite', componentRestrictions: {country: ['US']}},
}))(MapContainer);
