import React, {useEffect, useState} from 'react';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';

import Grid from '@mui/material/Grid';

import LinearProgress from '@mui/material/LinearProgress';

import loginStyle from './login.style';
import arkaLogo from '../../assets/images/logo.svg';

import microsoft from '../../assets/images/microsoft.png';

import {
  Alert,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import TSLLogo from '../../assets/images/tsl_logo.png';
import * as Yup from 'yup';
import {Formik} from 'formik';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {Theme} from '@mui/system';
import {Link} from 'react-router-dom';
import {RootState} from '../../core/redux/store';
import {useDispatch, useSelector} from 'react-redux';
import {setUser} from '../../core/redux/AppSlice';

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import appService, {appServiceTSL} from '../../core/service/app.service';
import envConfig from '../../config/envConfig';

import {loginRequest} from '../../config/authConfig';

import {useHistory} from 'react-router';
import axios from 'axios';

//@ts-ignore
import cookie from 'react-cookie';
import {getDomain} from '../../utils/utils';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',

    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

const TSLLogin: React.FC<any> = () => {
  const history = useHistory();
  const classes = useStyle();

  const [login, setLogin] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>('');

  const dispatch = useDispatch();

  const handleLogin = async (values: any) => {
    console.log(values);
    try {
      const {username, password} = values;
      setLogin(true);
      const body = {
        username,
        password,
      };

      const TSLLoginRes = await axios.post(
        `${envConfig.env.TSL_SERVER}/api-token-auth/`,
        body
      );

      console.log(envConfig.env);

      const TSLuserProfile = await axios.get(
        `${envConfig.env.TSL_SERVER}/api/users/${TSLLoginRes.data.user_id}/`,

        {
          headers: {
            Authorization: `Token ${TSLLoginRes.data.token}`,
          },
        }
      );

      const socialSignInBody = {
        appId: envConfig.env.APPID,

        email: TSLuserProfile.data.email,
        socialPlatform: 'tsl',
        associated_tsl_org_ID: TSLLoginRes.data.organisation_id,
        socialProfile: TSLuserProfile.data,
      };

      const ADPTSLSignInRes = await appService.ADPTSLSignIn(socialSignInBody);

      console.log(ADPTSLSignInRes);

      const user = {
        arka: ADPTSLSignInRes.message,
        tsl: {...TSLLoginRes.data, user_profile: TSLuserProfile.data},
      };

      // eslint-disable-next-line no-restricted-globals
      const domain = getDomain(location.origin);

      console.log('logging on domain1s1', domain);

      localStorage.setItem(envConfig.env.ARKA_KEY,JSON.stringify(ADPTSLSignInRes.message))

      localStorage.setItem(envConfig.env.TSL_KEY,JSON.stringify({...TSLLoginRes.data, user_profile: TSLuserProfile.data}))


      dispatch(setUser(user));
    } catch (err: any) {
      console.log(err);
      if (err.response) {
        setLoginError(err.response?.data?.message);
      } else if (typeof err === 'object') {
        setLoginError(err.message);
      } else {
        setLoginError(err);
      }

      setLogin(false);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={11} sm={6} md={6} lg={4}>
        <Card>
          <Grid
            container
            justifyContent={'center'}
            flexDirection="column"
            alignItems={'center'}
            px={2}
            py={5}
          >
            <Grid item xs={12} my={1}>
              <img src={TSLLogo} alt="arkalogo" height="100px" />
            </Grid>

            <Grid item xs={12}>
              <Typography>Please enter your credentials</Typography>
            </Grid>

            <Grid item xs={12} width="100%">
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  visible: false,
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string()
                    .email('Email must be formatted correctly.')
                    .max(255)
                    .required('Email is required.'),
                  password: Yup.string().required('Please Enter your password'),
                })}
                onSubmit={handleLogin}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => {
                  return (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid
                        item
                        xs={12}
                        container
                        flexDirection="column"
                        justifyContent="center"
                        alignItems={'center'}
                      >
                        <Grid item xs={9} width="100%">
                          <TextField
                            error={Boolean(touched.username && errors.username)}
                            fullWidth
                            helperText={touched.username && errors.username}
                            label="Email"
                            margin="normal"
                            name="username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={9} width="100%">
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={values.visible ? 'text' : 'password'}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'visible',
                                        !values.visible,
                                        false
                                      )
                                    }
                                  >
                                    {values.visible ? (
                                      <VisibilityIcon color="primary" />
                                    ) : (
                                      <VisibilityOffIcon color="primary" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        {loginError && (
                          <Grid item xs={9} width="100%" my={1}>
                            <Alert
                              severity="error"
                              onClose={() => {
                                setLoginError(null);
                              }}
                            >
                              {loginError}
                            </Alert>
                          </Grid>
                        )}

                        <Grid item xs={4} sm={3} width="100%" my={1}>
                          <LoadingButton
                            loading={login}
                            variant="contained"
                            color="secondary"
                            type="submit"
                            fullWidth
                          >
                            Sign In
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TSLLogin;
