import 'date-fns';
import React, {useState} from 'react';
import DatePicker from '@mui/lab/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import moment from 'moment';
import {makeStyles} from '@mui/styles';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime, Settings} from 'luxon';
import {Card, Typography} from '@mui/material';
import {setDatasets} from 'react-chartjs-2/dist/utils';
import {setDate} from 'date-fns';
Settings.defaultZone = 'Asia/Kolkata';

const useStyle = makeStyles(theme => ({
  pickerInput: {
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: 500,
    marginLeft: 10,
    textAlign: 'center',
  },
  rootInput: {
    justifyContent: 'center',
  },
}));

//props to this
// date - luxon Datetime object
// setDate() - will set date close dialog
// handleClose()
// views
// showDirectionalArrows
// disable weekends
//error text
export default function MaterialUIPickers(props: any) {
  const [selectedDate, setSelectedDate] = useState<any>(DateTime.now());
  const classes = useStyle();

  React.useEffect(() => {
    if (props.after && props.after > props.date) {
      props.setDate(props.after);
    }
  }, [props.after]);

  React.useEffect(() => {
    setSelectedDate(props.date);
  }, [props.date]);

  const handleDateChange = (date: any) => {
    props.setDate(date);
    console.log('called', date);
  };

  const handleCloseDialog = (d: any) => {
    console.log('called', d);
  };

  const getViews = () => {
    return ['year', 'month', 'day'];
  };

  const formatDate = (d: any) => {
    const date = DateTime.fromJSDate(new Date(d));
    return `${date.toFormat('MMMM dd, yyyy')}`;
  };

  const chevronDateChange = (forward: boolean) => {
    let temp = selectedDate ? selectedDate : DateTime.now();
    if (forward) {
      props.setDate(temp.plus({days: 1}));
    } else {
      props.setDate(temp.minus({days: 1}));
    }
  };

  return (
    <Card>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sx={{ml: 1}}>
          <Typography>{props.label}</Typography>
        </Grid>
        <Grid item style={{}}>
          <InputAdornment position="start">
            <IconButton
              onClick={d => chevronDateChange(false)}
              style={{padding: 0}}
              color="primary"
              disabled={props.disabled}
            >
              <ChevronLeft fontSize="large" sx={{color: '#1c3366'}} />
            </IconButton>
          </InputAdornment>
        </Grid>
        <Grid item sx={{maxWidth: '100px'}}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <MobileDatePicker
              disableFuture
              style={{justifyContent: 'center'}}
              variant="dialog"
              margin="none"
              id="date-picker-inline"
              openTo="day"
              //@ts-ignore
              views={getViews()}
              value={selectedDate}
              onChange={handleDateChange}
              onAccept={handleCloseDialog}
              // maxDate={props.before ? props.before : DateTime.now()}
              maxDate={DateTime.now()}
              minDate={
                props.after ? props.after : DateTime.now().minus({years: 5})
              }
              // error={props.errorText !== ''}
              // helperText={props.errorText}
              // @ts-ignore
              // shouldDisableDate={disableWeekends}
              renderInput={params => (
                <TextField
                  style={{fontSize: 20}}
                  {...params}
                  variant="standard"
                />
              )}
              OpenPickerButtonProps={{color: 'primary'}}
              InputProps={{
                classes: {input: classes.pickerInput},
                disableUnderline: true,
              }}
              //@ts-ignore
              rifmFormatter={formatDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Grid item>
            <InputAdornment position="start">
              <IconButton
                onClick={d => chevronDateChange(true)}
                style={{padding: 0}}
                color="primary"
                disabled={props.disabled}
              >
                <ChevronRight fontSize="large" sx={{color: '#1c3366'}} />
              </IconButton>
            </InputAdornment>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
