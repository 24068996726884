import {Box, Button, Dialog, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import BackDropCircularProgress from '../../../components/BackDropCircularProgress';
import appService from '../../../core/service/app.service';
import SliderDrawer from '../SliderDrawer';
import CreateProposal from './CreateProposal';
import PaymentHistory from './PaymentHistory';
import Quotes from './Quotes';

const Proposal = ({projectId, paymentTemplateId}) => {
  const [openCreateQuoteDialog, setOpenCreateQuoteDialog] = useState(false);

  const [productCategoryData, setProductCategoryData] = useState(null);
  const [proposalData, setProposalData] = useState(null);
  const [paymentInstallmentData, setPaymentInstallmentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const closeDialog = () => {
    setOpenCreateQuoteDialog(false);
  };

  const getProductData = async () => {
    try {
      let productCategory = await appService.getProductCategory();
      setProductCategoryData(productCategory.message);
    } catch (err) {
      console.log(err);
    }
  };
  const getPaymentStatusAndMode = (
    paymentHistory,
    cashPaymentHistory,
    installmentId
  ) => {
    let paymentHistoryData = paymentHistory.filter(
      jItem => jItem.payment_installment_ID === installmentId
    );

    let cashPaymentHistoryData = cashPaymentHistory.find(
      item => item.payment_installment_ID === installmentId
    );

    let status = null;

    if (cashPaymentHistoryData) {
      status = {status: 'captured', mode: cashPaymentHistoryData.payment_mode};
    } else {
      status = {
        status:
          paymentHistoryData.length === 1 ? paymentHistoryData[0].status : null,
        mode: status === null ? 'NA' : 'Payment Link',
      };
    }

    return status;
  };

  const getProposalData = async () => {
    setLoading(true);
    try {
      let proposalData = await appService.getProposalData(projectId);

      setProposalData(proposalData.message);

      const {installments, orders, paymentHistory, cashPaymentHistory, quotes} =
        proposalData.message;

      let designQuotes = [];
      let normalQuotes = [];
      quotes.map(item => {
        if (item.design_ID) designQuotes.push(item);
        else normalQuotes.push(item);
      });
      setPaymentInstallmentData({designQuotes, normalQuotes});
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getProductData();
    getProposalData();
  }, []);

  return (
    <Box>
      <BackDropCircularProgress open={loading} />

      <SliderDrawer
        open={openCreateQuoteDialog}
        handleClose={closeDialog}
        width={'80%'}
        title={'Create Proposal'}
      >
        {/* <Dialog open={openCreateQuoteDialog} onClose={closeDialog}> */}
        {openCreateQuoteDialog && (
          <CreateProposal
            productCategoryData={productCategoryData}
            projectId={projectId}
            handleCloseDialog={() => {
              getProposalData();
              setOpenCreateQuoteDialog(false);
            }}
            existingQuotes={paymentInstallmentData}
            updateBackdropLoading={() => setLoading(!loading)}
            paymentTemplateId={paymentTemplateId}
          />
        )}
        {/* </Dialog> */}
      </SliderDrawer>

      <Grid container flexDirection={'column'}>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenCreateQuoteDialog(true)}
          >
            Create
          </Button>
        </Grid>
      </Grid>

      {paymentInstallmentData && (
        <Quotes
          quoteData={paymentInstallmentData}
          updateBackdropLoading={() => setLoading(!loading)}
          getProposalData={getProposalData}
          projectId={projectId}
        />
      )}

      {/* {paymentInstallmentData && (
        <PaymentHistory
          projectId={projectId}
          paymentInstallmentData={paymentInstallmentData}
        />
      )} */}
    </Box>
  );
};

export default Proposal;
