import React from 'react';
import {Grid, Typography, Button} from '@mui/material';
const Headings = ({
  heading,
  btntitle,
  handleClick,
  btntitles,
  handleClicks,
  btntitle2,
  handleClick2,
  btntitle3,
  handleClick3,
  imagebtntitle,
  handleImage,
  searchBar,
  slider,
  projectDesignBtn,
  sendInviteBtn,
  handleSendInvite,
  spotlightCustomer,
  tslproject,
}: any) => {
  return (
    <Grid item container>
      <Grid
        item
        container
        xs={12}
        sm={4}
        lg={4}
        md={4}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="subtitle1">{heading}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={8}
        lg={8}
        md={8}
        justifyContent="flex-end"
        alignItems="center"
      >
        {slider}

        {btntitles && (
          <Button
            // startIcon={<FileDownloadIcon />}
            component="span"
            variant="outlined"
            onClick={handleClicks}
            color="secondary"
            sx={{mr: 1, background: 'white'}}
          >
            {btntitles}
          </Button>
        )}

        {imagebtntitle && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleImage}
            sx={{mr: 1}}
          >
            {' '}
            {imagebtntitle}
          </Button>
        )}

        {btntitle3 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick3}
            sx={{mr: 1}}
          >
            {' '}
            {btntitle3}
          </Button>
        )}
        {btntitle2 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick2}
            sx={{mr: 1}}
          >
            {' '}
            {btntitle2}
          </Button>
        )}
        {btntitle && (
          <Button variant="contained" color="secondary" onClick={handleClick}>
            {' '}
            {btntitle}
          </Button>
        )}

        {/* {heading === 'Firmwares' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClicks}
            sx={{ ml: 3 }}
          >
            {' '}
            {btntitles}
          </Button>
        )} */}
        {searchBar}
        {sendInviteBtn && !spotlightCustomer && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSendInvite}
            sx={{
              ml: 3,
              color: 'white !important',
              background: '#409eff !important',
            }}
          >
            {' '}
            {sendInviteBtn}
          </Button>
        )}
        {projectDesignBtn && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClicks}
            // sx={{
            //   ml: 3,
            //   cursor: 'pointer',
            //   color: !tslproject ? 'black' : 'white !important',
            //   background: !tslproject ? 'grey' : '#409eff !important',
            // }}
          >
            {' '}
            {projectDesignBtn}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Headings;
