import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Box,
  Badge,
  IconButton,
  DialogActions,
  Button,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import OverwriteDialog from './OverwriteDialog';
import {useSnackbar} from 'notistack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import appService from '../../../core/service/app.service';
import DialogHeading from '../../../components/DialogHeading';

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG'];

const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
  greyText: {
    color: 'grey',
  },
  mainPaddedContainers: {
    padding: '50px 100px 50px 100px',
  },
  paddedTextContainers: {
    padding: 20,
  },
  subPaddedContainers: {
    padding: 10,
  },
  marginTop: {
    marginTop: '5px !important',
  },
  dialogPaper: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    paddingBottom: 8,
  },
  imageContainer: {
    width: '80px',
    height: '80px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
}));
const issueTypes = [
  'Process Query',
  'Payment Issue',
  'Product Support',
  'Services Query',
  'General Query',
];
const CreateSupportTicketModal = ({
  open,
  setOpen,
  orderData,
  quoteData,
  projectId,
  getSupportTickets,
}) => {
  //   const [loading, setLoading] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [typeOfIssue, setTypeOfIssue] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [productID, setProductID] = useState(null);

  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);

  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleClose = () => {};

  const classes = useStyle();
  const handleChangeIssueType = e => {
    setTypeOfIssue(e.target.value);
  };
  const handleChangeOrderId = e => {
    setOrderID(e.target.value);
  };
  const handleChangeProductId = e => {
    setProductID(e.target.value);
  };

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };
  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };

  const handleAddFile = newFile => {
    console.log(newFile[0], files);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3)
        enqueueSnackbar('Maximum of 3 files can be uploaded', {
          variant: 'info',
        });
      else {
        setFiles([...files, ...newFile]);
        setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
      }
    }
  };

  const handleRemoveImage = index => {
    console.log(index);
    console.log(files);
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };
  const cancelOverWrite = () => {
    setMatchingFileIndex(null);
    setTempNewFile(null);
  };

  const initiateOverWrite = () => {
    let temp = files;
    let tempSrc = filesSrc;

    console.log(temp, tempSrc, tempNewFile);

    temp[matchingFileIndex] = tempNewFile;
    tempSrc[matchingFileIndex] = window.URL.createObjectURL(tempNewFile);

    console.log(temp, tempSrc);

    setFiles(temp);
    setFilesSrc(tempSrc);

    setMatchingFileIndex(null);
    setTempNewFile(null);
    forceUpdate();
  };

  const clearSupportCard = () => {
    setTypeOfIssue(null);
    setSubject('');
    setDescription('');
    setOrderID(null);
    setProductID(null);
    setFiles([]);
    setFilesSrc([]);
  };

  const createCustomerSupportRequest = async () => {
    setOpenProgressDialog(true);
    enqueueSnackbar('Creating support request');

    try {
      const formData = new FormData();
      if (files.length > 0) {
        files.map(item => formData.append('file', item));
      }
      formData.append('type_of_issue', typeOfIssue);
      formData.append('subject', subject);
      formData.append('description', description);
      formData.append('project_ID', projectId);

      if (typeOfIssue !== issueTypes[4]) {
        formData.append('order_ID', orderID);
      }
      if (typeOfIssue === issueTypes[2] || typeOfIssue === issueTypes[3]) {
        formData.append('product_ID', productID);
      }

      const createSupportTicketResult = await appService.createSupportTicket(
        formData
      );
      setOpenProgressDialog(false);
      enqueueSnackbar('Ticket successfully created', {variant: 'success'});
      getSupportTickets();
      setOpen(false);
      clearSupportCard();
    } catch (err) {
      console.log(err);
      setOpenProgressDialog(false);
      enqueueSnackbar('Error creating ticket');
    }
  };

  return (
    <Grid container sx={{width: 1}}>
      <OverwriteDialog
        matchingFileIndex={matchingFileIndex}
        fileName={tempNewFile ? tempNewFile.name : ''}
        cancelOverWrite={cancelOverWrite}
        initiateOverWrite={initiateOverWrite}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={'xl'}
        style={{width: '100vw'}}
        PaperProps={{classes: {root: classes.dialogPaper}}}
      >
        <DialogHeading
          title="New Support Request"
          handleClose={() => setOpen(false)}
        />
        {quoteData && orderData && (
          <DialogContent>
            <Grid
              container
              style={{width: '100%'}}
              justifyContent="space-around"
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Typography className={classes.marginTop} variant="body2">
                    {'Select the type of issue'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="body2"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <Select
                  size="small"
                  className={classes.marginTop}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfIssue}
                  onChange={handleChangeIssueType}
                  fullWidth
                >
                  {orderData && orderData.orders && orderData.orders.length > 0
                    ? issueTypes.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })
                    : [issueTypes[4]].map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                </Select>

                {typeOfIssue && typeOfIssue !== issueTypes[4] && (
                  <Grid container sx={{width: 1}}>
                    <Grid container>
                      <Typography className={classes.marginTop} variant="body2">
                        {'Select Order'}
                      </Typography>
                      <Typography
                        className={classes.marginTop}
                        variant="body2"
                        sx={{color: 'red', ml: 1}}
                      >
                        {'*'}
                      </Typography>
                    </Grid>
                    <Select
                      className={classes.marginTop}
                      value={orderID}
                      onChange={handleChangeOrderId}
                      fullWidth
                      size="small"
                    >
                      {orderData.orders.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.order_ID}>
                            {item.order_ID}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                )}

                {(typeOfIssue === issueTypes[2] ||
                  typeOfIssue === issueTypes[3]) &&
                  orderID && (
                    <Grid container sx={{width: 1}}>
                      <Grid container>
                        <Typography
                          className={classes.marginTop}
                          variant="body2"
                        >
                          {'Select Product'}
                        </Typography>
                        <Typography
                          className={classes.marginTop}
                          variant="body2"
                          sx={{color: 'red', ml: 1}}
                        >
                          {'*'}
                        </Typography>
                      </Grid>
                      <Select
                        size="small"
                        className={classes.marginTop}
                        value={productID}
                        onChange={handleChangeProductId}
                        fullWidth
                      >
                        {quoteData.filter(i => i.order_ID === orderID)
                          .length === 1 &&
                          quoteData
                            .filter(i => i.order_ID === orderID)[0]
                            .lineItems.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.product_ID}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        {/* <SelectedOrderItems /> */}
                      </Select>
                    </Grid>
                  )}
                <Grid container>
                  <Typography className={classes.marginTop} variant="body2">
                    {'Subject'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="body2"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <TextField
                  size="small"
                  className={classes.marginTop}
                  value={subject}
                  onChange={handleChangeSubject}
                  fullWidth
                />

                <Grid container>
                  <Typography className={classes.marginTop} variant="body2">
                    {'Description'}
                  </Typography>
                  <Typography
                    className={classes.marginTop}
                    variant="body2"
                    sx={{color: 'red', ml: 1}}
                  >
                    {'*'}
                  </Typography>
                </Grid>
                <TextField
                  className={classes.marginTop}
                  value={description}
                  onChange={handleChangeDescription}
                  maxRows={8}
                  multiline={true}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid container sx={{mt: 1}}>
                {files && files.length > 0 && filesSrc && filesSrc.length > 0 && (
                  <Grid container sx={{width: 1}}>
                    {filesSrc.map((image, index) => (
                      <Grid item sx={{mr: 5, mt: 2}}>
                        <Badge
                          badgeContent={
                            <IconButton
                              size="small"
                              style={{padding: 0}}
                              onClick={() => handleRemoveImage(index)}
                            >
                              <CloseRoundedIcon sx={{fontSize: '1rem'}} />
                            </IconButton>
                          }
                          color="primary"
                          sx={{
                            '& .MuiBadge-badge': {
                              padding: 0,
                            },
                          }}
                        >
                          <Box
                            component={'div'}
                            className={classes.imageContainer}
                          >
                            {' '}
                            <img
                              src={image}
                              alt="uploaded"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                        </Badge>
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    className={classes.marginTop}
                    variant="body2"
                    sx={{mb: 1}}
                  >
                    {'Upload image - (optional - maximum 3)'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FileUploader
                    multiple={true}
                    handleChange={handleAddFile}
                    name="file"
                    types={fileTypes}
                    children={
                      <Grid
                        container
                        sx={{
                          height: 100,
                          width: 1,
                          border: `2px dashed`,
                          borderColor: 'secondary.main',
                        }}
                        justifyContent="center"
                      >
                        <Typography variant="body2" sx={{mt: 2}}>
                          Click here to upload or drag and drop
                        </Typography>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              clearSupportCard();
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            onClick={() => createCustomerSupportRequest()}
            disabled={description === '' || subject === '' || !typeOfIssue}
            color="secondary"
          >
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CreateSupportTicketModal;
