import {Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';
import axios from 'axios';
import envConfig from '../../config/envConfig';
import appService, {appServiceTSL} from '../../core/service/app.service';
import CircularProgressDialog from '../../components/CircularProgressDialog';
import {useDispatch, useSelector} from 'react-redux';
import {setUser} from '../../core/redux/AppSlice';

//@ts-ignore
import cookie from 'react-cookie';
import {AssuredWorkloadOutlined} from '@mui/icons-material';
import {getDomain} from '../../utils/utils';

const SsoTSL = () => {
  const history = useHistory();

  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const initiateAuth = async () => {
    try {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let token = params.token;
      let user_id = params.user_id;

      const TSLuserProfile = await axios.get(
        `${envConfig.env.TSL_SERVER}/api/users/${user_id}/`,

        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const socialSignInBody = {
        appId: envConfig.env.APPID,

        email: TSLuserProfile.data.email,
        socialPlatform: 'tsl',
        associated_tsl_org_ID: TSLuserProfile.data.org_shares[0].organisation,
        socialProfile: TSLuserProfile.data,
      };

      const ADPTSLSignInRes = await appService.ADPTSLSignIn(socialSignInBody);

      console.log(ADPTSLSignInRes);

      const arka = ADPTSLSignInRes.message;

      const tsl = {token: token, user_profile: TSLuserProfile.data};

      const user = {
        arka: arka,
        tsl: tsl,
      };

      // eslint-disable-next-line no-restricted-globals
      const domain = getDomain(location.origin);

      console.log('logging on domain', domain);
      localStorage.setItem(envConfig.env.ARKA_KEY,JSON.stringify(arka))
      localStorage.setItem(envConfig.env.TSL_KEY,JSON.stringify(tsl))


 
      dispatch(setUser(user));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    initiateAuth();
  });

  return (
    <Grid container sx={{width: 1}}>
      <CircularProgressDialog open={open} />
    </Grid>
  );
};

export default SsoTSL;
