import {Grid, Typography} from '@mui/material';
import * as React from 'react';


const TitleValueDisplay = ({title, value}) => {
  return (
    <Grid item xs={4} sx={{backgroundColor: 'none'}}>
      <Typography
        variant="body2"
        fontSize={'0.75rem'}
        fontWeight={400}
        ml={2}
        color={'#777777'}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        fontSize={'0.8rem'}
        ml={2}
        fontWeight={400}
        color={'none'}
      >
        {value}
      </Typography>
    </Grid>
  );
};

export default TitleValueDisplay;
