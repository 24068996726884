/**
 *
 * @param {[String]} url [url string to be converted to]
 * * @return {[Array]}        [Array containing the url contents]
 */

const urlToArray = url => {
  if (typeof url !== 'string') {
    throw new Error('Only string arguments are allowed');
  }
  return url.replace(/^\/+|\/+$/gm, '').split('/');
};

const stripQueryStrings = url => url.split(/[?#]/)[0];

const createRegexFromResource = resource => {
  if (resource.startsWith(':') || resource === '*') {
    return '.*';
  }
  return `^${resource}$`;
};

const matchUrlToResource = (route, resource) => {
  if (resource === '*') return true;

  //  create an array form both route url and resource
  const routeArray = urlToArray(route);
  const resourceArray = urlToArray(resource);

  for (let key = 0; key < routeArray.length; key++) {
    if (key >= resourceArray.length) return false;

    if (resourceArray[key] === '*') return true;

    if (!routeArray[key].match(createRegexFromResource(resourceArray[key])))
      return false;
  }

  if (resourceArray.length > routeArray.length) {
    return resourceArray[routeArray.length] == '*';
  }

  return true;
};

const getPrefix = resource => resource.slice(0, resource.length - 2);

const findPermissionForRoute = (policy, route, method) => {
  // Strip query strings from route

  route = stripQueryStrings(route);

  let foundPermissions = [];
  for (let permission of policy) {
    let resource = permission.resource;
    // check if route prefix has been specified

    if (permission.subRoutes && permission.resource !== '*') {
      const currentPrefix = resource.endsWith('/*')
        ? getPrefix(resource)
        : resource;

      let currentPermission = findPermissionForRoute(
        route,
        method,
        currentPrefix,
        permission.subRoutes
      );
      if (currentPermission) {
        foundPermissions.push(currentPermission);
      }
    }

    if (matchUrlToResource(route, resource)) {
      foundPermissions.push(permission);
    }
  }

  return foundPermissions;
};

const isAllowed = (method, permission) => {
  const isGlobOrHasMethod =
    permission.methods === '*' || permission.methods.includes(method);

  switch (isGlobOrHasMethod) {
    case true:
      return permission.action === 'allow' ? true : false;
    default:
      return permission.action === 'allow' ? false : true;
  }
};

const checkIfHasAccess = (
  method,

  permissions
) => {
  const isDenied = permissions.find(
    permission =>
      permission.action === 'deny' &&
      (permission.methods === '*' || permission.methods.includes(method))
  );

  if (isDenied) return false;

  const allowed = [];
  permissions.forEach(permission => {
    allowed.push(isAllowed(method, permission));
  });
  return allowed.some(item => item === true);
};

//module.exports = {findPermissionForRoute, checkIfHasAccess};
export {findPermissionForRoute, checkIfHasAccess};
