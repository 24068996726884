import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Skeleton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import Slide from '@mui/material/Slide';
import appService from '../../../core/service/app.service';
import {makeStyles, StylesContext} from '@mui/styles';

import {useSnackbar} from 'notistack';
import {TransitionProps} from '@mui/material/transitions';
import IOrder from '../../Order/__types__/order.interface';

import {cloneDeep} from 'lodash';

import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid-pro';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {IOrderInstallments} from '../__types__/OrderInstallments.interface';
import {v4 as uuidv4, validate as uuidValidate} from 'uuid';
import Loading from '../../../components/CirularProgress';
import * as Yup from 'yup';

import {uniq} from 'lodash';
import {Form, Formik} from 'formik';
import CustomNoRowsOverlay from '../../../components/NoRowsOverlay';
import {AccountCircle} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {RootState} from '../../../core/redux/store';
import {currencyFormatter} from '../../../core/service/utils';

interface IOrderPaymentInstallments {
  open: boolean;
  handleClose: any;
  selectedOrder: IOrder;
}

const useStyle = makeStyles(theme => ({
  textfield: {
    // height: '1.6rem' + '!important',
  },
}));

interface IInstallmentData {
  installmentData: IOrderInstallments;
  mode: String | null;
}

// interface IInstallments {
//   mode:'absolute' | 'percentage'
//   absolute:IInstallmentData[] | null,
//   percentage:IInstallmentData[] | null,
// }

const OrderPaymentInstallments = ({
  open,
  handleClose,
  selectedOrder,
}: IOrderPaymentInstallments) => {
  const classes = useStyle();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [installmentMode, setInstallmentMode] = useState<
    'absolute' | 'percentage'
  >('percentage');

  const [backDropOpen, setBackDropOpen] = useState(false);

  const [installments, setInstallments] = useState<IInstallmentData[] | null>(
    null
  );

  const deletedInstallments = useRef<any[]>([]);

  const setTemp = useRef<IInstallmentData[] | null>([]);

  const myOrg = useSelector((state: RootState) => state.appState.org);

  const clear = () => {
    setInstallments(null);
    setTemp.current = [];
  };
  useEffect(() => {
    if (!selectedOrder || open === false) return;

    clear();

    appService
      .getOrderInstallments(selectedOrder.order_ID)
      .then(res => {
        console.log(res);

        const temp = res.message.map((installment: IOrderInstallments) => ({
          installmentData: installment,
          mode: null,
        }));

        setInstallments(temp);

        setTemp.current = cloneDeep(temp);
      })

      .catch(err => {
        console.log(err);
      });
  }, [open]);

  const handleModeChange = (event: any) => {
    setInstallmentMode(event.target.value);
  };

  const onDelete = (installmentId: string | undefined) => {
    if (!installments) return;
    const tempInstallments = installments?.filter(
      installment =>
        installment.installmentData.payment_installment_ID !== installmentId
    );

    // store deleted installment ids

    if (!uuidValidate(installmentId || '')) {
      deletedInstallments.current = uniq([
        ...deletedInstallments.current,
        installmentId,
      ]);
    }

    setInstallments(tempInstallments);
  };

  const onEdit = (installmentId: string | undefined) => {
    if (!installments) return;

    const installment = installments?.find(
      installment =>
        installment.installmentData.payment_installment_ID === installmentId
    );

    if (installment) {
      installment.mode = 'edit';
      setInstallments([...installments]);
    }
  };

  const onSave = (values: any) => {
    console.log(values);
    if (!installments) {
      return;
    }
    const {payment_installment_ID, percentage_value} = values;

    values.percentage_value = percentage_value / 100;

    const installment = installments?.find(
      installment =>
        installment.installmentData.payment_installment_ID ===
        payment_installment_ID
    );

    if (installment) {
      installment.mode = null;
      installment.installmentData = {...installment.installmentData, ...values};

      setTemp.current = cloneDeep(installments);

      setInstallments([...installments]);
    }
  };

  const onCancel = (installmentId: string | undefined) => {
    if (!installments) return;

    const installment = installments?.find(
      installment =>
        installment.installmentData.payment_installment_ID === installmentId
    );

    if (installment) {
      installment.mode = null;
      setInstallments([...installments]);
    }
  };

  const addNewInstallment = () => {
    const newInstallment: any = {
      installmentData: {
        name: '',
        description: '',
        payment_installment_ID: uuidv4(),
        percentage_value: 0,
      },
      mode: 'edit',
    };

    if (!installments) {
      setInstallments([newInstallment]);

      setTemp.current = [newInstallment];
    } else {
      setInstallments([...installments, newInstallment]);

      setTemp.current = [...installments, newInstallment];
    }
  };

  const handleInstallmentsSave = async () => {
    console.log(installments);
    const isZeroInstalments = installments?.length === 0;

    if (isZeroInstalments) {
      enqueueSnackbar(`Customer Order must have installments`, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }

    const isEditAny = installments?.some(
      installment => installment.mode === 'edit'
    );

    if (isEditAny) {
      enqueueSnackbar(`Some installments are not saved. Please save them`, {
        variant: 'info',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }

    if (installmentMode === 'percentage') {
      const totalPercent = installments?.reduce(
        (sum, {installmentData}) =>
          Number(installmentData?.percentage_value) + sum,
        0
      );

      if (totalPercent !== 1) {
        enqueueSnackbar(`Percentage is not adding upto 100%`, {
          variant: 'info',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
    }

    setBackDropOpen(true);

    try {
      const data = {
        installments: installments?.map((installment, index) => ({
          name: installment.installmentData.name,
          description: installment.installmentData.description,
          percentage_value: installment.installmentData.percentage_value,
          amount: installment.installmentData.amount,
          order_ID: selectedOrder.order_ID,
          sequence: String(index + 1),
          payment_installment_ID: !uuidValidate(
            installment.installmentData.payment_installment_ID || ''
          )
            ? installment.installmentData.payment_installment_ID
            : undefined,
        })),
        deleted_installments: deletedInstallments.current,
      };

      const res = await appService.updateOrderInstallments(
        selectedOrder.order_ID,
        data
      );

      const temp = res.message.map((installment: IOrderInstallments) => ({
        installmentData: installment,
        mode: null,
      }));

      setInstallments(temp);

      setTemp.current = cloneDeep(temp);

      enqueueSnackbar(`Saved Successfully!`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } catch (err) {
      enqueueSnackbar(`Error Occured!`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.log(err);
    }

    setBackDropOpen(false);
  };

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        clear();
        handleClose();
      }}
      sx={{minWidth: {xs: '100%', sm: '100%', md: '100%'}, margin: 'auto'}}
      maxWidth="md"
      fullWidth
    >
      <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.modal + 1}}
        open={backDropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle>Payments</DialogTitle>
      <DialogContent sx={{minHeight: 200}}>
        <Grid container flexDirection="column" mt={1}>
          <Grid item container>
            <Grid item xs={12}>
              {' '}
              <Typography color="secondary">
                {' '}
                Select Payment Mode{' '}
              </Typography>{' '}
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="percentage"
                name="radio-buttons-group"
                row
                value={installmentMode}
                onChange={handleModeChange}
              >
                <FormControlLabel
                  value="percentage"
                  control={<Radio />}
                  label="Percentage"
                />
                <FormControlLabel
                  value="absolute"
                  control={<Radio />}
                  label="Absolute"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid item sx={{margin: 'auto', marginRight: 0}}>
            <Button
              size="small"
              variant="contained"
              onClick={addNewInstallment}
            >
              {' '}
              Add Payment{' '}
            </Button>
          </Grid>
          {installments ? (
            installments.length === 0 ? (
              <CustomNoRowsOverlay text="No Installments Available" />
            ) : (
              <Grid container rowSpacing={1} mt={1}>
                {installments.map((installment, index) => {
                  const {installmentData, mode} = installment;
                  const {
                    name,
                    percentage_value,
                    description,
                    payment_installment_ID,
                    amount,
                  } = installmentData;

                  const isEditMode = mode === 'edit';

                  const initialValues = {
                    name: name,
                    description: description,
                    percentage_value: Number(percentage_value) * 100,
                    payment_installment_ID,
                    amount: amount,
                  };

                  return (
                    <Grid item xs={12}>
                      <Formik
                        key={Math.random()}
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={values => {
                          console.log(values);
                          onSave(values);
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(),

                          // isAbsolute: Yup.boolean(),
                          // amount: Yup.string().when('isAbsolute', {
                          //   is: true,
                          //   then: Yup.number()
                          //     .required('Amount is required')
                          //     .min(0),
                          // }),

                          amount: Yup.number()
                            // .required('Amount is required')
                            .min(0),

                          description: Yup.string().required(),
                          percentage_value: Yup.number()
                            .typeError('Please enter a valid number')
                            .required('Percentage is required')
                            .min(0, 'Minimum atleast 0')
                            .max(100, 'Allowed maximum is 100'),
                        })}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values,
                          setFieldValue,
                          resetForm,
                          validateForm,
                          submitForm,
                        }) => {
                          return (
                            <Form noValidate onSubmit={handleSubmit}>
                              <Card elevation={2}>
                                <CardContent sx={{p: 1.5}}>
                                  {
                                    <Grid item container spacing={0.5}>
                                      <Grid item>
                                        <Typography
                                          fontSize={'1.1rem'}
                                          fontWeight="500"
                                        >{`Payment ${
                                          index + 1
                                        }`}</Typography>{' '}
                                      </Grid>

                                      <Grid
                                        item
                                        container
                                        flexDirection={'row'}
                                        spacing={0.5}
                                      >
                                        <Grid
                                          item
                                          container
                                          flexDirection={'column'}
                                          xs={7}
                                          spacing={0.5}
                                        >
                                          <Grid item>
                                            {' '}
                                            {isEditMode ? (
                                              <TextField
                                                required
                                                error={Boolean(
                                                  touched.name && errors.name
                                                )}
                                                autoFocus
                                                fullWidth
                                                size="small"
                                                helperText={
                                                  touched.name && errors.name
                                                }
                                                // value={values.name}

                                                defaultValue={values.name}
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{
                                                  className: classes.textfield,
                                                }}
                                              />
                                            ) : (
                                              name
                                            )}{' '}
                                          </Grid>
                                          <Grid item>
                                            {isEditMode ? (
                                              <TextField
                                                required
                                                error={Boolean(
                                                  touched.description &&
                                                    errors.description
                                                )}
                                                helperText={
                                                  touched.description &&
                                                  errors.description
                                                }
                                                fullWidth
                                                value={values.description}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name="description"
                                                size="small"
                                                inputProps={{
                                                  className: classes.textfield,
                                                }}
                                                maxRows={3}
                                                minRows={2}
                                                multiline
                                              />
                                            ) : (
                                              description
                                            )}
                                          </Grid>
                                        </Grid>

                                        {installmentMode === 'absolute' && (
                                          <Grid item xs={2}>
                                            {' '}
                                            {isEditMode ? (
                                              <TextField
                                                fullWidth
                                                size="small"
                                                id="input-with-icon-textfield"
                                                label="TextField"
                                                name="amount"
                                                type="number"
                                                error={Boolean(
                                                  touched.amount &&
                                                    errors.amount
                                                )}
                                                helperText={
                                                  touched.amount &&
                                                  errors.amount
                                                }
                                                value={values.amount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      {myOrg?.currency === 'IN'
                                                        ? '₹'
                                                        : '$'}
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            ) : (
                                              currencyFormatter(
                                                Number(amount || 0),
                                                myOrg?.currency
                                              )
                                            )}
                                          </Grid>
                                        )}

                                        {installmentMode === 'percentage' && (
                                          <Grid item xs={2}>
                                            {' '}
                                            {isEditMode ? (
                                              <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                type="number"
                                                name="percentage_value"
                                                error={Boolean(
                                                  touched.percentage_value &&
                                                    errors.percentage_value
                                                )}
                                                helperText={
                                                  touched.percentage_value &&
                                                  errors.percentage_value
                                                }
                                                value={values.percentage_value}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{
                                                  className: classes.textfield,
                                                  min: 0,
                                                  max: 100,

                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      %
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            ) : (
                                              Number(percentage_value) * 100 +
                                              '%'
                                            )}
                                          </Grid>
                                        )}
                                        <Grid item xs={3}>
                                          <Button
                                            size="small"
                                            onClick={() =>
                                              onDelete(payment_installment_ID)
                                            }
                                          >
                                            {' '}
                                            Delete{' '}
                                          </Button>{' '}
                                          {!isEditMode && (
                                            <Button
                                              size="small"
                                              onClick={() =>
                                                onEdit(payment_installment_ID)
                                              }
                                            >
                                              {' '}
                                              Edit{' '}
                                            </Button>
                                          )}
                                          {isEditMode && (
                                            <>
                                              {' '}
                                              <Button
                                                size="small"
                                                type="submit"
                                                // onClick={submitForm}
                                              >
                                                {' '}
                                                Save
                                              </Button>
                                              <Button
                                                size="small"
                                                onClick={async () => {
                                                  const res =
                                                    await validateForm();

                                                  resetForm();
                                                  onCancel(
                                                    payment_installment_ID
                                                  );
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                            </>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  }
                                </CardContent>
                              </Card>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  );
                })}
              </Grid>
            )
          ) : (
            <Box>
              {[1, 2].map(() => (
                <Skeleton variant="rectangular" height={'100px'} sx={{my: 1}} />
              ))}
            </Box>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => {
            clear();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleInstallmentsSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderPaymentInstallments;
