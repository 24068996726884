import { Grid } from '@mui/material';
import React from 'react';
import PosterPlaceHolder from '../../assets/images/360View.png';
import HandIcon from '../../assets/images/hand.webp';
import './ModelViewer.css'

const ModelViewer = ({model, poster}) => {
  return (
    <Grid container sx={{width: 1}}>
      <model-viewer
        src={model ? model : ''}
        poster={poster ? poster : PosterPlaceHolder}
        camera-controls
        alt="A 3D model carousel"
        shadow-intensity="1"
        // ar
        ar-modes="quick-look"
        camera-orbit="90deg "
        style={{
          background: 'white',
          width: '100%',
          height: '9rem',
        }}
      >
        <button slot="ar-button" id="ar-button">
          View in 3D
        </button>

        <div id="ar-prompt">
          <img src={HandIcon} />
        </div>

        <button id="ar-failure">
          Please use a spacious outdoor environment to view the model properly
        </button>
      </model-viewer>
    </Grid>
  );
};

export default ModelViewer;
