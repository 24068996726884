import {useEffect, Suspense, lazy, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  
} from 'react-router-dom';
//
import Login from '../../Pages/Login/Login';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';

import {loginRequest} from '../../config/authConfig';

import {useDispatch, useSelector} from 'react-redux';
import {
  getl2c2cStages,
  getProjects,
  getUserPrefs,
  getDocumentTypes,
  getACL,
} from '../redux/AppThunk';
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import Permits from '../../Pages/Permits/Permits';
import MiniDrawer from '../../components/AppBar/MiniDrawer';
import {RootState} from '../redux/store';
import {Box} from '@mui/system';
import ProtectedRoute from '../../components/ProtectedRoute';
import ForgotPassword from '../../Pages/Login/ForgotPassword';
import NotAuthroized from '../../Pages/NotAuthorized/NotAuthroized';
import {
  checkPageAccess,
  checkPageAccess2,
  checkPermisssion,
  getDomain,
} from '../../utils/utils';
import CreateLead from '../../Pages/Lead/createLead';
import LeadManagement from '../../Pages/Lead/Leads';
import Lead from '../../Pages/Lead/Lead';

import TSLLead from '../../Pages/Lead/tsl/Lead';

import Designs from '../../Pages/Designs/Designs';
import Design from '../../Pages/Designs/Design';
import SupportTicket from '../../Pages/Projects/SupportTicket';

import InstallerViewDesigns from '../../Pages/Designs/installerView/Designs';
import myOrders from '../../Pages/Order/MyOrders';
import TSLLogin from '../../Pages/Login/TSLLogin';
import {Fade, Grid} from '@mui/material';
import envConfig from '../../config/envConfig';
import SsoTSL from '../../Pages/Login/SsoTSL';
// import MiniDrawer2 from '../../components/AppBar/MiniDrawer2';
import {createBrowserHistory} from 'history';
import Stages from '../../Pages/Stages/Stages';
import Productorders from '../../Pages/ProductOrders/Productorders';
import appService from '../service/app.service';
import organisationService from '../service/organisation.service';
import {
  addArkaProfile,
  setLHS,
  setMyOrganisation,
  setRules,
} from '../redux/AppSlice';
import ProductCategory from '../../Pages/ProductCategory/ProductCategory';
import ImagesGallery from '../../Pages/ImagesGallery/ImagesGallery';
import SystemAlert from '../../Pages/SystemAlert/SystemAlert';
import Inverter from '../../Pages/Inverter/Inverter';
import Actions from '../../Pages/Actions/Actions';
import DefaultTasks from '../../Pages/DefaultTasks/DefaultTasks';
import {AppSettingsAltRounded} from '@mui/icons-material';

//@ts-ignore
import cookie from 'react-cookie';

import SplashScreen from '../../assets/images/splash360.png';
import {getLHS} from '../redux/initialState';
import TaskSummary from '../../Pages/TaskManagment/TaskSummary';

const MiniDrawer2 = lazy(() => import('../../components/AppBar/MiniDrawer2'));

const Profile = lazy(() => import('../../Pages/Profile/Profile'));

const TSLDashboard = lazy(() => import('../../Pages/Dashboard/tsl/Dashboard2'));
const Dashboard = lazy(() => import('../../Pages/Dashboard/dashboard'));

const Leads = lazy(() => import('../../Pages/Projects/temp/Projects'));

const Projects = lazy(() => import('../../Pages/Projects/Projects'));

const Project = lazy(() => import('../../Pages/Projects/Project1'));

const DMS = lazy(() => import('../../Pages/Device management/DMS'));

const ActivitiesOverview = lazy(
  () => import('../../Pages/ActivityOverview/ActivityOverview')
);

const Firmware = lazy(() => import('../../Pages/Firmwares/Firmware'));

const Permit = lazy(() => import('../../Pages/Permits/Permit'));

const Orders = lazy(() => import('../../Pages/Order/temp/Orders'));

const Roles = lazy(() => import('../../Pages/Roles/Roles'));

const Organisations = lazy(
  () => import('../../Pages/Organisation/Organisations')
);

const Users = lazy(() => import('../../Pages/Users/Users'));

function IndexSwitch({themeSetter}: any) {
  const dispatch = useDispatch();

  const userPrefs = useSelector((state: RootState) => state.appState.userPrefs);

  const menuItems = useSelector((state: RootState) => state.appState.lhs);
  const rules = useSelector((state: RootState) => state.appState.rules);
  const user = useSelector((state: RootState) => state.appState.user);
  const history = createBrowserHistory();

  useEffect(() => {
    console.log(user);

    if (!user) return;

    const {arka, tsl} = user;
    // eslint-disable-next-line no-restricted-globals
    const domain = getDomain(location.origin);

    console.log(domain);

    if (!arka) {
      appService
        .getSL360UserProfile({
          jwt_encoded_data: tsl.jwt_encoded_data,
        })
        .then(res => {
          console.log(res);

          const arkaUserProfile = res.message;

          localStorage.setItem(envConfig.env.ARKA_KEY , JSON.stringify( arkaUserProfile))

          const roleId = arkaUserProfile.roleId;

          console.log('role ID', roleId);
          appService
            .getACL(roleId)
            .then(res => {
              console.log(res);

              const {message} = res;

              dispatch(setRules(message));
            })
            .catch(err => {
              console.log(err);
            });

          dispatch(addArkaProfile(arkaUserProfile));
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      // gets called  when logged in on sl360 side
      const roleId = arka.roleId;

      appService
        .getACL(roleId)
        .then(res => {
          console.log(res);

          const {message} = res;

          dispatch(setRules(message));
        })
        .catch(err => {
          console.log(err);
        });

      organisationService
        .getMyOrganisations()
        .then(res => {
          console.log(res);

          dispatch(setMyOrganisation(res.message[0]));
        })
        .catch(err => {
          console.log(err);
        });

      const LHS = getLHS();

      console.log('userPermissions from cookie', LHS);
      if (!LHS) {
        organisationService
          .getLHS(roleId)
          .then(res => {
            console.log(res);

            const {message} = res;

            localStorage.setItem(
              envConfig.env.SIDEBAR,
              JSON.stringify(message)
            );

            dispatch(setLHS(message));
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        dispatch(setLHS(LHS));
      }
    }
  }, [user]);

    const BUILD_PATH  = envConfig.env.BUILD_PATH
  let redirectPath: any = menuItems
    ? menuItems.length > 0
      ? menuItems[0].path
      : '/'
    : '/';



    redirectPath =  `${BUILD_PATH}${redirectPath}`

    

  // const redirectPath =
  //   menuItems.length > 0
  //     ? menuItems[0].label === 'Dashboard'
  //       ? '/dashboard_'
  //       : // window.location.href = 'https://app.thesolarlabs.com'

  //         menuItems[0].url
  //     : '/';

  const pathname = history.location.pathname;
  console.log(menuItems, redirectPath , pathname );
 



  return (
    <>
      {!user ? (
        <>
          <Router >
            <Switch >


              {/* <Route path="/tslLogin" exact component={TSLLogin} /> */}
              <Route path={`${BUILD_PATH}/tslLogin_fix`} exact component={TSLLogin} />
              <Route
                path={`${BUILD_PATH}/tslLogin`}
                exact
                component={() => {
                  window.location.href = envConfig.env.TSL_AUTH;
                  return null;
                }}
              ></Route>

              <Route path={`${BUILD_PATH}/sso/tsl`} exact component={SsoTSL} />
              <Route path={`${BUILD_PATH}/login`} component={Login} exact />

              <Route path={`${BUILD_PATH}/forgotpassword`} component={ForgotPassword} exact />
              <Route path="*">
                <Redirect to={`${BUILD_PATH}/login`}></Redirect>
              </Route>

              
            </Switch>
          </Router>
        </>
      ) : menuItems ? (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <MiniDrawer2
            title="PM Portal"
            themeSetter={themeSetter}
            theme={userPrefs.prefs.mode}
          >
            <Grid container sx={{width: 1}} justifyContent={'center'}>
              <Box
                sx={{width: '100%', maxWidth: 1600}}
                justifyContent={'center'}
              >
                   <Suspense fallback={<div>Loading...</div>}>
                <Switch>

               
                  <Route path={`${BUILD_PATH}/`} exact>
                    <Redirect to={redirectPath}></Redirect>
                  </Route>
                  <Route path={`${BUILD_PATH}/login`} exact>
                    <Redirect to={redirectPath}></Redirect>
                  </Route>

                  <Route path={`${BUILD_PATH}/tslLogin` }exact>
                    <Redirect to={redirectPath}></Redirect>
                  </Route>

                  <Route path={`${BUILD_PATH}/tslLogin_fix` }exact>
                    <Redirect to={`${BUILD_PATH}/tslLogin_fix`}></Redirect>
                  </Route>

                  <Route path={`${BUILD_PATH}/profile`} component={Profile} />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/dashboard`}
                    component={TSLDashboard}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/tsl/projects',
                      system: 'sl360',
                    })}
                    redirectPath={redirectPath}
                  />

                  <Route
                    path={`${BUILD_PATH}/tsl/dashboard`}
                    exact
                    component={() => {
                      window.location.href = envConfig.env.TSL_URL;
                      return null;
                    }}
                  ></Route>

                  <ProtectedRoute
                path={`${BUILD_PATH}/dashboard_`}
                    component={Dashboard}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/dashboard_',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/dashboard_`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/projects`}
                    component={Projects}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/projects',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/projects`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/leadsboard`}
                    component={Leads}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/leadsboard',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/leadsboard`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/projects/:id`}
                    component={Project}
                    exact
                    isAllowed={checkPermisssion(
                      rules,
                      'projects/:projectId',
                      'GET'
                    )}
                    redirectPath={`${BUILD_PATH}/projects/:id`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/DeviceMangement`}
                    component={DMS}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/DeviceMangement',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/DeviceMangement`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/activity`}
                    component={ActivitiesOverview}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/activity',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/activity`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/firmwares`}
                    component={Firmware}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/firmwares',
                      system: 'sl360',
                    })}
                    redirectPath={'/firmwares'}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/permits`}
                    exact
                    component={Permits}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/permits',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/permits`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/permits/:permitId`}
                    exact
                    component={Permit}
                    isAllowed={checkPageAccess(rules, [
                      {route: '/permits/:permitid', method: 'GET'},
                    ])}
                    redirectPath={pathname}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/leadmanagement`}
                    exact
                    component={LeadManagement}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/leadmanagement',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/leadmanagement`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/leads/:leadId`}
                    exact
                    component={Lead}
                    isAllowed={checkPermisssion(rules, '/leads/:leadId', 'GET')}
                    redirectPath={pathname}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/tsl/leads/:leadId`}
                    exact
                    component={TSLLead}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/tsl/leads/:leadId',
                      system: 'sl360',
                    })}
                    redirectPath={pathname}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/createlead`}
                    exact
                    component={CreateLead}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/createlead',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/createlead`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/orders`}
                    exact
                    component={Orders}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/orders',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/orders`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/myorders`}
                    exact
                    component={myOrders}
                    isAllowed={checkPageAccess(rules, [
                      {route: '/orders/myorders', method: 'GET'},
                    ])}
                    redirectPath={`${BUILD_PATH}/myorders`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/org/settings`}
                    exact
                    component={Organisations}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/org/settings',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/org/settings`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/org/users`}
                    exact
                    component={Users}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/org/users',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/org/users`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/designs`}
                    exact
                    component={Designs}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/designs',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/designs`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/designs/installer`}
                    exact
                    component={InstallerViewDesigns}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/designs/installer',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/designs/installer`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/org/roles`}
                    exact
                    component={Roles}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/org/roles',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/org/roles`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/designs/:designId`}
                    exact
                    component={Design}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/designs/:designId',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/designs/:designId`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/supportTicket`}
                    exact
                    component={SupportTicket}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/supportTicket',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/supportTicket`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/productorders`}
                    exact
                    component={Productorders}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/productorders',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/productorders`}
                  />
                  <ProtectedRoute
                    path={`${BUILD_PATH}/productCategory2`}
                    component={ProductCategory}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/productCategory2',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/productCategory2`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/imageGallery`}
                    exact
                    component={ImagesGallery}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/imageGallery',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/imageGallery`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/systemAlert`}
                    exact
                    component={SystemAlert}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/systemAlert',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/systemAlert`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/inverter`}
                    exact
                    component={Inverter}
                    isAllowed={checkPageAccess2(rules, {
                      route: '/inverter',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/inverter`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/orgStages`}
                    component={Stages}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/orgStages',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/orgStages`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/WorkFlows`}
                    component={Actions}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/WorkFlows',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/WorkFlows`}
                  />

                  <ProtectedRoute
                    path={`${BUILD_PATH}/DefaultTasks`}
                    component={DefaultTasks}
                    exact
                    isAllowed={checkPageAccess2(rules, {
                      route: '/DefaultTasks',
                      system: 'sl360',
                    })}
                    redirectPath={`${BUILD_PATH}/DefaultTasks`}
                  />

                    <ProtectedRoute
                      path="/WorkFlows"
                      component={Actions}
                      exact
                      isAllowed={checkPageAccess2(rules, {
                        route: '/WorkFlows',
                        system: 'sl360',
                      })}
                      redirectPath={'/WorkFlows'}
                    />

                  <Route
                    path={`${BUILD_PATH}/notauthorized`}
                    exact
                    component={NotAuthroized}
                  />


                </Switch>
                </Suspense>
              </Box>
            </Grid>
          </MiniDrawer2>{' '}
        </Suspense>
      ) : (
        <div style={{width: '100%', height: '100vh'}}>
          <Fade in={true} {...(rules ? {timeout: 1000} : {})}>
            <img
              src={SplashScreen}
              height="100%"
              width="100%"
              style={{objectFit: 'contain'}}
              alt={'splash screen'}
            />
          </Fade>
        </div>
      )}
    </>
  );
}

export default IndexSwitch;
