import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import {checkPermisssion} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
import appService from '../../core/service/app.service';
import LoadingButton from '@mui/lab/LoadingButton';
import ILead from './__types__/lead.interface';
import {useSnackbar} from 'notistack';

import {
  DataGrid,
  GridCellParams,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid';

import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {Box} from '@mui/system';
import {AnyAsyncThunk} from '@reduxjs/toolkit/dist/matchers';
import {useParams, Link} from 'react-router-dom';
import {makeStyles, useTheme} from '@mui/styles';
import DeleteDialogOrg from '../Organisation/DeleteDialogOrg';
import {props} from 'bluebird';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import CreateProjectDialogue from './createProjectDialogue';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import IUser from '../Users/__types__/User.interface';
import organisationService from '../../core/service/organisation.service';
import envConfig from '../../config/envConfig';

interface ILeadViewInterface {
  leads: ILead[];
  setLeads: React.Dispatch<React.SetStateAction<ILead[] | null>>;

  view: 'grid' | 'list';
  row: any;
  setRows: any;
}

const useStyles = makeStyles((theme: any) => ({
  linkDecorationReset: {
    // @ts-ignore
    color: theme.palette.secondary.main,
  },
}));

function LeadView({leads, setLeads, view, row, setRows}: ILeadViewInterface) {
  const styles = useStyles();
  const history = useHistory();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const rules = useSelector((state: RootState) => state.appState.rules);

  const user = useSelector((state: RootState) => state.appState.user);
  const [rejectDialogOpen, setRejectDialogOpen] = React.useState(false);

  const [createProjectOpen, setCreateProjectOpen] = React.useState<{
    state: boolean;
    leadData: ILead | null;
  }>({state: false, leadData: null});

  const [loading, setLoading] = useState(false);
  const [leadId, setLeadId] = useState<any>();
  const [rejectE, setRejectE] = useState<any>();

  const [leadData, setLeadData] = useState<ILead | null>(null);

  const convertAction = checkPermisssion(rules, 'leads/convert', 'POST');

  console.log(convertAction);
  const tableColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Lead ID',
      flex: 2,
      renderCell: (params: any) => (
        <Grid>
          <Link
            to={`${envConfig.env.BUILD_PATH}/leads/${params.id}`}
            className={styles.linkDecorationReset}
            title="view project"
          >
            <b>{params.id}</b>
          </Link>
        </Grid>
      ),
    },
    {
      field: 'name',
      headerName: ' Name',
      flex: 3,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 7,
    },

    {
      field: 'assignee',
      headerName: 'Assignee',
      flex: 3,
    },
  ];

  if (convertAction) {
    tableColumns.push({
      field: 'action',
      headerName: 'Actions',
      flex: 3,
      align: 'center',
      headerAlign: 'center',

      sortable: false,
      hideSortIcons: true,
      renderCell: params => {
        const lead = leads.find(lead => lead.lead_ID === params.row.id);

        if (!lead) return;

        const {is_accepted} = lead;

        return (
          <>
            {is_accepted ? (
              <Link
                to={`${envConfig.env.BUILD_PATH}/projects/${lead.project_ID}`}
                className={styles.linkDecorationReset}
                title="view project"
              >
                <b>{lead.project_ID}</b>
              </Link>
            ) : (
              <Grid item>
                <Button
                  size="small"
                  sx={{mx: 1}}
                  onClick={e => dialogOpen(e, lead.lead_ID)}
                  title="Reject Lead"
                >
                  Reject
                </Button>

                <LoadingButton
                  variant="contained"
                  color="secondary"
                  loadingPosition="start"
                  size="small"
                  style={{color: 'white'}}
                  title="Accept Lead"
                  sx={{
                    mx: 1,

                    '& .MuiLoadingButton-loadingIndicator': {
                      position: 'initial',
                      mx: 0.5,
                    },
                  }}
                  onClick={e => {
                    setCreateProjectOpen({state: true, leadData: lead});
                  }}
                >
                  Accept
                </LoadingButton>
              </Grid>
            )}
          </>
        );
      },
    });
  }

  const convertToProject = async (
    lead_ID: string | undefined,
    user_ID: string | undefined,
    values: any
  ) => {
    try {
      setLoading(true);

      const data = {
        name: values.project_name,
        lead_ID: lead_ID,
        start_date: values.start_date,
        projected_close_date: values.end_date,
        survey_template_ID: values.survey_template_ID,
        activity_template_ID: values.activity_template_ID,
      
      };

      const updatedLeadData: ILead = {
        first_name: values.first_name,
        last_name: values.last_name,
        gmaps_address_string: values.location,
        address: values.address,
        email: values.email,
        phone_number: values.phone_number,
      };

      const updatedUserData: IUser = {
        first_name: values.first_name,
        gmaps_address_string: values.location,
        email: values.email,
        mobile_telephone_number: values.phone_number,
      };

      await appService.updateLead(lead_ID, updatedLeadData);

      if (user_ID) {
        await organisationService.updateUser(user_ID, updatedUserData);
      }

      const res = await appService.convertLeadToProject(data);

      const {project, lead: leadResult} = res.message;

      if (user.tsl) {
        try {
          const tslBody = {
            tsl_token: user?.tsl?.token,

            name: values.project_name,
            latitude: values.lat,
            longitude: values.lng,
            zoom: 21,
            client_name: values.first_name,
            client_email_id: values.email,
            client_address: values.location,
            projectId: project.project_ID,
          };

          const tslprojectresponse = await appService.createTSLProject(tslBody);
        } catch (error: any) {
          console.log(error);
          enqueueSnackbar(`Design creation Error: ${error}`, {
            variant: 'error',
            autoHideDuration: 5000,
          });
        }
      }

      const lead = leads.find(lead => lead.lead_ID === lead_ID);

      if (lead) {
        lead.is_accepted = true;
        lead.project_ID = project.project_ID;
        lead.address = leadResult.address;
        lead.first_name = leadResult.first_name;
        lead.fullname = leadResult.fullname;
        setLeads([...leads]);
      }

      console.log(res);

      enqueueSnackbar(`Lead is successfully converted to project`, {
        variant: 'success',
        autoHideDuration: 3000,
      });

      setLoading(false);

      setCreateProjectOpen({state: false, leadData: null});
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const reject = async (e: any, lead_ID: string | undefined) => {
    e.stopPropagation();
    console.log(lead_ID);
    try {
      const data = {lead_ID: lead_ID};
      const res = await appService.rejectLead(data);

      console.log(res);

      setLeads(leads.filter(lead => lead.lead_ID !== lead_ID));
      enqueueSnackbar(`${lead_ID} is rejected`, {
        variant: 'default',
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar('Operation failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      console.log(error);
    }
  };
  const dialogOpen = (e: any, leadId: any) => {
    setRejectDialogOpen(true);
    setLeadId(leadId);
    setRejectE(e);
  };

  const getFormattedRows = (leads: ILead[]) => {
    const rows = leads.map(lead => ({
      id: lead.lead_ID,
      name: lead.fullname,
      address: lead.address,
      assignee: lead.assignee?.org?.name,
    }));

    return rows;
  };

  const pushHistory = (lead_ID: string | undefined) => {
    history.push(`${envConfig.env.BUILD_PATH}/leads/${lead_ID}`);
  };

  const deleteLead = (leadId: string) => {
    setLoading(true);
    appService
      .deleteLead(leadId)
      .then(res => {
        console.log(res.message);

        const filteredLeads = leads.filter(lead => lead.lead_ID !== leadId);

        setLeads(filteredLeads);
        setRows(filteredLeads);

        setLoading(false);

        setRejectDialogOpen(false);

        enqueueSnackbar(`${leadId} deleted successfully`, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch(err => {
        setLoading(false);

        setRejectDialogOpen(false);
        enqueueSnackbar('Operation Failed ', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        console.log(err);
      });
  };
  console.log(leads);
  return (
    <Box>
      <BackDropCircularProgress open={loading} />
      <CreateProjectDialogue
        open={createProjectOpen.state}
        handleClose={() => setCreateProjectOpen({state: false, leadData: null})}
        leadData={createProjectOpen.leadData}
        convertToProject={convertToProject}
      />
      <DeleteDialogOrg
        open={rejectDialogOpen}
        handleClose={() => setRejectDialogOpen(false)}
        // handleDeleteOrgId={() => reject(rejectE, leadId)}
        handleDeleteOrgId={() => deleteLead(leadId)}
        info={'Are you sure you want to reject this lead?'}
      />{' '}
      <Box sx={{height: '70vh', width: '100%'}}>
        <DataGrid
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <PeopleAltIcon
                  fontSize="large"
                  sx={{color: '#999999', mb: 1}}
                />
                <Grid sx={{fontWeight: '500', mb: 1}}>No Leads Available</Grid>
                You can create/import lead by clicking on any of the button at
                the top right.
              </Stack>
            ),
          }}
          rows={row ? getFormattedRows(row) : []}
          columns={tableColumns}
        />
      </Box>
    </Box>
  );
}

export default LeadView;
