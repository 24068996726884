import * as React from 'react';

import {
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import {IActionOutcome, IFactOutcome} from './__types__/ActionOutcome';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export type DraggableListProps = {
  onDragEnd: any;

  items: IFactOutcome[];
  actionID: string;
};

const DraggableList = React.memo(
  ({onDragEnd, items, actionID}: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={e => onDragEnd(e, actionID)}>
        <Droppable droppableId={actionID}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {/* @ts-ignore */}
              {items.map((item, index) => (
                <Draggable
                  key={item.ID}
                  draggableId={item.outcomes_ID}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <ListItem
                        sx={{minwidth: 200, py: 0, pl: 0}}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // className={snapshot.isDragging ? classes.draggingListItem : ''}
                      >
                        {' '}
                        <ListItemText>
                          <Grid container alignItems={'center'}>
                            <Grid item xs={12}>
                              {' '}
                              <Typography>Outcome {index + 1}</Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              container
                              alignItems={'center'}
                              sx={{
                                background: '#E8EDF2',
                                borderLeft: '3px solid',
                              }}
                            >
                              <Grid item container xs={6} sx={{pl: 1}}>
                                <Grid item xs={12}>
                                  <Typography variant="h6">system</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="h5">
                                    {' '}
                                    {item.system}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid item container xs={6}>
                                <Grid item xs={12}>
                                  <Typography variant="h6">Outcome</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="h5">
                                    {' '}
                                    {item.subject}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>{' '}
                        </ListItemText>
                      </ListItem>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;
