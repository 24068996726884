import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import ConstructionIcon from '@mui/icons-material/Construction';
import {checkPermisssion} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
import appService from '../../core/service/app.service';
import LoadingButton from '@mui/lab/LoadingButton';
import IDesign from './__types__/design.interface';
import {useSnackbar} from 'notistack';

interface IMiniDesignCard {
  designData: IDesign;
  view: 'grid' | 'list';
}

const NA = '--:--';
function MiniDesignCard({designData, view}: IMiniDesignCard) {
  const history = useHistory();

  const formatCustomername = () => {
    try {
      const {Lead} = designData.project;
      const {first_name, salutation, last_name} = Lead;

      return `${salutation} ${first_name} ${last_name}`;
    } catch (error) {
      return NA;
    }
  };

  return (
    <Card elevation={2}>
      <CardContent
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
          position: 'relative',
          p: view === 'grid' ? '16px' : '2px 16px !important',

          minHeight: view === 'grid' ? 100 : '0',
        }}
        onClick={() => {
          console.log(history.goBack);
          history.push(`/designs/${designData.design_project_ID}`);
        }}
      >
        {view === 'grid' ? (
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1" gutterBottom>
                {designData.design_project_ID}
              </Typography>
            </Grid>

            <Grid item container flexDirection="row" alignItems={'center'}>
              <Grid item xs={4} style={{alignItems: 'center'}}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight={'bold'}
                >
                  {formatCustomername()}
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth style={{alignItems: 'center'}}>
                <Typography noWrap fontStyle={'italic'} variant="body2">
                  {designData.project?.Lead?.address || NA}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" alignItems={'center'}>
            <Grid item xs={3}>
              <Typography variant="subtitle1" gutterBottom>
                {designData.design_project_ID}
              </Typography>
            </Grid>

            <Grid item xs={4} style={{alignItems: 'center'}}>
              <Typography variant="subtitle1" gutterBottom fontWeight={'bold'}>
                {formatCustomername()}
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth style={{alignItems: 'center'}}>
              <Typography noWrap fontStyle={'italic'} variant="body2">
                {designData.project?.Lead?.address || NA}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default MiniDesignCard;
