import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  Card,
  IconButton,
} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import React, {useEffect, useState} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import appService, {appServiceTSL} from '../../core/service/app.service';
import ILead from './__types__/lead.interface';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallIcon from '@mui/icons-material/Call';
import EditIcon from '@mui/icons-material/Edit';

import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Map from '../Projects/Map';
import envConfig from '../../config/envConfig';
import IUser from '../../core/__types__/Users.interface';
import LoadingButton from '@mui/lab/LoadingButton';
import {useSnackbar} from 'notistack';
import IOrganisation from './__types__/organisation.interface';
import LinearProgressWithLabel from '../../components/LinearProgressBar';
import {checkPermisssion} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
import TitleValueDisplay from './TitleValueDisplay';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditleadDialogue from './EditleadDialogue';

const useStyles = makeStyles(theme => ({
  linkDecorationReset: {
    textDecoration: 'none',
    // @ts-ignore
    color: theme.palette.secondary.main,
  },
}));

const titleMapping = [
  {key: 'fullname', label: 'Full Name'},
  {key: 'phone_number', label: 'Contact No.'},
  {key: 'email', label: 'Email'},
  {key: 'gmaps_address_string', label: 'Address'},
  {key: 'energy_consumption', label: 'Energy Consumption'},
  {
    key: 'previous_month_electricity_bill',
    label: 'Previous month electricity bill',
  },
  {key: 'latitude', label: 'Latitude'},
  {key: 'longitude', label: 'Longitude'},
  {key: 'lead_source', label: 'Source'},
  {key: 'roof_size', label: 'Roof size'},
  {key: 'terrace_yard_size', label: 'Terrace/Yard size'},
];

function Lead() {
  const rules = useSelector((state: RootState) => state.appState.rules);
  const {leadId} = useParams<{leadId: string}>();
  const [progress, setProgress] = useState(0);
  const [leadData, setLeadData] = useState<null | ILead>(null);

  const [installers, setInstallers] = useState<IOrganisation[] | null>(null);

  const [installer, setInstaller] = useState<IOrganisation | any>('');

  const [assigning, setAssigning] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [assigned, setAssigned] = useState<boolean>(false);

  const [interestData, setInterestData] = useState<any>();

  const styles = useStyles();
  const history = useHistory();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const getLeadData = () => {
    appService
      .getLeadById(leadId)
      .then(res => {
        console.log(res);

        const leadData = res.message;
        setLeadData(leadData);

        if (leadData?.user_ID && leadData?.user_ID !== '') {
          appService
            .getUserInterests(leadData.user_ID)
            .then(interestsResponse => {
              setInterestData(interestsResponse.message);
            });
        }

        appService
          .getOrganisations(envConfig.env.ORGID)
          .then(res => {
            console.log(res);

            const organisations: IOrganisation[] = res.message;

            const assignedorg = organisations.find(
              org => org.org_ID === leadData?.assignee?.org?.org_ID
            );

            console.log(assignedorg);

            setInstallers(organisations);

            if (assignedorg) {
              setInstaller(assignedorg);

              setAssigned(true);
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getLeadData();
  }, []);

  const breadcrumbs = [
    <Link className={styles.linkDecorationReset} to={`/leadmanagement`}>
      <Typography sx={{color: '#263342'}} variant="body1">
        Lead Management
      </Typography>
    </Link>,
    <Typography
      variant="body1"
      key="2"
      sx={{cursor: 'pointer', color: '#263342'}}
    >
      {/* {leadId} */}
      {'Lead Summary'}
    </Typography>,
  ];

  const handleInstallerSelect = (e: any, value: IOrganisation) => {
    console.log(value);
    setInstaller(value);
  };
  console.log(installers);
  const handleAssignLead = async () => {
    setAssigning(true);
    try {
      if (!leadData) return;

      const createProjectBody = {
        ...leadData,
        name: leadData.fullname,
        client_name: leadData.fullname,
        client_email_id: leadData.email,
        client_phone: leadData.phone_number,
        quota_type: 'LARGE',
        org_ID: installer.org_ID,
        leads: [{lead_ID: leadId}],
      };

      const response = await appService
        .assignLead(createProjectBody)
        .then(res => console.log(res))
        .catch(err => console.log(err));

      console.log(createProjectBody);

      const data = {
        leadId: leadData.lead_ID,
        type: 'INSTALLER_ASSIGNED',
      };
      if (leadData.user_ID) appService.sendMailOnTaskUpdate(data);

      enqueueSnackbar(`Lead is successfully assigned to ${installer.name}.`, {
        variant: 'success',
        autoHideDuration: 3000,
      });

      setAssigned(true);
    } catch (err) {
      console.log(err);

      enqueueSnackbar(`Operation Failed`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setAssigning(false);
  };

  return (
    <Box>
      <Breadcrumbs
        separator="/"
        aria-label="breadcrumb "
        sx={{backgroundColor: 'none'}}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <EditleadDialogue
        handleClose={() => {
          setEditOpen(false);
        }}
        open={editOpen}
        leadData={leadData}
        getLeadData={getLeadData}
      />
      {leadData ? (
        <Grid container sx={{backgroundColor: 'none ', maxheight: 350}}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            mt={1}
            sx={{backgroundColor: 'none'}}
          >
            <Grid item>
              <Typography variant="subtitle1"> {leadData.lead_ID} </Typography>{' '}
            </Grid>
          </Grid>

          <Grid
            item
            container
            p={0}
            pt={2}
            sx={{borderTop: '0px solid white', backgroundColor: 'none'}}
            //mt={1}
          >
            <Grid container item sx={{maxheight: 150}} spacing={0} m={0}>
              <Grid item md={8} sx={{backgroundColor: 'none'}} p={0}>
                <Card style={{minHeight: '260px'}}>
                  <Grid
                    container
                    item
                    alignItems={'center'}
                    sx={{background: '#E8EDF2'}}
                  >
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2" sx={{ml: 1}}>
                        LEAD INFORMATION
                      </Typography>
                      <IconButton onClick={() => setEditOpen(true)}>
                        <Grid container alignItems="center">
                          <EditOutlinedIcon />
                          <Typography sx={{ml: 1}}>{'Edit'}</Typography>
                        </Grid>
                      </IconButton>
                    </Grid>
                  </Grid>
                  {/*cutomer details*/}
                  <Grid
                    item
                    container
                    //  flexDirection="column"
                    // pl={3}
                    my={2}
                    xs={12}
                    md={12}
                  >
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      xs={12}
                      sx={{backgroundColor: 'none'}}
                      rowSpacing={2}
                    >
                      {/* @ts-ignore */}
                      {titleMapping.map(
                        (item: any) =>
                          // @ts-ignore
                          leadData[item.key] && (
                            <TitleValueDisplay
                              title={item.label}
                              // @ts-ignore

                              value={leadData[item.key]}
                            />
                          )
                      )}

                      {checkPermisssion(rules, 'leads/assign', 'POST') && (
                        <Grid item xs={4}>
                          {installers ? (
                            <Grid
                              item
                              container
                              mb={2}
                              sx={{backgroundColor: 'none'}}
                            >
                              {/* installer 
                            <Grid
                              item
                              container
                              xs={12}
                              mt={2}
                              md={12}
                              alignItems="center"
                            > */}
                              {/* <Grid item xs={12} md={12}>
                              <Typography>Installer :</Typography>
                            </Grid> */}

                              <Grid item xs={12} md={12}>
                                <Typography
                                  variant="body2"
                                  fontSize={'0.75rem'}
                                  fontWeight={400}
                                  ml={2}
                                  mb={1}
                                  color={'#777777'}
                                >
                                  {'Installer'}
                                </Typography>
                                <Autocomplete
                                  size="small"
                                  disabled={assigned}
                                  onChange={handleInstallerSelect}
                                  disablePortal
                                  id="combo-box-demo"
                                  value={installer}
                                  options={installers}
                                  getOptionLabel={option => option.name || ''}
                                  sx={{
                                    width: '90%',
                                    ml: 2,
                                    fontSize: '0.8rem',
                                    fontWeight: '400',
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Installer"
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Skeleton />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {/* <Grid item container> */}
                    {installer && (
                      <Grid
                        item
                        container
                        justifyContent={'flex-start'}
                        sx={{ml: 2, mt: 2}}
                        alignItems={'flex-start'}
                      >
                        <LoadingButton
                          loading={assigning}
                          variant="contained"
                          color="secondary"
                          onClick={handleAssignLead}
                          disabled={assigned}
                        >
                          Assign
                        </LoadingButton>
                      </Grid>
                    )}

                    {/* {!leadData.user_ID &&
                      <Grid
                        container
                        sx={{mt:2}}
                        >
                        <TitleValueDisplay title='User ID' value={leadData.user_ID} />

                      </Grid>
                    } */}
                  </Grid>
                </Card>
              </Grid>

              {/*Geo map*/}
              <Grid
                //container
                item
                xs={12}
                md={4}
                style={{height: '100%'}}
                sx={{
                  backgroundColor: 'none',
                  pl: {lg: 2, xl: 2, md: 2},
                  pt: {xs: 2, sm: 2, lg: 0, md: 0, xl: 0},
                }}
              >
                <Map
                  address={leadData.address}
                  latlng={{lat: leadData.latitude, lng: leadData.longitude}}
                  minheight={'260px'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Box>
  );
}

export default Lead;
