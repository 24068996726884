import {CircularProgress, Grid} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect, useState} from 'react';
import appService from '../../core/service/app.service';
import MiniOrderCard from '../Order/MiniOrderCard';
import MiniDesignCard from './MiniDesignCard';
import IDesign from './__types__/design.interface';
import SearchFilter from '../../components/SearchFilter';


function Designs() {
  const [designs, setDesigns] = useState<null | IDesign[]>(null);
  const [view, setView] = useState<'list' | 'grid'>('grid');
  useEffect(() => {
    appService
      .getDesignProjects()
      .then(res => {
        console.log(res);

        setDesigns(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const changeView = (view: 'list' | 'grid') => {
    setView(view);
  };
  
  return (
    <Box>
      <SearchFilter changeView={changeView} view={view}  />
      <Grid container spacing={view === 'grid' ? 3 : 0.5} pt={1}>
        {designs ? (
          designs.map(design => (
            <Grid
              item
              xs={12}
              md={view === 'grid' ? 6 : 12}
              xl={view === 'grid' ? 4 : 12}
            >
              <MiniDesignCard designData={design} view={view} />
            </Grid>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={300}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default Designs;
