import {createSlice, Slice} from '@reduxjs/toolkit';
import {getUniqueValues} from '../../utils/utils';
import AppState from '../__types__/AppState.interface';
import {
  addUserPrefs,
  getDocumentTypes,
  getHubSpotContactData,
  getl2c2cStages,
  getProfileData,
  getProjects,
  getSalesforceData,
  getUserPrefs,
  getACL,
  getOrganisations,
  getAdminUsers,
  updateOrganisationThunk,
  createOrganisationThunk,
} from './AppThunk';
import {
  contactDataAdapter,
  dashboardDataAdapter,
  initialState,
  leadDataAdapter,
  opportunityDataAdapter,
  quoteDataAdapter,
  projectDataAdapter,
} from './initialState';
import {RootState} from './store';

export const appSlice: Slice<AppState> = createSlice({
  name: 'arka/project-management',
  initialState,
  reducers: {
    setOrganisations(state, action) {
      state.organisations = action.payload;
    },
    setMyOrganisation(state, action) {
      state.org = action.payload;
    },

    addOrganisation(state, action) {
      const {organisations} = state;
      if (organisations) {
        state.organisations = [{...action.payload}, ...organisations];
      }
    },

    setRules(state, action) {
      state.rules = action.payload;
    },

    setLHS(state, action) {
      state.lhs = action.payload;
    },

    addArkaProfile(state, action) {
      const {user} = state;
      if (user) {
        state.user = {...state.user, arka: {...action.payload}};
      }
    },

    updateOrganisation(state, action) {
      const updatedOrg = action.payload;
      const org = state.organisations?.find(
        org => org.org_ID === updatedOrg.org_ID
      );

      if (org) {
        org.name = updatedOrg.name;
        org.description = updatedOrg.description;
      }
    },

    updateOrganisationAdmin(state, action) {
      const updatedOrg = action.payload;
      const org = state.organisations?.find(
        org => org.org_ID === updatedOrg.org_ID
      );

      if (org) {
        org.admin = updatedOrg.admin;
      }
    },

    setUser(state, action) {
      state.user = action.payload;
    },
    setEnv(state, action) {
      state.env = action.payload;
    },

    addTowishlist(state, action) {
      state.userPrefs.prefs.wishlist.push(action.payload);
    },
    removeFromwishlist(state, action) {
      const {wishlist} = state.userPrefs.prefs;
      state.userPrefs.prefs.wishlist = wishlist.filter(
        (id: String) => id !== action.payload
      );
    },
    updateTheme(state, action) {
      state.userPrefs.prefs.mode = action.payload;
    },

    updateProjectStage(state, action) {
      const {id, stage} = action.payload;

      const project = state.TSLProjects?.find(project => project.id === id);

      console.log(project, action);
      project.stage = stage;
    },

    updateTwinDeviceIds(state, action) {
      state.twinDevices.devices = [
        ...new Set([...state.twinDevices.devices, ...action.payload]),
      ];
    },

    removeTwinDeviceIds(state, action) {
      state.twinDevices.devices = state.twinDevices.devices.filter(
        id => !action.payload.includes(id)
      );
    },

    updateTwinDeviceProgress(state, action) {
      state.twinDevices.progress = action.payload;
    },

    addMenuItems(state, action) {
      state.menuItems = action.payload;
    },

    addTSLProjects(state, action) {
      state.TSLProjects = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOrganisations.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrganisations.fulfilled, (state, action) => {
        state.organisations = action.payload;
        state.loading = false;
      })
      .addCase(getHubSpotContactData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHubSpotContactData.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.loading = false;
        state.l2c2Stages = action.payload.l2c2StagesResults;

        dashboardDataAdapter.addMany(
          state.dashboardDataAdapter,
          action.payload.hubspotResults
        );
      })
      .addCase(getSalesforceData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSalesforceData.fulfilled, (state, action) => {
        if (action.payload.type === 'lead') {
          leadDataAdapter.addMany(
            state.leadDataAdapter,
            action.payload.results
          );
        } else if (action.payload.type === 'contact') {
          contactDataAdapter.addMany(
            state.contactDataAdapter,
            action.payload.results
          );
        } else if (action.payload.type === 'opportunity') {
          opportunityDataAdapter.addMany(
            state.opportunityDataAdapter,
            action.payload.results
          );
        } else if (action.payload.type === 'quote') {
          quoteDataAdapter.addMany(
            state.quoteDataAdapter,
            action.payload.results
          );
        }
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        console.log(action.payload);
        state.profileData = action.payload.profile;
      })
      .addCase(getProfileData.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
        // projectDataAdapter.addMany(state.projectDataAdapter, action.payload);
      })
      .addCase(getProjects.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(getUserPrefs.fulfilled, (state, action) => {
        state.userPrefs = action.payload;
      })
      .addCase(addUserPrefs.fulfilled, (state, action) => {
        state.userPrefs = action.payload;
      })
      .addCase(addUserPrefs.rejected, (state, action) => {
        console.log('add user prefs rejected', state, action);
      })
      .addCase(getl2c2cStages.fulfilled, (state, action) => {
        state.l2c2Stages = action.payload;
      })
      .addCase(getl2c2cStages.rejected, (state, action) => {
        console.log('action getl2c2 rejected', state, action);
      })
      .addCase(getDocumentTypes.fulfilled, (state, action) => {
        state.documentTypes = action.payload;
      })
      .addCase(getDocumentTypes.rejected, (state, action) => {
        console.log('get document types rejected', state, action);
      })
      .addCase(getACL.fulfilled, (state, action) => {
        state.rules = action.payload;
      })
      .addCase(getACL.rejected, (state, action) => {
        console.log('get document types rejected', state, action);
      })
      .addCase(getAdminUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAdminUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.adminUsers = action.payload;
      })
      .addCase(updateOrganisationThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrganisationThunk.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(createOrganisationThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createOrganisationThunk.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      });
  },
});

export const selectDashboardData = (state: RootState) =>
  state.appState.dashboardData;
export const selectLoading = (state: RootState) => state.appState.loading;
export const selectOrganisationData = (state: RootState) =>
  state.appState.organisations;
export const selectAdminUsersData = (state: RootState) =>
  state.appState.adminUsers;
export const selectNotificationData = (state: RootState) =>
  state.appState.notifications;

export const dashboardDataSelector = dashboardDataAdapter.getSelectors(
  (state: RootState) => state.appState.dashboardDataAdapter
);
export const selectL2CStages = (state: RootState) => state.appState.l2c2Stages;

export const selectDashboardDataAdapter = dashboardDataAdapter.getSelectors(
  (state: RootState) => state.appState.dashboardDataAdapter
);
export const selectLeadDataAdapter = dashboardDataAdapter.getSelectors(
  (state: RootState) => state.appState.leadDataAdapter
);
export const selectOpportunityDataAdapter = dashboardDataAdapter.getSelectors(
  (state: RootState) => state.appState.opportunityDataAdapter
);
export const selectContactDataAdapter = dashboardDataAdapter.getSelectors(
  (state: RootState) => state.appState.contactDataAdapter
);

export const selectQuoteDataAdapter = quoteDataAdapter.getSelectors(
  (state: RootState) => state.appState.quoteDataAdapter
);

// export const selectProjectDataAdapter = projectDataAdapter.getSelectors(
//   (state: RootState) => state.appState.projectDataAdapter
// );

// exporting reducer
export default appSlice.reducer;

const {
  addTowishlist,
  removeFromwishlist,
  updateTheme,
  updateTwinDeviceIds,
  updateTwinDeviceProgress,
  removeTwinDeviceIds,
  setUser,
  addMenuItems,
  addTSLProjects,
  setNotifications,
  updateProjectStage,
  setOrganisations,
  updateOrganisation,
  updateOrganisationAdmin,
  setMyOrganisation,
  addOrganisation,
  addArkaProfile,
  setRules,
  setLHS,
  setEnv,
} = appSlice.actions;

export {
  addTowishlist,
  removeFromwishlist,
  updateTheme,
  updateTwinDeviceIds,
  updateTwinDeviceProgress,
  removeTwinDeviceIds,
  setUser,
  addMenuItems,
  updateProjectStage,
  addTSLProjects,
  setNotifications,
  setOrganisations,
  setMyOrganisation,
  updateOrganisation,
  updateOrganisationAdmin,
  addOrganisation,
  addArkaProfile,
  setRules,
  setLHS,
  setEnv,
};
