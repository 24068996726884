import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, Typography} from '@mui/material';
import ILead from './__types__/lead.interface';
import TitleValueDisplay from './TitleValueDisplay';
import * as Yup from 'yup';
import appService from '../../core/service/app.service';
import {Formik} from 'formik';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import {useSnackbar} from 'notistack';
interface IEditlead {
  handleClose: any;
  open: boolean;

  leadData: ILead | null;

  getLeadData: any;
}
function EditleadDialogue({
  handleClose,
  open,
  leadData,
  getLeadData,
}: IEditlead) {
  //@ts-ignore

  const titleMapping = [
    {
      key: 'first_name',
      label: 'First Name',
      validation: Yup.string().required('First name is required.'),
    },
    {
      key: 'last_name',
      label: 'Last Name',
      validation: Yup.string().required('First name is required.'),
    },
    {
      key: 'phone_number',
      label: 'Contact No.',
      validation: Yup.string().required('First name is required.'),
    },
    {
      key: 'email',
      label: 'Email',
      validation: Yup.string()
        .email('Email must be formatted correctly.')
        .max(255)
        .required('Email is required.'),
    },

    {key: 'energy_consumption', label: 'Energy Consumption'},
    {
      key: 'previous_month_electricity_bill',
      label: 'Previous month electricity bill',
    },

    {key: 'roof_size', label: 'Roof size'},
    {key: 'terrace_yard_size', label: 'Terrace/Yard size'},
  ];

  const [initvalues, setInitValues] = useState({});

  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    if (!leadData) return;
    //@ts-ignore
    let init = {};

    let val = {};

    titleMapping.forEach(item => {
      //@ts-ignore
      init = {...init, [item.key]: leadData[item.key]};

      val = {...val, [item.key]: item.validation};
    });

    console.log(init, val);
    setInitValues(init);
    setValidation(val);
  }, [leadData]);

  const updateLead = async (values: any) => {
    console.log(values);

    setLoading(true);
    try {
      const result = await appService.updateLead(leadData?.lead_ID, values);

      console.log(result);

      enqueueSnackbar(`Updated Successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });

      getLeadData();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(`Operation Failed`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setLoading(false);

    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <BackDropCircularProgress open={loading} />
      <DialogTitle>Edit Lead</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initvalues}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object().shape(validation)}
          onSubmit={updateLead}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                {leadData && (
                  <Grid
                    item
                    container
                    //  flexDirection="column"
                    // pl={3}
                    my={2}
                    xs={12}
                    md={12}
                  >
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      xs={12}
                      sx={{backgroundColor: 'none'}}
                      spacing={2}
                    >
                      {/* @ts-ignore */}
                      {titleMapping.map(
                        (item: any) =>
                          // @ts-ignore
                          leadData[item.key] && (
                            <>
                              {' '}
                              <Grid item xs={4} sx={{backgroundColor: 'none'}}>
                                <Typography
                                  variant="body2"
                                  fontSize={'0.75rem'}
                                  fontWeight={400}
                                  color={'#777777'}
                                >
                                  {item.label}
                                </Typography>

                                <TextField
                                  // @ts-ignore
                                  error={Boolean(errors[item.key])}
                                  // @ts-ignore

                                  helperText={errors[item.key]}
                                  variant="outlined"
                                  size="small"
                                  name={item.key}
                                  //@ts-ignore
                                  value={values[item.key]}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </>
                          )
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit">
                          Save
                        </Button>
                      </DialogActions>
                    </Grid>
                  </Grid>
                )}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default EditleadDialogue;
