import {Grid, Typography, Button, Badge, IconButton, Box} from '@mui/material';
import React, {useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useSnackbar} from 'notistack';
import ModelViewer from '../ModelViewer/ModelViewer';

const FileUploaderDnd = ({
  fileTypes,
  files,
  setFiles,
  filesSrc,
  setFilesSrc,
  limit,
  title,
  //deleted ids tracker for UpdateSku
  setDeletedIds,
  setNewFileCount,
}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);

  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleAddFile = newFile => {
    console.log(newFile[0], files);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files && files.length === limit)
        enqueueSnackbar('Maximum of ' + limit + ' file can be uploaded', {
          variant: 'info',
        });
      else {
        if(typeof setNewFileCount === 'function'){
          setNewFileCount(prevValue => prevValue + 1);
        }
        setFiles([...files, ...newFile]);
        setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
      }
    }
  };

  const handleRemoveImage = (index, file) => {
    console.log(index, file);
    console.log(files);

    if(typeof setDeletedIds === 'function' && typeof setNewFileCount === 'function'){
      if (file && file.image_ID) {
        setDeletedIds(deletedIds =>
          setDeletedIds([...deletedIds, file.image_ID])
        );
      } else {
        setNewFileCount(prevValue => prevValue - 1);
      }
    }
    else{
      console.log('setDeletedIds & setNewFileCount not found')
    }
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };

  const cancelOverWrite = () => {
    setMatchingFileIndex(null);
    setTempNewFile(null);
  };

  const initiateOverWrite = () => {
    let temp = files;
    let tempSrc = filesSrc;

    console.log(temp, tempSrc, tempNewFile);

    temp[matchingFileIndex] = tempNewFile;
    tempSrc[matchingFileIndex] = window.URL.createObjectURL(tempNewFile);

    console.log(temp, tempSrc);

    setFiles(temp);
    setFilesSrc(tempSrc);

    setMatchingFileIndex(null);
    setTempNewFile(null);
    forceUpdate();
  };
  return (
    <Grid container sx={{mt: 1}}>
      {files && files.length > 0 && filesSrc && filesSrc.length > 0 && (
        <Grid container sx={{width: 1}}>
          {filesSrc.map((image, index) => (
            <Grid item sx={{mr: 5, mt: 2}}>
              <Badge
                badgeContent={
                  <IconButton
                    size="small"
                    style={{padding: 0}}
                    onClick={() => handleRemoveImage(index, files[index])}
                  >
                    <CloseRoundedIcon sx={{fontSize: '1rem'}} />
                  </IconButton>
                }
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    padding: 0,
                  },
                }}
              >
                {fileTypes[0] === 'GLB' ? (
                  <Box
                    component={'div'}
                    sx={{
                      width: '160px',
                      height: '100px',
                      mb: 10,
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                    }}
                  >
                    <ModelViewer model={image} />
                  </Box>
                ) : (
                  <Box
                    component={'div'}
                    sx={{
                      width: '80px',
                      height: '80px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                    }}
                  >
                    <img
                      src={image}
                      alt="uploaded"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                )}
              </Badge>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2" sx={{mx: 1}}>
          {title + ' - ( maximum ' + limit + ')'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FileUploader
          multiple={true}
          handleChange={handleAddFile}
          name="file"
          types={fileTypes}
          children={
            <Grid
              container
              sx={{
                height: 100,
                width: 1,
                border: `2px dashed`,
                borderColor: 'secondary.main',
              }}
              justifyContent="center"
            >
              <Typography variant="body2" sx={{mt: 2}}>
                Click here to upload or drag and drop
              </Typography>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default FileUploaderDnd;
