import react, {useEffect, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';

import InboxIcon from '@mui/icons-material/Inbox';

import {makeStyles} from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import IStage from './__types__/Stages.interface';

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteDialogOrg from '../Organisation/DeleteDialogOrg';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import appService from '../../core/service/app.service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tasks from './Tasks';
import {TaskSharp} from '@mui/icons-material';

const useStyles = makeStyles({
  draggingListItem: {
    // background: 'rgb(235,235,235)',
  },
});

interface Stage {
  [key: string]: IStage[];
}

export type DraggableListItemProps = {
  stage: IStage;
  index: number;
  setStages: any;
  stages: Stage | null;
  stageCategory: string;
  onStageDelete: any;
  onActivityDelete: any;
};

const DraggableListItem = ({
  stage,
  index,
  setStages,
  stages,
  stageCategory,
  onStageDelete,
  onActivityDelete,
}: DraggableListItemProps) => {
  const classes = useStyles();

  const [edit, setEdit] = useState(false);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [value, setValue] = useState(stage.name);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleDelete = () => {
    const stageCategory = stage.stage_category;

    if (!stageCategory || !stages) return;

    const tempStages = {
      ...stages,
      [stageCategory]: stages[stageCategory]?.filter(
        //@ts-ignore
        item => item.stage_ID !== stage.stage_ID
      ),
    };

    if (tempStages) setStages(tempStages);
    if (stage?.stage_ID) onStageDelete(stage.stage_ID);
  };
  const handleSave = () => {
    setEdit(false);

    if (!stages) return;

    const Id = stage.Id;

    const x = stages[stageCategory].find(stage => stage.Id === Id);
    if (x) {
      x.name = value;

     
      setStages({...stages});
    }
  };

  return (
    <>
      {' '}
      <DeleteDialogOrg
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
        handleDeleteOrgId={handleDelete}
        info={'Are you sure you want to delete this stage?'}
      />
      <Draggable draggableId={String(stage.Id)} index={index}>
        {(provided, snapshot) => {
          return (
            <Accordion
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={snapshot.isDragging ? classes.draggingListItem : ''}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container alignItems={'center'}>
                  <Grid item xs={12}>
                    {' '}
                    <Typography>Stage {index}</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    alignItems={'center'}
                    sx={{background: '#E8EDF2', borderLeft: '3px solid'}}
                  >
                    <Grid item xs={8} sx={{pl: 1}}>
                      {' '}
                      {edit ? (
                        <TextField
                          size="small"
                          value={value}
                          onChange={e => {
                            setValue(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography> {value}</Typography>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      {' '}
                      <>
                        {edit ? (
                          <IconButton size="small" onClick={handleSave}>
                            <DoneIcon />
                          </IconButton>
                        ) : (
                          <IconButton size="small" onClick={handleEdit}>
                            <EditIcon />
                          </IconButton>
                        )}

                        <IconButton
                          size="small"
                          onClick={() => {
                            setDeleteOpen(true);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>

                        <IconButton size="small" {...provided.dragHandleProps}>
                          <DragIndicatorIcon />
                        </IconButton>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Tasks
                  Id={stage.Id}
                  stageId={stage.stage_ID}
                  tasks={stage.activities}
                  stages={stages}
                  setStages={setStages}
                  stageCategory={stageCategory}
                  onActivityDelete={onActivityDelete}
                />
              </AccordionDetails>
            </Accordion>
          );
        }}
      </Draggable>
    </>
  );
};

export default DraggableListItem;
