import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Grid,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgressDialog = props => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpenDialog(false)}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography variant="h5">{'Please wait...'}</Typography>
      </DialogTitle>
      <DialogContent sx={{minWidth: 500, minHeight: 200}}>
        <Grid
          container
          style={{width: '100%', height: 200}}
          justifyContent="center"
        >
          <CircularProgress sx={{mt:10}} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CircularProgressDialog;
