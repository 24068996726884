import React, {useEffect, useState} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import appService from '../../core/service/app.service';
import Headings from '../../components/Headings';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Card,
} from '@mui/material';
import ImageUploader from './ImageUploader';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditImageGallery from './EditImageGallery';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import {DateTime, Settings} from 'luxon';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ViewImageGallery from './ViewImageGallery';
import DeleteDialogOrg from '../Organisation/DeleteDialogOrg';

const ImagesGallery = () => {
  const [galleryData, setGalleryData] = useState<any>();
  const [imgUploader, setImageUplaoder] = useState<Boolean>(false);
  const [editDialog, setEditDialog] = useState<Boolean>(false);
  const [imgesUpdate, setImageUpdate] = useState<Boolean>(false);
  const [imges3dUpdate, setImage3dUpdate] = useState<Boolean>(false);
  const [backdrop, setBackdrop] = useState<any>(false);
  const [editData, setEditData] = useState<any>();
  const [viewDetailsData, setViewDetailsData] = useState<any>();

  const [viewDetails, setViewDetails] = useState<Boolean>(false);
  const [deleteDial, setDeleteDial] = useState<Boolean>(false);
  const [deleteData, setDeleteData] = useState<Boolean>();

  useEffect(() => {
    setBackdrop(true);
    appService
      .getImageGallery()
      .then(res => {
        setGalleryData(res.message);
        setBackdrop(false);
      })
      .catch(err => console.log(err));

    appService
      .getProductImage()
      .then(res => console.log(res))
      .catch(err => console.log(err));
  }, [imgesUpdate]);
  console.log(galleryData);

  const deleteImageGallery = (file: any) => {
    setBackdrop(true);
    appService
      .deleteImageFromGallery(file.image_ID)
      .then(res => {
        setImageUpdate(!imgesUpdate);
        setBackdrop(false);
        setDeleteDial(false);
      })
      .catch(err => console.log(err));
  };

  const handleStatus = () => {
    console.log('status');
    setImageUpdate(!imgesUpdate);
  };

  const handleView = (item: any) => {
    console.log(item);
    setViewDetailsData(item);
    setViewDetails(true);
  };

  const handleEdit = (item: any) => {
    console.log(item);
    setEditDialog(true);
    setEditData(item);
  };
  return (
    <Grid container xs={12} spacing={3}>
      <DeleteDialogOrg
        open={deleteDial}
        handleClose={() => setDeleteDial(false)}
        handleDeleteOrgId={() => deleteImageGallery(deleteData)}
        info={`Are you sure you want to Delete the Image`}
      />
      <Headings
        heading={'Image Gallery'}
        btntitle={'Add 3d Image'}
        handleClick={() => setImage3dUpdate(true)}
        imagebtntitle={'Add Image'}
        handleImage={() => setImageUplaoder(true)}
      />
      <ImageUploader
        open={imgUploader}
        handleClose={() => setImageUplaoder(false)}
        handleStatus={handleStatus}
        type="img"
      />
      <ImageUploader
        open={imges3dUpdate}
        handleClose={() => setImage3dUpdate(false)}
        handleStatus={handleStatus}
        type="3d"
      />
      <ViewImageGallery
        open={viewDetails}
        handleClose={() => setViewDetails(false)}
        data={viewDetailsData}
      />
      <EditImageGallery
        open={editDialog}
        handleClose={() => setEditDialog(false)}
        data={editData}
        handleStatus={handleStatus}
      />
      <BackDropCircularProgress open={backdrop} />

      <Grid item xs={12} sx={{backgroundColor: 'none'}}>
        <ImageList cols={3} gap={12}>
          {galleryData &&
            galleryData.map((item: any) => (
              <Card sx={{p: 2}}>
                <ImageListItem key={item.storage_url}>
                  <Grid
                    sx={{height: '50%', width: '100%', background: 'white'}}
                  >
                    {item.name.split('.')[1] === 'glb' ||
                    item.name.split('.')[1] === 'obj' ? (
                      <model-viewer
                        src={item ? item.storage_url : ''}
                        camera-controls
                        //@ts-ignore
                        alt="A 3D model carousel"
                        shadow-intensity="1"
                        // ar
                        ar-modes="quick-look"
                        camera-orbit="90deg "
                        style={{
                          background: 'white',
                          width: '100%',
                          height: '9rem',
                        }}
                      ></model-viewer>
                    ) : (
                      <img
                        src={`${item.storage_url}`}
                        srcSet={`${item.storage_url}`}
                        alt={item.name}
                        loading="lazy"
                        style={{height: '9rem', width: '100%'}}
                      />
                    )}
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={6} sx={{p: 1}}>
                        <Typography variant="h5">Image Name</Typography>
                        <Typography>{item.name ? item.name : 'NA'}</Typography>
                      </Grid>
                      <Grid item xs={6} sx={{p: 1}}>
                        <Typography variant="h5">Product Name</Typography>
                        <Typography>
                          {item.product_name ? item.product_name : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{p: 1}}>
                        <Typography variant="h5">Category Name</Typography>
                        <Typography>
                          {item.product_category_name
                            ? item.product_category_name
                            : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{p: 1}}>
                        <Typography variant="h5">SKU Name</Typography>
                        <Typography>
                          {item.SKU_name ? item.SKU_name : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{p: 1}}>
                        <Typography variant="h5">Last Modified Date</Typography>
                        <Typography>
                          {item.last_modified
                            ? DateTime.fromISO(item.last_modified).toFormat(
                                'dd/MM/y '
                              )
                            : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{p: 1}}>
                        <Typography variant="h5">Last Modified Date</Typography>
                        <Grid container>
                          <Typography>
                            <IconButton onClick={() => handleView(item)}>
                              <VisibilityOutlinedIcon />
                            </IconButton>
                          </Typography>
                          <Typography>
                            <IconButton onClick={() => handleEdit(item)}>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Typography>
                          <Typography>
                            <IconButton
                              onClick={() => {
                                setDeleteData(item);
                                setDeleteDial(true);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ImageListItem>
              </Card>
            ))}
        </ImageList>
      </Grid>
    </Grid>
  );
};

export default ImagesGallery;
