class ENVConfig {
  constructor() {
    this.static = {
      CONTRACT_DOCUMENT_ID: 'DOCTYPE000002',
      PERMIT_DOCUMENT_ID: 'DOCTYPE000008',
      APPID: 'APP000002',
      ORGID: 'ORG001',
      DOCUMENTSERVICE_URI:
        'https://arka-functions-app.azurewebsites.net/api/sasGenerator',

      INSTALLERADMIN: 'installer_admin',
      PERMIT_SERVICE_ID: 'SRVC000001',
      DESIGN_SERVICE_ID: 'SRVC000002',
      SURVEY_TEMPLATE_ID: 'TMP000001',

      MSAL_AUTHORITY: 'https://login.microsoftonline.com/arka.energy',
      SPOTLIGHT_PROD_URL: 'https://spotlight-production.azurewebsites.net',
      EVENT_HUB_CONNECTION_STRING:
        'Endpoint=sb://sl360eventhubns.servicebus.windows.net/;SharedAccessKeyName=sendCDC;SharedAccessKey=2KYt5sK36/NHetqX9MfGmAUdSmZx4vYkS62R3NFtDCE=;EntityPath=sl360changedatacapture',
      EVENT_HUB_NAME: 'sl360changedatacapture',

      TSL_HELP_URL: 'https://help.thesolarlabs.com',
      SIDEBAR: 'sidebar',

      TSL_KEY: 'tsl',
      ARKA_KEY: 'arka',
      BUILD_PATH: '', 
// 
      // BUILD_PATH: '/360', 

    };

    this.staging = {
      BACKEND_URL: 'http://localhost:8080',
      TASKS_BACKEND_URL: 'http://localhost:8082',
      AUTH_SERVER: 'https://arka-authentication-staging.azurewebsites.net',
      //AUTH_SERVER: 'http://localhost:8080',
      INTEGRATION_SERVER:
        'https://integrations-service-staging.azurewebsites.net',
      MSAL_REDIRECT_URI: 'https://arka-pm-portal-staging.azurewebsites.net',
      TSL_SERVER: 'https://devapi.thesolarlabs.com:8004',
      ...this.static,
    };
    this.testing = {
      BACKEND_URL: 'https://arka360-production-testing.azurewebsites.net',
      TASKS_BACKEND_URL:
        'https://sl360-task-management-service-testing.azurewebsites.net',

      AUTH_SERVER: 'https://arka-authentication-testing.azurewebsites.net',
      //AUTH_SERVER: 'http://localhost:8080',
      INTEGRATION_SERVER:
        'https://integrations-service-development.azurewebsites.net',
      MSAL_REDIRECT_URI: 'https://arka-pm-portal-development.azurewebsites.net',
      TSL_SERVER: 'https://betaapi.thesolarlabs.com',
      TSL_AUTH: 'https://beta.thesolarlabs.com/arkaLogin',
      TSL_URL: 'https://beta.thesolarlabs.com',

      SURVEY_URL: 'https://tsl-survey-tool-development.azurewebsites.net',
      WEBSOCKETCHAT_URL: '//devapi.thesolarlabs.com:8001',

      domain: 'arka360-production-testing.azurewebsites.net',
      TSL_COOKIE_KEY: 'tsl_test',
      ARKA_COOKIE_KEY: 'arka_test',
      LHS_COOKIE_KEY: 'lhs_test',
      ...this.static,
    };
    this.development = {
      BACKEND_URL: 'https://arka360-production-development.azurewebsites.net',
      TASKS_BACKEND_URL:
        'https://sl360-task-management-service-development.azurewebsites.net',

      AUTH_SERVER: 'https://arka-authentication-development.azurewebsites.net',
      //AUTH_SERVER: 'http://localhost:8080',
      INTEGRATION_SERVER:
        'https://integrations-service-development.azurewebsites.net',
      MSAL_REDIRECT_URI: 'https://arka-pm-portal-development.azurewebsites.net',
      TSL_SERVER: 'https://devapi.thesolarlabs.com:8004',
      TSL_AUTH: 'https://dev.thesolarlabs.com/arkaLogin',
      TSL_URL: 'https://dev.thesolarlabs.com',

      SURVEY_URL: 'https://tsl-survey-tool-development.azurewebsites.net',
      WEBSOCKETCHAT_URL: '//devapi.thesolarlabs.com:8001',

      domain: 'arka360-production-development.azurewebsites.net',
      TSL_COOKIE_KEY: 'tsl_dev',
      ARKA_COOKIE_KEY: 'arka_dev',
      LHS_COOKIE_KEY: 'lhs_dev',
      ...this.static,
    };

    this.development2 = {
      BACKEND_URL: 'https://arka360-production-1-2-1.azurewebsites.net',
      TASKS_BACKEND_URL:
        'https://sl360-task-management-service-development.azurewebsites.net',

      AUTH_SERVER: 'https://arka-authentication-development.azurewebsites.net',
      //AUTH_SERVER: 'http://localhost:8080',
      INTEGRATION_SERVER:
        'https://integrations-service-development.azurewebsites.net',
      MSAL_REDIRECT_URI: 'https://arka-pm-portal-development.azurewebsites.net',
      TSL_SERVER: 'https://devapi.thesolarlabs.com:8004',
      TSL_AUTH: 'https://dev.thesolarlabs.com/arkaLogin',
      TSL_URL: 'https://dev.thesolarlabs.com',

      SURVEY_URL: 'https://tsl-survey-tool-development.azurewebsites.net',
      WEBSOCKETCHAT_URL: '//devapi.thesolarlabs.com:8001',

      domain: 'arka360-production-1-2-1.azurewebsites.net',
      TSL_COOKIE_KEY: 'tsl_dev',
      ARKA_COOKIE_KEY: 'arka_dev',
      LHS_COOKIE_KEY: 'lhs_dev',
      ...this.static,
    };

    this.production = {
      BACKEND_URL: 'https://arka360-production.azurewebsites.net',
      TASKS_BACKEND_URL:
        'https://sl360-task-management-service.azurewebsites.net',

      AUTH_SERVER: 'https://arka-authentication-development.azurewebsites.net',

      INTEGRATION_SERVER: 'https://integrations-service.azurewebsites.net',
      MSAL_REDIRECT_URI: 'https://arka-pm-portal.azurewebsites.net',
      TSL_SERVER: 'https://prodapi.thesolarlabs.com',
      TSL_AUTH: 'https://prodapi.thesolarlabs.com/arkaLogin',

      TSL_URL: 'https://app.thesolarlabs.com',
      SURVEY_URL: `https://tsl-survey-tool.azurewebsites.net`,
      WEBSOCKETCHAT_URL: '//prodapi.thesolarlabs.com',
      domain: '.thesolarlabs.com',
      TSL_COOKIE_KEY: 'tsl',
      ARKA_COOKIE_KEY: 'arka',
      LHS_COOKIE_KEY: 'lhs',
      ...this.static,
    };
    this.local = {
      BACKEND_URL: 'http://localhost:8080',
      TASKS_BACKEND_URL: 'http://localhost:8082',
      AUTH_SERVER: 'https://arka-authentication-development.azurewebsites.net',
      INTEGRATION_SERVER:
        'https://integrations-service-development.azurewebsites.net',

      MSAL_REDIRECT_URI: 'http://localhost:3000',

      TSL_SERVER: 'https://devapi.thesolarlabs.com:8004',
      TSL_AUTH: 'https://dev.thesolarlabs.com/arkaLogin',
      TSL_URL: 'https://dev.thesolarlabs.com',

      SURVEY_URL: 'https://tsl-survey-tool-development.azurewebsites.net',

      WEBSOCKETCHAT_URL: '//beta360.thesolarlabs.com',
      domain: 'localhost',
      TSL_COOKIE_KEY: 'tsl_local',
      ARKA_COOKIE_KEY: 'arka_local',
      LHS_COOKIE_KEY: 'lhs_local',
      ...this.static,
    };
    this.env = {...this.production};

    this.envType = 'production';

    this.websocketurl =
      this.envType === 'development'
        ? '//devapi.thesolarlabs.com:8001'
        : '//prodapi.thesolarlabs.com';
  }

  setEnvironment(env) {
    this.envType = env;
    if (env === 'local') this.env = this.local;
    else if (env === 'development') this.env = this.development;
    else if (env === 'development2') this.env = this.development2;
    else if (env === 'staging') this.env = this.staging;
    else if (env === 'production') this.env = this.production;
    else if (env === 'testing') this.env = this.testing;
  }
}
const envConfig = new ENVConfig();

export default envConfig;
