import {Button, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../core/redux/store';
import appService from '../../../core/service/app.service';

import BackDropCircularProgress from '../../../components/BackDropCircularProgress';
import Loading from '../../../components/CirularProgress';

import {INPS} from '../__types__/NPS.interface';
import {useSnackbar} from 'notistack';
import ILead from '../../Lead/__types__/lead.interface';
import {Box} from '@mui/system';

interface ICustomerFeedback {
  lead: ILead;
}

interface InpsData {
  data: INPS;
  template: any;
}

function CustomerFeedback({lead}: ICustomerFeedback) {
  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [npsData, setNpsData] = useState<null | undefined | InpsData>();

  const user = useSelector((state: RootState) => state.appState.user);
  useEffect(() => {
    appService
      .getNPS({leadId: lead.lead_ID, orgId: lead.org_ID})
      .then(res => {
        console.log(res);
        setNpsData(res.message);
      })
      .catch(err => console.log(err));
  }, []);

  const handleSend = async () => {
    setLoading(true);
    try {
      const body = {
        event: 'nps_link',
        data: {
          leadId: lead.lead_ID,
          orgId: user.arka.orgId,
        },
      };

      await appService.sendEvent(body);
      const tempNPSData: null | undefined | InpsData = {
        template: null,
        data: {is_submitted: false},
      };

      setNpsData(tempNPSData);

      enqueueSnackbar('Link sent successfully', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar('Error Occured', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    setLoading(false);
  };

  const handleResend = async () => {
    setLoading(true);
    try {
      const data = {
        email: lead.email,
        leadId: lead.lead_ID,
      };

      const result = await appService.resendNPSLink(data);

      console.log(result);

      enqueueSnackbar('Link resent successfully', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } catch (error) {
      console.log(error);

      enqueueSnackbar('Error Occured', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    setLoading(false);
  };

  const getEmotion = (value: number | undefined) => {
    if (!value) return;
    let emotion = '';

    npsData?.template?.template?.score_mappings.forEach((mapping: any) => {
      if (value >= mapping.start && value <= mapping.end) {
        emotion = mapping.value;
      }
    });

    return emotion;
  };

  return (
    <Box>
      {npsData !== undefined ? (
        <Grid container spacing={2}>
          <BackDropCircularProgress open={loading} />

          {!npsData && (
            <Grid item xs={12}>
              <Typography>
                Link is not yet sent to the customer. Please send.
              </Typography>
            </Grid>
          )}

          <Grid item>
            {!npsData ? (
              <Button onClick={handleSend} size="small" variant="contained">
                Send Link
              </Button>
            ) : (
              <Button
                onClick={handleResend}
                size="small"
                variant="contained"
                disabled={npsData?.data?.is_submitted}
              >
                Resend Link
              </Button>
            )}
          </Grid>
          {npsData && (
            <Grid item container spacing={2}>
              {' '}
              <Grid item container>
                <Grid item sx={{pr: 1}}>
                  {' '}
                  <Typography>Status :</Typography>
                </Grid>
                <Grid item xs>
                  {' '}
                  <Typography>
                    {npsData?.data?.is_submitted
                      ? 'Submitted'
                      : 'Not yet submitted by the customer'}
                  </Typography>
                </Grid>
              </Grid>
              {npsData?.data?.is_submitted && (
                <Grid item container>
                  {' '}
                  <Grid item container>
                    <Grid item sx={{pr: 1}}>
                      {' '}
                      <Typography>Score :</Typography>
                    </Grid>
                    <Grid item xs>
                      {' '}
                      <Typography>
                        {npsData.data.score} - {getEmotion(npsData.data.score)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item sx={{pr: 1}}>
                      {' '}
                      <Typography>Reason :</Typography>
                    </Grid>
                    <Grid item xs>
                      {' '}
                      <Typography>{npsData.data.reason}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <Loading height={100} />
      )}
    </Box>
  );
}

export default CustomerFeedback;
