import {Grid, Box} from '@mui/material';
import {DataGrid, GridCellParams, MuiEvent} from '@mui/x-data-grid';
import React, {useState} from 'react';

const ImportLeadTable = props => {
  console.log('ImportLeadTable', props);

  const getFormattedRows = leads => {
    const getAddressFromSalesforce = lead => {
      let address = '';
      if (props.uploadSource === props.uploadSourceMapping.SalesForce) {
        if (lead.Address) {
          address = `${lead.Address.street}, ${lead.Address.city}, ${lead.Address.postalCode}, ${lead.Address.country}`;
        }
      }

      return address;
    };

    const rows = leads.map((lead, index) => ({
      id: index,
      ...lead,
    }));

    return rows;
  };

  const setSelectedRows = newSelectionModel => {
    props.setSelectedRows(newSelectionModel);
  };

  const getColumns = leads => {
    console.log({leads});
    let columns = [];

    console.log('BP1 ', props.mappingData);

    if (props.uploadSource === props.uploadSourceMapping.HubSpot) {
      props.mappingData.source_columns.map(item => {
        columns = [
          ...columns,
          {
            field: item,
            headerName: item,
            flex: 3,
          },
        ];
      });
    } else if (props.uploadSource === props.uploadSourceMapping.SalesForce) {
      props.mappingData.source_columns.map(item => {
        columns = [
          ...columns,
          {
            field: item,
            headerName: item,
            flex: 3,
          },
        ];
      });
    } else if (props.uploadSource === props.uploadSourceMapping.CSV) {
      props.mappingData.source_columns.map(item => {
        columns = [
          ...columns,
          {
            field: item,
            headerName: item,
            flex: 3,
          },
        ];
      });
    } else {
      throw new Error('No Matching Upload Source found');
    }

    return columns;
  };

  return (
    <Grid container sx={{width: '100%'}}>
      <Box sx={{height: '70vh', width: '100%'}}>
        <DataGrid
          columns={getColumns(props.data)}
          rows={getFormattedRows(props.data)}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={newSelectionModel =>
            setSelectedRows(newSelectionModel)
          }
        />
      </Box>
    </Grid>
  );
};

export default ImportLeadTable;
