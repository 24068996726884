import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IOrder from './__types__/order.interface';

// import MiniLeadCard from './MiniLeadCard';
import appService from '../../core/service/app.service';
import {Button, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../core/redux/store';
import {useSelector} from 'react-redux';
import {checkPermisssion} from '../../utils/utils';
import MiniOrderCard from './MiniOrderCard';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SearchFilter from '../../components/SearchFilter';
import MiniMyOrderCard from './MiniMyOrderCard';

function MyOrders() {
  const [view, setView] = useState<'list' | 'grid'>('grid');

  const [myOrders, setMyOrders] = useState<null | IOrder[]>(null);

  const [value, setValue] = React.useState('');

  const rules = useSelector((state: RootState) => state.appState.rules);

  const myordersAccess = checkPermisssion(rules, 'orders/myorders', 'GET');

  const changeView = (view: 'list' | 'grid') => {
    setView(view);
  };

  useEffect(() => {
    (async () => {
      if (myordersAccess) {
        const result = await appService.getMyOrders();
        setMyOrders(result.message);
      }
    })();
  }, [rules]);

  const onProcess = (orderID: string) => {
    if (!myOrders) return;
    const tempOrders = myOrders.filter(order => order.order_ID !== orderID);

    setMyOrders(tempOrders);
  };
  return (
    <Box minWidth={'420px'}>
      <SearchFilter changeView={changeView} view={view} />
      <Grid container spacing={view === 'grid' ? 3 : 0.5} pt={1}>
        {myOrders ? (
          myOrders.map(order => (
            <Grid
              item
              xs={12}
              sm={view === 'grid' ? 6 : 12}
              md={view === 'grid' ? 4 : 12}
              xl={view === 'grid' ? 4 : 12}
            >
              <MiniMyOrderCard orderData={order} onProcess={onProcess} />
            </Grid>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={300}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default MyOrders;
