import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Grid, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogHeading from '../../components/DialogHeading';
import {propsToClassKey} from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SlideDrawer(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        maxWidth={'xl'}
        sx={{width: props.width, margin: 'auto'}}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        {/* <Grid container sx={{width:1}} justifyContent='flex-end'>
          <IconButton color='primary' sx={{p:2}} onClick={props.handleClose}>
            <CloseIcon sx={{transform:'scale(1.5)'}}/>
          </IconButton>
        </Grid> */}
        <DialogHeading title={props.title} handleClose={props.handleClose} />
        {props.children}
      </Dialog>
    </div>
  );
}
