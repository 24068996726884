import {
  Backdrop,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {Box} from '@mui/system';
import {DataGrid} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import CirularProgress from '../../components/CirularProgress';
import CustomNoRowsOverlay from '../../components/NoRowsOverlay';
import appService from '../../core/service/app.service';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateSupportTicketModal from './Modals/CreateSupportTicketModal';
import Headings from '../../components/Headings';
import {DateTime} from 'luxon';
import SearchComponent from '../../components/SearchComponent';
import SupportTicketDetails from './SupportTicketDetails';

const SupportTicket = ({viewForProject, orderData, quoteData}) => {
  const {id} = useParams();

  const [supportTickets, setSupportTickets] = useState();
  const [loading, setLoading] = useState(false);

  const [deletionTicket, setDeletionTicket] = useState();

  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);
  const [rows, setRows] = useState(supportTickets);
  const [searched, setSearched] = useState('');
  const [ticketDataID, setTicketDataID] = useState('');
  const [detailsDia, setDetailsDia] = useState('');

  const getSupportTickets = async () => {
    setLoading(true);

    try {
      let body;
      if (viewForProject && id) {
        body = {
          project_ID: id,
        };
      }

      const getSupportTicketsResult = await appService.getSupportTickets(body);

      console.log(getSupportTicketsResult);
      setSupportTickets(getSupportTicketsResult.message);
      setRows(getSupportTicketsResult.message);
      setLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const changeStatus = async (status, ticketId, project_ID) => {
    if (supportTickets && !project_ID) {
      project_ID = supportTickets.filter(
        st => st.support_ticket_ID === ticketId
      )[0].project_ID;
    }

    if (!supportTickets) return;

    try {
      setLoading(true);

      const res = await appService.updateSupportTicket({
        support_ticket_ID: ticketId,
        project_ID,
        status,
      });
      console.log(res);
      setLoading(false);

      const tempAct = supportTickets.find(
        act => act.support_ticket_ID === ticketId
      );

      if (tempAct) {
        tempAct.status = status;

        setSupportTickets([...supportTickets]);
        setRows([...supportTickets]);
      }

      // onAssignUser(orderData.id, user);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const confirmDelete = ticket => {
    setDeletionTicket(ticket);
  };

  const cancelDelete = () => {
    setDeletionTicket(null);
  };

  const deleteTicket = async () => {
    setLoading(true);
    try {
      const deleteSupportTicketResult = await appService.deleteSupportTicket({
        support_ticket_ID: deletionTicket.support_ticket_ID,
      });

      const tempTickets = supportTickets.filter(
        tik => tik.support_ticket_ID !== deletionTicket.support_ticket_ID
      );
      setSupportTickets(tempTickets);
      setRows(tempTickets);
      setLoading(false);
      setDeletionTicket(null);
    } catch (err) {
      console.log('err', err);
    }
  };

  const tableColumns = [
    {field: 'project_ID', headerName: 'Project ID', flex: 4},
    {field: 'id', headerName: 'Support Ticket ID', flex: 4},
    {
      field: 'subject',
      headerName: ' Subject',
      flex: 4,
    },
    {
      field: 'description',
      headerName: ' Description',
      flex: 4,
    },
    {
      field: 'type_of_issue',
      headerName: ' Type of issue',
      flex: 4,
    },
    {
      field: 'status',
      headerName: ' Status',
      flex: 4,
      renderCell: params => {
        if (!supportTickets) return;

        const ticket = supportTickets.find(
          tik => tik.support_ticket_ID === params.row.id
        );

        return (
          <Select
            key={'status'}
            size="small"
            value={ticket?.status || ''}
            fullWidth
            onChange={e => {
              changeStatus(e.target.value, ticket?.support_ticket_ID, id);
            }}
            sx={{height: '2.2rem', mr: 2}}
          >
            {['Unresolved', 'In-progress', 'Resolved'].map(item => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        );
      },
    },

    {
      field: 'created_date',
      headerName: 'Created Date',
      flex: 4,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: params => {
        const ticketId = params.row.id;
        const ticket = supportTickets?.find(
          tik => tik.support_ticket_ID === ticketId
        );
        return (
          <Box>
            <Tooltip title="Delete Ticket">
              <IconButton
                color="error"
                onClick={e => {
                  confirmDelete(ticket);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const getFormattedRows = supportTickets => {
    console.log(supportTickets);
    let rows;
    if (supportTickets)
      rows = supportTickets.map(ticket => ({
        project_ID:ticket.project_ID,
        id: ticket.support_ticket_ID,
        subject: ticket.subject,
        description: ticket.description,
        type_of_issue: ticket.type_of_issue,
        status: ticket.status,
        created_date: DateTime.fromISO(ticket.created_date)
          .toFormat('dd/MM/yyyy')
          .toString(),
      }));

    return rows;
  };

  useEffect(() => {
    getSupportTickets();
  }, []);

  const requestSearch = searchedVal => {
    if (supportTickets) {
      const filteredRows = supportTickets.filter(
        row =>
          row.support_ticket_ID
            ?.toLowerCase()
            ?.includes(searchedVal.toLowerCase().trim())

        // return row.project_ID.toLowerCase().includes(searchedVal.toLowerCase());
      );
      // console.log(filteredRows)
      setRows(filteredRows);
      setSearched(searchedVal);
    }
  };
  const cancelSearch = () => {
    setSearched('');
    console.log(searched);

    requestSearch('');
  };

  return (
    <Grid container sx={{width: 1}}>
      <Headings
        heading={'Support Tickets'}
        searchBar={
          <SearchComponent
            searched={searched}
            cancelSearch={cancelSearch}
            requestSearch={requestSearch}
            placeholdertext={'Enter Support ID'}
          />
        }
        btntitle2={viewForProject ? 'Create Support Ticket' : null}
        handleClick2={() => setOpenCreateTicketDialog(true)}
      />
      {/* <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.modal + 1}}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <SupportTicketDetails
        open={detailsDia}
        data={ticketDataID}
        handleClose={() => setDetailsDia(false)}
      />

      {deletionTicket && (
        <Dialog open={deletionTicket}>
          <DialogTitle>Permanently delete ticket?</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the ticket with ID :{' '}
            {deletionTicket.support_ticket_ID}
          </DialogContent>
          <DialogActions>
            <Grid container sx={{width: 1}} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={cancelDelete}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={deleteTicket}
                sx={{ml: 1}}
              >
                Delete
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      {openCreateTicketDialog && (
        <CreateSupportTicketModal
          open={openCreateTicketDialog}
          setOpen={setOpenCreateTicketDialog}
          orderData={orderData}
          quoteData={quoteData}
          projectId={id}
          getSupportTickets={getSupportTickets}
        />
      )}

      {/* {viewForProject && (
        <Grid item container xs={12} sx={{mb: 2}} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCreateTicketDialog(true)}
          >
            Create Support Ticket
          </Button>
        </Grid>
      )} */}
      {supportTickets ? (
        supportTickets.length === 0 ? (
          <CustomNoRowsOverlay text={'No Support Tickets Available'} />
        ) : (
          <Box sx={{height: '70vh', width: '100%', mt: 2}}>
            <DataGrid
              hideFooter
              hideFooterPagination
              rows={rows ? getFormattedRows(rows) : []}
              columns={tableColumns}
              onCellClick={row => {
                const ticketId = row.id;

                if (row.field === 'action') {
                  setDetailsDia(false);
                }
                if (ticketId && row.field !== 'action') {
                  const newData = supportTickets.filter(
                    d => d.support_ticket_ID === ticketId
                  )[0];

                  setDetailsDia(true);
                  setTicketDataID(newData);
                }
              }}
            />
          </Box>
        )
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Grid>
  );
};

export default SupportTicket;
