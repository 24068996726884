import { Button, CircularProgress, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import appService from '../../core/service/app.service';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { EditOutlined } from '@mui/icons-material';
import OrderPaymentInstallments from '../Projects/Modals/OrderPaymentInstallments';
import EditOrderModal from '../Projects/Modals/EditOrderModal';
import Headings from '../../components/Headings';


const Productorders = () => {

    const [productOrderData,setProductOrderData]=useState<any>()
    const [OpenInstallments,setOpenInstallments]=useState<any>()
    const [openEditDialog,setOpenEditDialog]=useState<any>()
    const [selectedOrder,setSelectedOrder]=useState<any>(null)
    const [updateStatus,setUpdatedStatus]=useState<any>()


    useEffect(()=>{
appService.getProductOrders()
.then(res=>{
    const orderData= res.message.filter((data:any)=>data.SKU_ID==='null'&& data.price_quote_ID!==null&&data.project_ID!==null)
    console.log(orderData)
    setProductOrderData(orderData)
    setOpenEditDialog(false)

}
   
    )
.catch(err=>console.log(err))

    },[updateStatus])
    

    const tableColumns: GridColDef[] = [
        {field:"order_ID",headerName:'Order ID',flex:1},
        {field:"pricequoteID",headerName:'Price Quote ID',flex:2},
        {field:"status",headerName:'Status',flex:1},
        {field:"action",headerName:'Action',flex:1,
        renderCell: params => (
            <Box>
            <Tooltip arrow title={'Edit Status'}>
     <IconButton
       color="primary"
       component="span"
       size="small"
       onClick={()=>setOpenEditDialog(true)}
     >
       <EditOutlined sx={{color: '#999999', border: '1px solid',borderRadius:"6px"}} />
     </IconButton>
     
   </Tooltip>
   <Tooltip arrow title={'Edit Installments'}>
   <Button variant="text" color='secondary' sx={{color:'rgb(64, 158, 255) !important'}}
   onClick={()=>setOpenInstallments(true)}
   >
    Installments</Button>
     
   </Tooltip>
         </Box>
        )
    },



    ]

    const getFormattedRows = (productOrderData: any) => {
        if(productOrderData){
        const rows = productOrderData.map((orders:any) => ({
            order_ID: orders.order_ID,
          pricequoteID: orders.price_quote_ID,
          status: orders.status,
          project_ID:orders.project_ID
          
        }));
    
        return rows;
    }
      };
     
     
      const getOrderHistory=()=>{
        const projectID=productOrderData.filter((data:any)=>data.order_ID===selectedOrder.order_ID)[0].project_ID
   console.log(projectID)
   appService
   .getOrderHistory(selectedOrder.project_ID)
   .then(({message}) => {
     console.log(message);
     setUpdatedStatus(message)
    //  setProductOrderData(message);
   })
   .catch(err => {
     console.log(err);
   });
      }
    
    return (
   <Box>
    <Grid container sx={{mb:1}} >
    <Headings heading={'Product Orders'} />
    </Grid>
 <OrderPaymentInstallments
        open={OpenInstallments}
        handleClose={() => setOpenInstallments(false)}
        selectedOrder={selectedOrder}
      />
       {selectedOrder && (
        <EditOrderModal
        openDialog={openEditDialog}
        setOpenDialog={setOpenEditDialog}
        selectedOrder={selectedOrder}
        getOrderHistory={getOrderHistory}
        page='productsOrder'
      />
       )}
        
{productOrderData?
    <Box sx={{height: '70vh', width: '100%'}}>
        <DataGrid
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <PeopleAltIcon
                  fontSize="large"
                  sx={{color: '#999999', mb: 1}}
                />
                <Grid sx={{fontWeight: '500', mb: 1}}>No Product Orders Available</Grid>
              
              </Stack>
            ),
          }}
          getRowId={(productOrderData) => productOrderData.order_ID}
          rows={productOrderData?getFormattedRows(productOrderData):[]}
          columns={tableColumns}
          onCellClick={(order: GridCellParams)=>{
            console.log(order.row)

            setSelectedOrder(order.row);

            
          }}
        />
      </Box>
      :
      <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={300}
            >
              <CircularProgress color="inherit" />
            </Box>
}
     
   </Box>
    );
};

export default Productorders;