import {IRules} from '../core/__types__/AppState.interface';

import {findPermissionForRoute, checkIfHasAccess} from './acl';

//@ts-ignore
import parser from 'tld-extract';

export const getUniqueValues = (dataList: Array<string>) => [
  ...new Set(dataList),
];

export const checkPageAccess = (
  rules: IRules | null,
  endPoints: {route: string; method: string}[]
) => {
  if (!rules) return false;

  const results: any[] = [];

  endPoints.forEach(endpoint => {
    const res = checkPermisssion(rules, endpoint.route, endpoint.method);
    results.push(res);
  });

  return results.some(result => result === true);
};

export const checkPageAccess2 = (
  rules: IRules | null,
  path: {route: string; system: string}
) => {
  if (!rules) return false;

  let hasAccess = false;

  rules.permissions.forEach(permission => {
    if (permission.resource === path.route) {
      hasAccess = true;
    }
  });

  return hasAccess;
};

export const checkPermisssion = (
  rules: IRules | null,
  resource: string,
  method: string
) => {
  if (!rules) return false;

  const permissions = findPermissionForRoute(
    rules.permissions,
    resource,
    method
  );

  if (permissions.length === 0) return false;

  return checkIfHasAccess(method, permissions);
};

export const namevalidator = (event: any) => {
  const inputValue = event.keyCode;
  // allow letters and whitespaces only.
  if (
    !(inputValue >= 65 && inputValue <= 120) &&
    inputValue !== 32 &&
    inputValue !== 0 &&
    inputValue !== 8
  ) {
    event.preventDefault();
  }
};

export const phoneNumberValidator = (event: any) => {
  console.log(event);
  const inputValue = event.keyCode;
  // allow letters and whitespaces only.
  if (!(inputValue >= 48 && inputValue <= 57) && inputValue !== 8) {
    event.preventDefault();
  }
};

export const ValidateEmail = (email: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
    return true;
  }

  return false;
};

export const printToPDF = () => {
  console.log('converting...');
};

export const getDomain = (origin: string) => {
  let domain;

  console.log(domain);
  if (origin.includes('.azurewebsites.net')) {
    domain = origin?.split('//')[1];
  } else if (origin.includes('localhost')) {
    domain = 'localhost';
  } else {
    domain = '.' + parser(origin).domain;
  }

  return domain;
};
