import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import DialogHeading from '../../components/DialogHeading';
import * as Yup from 'yup';
import appService from '../../core/service/app.service';
import CircularProgressDialog from '../../components/CircularProgressDialog';
import {useSnackbar} from 'notistack';
import {FileUploader} from 'react-drag-drop-files';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FileUploaderDnd from '../../components/FileUploaderDnd/FileUploaderDnd';
import {set} from 'date-fns';
import ModelViewer from '../../components/ModelViewer/ModelViewer';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG'];

const CreateDialog = ({
  editableData,
  open,
  modes,
  mode,
  handleClose,
  data,
  getAllProductData,
}) => {
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);

  const [files_3d, setFiles_3d] = useState([]);
  const [filesSrc_3d, setFilesSrc_3d] = useState([]);

  const [files_3d_poster, setFiles_3d_poster] = useState([]);
  const [filesSrc_3d_poster, setFilesSrc_3d_poster] = useState([]);

  const [productCategoryId, setProductCategoryId] = useState();
  const [productId, setProductId] = useState();

  const [productList, setProductList] = useState();

  const [editCoverPicture, setEditCoverPicture] = useState(false);
  const [edit3dModel, setEdit3dModel] = useState(false);
  const [edit3dPoster, setEdit3dPoster] = useState(false);
  const [editSkuImages, setEditSkuImages] = useState(false);

  const [deletedImageIds, setDeletedImageIds] = useState([]);

  const [newImageCount, setNewImageCount] = useState(0);

  // const [initialValuesForEdit, setInitialValuesForEdit] = useState();

  useEffect(() => {
    if (data && data.length > 0 && productCategoryId) {
      let products = data.find(
        category => category.product_category_ID === productCategoryId
      );
      // console.log({products});
      setProductList(products.product);
    }
  }, [data, productCategoryId]);

  const getInitialValues = () => {};

  //runs if edit mode
  useEffect(() => {
    if (editableData) {
      if (editableData.productId) setProductId(editableData.productId);
      if (editableData.productCategoryId)
        setProductCategoryId(editableData.productCategoryId);

      if (editableData.sku_ID && editableData.image.length > 0) {
        setFiles(editableData.image);
        setFilesSrc(editableData.image.map(item => item.image_url));
      }
    }
  }, [editableData]);

  const clearCreateCard = () => {
    setFiles([]);
    setDeletedImageIds([]);
    setNewImageCount(0);
    setEdit3dPoster(false);
    setEdit3dModel(false);
    setEditSkuImages(false);
    setEditCoverPicture(false);
    setFilesSrc([]);
    setFilesSrc_3d([]);
    setFilesSrc_3d_poster([]);
    setProductCategoryId([]);
    setProductId([]);
  };

  const handleCloseDialog = () => {
    clearCreateCard();
    handleClose();
  };

  const handleCreate = async values => {
    enqueueSnackbar('Adding ' + mode);
    setLoading(true);
    try {
      if (editableData) {
        if (mode === modes.category) {
          const updateProductCategory = await appService.updateProductCategory({
            name: values.name,
            description: values.description,
            isGazebo: values.isGazebo,
            productCategoryId: editableData.product_category_ID,
          });
        } else if (mode === modes.product) {
          const formData = new FormData();

          formData.append('productCategoryId', productCategoryId);
          formData.append('productId', editableData.product_ID);

          if (
            values.name !== editableData.name ||
            values.description !== editableData.description ||
            values.shortDescription !== editableData.short_description
          ) {
            formData.append(
              'data',
              JSON.stringify({
                name: values.name,
                description: values.description,
                short_description: values.shortDescription,
              })
            );
          }

          if (files.length === 1) {
            formData.append('file', files[0]);
          }

          const updateProduct = await appService.updateProduct(formData);
          setEditCoverPicture(false);
          setFiles([]);
          setFilesSrc([]);
        } else if (mode === modes.sku) {
          const formData = new FormData();

          formData.append('productCategoryId', productCategoryId);
          formData.append('productId', editableData.productId);
          formData.append('skuId', editableData.sku_ID);

          //text data
          if (
            values.name !== editableData.sku_name ||
            values.description !== editableData.description
          ) {
            formData.append(
              'data',
              JSON.stringify({
                name: values.name,
                description: values.description,
              })
            );
          }

          //new 3d
          if (files_3d.length === 1) {
            formData.append('data_3d', files_3d[0]);
            if (files_3d_poster.length === 1) {
              formData.append('poster', files_3d_poster[0]);
            }
          }

          console.log({editableData});
          //new poster when 3d exists
          if (
            editableData.data_3d &&
            editableData.data_3d.model &&
            !files_3d.length &&
            files_3d_poster.length === 1
          ) {
            formData.append('poster', files_3d_poster[0]);
          }

          //if deleted files
          if (deletedImageIds && deletedImageIds.length > 0) {
            deletedImageIds.map(imageId =>
              formData.append('deletedImageIds', imageId)
            );
          }

          //if new files added
          if (newImageCount > 0 && files.length > 0) {
            let updateObj = [];
            let newFileCount = 0;

            files.map((item, index) => {
              if (item instanceof File) {
                formData.append('file', item);
                updateObj.push({
                  newFileIndex: newFileCount,
                  sequence: index + 1,
                });
                newFileCount++;
              } else if (item.image_ID) {
                updateObj.push({
                  imageId: item.image_ID,
                  sequence: index + 1,
                });
              }
            });

            formData.append('updatedData', JSON.stringify(updateObj));
          }
          //only image sequence change
          //need condition done properly
          //needs to be done if just images deleted
          else if (editSkuImages) {
            let updateObj = [];

            files.map((item, index) => {
              if (item.image_ID) {
                updateObj.push({
                  imageId: item.image_ID,
                  sequence: index + 1,
                });
              }
            });
            formData.append('updatedData', JSON.stringify(updateObj));
          }

          //clearing 3D data
          if (editableData.data_3d && edit3dModel && files_3d.length === 0) {
            formData.append('delete3d', true);
          }

          console.log(formData);
          const updateProduct = await appService.updateSku(formData);
          // setEditCoverPicture(false);
          // setFiles([]);
          // setFilesSrc([]);
        }

        enqueueSnackbar('Successfully updated product', {variant: 'success'});
      } else {
        if (mode === modes.category) {
          let resp = await appService.createProductCategory(values);
        } else if (mode === modes.product) {
          const formData = new FormData();

          if (files.length === 1) {
            formData.append('file', files[0]);
          }
          formData.append(
            'data',
            JSON.stringify({
              name: values.name,
              description: values.description,
              short_description: values.shortDescription,
              product_category_ID: productCategoryId,
            })
          );
          let resp = await appService.createProduct(formData);
        } else if (mode === modes.sku) {
          const formData = new FormData();

          if (files.length > 0) {
            files.map(item => formData.append('file', item));
          }
          formData.append(
            'data',
            JSON.stringify({
              name: values.name,
              description: values.description,
              short_description: values.shortDescription,
              product_category_ID: productCategoryId,
              product_ID: productId,
            })
          );

          if (files_3d.length > 0) {
            formData.append('data_3d', files_3d[0]);
          }
          if (files_3d_poster.length > 0 && files_3d.length > 0) {
            formData.append('poster', files_3d_poster[0]);
          }
          let resp = await appService.createSku(formData);
        }
        enqueueSnackbar('Successfully added product', {variant: 'success'});
      }
      handleClose();
      setLoading(false);
      clearCreateCard();
      getAllProductData();
    } catch (error) {
      console.log(error);
      setLoading(false);

      enqueueSnackbar('Error adding product category', {variant: 'failure'});
    }
  };

  const getInitialTextValues = () => {
    if (editableData.skuId) {
      data.find();
      return {};
    }
  };

  const GreyDivider = () => (
    <Divider
      sx={{borderTop: 2, width: '100%', my: 2, color: 'rgb(204,204,204)'}}
    />
  );
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      // maxWidth={'md'}
      // style={{width: '100vw'}}
      // aria-labelledby="alert-dialog-title"
      // aria-describedby="alert-dialog-description"
      // PaperProps={{classes: {root: classes.dialogPaper}}}
    >
      <DialogHeading
        title={editableData ? 'Update ' : 'Create ' + mode}
        handleClose={handleCloseDialog}
      />
      <DialogContent sx={{minWidth: 500, minHeight: 200}}>
        {loading ? (
          <BackDropCircularProgress open={loading} />
        ) : (
          <Grid container sx={{width: 1}}>
            {/* Product Category */}
            {mode === modes.category && (
              <Grid item container xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={
                    editableData
                      ? {
                          name: editableData.name,
                          description: editableData.description,
                          isGazebo: editableData.is_gazebo,
                        }
                      : {name: '', description: '', isGazebo: false}
                  }
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .min(2, 'Too Short!')
                      .max(50, 'Too Long!')
                      .required('Product category Name required.'),

                    description: Yup.string()
                      .min(2, 'Too Short!')
                      .max(100, 'Too Long!')
                      .required('Product category Name required.'),
                  })}
                  onSubmit={handleCreate}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,

                    touched,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <Form>
                        <Grid container sx={{width: 1}}>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label="Name *"
                              margin="normal"
                              name="name"
                              defaultValue={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              variant="outlined"
                              size="small"
                            ></TextField>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(
                                touched.description && errors.description
                              )}
                              fullWidth
                              helperText={
                                touched.description && errors.description
                              }
                              label="Description *"
                              margin="normal"
                              name="description"
                              defaultValue={values.description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              size="small"
                              variant="outlined"
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} sx={{px: 3}}>
                            <FormControlLabel
                              label="Is this product a Gazebo"
                              control={
                                <Checkbox
                                  color="secondary"
                                  name="isGazebo"
                                  checked={values.isGazebo}
                                  sx={{pl: 0}}
                                  onChange={handleChange}
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          justifyContent={'flex-end'}
                          sx={{p: 2}}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={
                              values.name === '' || values.description === ''
                            }
                          >
                            {editableData ? 'Update' : 'Create'}
                          </Button>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            )}
            {/* Product */}
            {mode === modes.product && (
              <Grid item container xs={12}>
                <Formik
                  initialValues={
                    editableData
                      ? {
                          name: editableData.name,
                          description: editableData.description,
                          shortDescription: editableData.short_description,
                        }
                      : {name: '', description: '', shortDescription: ''}
                  }
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .min(2, 'Too Short!')
                      .max(50, 'Too Long!')
                      .required('Product category Name required.'),

                    shortDescription: Yup.string()
                      .min(2, 'Too Short!')
                      .max(50, 'Too Long!')
                      .required('Product category Name required.'),
                    description: Yup.string()
                      .min(2, 'Too Short!')
                      .max(100, 'Too Long!')
                      .required('Product category Name required.'),
                  })}
                  onSubmit={handleCreate}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,

                    touched,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <Form>
                        <Grid container sx={{width: 1}}>
                          <Grid item xs={6} sx={{p: 2, mt: 2}}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Product Category *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={productCategoryId}
                                label="Product Category *"
                                size="small"
                                onChange={e =>
                                  setProductCategoryId(e.target.value)
                                }
                                disabled={editableData}
                              >
                                {data.map(
                                  category =>
                                    category.is_gazebo && (
                                      <MenuItem
                                        value={category.product_category_ID}
                                        key={category.product_category_ID}
                                      >
                                        {category.name}
                                      </MenuItem>
                                    )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label="Name *"
                              margin="normal"
                              name="name"
                              defaultValue={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              size="small"
                              variant="outlined"
                            ></TextField>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(
                                touched.shortDescription &&
                                  errors.shortDescription
                              )}
                              fullWidth
                              helperText={
                                touched.shortDescription &&
                                errors.shortDescription
                              }
                              label="Short Description *"
                              margin="normal"
                              name="shortDescription"
                              defaultValue={values.shortDescription}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              size="small"
                              variant="outlined"
                            ></TextField>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label="Description *"
                              margin="normal"
                              name="description"
                              defaultValue={values.description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              size="small"
                              variant="outlined"
                            ></TextField>
                          </Grid>
                          <GreyDivider />

                          {editableData &&
                            !editCoverPicture &&
                            editableData.cover_image && (
                              <Grid container sx={{p: 2}}>
                                <Grid item xs={12}>
                                  <Typography>Current Cover Image</Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    height: 140,
                                    width: 250,
                                  }}
                                >
                                  <img
                                    src={editableData.cover_image}
                                    style={{width: '100%', height: '100%'}}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent={'center'}
                                  xs={6}
                                >
                                  <Grid item>
                                    <Button
                                      onClick={() => setEditCoverPicture(true)}
                                      color="secondary"
                                      variant="contained"
                                    >
                                      Replace Cover Image
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          {((editableData &&
                            editCoverPicture | !editableData.cover_image) ||
                            !editableData) && (
                            <Grid container sx={{p: 2}}>
                              <FileUploaderDnd
                                fileTypes={fileTypes}
                                files={files}
                                setFiles={setFiles}
                                filesSrc={filesSrc}
                                setFilesSrc={setFilesSrc}
                                limit={1}
                                title={'Upload Cover Image'}
                              />
                            </Grid>
                          )}

                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent={'flex-end'}
                            sx={{p: 2}}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={
                                values.name === '' ||
                                values.shortDescription === '' ||
                                values.description === '' ||
                                !productCategoryId ||
                                // (!editableData && files.length !== 1) ||
                                (editableData &&
                                  editCoverPicture &&
                                  files.length !== 1)
                              }
                            >
                              {editableData ? 'Update' : 'Create'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            )}
            {/* SKU */}
            {mode === modes.sku && (
              <Grid item container xs={12}>
                <Formik
                  initialValues={
                    editableData
                      ? {
                          name: editableData.sku_name,
                          description: editableData.description,
                        }
                      : {name: '', description: ''}
                  }
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .min(2, 'Too Short!')
                      .max(50, 'Too Long!')
                      .required('Product category Name required.'),
                    description: Yup.string()
                      .min(2, 'Too Short!')
                      .max(100, 'Too Long!')
                      .required('Product category Name required.'),
                  })}
                  onSubmit={handleCreate}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,

                    touched,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <Form>
                        <Grid container sx={{width: 1}}>
                          <Grid item xs={6} sx={{p: 2}}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Product Category *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={productCategoryId}
                                size="small"
                                label="Product Category *"
                                onChange={e =>
                                  setProductCategoryId(e.target.value)
                                }
                                disabled={mode === modes.sku && editableData}
                              >
                                {data.map(
                                  category =>
                                    category.is_gazebo && (
                                      <MenuItem
                                        value={category.product_category_ID}
                                        key={category.product_category_ID}
                                      >
                                        {category.name}
                                      </MenuItem>
                                    )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Product *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={productId}
                                label="Product *"
                                size="small"
                                onChange={e => setProductId(e.target.value)}
                                disabled={editableData || !productCategoryId}
                              >
                                {productList &&
                                  productList.map(item => (
                                    <MenuItem
                                      value={item.product_ID}
                                      key={item.product_ID}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label="Name *"
                              margin="normal"
                              name="name"
                              defaultValue={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              size="small"
                              variant="outlined"
                            ></TextField>
                          </Grid>
                          <Grid item xs={6} sx={{p: 2}}>
                            <TextField
                              error={Boolean(
                                touched.description && errors.description
                              )}
                              fullWidth
                              helperText={
                                touched.description && errors.description
                              }
                              label="Description *"
                              margin="normal"
                              name="description"
                              defaultValue={values.description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              size="small"
                              variant="outlined"
                            ></TextField>
                          </Grid>

                          <GreyDivider />

                          {/* SKU Images */}
                          {editableData && !editSkuImages ? (
                            <Grid container sx={{width: 1}}>
                              <Grid item xs={12}>
                                <Typography>Current SKU images</Typography>
                              </Grid>
                              <Grid item container xs={6}>
                                {editableData.image.length > 0 ? (
                                  editableData.image.map((image, index) => (
                                    <Grid
                                      key={index}
                                      item
                                      sx={{
                                        height: 60,
                                        width: 80,
                                        p: 0.5,
                                      }}
                                    >
                                      <img
                                        src={image.image_url}
                                        style={{width: '100%', height: '100%'}}
                                      />
                                    </Grid>
                                  ))
                                ) : (
                                  <Typography variant="body2" sx={{mt: 1}}>
                                    No images uploaded{' '}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <Grid
                                  item
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Button
                                    onClick={() => setEditSkuImages(true)}
                                    color="secondary"
                                    variant="contained"
                                  >
                                    Edit SKU images
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <FileUploaderDnd
                              fileTypes={fileTypes}
                              files={files}
                              setFiles={setFiles}
                              filesSrc={filesSrc}
                              setFilesSrc={setFilesSrc}
                              limit={9}
                              title="Upload SKU images"
                              setDeletedIds={setDeletedImageIds}
                              setNewFileCount={setNewImageCount}
                            />
                          )}

                          <GreyDivider />

                          {/* 3D model */}

                          {editableData &&
                          editableData.data_3d &&
                          !edit3dModel ? (
                            <Grid container sx={{width: 1}}>
                              <Grid item xs={12}>
                                <Typography>Current 3D model</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                {editableData.data_3d.model ? (
                                  <ModelViewer
                                    model={editableData.data_3d.model}
                                  />
                                ) : (
                                  <Typography variant="body2" sx={{mt: 1}}>
                                    No model uploaded
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <Grid
                                  item
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Button
                                    onClick={() => {
                                      setEdit3dModel(true);
                                      setEdit3dPoster(true);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                  >
                                    Replace 3D model
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <FileUploaderDnd
                              fileTypes={['GLB']}
                              files={files_3d}
                              setFiles={setFiles_3d}
                              filesSrc={filesSrc_3d}
                              setFilesSrc={setFilesSrc_3d}
                              limit={1}
                              title="Upload 3D Model"
                            />
                          )}
                          <GreyDivider />
                          {/* Poster */}
                          {editableData &&
                          editableData.data_3d &&
                          editableData.data_3d.poster &&
                          !edit3dPoster ? (
                            <Grid container sx={{width: 1}}>
                              <Grid item xs={12}>
                                <Typography>Current 3D poster</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid
                                  item
                                  sx={{
                                    height: 160,
                                    width: '100%',
                                  }}
                                >
                                  <img
                                    src={editableData.data_3d.poster}
                                    style={{width: '100%', height: '100%'}}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid
                                  item
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {/* <Button
                                    onClick={() => {
                                      setEdit3dPoster(true);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                  >
                                    Replace 3D poster
                                  </Button> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <FileUploaderDnd
                              fileTypes={fileTypes}
                              files={files_3d_poster}
                              setFiles={setFiles_3d_poster}
                              filesSrc={filesSrc_3d_poster}
                              setFilesSrc={setFilesSrc_3d_poster}
                              limit={1}
                              title="Upload Poster images"
                            />
                          )}

                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent={'flex-end'}
                            sx={{p: 2}}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              disabled={
                                values.name === '' ||
                                values.description === '' ||
                                !productCategoryId ||
                                !productId
                              }
                            >
                              {editableData ? 'Update' : 'Create'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateDialog;
