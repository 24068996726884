import React, {useEffect} from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ISetUpDialogue {
  open: boolean;
  handleClose: any;
  handleSetUp: any;
}
function SetUpDialogue({open, handleClose, handleSetUp}: ISetUpDialogue) {
  return (
    <Dialog
      open={open}
      onClose={(e: any, reason) => {
        console.log(e);
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        } else handleClose(e);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Set Up Stages'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Stages and activities are not setup. To create initial stages and
          activities, please click on SetUp
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSetUp} autoFocus variant="contained">
          Setup
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SetUpDialogue;
