import React, {FC, useEffect, useRef, useState} from 'react';
import {Map, GoogleApiWrapper, IProvidedProps, Marker} from 'google-maps-react';

import {Box} from '@mui/system';
import {AnySchema} from 'yup';

interface IMap extends IProvidedProps {
  center: Ilatlng;
  latlng: Ilatlng;
  handleClick?: (arg1: Ilatlng) => void;
}

export interface Ilatlng {
  lat: number | null | string | undefined;
  lng: number | null | string | undefined;
}
const mapStyles = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const MapContainer: FC<IMap> = props => {
  // const [position, setPosition] = useState<Ilatlng | null>(null);
  const mapRef = useRef<any>();

  const handleMapClick = (mapProps: AnySchema, map: any, clickEvent: any) => {
    if (props.handleClick) {
      props.handleClick({
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      });
    }
  };
  //@ts-nocheck
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '260px',
        height: '100%',
        position: 'relative',
      }}
    >
      {' '}
      <Map
        ref={mapRef}
        /** @ts-ignore */
        tilt={90}
        zoom={20}
        mapType="satellite"
        google={props.google}
        style={mapStyles}
        /** @ts-ignore */
        onClick={handleMapClick}
        center={props.center}
      >
        {props.latlng ? (
          <Marker
            /** @ts-ignore */
            position={props.latlng}
          />
        ) : null}
      </Map>
    </Box>
  );
};

export default GoogleApiWrapper((props: any) => ({
  apiKey: String(process.env.REACT_APP_GMAPS_KEY),

  options: {mapTypeId: 'satellite', componentRestrictions: {country: ['US']}},
}))(MapContainer);
