import {CircularProgress, Backdrop, Button} from '@mui/material';
import {Box} from '@mui/system';
import * as React from 'react';

interface IBackDropCicrularProgress {
  open: boolean;
}
const BackDropCircularProgress = (props: IBackDropCicrularProgress) => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.modal + 1}}
      open={props.open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackDropCircularProgress;
