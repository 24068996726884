/* eslint-disable no-undef */
import React, {Component, useEffect, useRef, useState} from 'react';
import {gantt} from '../../ganttsource/codebase/dhtmlxgantt';
import '../../ganttsource/codebase/dhtmlxgantt.css';

import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import axios from 'axios';
import Toolbar from './Toolbar/Toolbar';
import {useSelector} from 'react-redux';
import appService from '../../core/service/app.service';
import {generateApiUrlWithFilters, getActivitiesData} from './GetTasks';
import {DateTime} from 'luxon';
import {selectLoading} from '../../core/redux/AppSlice';
import './Gantt.css';
import envConfig from '../../config/envConfig';
import {ConnectedGroupsHelper} from '../../ganttsource/codebase/sources/dhtmlxgantt';
import DialogHeading from '../../components/DialogHeading';
import LightBox from './LightBox';
import {useHistory} from 'react-router-dom';

const Gantt = props => {
  let taskrefData = {};
  const taskStatu = [
    {
      all: 'All',
    },
    {toDo: 'To do'},

    {inProgress: 'In-progress'},

    {complete: 'Complete'},
  ];

  const statusTypes = {
    toDo: 'To do',

    inProgress: 'In-progress',

    complete: 'Complete',
  };

  const [taskName, setTaskName] = useState();
  const [apiflag, setApiflag] = useState();
  const ganttContainer = useRef(null);
  const [selectedtask, setSelectedTask] = useState();
  const user = useSelector(state => state.appState.user);
  const [startDate, setStartDate] = useState(DateTime.now().startOf('month'));
  const [endDate, setEndDate] = useState(DateTime.now());
  const [open, setOpen] = useState(false);
  const [lightBoxData, setLightBoxData] = useState();
  const [lightBoxTitle, setLightBoxTitle] = useState();
  const history = useHistory();

  const pushHistory = ({path, taskData}) => {
    history.push({
      pathname: `/activity/${path}`,
      state: {
        task: taskData,
      },
    });
  };
  let title = 'Add Task';

  console.log(user);

  let dataProcessor;

  const handleColor = (status, endDate) => {
    const now = new Date().toISOString();
    if (status === statusTypes.complete) {
      return '#00C853';
    }
    if (now > endDate.toISOString()) {
      return '#FF0000';
    } else if (status === statusTypes.toDo) {
      return '#ffc35e';
    } else if (status === statusTypes.inProgress) {
      return '#409eff';
    } else {
      return '#ffc35e';
    }
  };

  const initZoom = () => {
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 30,
          scales: [
            {unit: 'day', step: 1, format: '%d %M'},
            {unit: 'hour', step: 1, format: '%H'},
          ],
        },
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            {unit: 'week', step: 1, format: 'Week %W'},
            {unit: 'day', step: 1, format: '%d %M'},
          ],
        },
        {
          name: 'Months',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            {unit: 'month', step: 1, format: '%F'},
            {unit: 'week', step: 1, format: 'Week %W '},
          ],
        },
        {
          name: 'Years',
          scale_height: 60,
          min_column_width: 150,
          scales: [
            {unit: 'month', step: 1, format: '%y'},
            {unit: 'month', step: 1, format: '%F'},
          ],
        },
      ],
    });
  };

  const setZoom = value => {
    if (!gantt.ext.zoom.getLevels()) {
      initZoom();
    }
    gantt.ext.zoom.setLevel(value);
  };

  const initGanttDataProcessor = () => {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onDataUpdated = props.onDataUpdated;
    dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      console.log(type, action, item);
      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated(type, action, item, id);
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});
        return resolve();
      });
    });
  };

  <>
    <div id="my-form" style={{width: '50%', height: '100%'}}>
      <label for="description">
        Task text
        <input
          name="descriptions"
          value={taskName}
          onChange={e => console.log(e.target.value)}
        />
      </label>
      <TextField
        label={'description'}
        name="description"
        variant="outlined"
        value={taskName}
        onChange={e => console.log(e.target.value)}
        fullWidth
        size="small"
      />{' '}
      <br />
      <input type="button" name="save" value="Save" />
      <input type="button" name="close" value="Close" />
      <input type="button" name="delete" value="Delete" />
    </div>
  </>;

  function show_owners(selected_owner) {
    console.log(props.task);
    show_owner = selected_owner;
    gantt.render();
  }

  gantt.owners = props.task;

  var show_owner = 'All';

  function find_by_id(owner_id) {
    for (var i = 0; i < gantt.owners.length; i++) {
      if (gantt.owners[i].id == 'PRID000232') return gantt.owners[i];
    }
    return gantt.owners[0];
  }

  useEffect(() => {
    setSelectedTask(props.task);
  }, [props]);
  var inlineEditors = gantt.ext.inlineEditors;

  // inlineEditors.attachEvent('onBeforeEditStart', function (state) {
  //   console.log(state);
  //   inlineEditors.attachEvent('onBeforeSave', function (states) {
  //     console.log(states);
  //     const data = {};
  //     if (states.columnName === 'status') data.status = states.newValue;
  //     if (states.columnName === 'owner_name') data.owner_ID = states.newValue;
  //     console.log(data);
  //     props.setLoading(true);

  //     appService
  //       .updateTask(states.id, data)
  //       .then(res => {
  //         getActivitiesData(
  //           false,
  //           generateApiUrlWithFilters(
  //             startDate,
  //             endDate,
  //             props.projectId,
  //             '',
  //             ''
  //           ),
  //           props.users,
  //           props.setTaskDatas,
  //           props.projectId,
  //           props.handleColor
  //         );
  //         props.setLoading(false);
  //       })
  //       .catch(err => console.log(err));

  //     // -> { id: itemId,
  //     //      columnName: columnName,
  //     //      oldValue: value,
  //     //      newValue: value
  //     //    };
  //     return true;
  //   });

  //   // -> {id: itemId, columnName: columnName};
  //   return true;
  // });

  const handleSurvey = async taskData => {
    console.log('survey');
    try {
      // if ('taskData') {
      //   window.open(
      //     `${envConfig.env.SURVEY_URL}/sitesurvey/${surveyData.survey_ID}/tsl`
      //   );
      // } else {
      // enqueueSnackbar(`Generating Survey Link ...`, {
      //   variant: 'success',
      //   autoHideDuration: 3000,
      // });
      console.log(taskData);
      const data = {
        // templateId: envConfig.env.SURVEY_TEMPLATE_ID,
        projectId: taskData.project_ID,
        name: `${taskData.name} `,
      };
      const res = await appService.createSurvey(data);
      // setSurveyData(res.message);
      window.open(
        `${envConfig.env.SURVEY_URL}/sitesurvey/${res.message.survey_ID}/tsl`
      );
      // }
    } catch (error) {
      // enqueueSnackbar(`Error occured`, {
      //   variant: 'error',
      //   autoHideDuration: 3000,
      // });

      console.log(error);
    }
  };

  const addTaskData = () => {
    gantt.createTask();
  };

  useEffect(() => {
    // customlight box
    // var lightbox_type = lightbox1;
    getForm().style.display = 'none';

    var taskId = null;

    gantt.showLightbox = function (id) {
      title = props.task.find(d => d.id === id)
        ? setLightBoxTitle('Update Task')
        : setLightBoxTitle('Create Task');
      console.log(id);
      gantt.callEvent('onLightbox', [id]);

      console.log(id);

      setOpen(true);

      taskId = id;
      var task = gantt.getTask(id);
      console.log(task);
      setLightBoxData(task);
      console.log(task);

      var form = getForm();
    };

    gantt.hideLightbox = function () {
      getForm().style.display = 'none';
      taskId = null;
    };

    function getForm() {
      return document.getElementById('lightbox1');
    }

    function save() {
      console.log(gantt);
      var task = gantt.getTask(taskId);

      task.text = getForm().querySelector("[name='description']").value;

      if (task.$new) {
        gantt.addTask(task, task.parent);
        task.$new = false;
      } else {
        gantt.updateTask(task.id);
      }

      gantt.hideLightbox();
    }

    function cancel() {
      var task = gantt.getTask(taskId);

      if (task.$new) gantt.deleteTask(task.id);
      gantt.hideLightbox();
    }

    function remove() {
      gantt.deleteTask(taskId);
      gantt.hideLightbox();
    }

    gantt.plugins({keyboard_navigation: true, marker: true});

    gantt.config.keyboard_navigation = true;
    // gantt.config.marker = true;

    gantt.attachEvent('onTaskDrag', function (id, mode, task, original) {
      const data = {};
      var modes = gantt.config.drag_mode;
      if (mode == modes.move || mode == modes.resize) {
        var diff = original.duration * (1000 * 60 * 60 * 24);

        if (+task.end_date) {
          // task.end_date = new Date(rightLimit);
          if (mode == modes.move)
            task.start_date = new Date(task.end_date - diff);
        }
        if (+task.start_date) {
          // task.start_date = new Date(leftLimit);
          if (mode == modes.move)
            task.end_date = new Date(+task.start_date + diff);
        }
      }
      data.start_date = task.start_date;
      data.end_date = task.end_date;
      console.log(data);
      appService
        .updateTask(task.id, data)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    });

    if (apiflag === undefined) {
      setApiflag(true);
      //Adding and updating the Tasks
      // gantt.attachEvent('onLightboxSave', function (id, task, is_new) {
      //   props.setLoading(true);
      //   console.log(
      //     props.task.filter(
      //       ac => ac.id === task.id || ac.activity_ID === task.activity_ID
      //     )
      //   );
      //   const projectID = props.task.filter(d => d.id === task.parent)[0]
      //     .project_ID;
      //   const updateFlag = props.task.filter(d => d.id === task.id);

      //   console.log(updateFlag);
      // const data = {};
      // data.project_ID = projectID;
      // // task.org_ID = user.arka.orgId;
      // // task.created_by = user.arka.userId;
      // data.actual_start_date = task.start_date ? task.start_date : null;
      // data.stage_ID = task.stage_ID ? task.stage_ID : null;
      // data.owner_ID = task.owner_ID ? task.owner_ID : null;
      // data.actual_end_date = task.end_date ? task.end_date : null;
      // data.activity_type = task.activity_type ? task.activity_type : null;
      // data.duration_in_days = task.duration ? task.duration : null;
      // data.start_date = task.start_date ? task.start_date : null;
      // data.end_date = task.end_date ? task.end_date : null;
      // data.parent = task.parent ? task.parent : null;
      // data.name = task.name ? task.name : null;
      // data.status = task.status ? task.status : null;
      // data.is_completed = task.is_completed ? task.is_completed : null;
      // data.sequence = task.sequence ? task.sequence : null;
      // data.document_type = task.document_type ? task.document_type : null;
      // data.id = task.id ? task.id : null;
      //   console.log(data);
      //   props.task.map(m => console.log(m.id));
      //   if (updateFlag.length > 0) {
      //     appService
      //       .updateTask(task.id, data)
      //       .then(res => {
      //         if (res.success === true) {
      //           const taskd = getActivitiesData(
      //             false,
      //             generateApiUrlWithFilters(
      //               startDate,
      //               endDate,
      //               props.projectId,
      //               '',
      //               ''
      //             ),
      //             props.users,
      //             props.setTaskDatas,
      //             props.projectId,
      //             props.handleColor
      //           );
      //           props.setLoading(false);
      //         }
      //       })
      //       .catch(err => console.log(err));
      //   } else {
      //     appService
      //       .addTask(data)
      //       .then(res => {
      //         props.setLoading(false);
      //         if (res.success === true) {
      //           const taskd = getActivitiesData(
      //             false,
      //             generateApiUrlWithFilters(
      //               startDate,
      //               endDate,
      //               props.projectId,
      //               '',
      //               ''
      //             ),
      //             props.users,
      //             props.setTaskDatas,
      //             props.projectId,
      //             props.handleColor
      //           );
      //         }
      //       })
      //       .catch(err => console.log(err));
      //   }

      //   //any custom logic here
      //   return true;
      // });
      // gantt.config.buttons_left = [];
      let btns = [];

      gantt.attachEvent('onGanttReady', function () {
        console.log(btns);
      });

      gantt.attachEvent('onBeforeLightbox', function (id) {
        // props.task.find(d => d.id === id)
        //   ? console.log([
        //       'gantt_save_btn',
        //       'gantt_cancel_btn',
        //       'gantt_delete_btn',
        //     ])
        //   : console.log(['gantt_save_btn', 'gantt_cancel_btn']);

        // console.log(id);

        // if (props.task.find(d => d.id === id)) {
        //   btns = ['gantt_save_btn', 'gantt_cancel_btn', 'gantt_delete_btn'];
        // } else {
        //   btns = ['gantt_save_btn', 'gantt_cancel_btn'];
        // }
        // console.log(btns);
        // gantt.config.buttons_right = btns;
        // gantt.config.buttons_left = [];

        // //any custom logic here
        // console.log(id);
        props.task.find(d => d.id === id)
          ? (title = 'UPDATE TASK')
          : (title = 'Craete Task');

        console.log();

        return true;
      });
      gantt.attachEvent('onBeforeTaskUpdate', function (id, new_item) {
        gantt.config.buttons_right = [
          'gantt_save_btn',
          'gantt_cancel_btn',
          'gantt_delete_btn',
        ];
        //any custom logic here
        title = 'Update TASK';
        return true;
      });
      gantt.attachEvent('onAfterTaskAdd', function (id, task) {
        task.parent =
          task.parent === 0 || task.parent === '0'
            ? props.projectId
            : task.parent;
        console.log(task);

        let newTask = {};
        console.log(props.task);
        const projectID = props.task.find(d => d.id === task.parent)
          ? props.task.find(d => d.id === task.parent).project_ID
          : props.projectId;
        const parent = props.task.find(d => d.id === task.parent);
        console.log(parent);

        console.log(projectID);
        const data = {};
        data.project_ID = projectID;
        // task.org_ID = user.arka.orgId;
        // task.created_by = user.arka.userId;
        data.actual_start_date = task.start_date ? task.start_date : null;
        data.stage_ID = task.stage_ID ? task.stage_ID : null;
        data.owner_ID = task.owner_ID ? task.owner_ID : null;
        data.actual_end_date = task.end_date ? task.end_date : null;
        data.activity_type = task.activity_type ? task.activity_type : null;
        data.duration_in_days = task.duration ? task.duration : null;
        data.start_date = task.start_date ? task.start_date : null;
        data.end_date = task.end_date ? task.end_date : null;
        data.parent =
          task.parent === props.projectId || task.parent === projectID
            ? ''
            : task.parent;
        data.name = task.name ? task.name : null;
        data.status = task.status ? task.status : null;
        data.is_completed = task.is_completed ? task.is_completed : null;
        data.sequence = task.sequence ? task.sequence : null;
        data.document_type = task.document_type ? task.document_type : null;
        data.id = task.id ? task.id : null;
        data.description = task.description ? task.description : null;

        data.sequence =
          props.task
            .filter(d => d.project_ID === projectID)
            .sort((a, b) => b.sequence - a.sequence)[0].sequence + 1;

        data.stage_ID = task.stage_ID ? task.stage_ID : null;
        console.log(data);
        console.log(taskrefData);
        console.log(props.task);
        props.task.map(d => {
          d.owner_name = props.users.filter(us => us.user_ID === d.owner_ID)[0]
            ? props.users.filter(us => us.user_ID === d.owner_ID)[0].fullName
            : 'NA';
        });

        // selectedtask.push(props.task);

        appService
          .addTask(data)
          .then(res => {
            console.log(task);
            // gantt.updateTask(task.id);
            // gantt.refreshData();
            gantt.getTask(task.id).project_ID = res.message.project_ID;
            gantt.getTask(task.id).sequence = res.message.sequence;

            gantt.getTask(task.id).color = handleColor(
              task.status,
              task.end_date
            );
            console.log(projectID);
            gantt.getTask(task.id).parent =
              props.projectId || task.parent !== projectID
                ? res.message.parent
                : projectID;
            gantt.getTask(task.id).owner_name = props.users.filter(
              us => us.user_ID === data.owner_ID
            )[0]
              ? props.users.filter(us => us.user_ID === data.owner_ID)[0]
                  .fullName
              : 'NA';
            gantt.getTask(task.id).refId = task.id;

            gantt.getTask(task.id).id = res.message.activity_ID;

            props.task.push(task);
            gantt.clearAll();

            // taskrefData.push(task);
            console.log(task);
            taskrefData = task;
            console.log(props.task);
            gantt.parse({
              data: props.task,
              links: links,
            });

            let datas = {
              userId: res.message.owner_ID,
              type: 'ADD_TASK',
              activityID: res.message.activity_ID,
              projectID: res.message.project_ID,
            };
            appService.sendMailOnTaskUpdate(datas);
          })
          .catch(err => console.log(err));

        //any custom logic here
      });

      // Updating task data
      gantt.attachEvent('onAfterTaskUpdate', function (id, item) {
        // console.log(item);
        const color = handleColor(item.status, item.end_date);
        console.log(color);
        gantt.getTask(item.id).owner_name = props.users.filter(
          us => us.user_ID === item.owner_ID
        )[0]
          ? props.users.filter(us => us.user_ID === item.owner_ID)[0].fullName
          : 'NA';
        gantt.getTask(item.id).text = item.name;

        gantt.getTask(item.id).last_modified = DateTime.now();
        gantt.getTask(item.id).last_modified_by =
          user.arka.first_name + ' ' + user.arka.surname;
        console.log(user.arka.first_name);
        gantt.getTask(item.id).color = color;

        console.log(item);
        const activityID = item.activity_ID;
        const data = {...item};

        console.log(data);
        delete data.activity_ID;
        console.log(data);
        console.log(activityID);
        appService
          .updateTask(item.id, data)
          .then(res => {
            console.log(res);
            let data = {
              userId: res.message.owner_ID,
              type: 'TASK_UPDATE',
              activityID: res.message.activity_ID,
              projectID: res.message.project_ID,
            };
            appService.sendMailOnTaskUpdate(data);
          })
          .catch(err => console.log(err));
      });

      //Delete Task
      gantt.attachEvent('onAfterTaskDelete', function (id, item) {
        // props.setLoading(true);

        let index = props.task.findIndex(x => x.id === id);
        console.log(id);
        // console.log(id);
        const activityId = id;
        appService
          .deleteTask(activityId)
          .then(res => {
            if (res.success === true && index !== -1) {
              console.log(index);

              props.task.splice(index, 1);
              console.log(props.task);
              gantt.clearAll();

              gantt.parse({
                data: props.task,
                links: links,
              });
            }
            props.setLoading(false);
          })
          .catch(err => console.log(err));
        //any custom logic here
      });

      //Adding Links
      gantt.attachEvent('onAfterLinkAdd', function (id, item) {
        console.log(item);
        console.log(props.links);
        appService
          .addTaskLink(item)
          .then(res => {
            gantt.getLink(item.id).activity_links_ID =
              res.message.activity_links_ID;

            props.links.push(item);
          })
          .catch(err => console.log(err));
        //any custom logic here
      });

      //Delete Link
      gantt.attachEvent('onAfterLinkDelete', function (id, item) {
        console.log(item);
        const activitylinkID = item.activity_links_ID;

        appService
          .deleteTaskLink(item.activity_links_ID)
          .then(res => {
            let index = props.links.findIndex(
              x => x.activity_links_ID === item.activity_links_ID
            );

            if (res.success === true && index !== -1) {
              console.log(index);

              props.links.splice(index, 1);
              console.log(props.links);
              gantt.clearAll();

              gantt.parse({
                data: props.task,
                links: props.links,
              });
            }
          })
          .catch(err => console.log(err));
        //any custom logic here
      });

      //UPDATE Links

      gantt.attachEvent('onAfterLinkUpdate', function (id, item) {
        console.log(item);
        //any custom logic here
      });
    }

    // inlineEditors.attachEvent('onBeforeSave', function (state) {
    //   // console.log(state);
    //   const data = {};
    //   if (state.columnName === 'status') data.status = state.newValue;
    //   if (state.columnName === 'owner_name') data.owner_ID = state.newValue;
    //   console.log(data);

    //   appService.updateTask(state.id, data);
    //   // .then(res => props.handleTaskUpdateFlag())
    //   // .catch(err => console.log(err));
    //   const taskd = getActivitiesData(
    //     false,
    //     generateApiUrlWithFilters(startDate, endDate, '', ''),
    //     props.users,
    //     props.setTaskDatas,
    //     props.projectId,
    //     props.handleColor
    //   );

    //   // -> { id: itemId,
    //   //      columnName: columnName,
    //   //      oldValue: value,
    //   //      newValue: value
    //   //    };
    //   return true;
    // });

    inlineEditors.attachEvent('onSave', function (state) {
      console.log(state);
      // -> { id: itemId,
      //      columnName: columnName,
      //      oldValue: value,
      //      newValue: value
      //    };
      return true;
    });
    inlineEditors.attachEvent('onEditEnd', function (state) {
      console.log(state);
      // -> {id: itemId, columnName: columnName};
      return true;
    });

    //Update Task

    // gantt.attachEvent('onAfterBatchUpdate', function (id, task) {
    //   console.log(task);
    //   // if (show_owner === 'All') return true;
    //   if (props.task) return true;
    //   return false;
    // });

    gantt.config.open_tree_initially = true;
    // gantt.attachEvent('onBeforeParse', function () {
    //   setSelectedTask(props.task);

    //   //any custom logic here
    // });

    console.log(props.users);
    gantt.config.date_format = '%Y-%m-%d %H:%i';
    const {tasks, task, links} = props;

    console.log(props.task);
    console.log(links);
    //defing the lightbox elements

    // gantt.locale.labels.section_priority = "Priority";
    gantt.locale.labels.section_name = 'Task Name';
    gantt.locale.labels.section_start_date = 'Start Time';
    gantt.locale.labels.section_end_date = 'End Time';

    gantt.locale.labels.section_status = 'Status';
    gantt.locale.labels.section_owner_ID = 'Assignee';
    gantt.locale.labels.section_activity_type = 'Type';

    // gantt.locale.labels.section_enddate = 'end_Date';

    // gantt.getLightboxSection('project_ID').setValue('project_ID');

    gantt.serverList('statusOptions', [
      {key: 'To do', label: 'To do'},
      {key: 'In-progress', label: 'In Progress'},
      {key: 'Complete', label: 'Complete'},
    ]);
    props.users.map(user => {
      user.key = user.user_ID;
      user.label = user.fullName;
    });
    console.log(props.users);

    gantt.serverList('userOptions', props.users);

    gantt.refreshData();
    var textEditor = {
      map_to: 'status',
      type: 'select',
      options: gantt.serverList('statusOptions'),
    };
    var userEditor = {
      map_to: 'owner_ID',
      type: 'select',
      options: gantt.serverList('userOptions'),
    };

    const {preview} = props;
    gantt.config.columns = [
      {
        name: 'name',
        label: 'Task name',
        tree: true,
        width: '*',
        min_width: 150,
      },
      {
        name: 'status',
        label: 'Status',
        align: 'center',
        width: '*',
        min_width: 100,
        type: 'select',

        options: gantt.serverList('statusOptions'),
        editor: textEditor,
        hide: preview ? true : false,
      },
      {
        name: 'start_date',
        label: 'Start Date',
        align: 'center',
        width: '*',
        min_width: 100,
      },
      {
        name: 'end_date',
        label: 'End date',
        align: 'center',
        width: '*',
        min_width: 100,
      },
      {
        name: 'owner_name',
        label: 'Assignee',
        align: 'center',
        width: '*',
        min_width: 100,
        type: 'select',
        editor: userEditor,
        hide: preview ? true : false,
      },
      // {
      //   name: 'activity_type',
      //   label: '',
      //   align: 'center',
      //   width: '0',
      //   type: 'text',
      //   min_width: 100,
      //   hidden: true,
      // },

      {name: 'add', label: 'Add', width: 25, hide: preview ? true : false},
    ];
    //  Add conditional rendring for Activity Type buttons

    // gantt.attachEvent('onBeforeLightbox', function (task_id) {
    //   console.log(task_id);
    //   const taskData = props.task.find(d => d.id === task_id);
    //   console.log(taskData);

    //   console.log(taskData.activity_type);

    //   return true;
    // });

    // gantt.attachEvent('onTaskDblClick', function (id, e) {
    //   let taskde = '';
    //   taskde = props.task.find(d => d.id === id);
    //   console.log(id);
    // gantt.attachEvent('onTaskClick', function (id, e) {
    //   taskde = props.task.find(d => d.id === id);
    //   console.log(taskde);
    //   //any custom logic here
    //   return true;
    // });
    //   gantt.resetLightbox();
    //   console.log(taskde.activity_type === 'site_survey');
    //   let type;
    //   if (taskde.activity_type === 'site_survey') {
    //     gantt.config.buttons_left = [
    //       'gantt_save_btn',
    //       'gantt_cancel_btn',
    //       'site_survey',
    //     ];
    //     gantt.config.buttons_right = ['gantt_delete_btn'];
    //   } else {
    //     gantt.config.buttons_left = ['gantt_save_btn', 'gantt_cancel_btn'];
    //     gantt.config.buttons_right = ['gantt_delete_btn'];
    //   }

    //   console.log(typeof type);

    //   return true;
    // });

    // gantt.locale.labels['site_survey'] = 'Open Site Survey';
    // gantt.attachEvent('onLightboxButton', function (button_id, node, e) {
    //   console.log(button_id);
    //   if (button_id == 'site_survey') {
    //     var opened_task = gantt.getState().lightbox;
    //     const taskData = props.task.find(d => d.id === opened_task);
    //     console.log(taskData);
    //     handleSurvey(taskData);
    //   }
    // });

    gantt.attachEvent('onTaskClick', function (id, e) {
      const task = gantt.getTask(id);
      console.log(task);
      // pushHistory({
      //   path: task.id,
      //   taskData: task,
      // });

      return true;
    });

    gantt.templates.lightbox_header = function (start_date, end_date, task) {
      return (
        <>
          <p>{title}</p>
        </>
      );
    };

    gantt.config.lightbox.sections = [
      {
        name: 'name',
        height: 25,
        map_to: 'name',
        type: 'textarea',
        focus: true,
        default_value: 'New Task',
      },
      {
        name: 'status',
        height: 25,
        map_to: 'status',
        type: 'select',
        options: gantt.serverList('statusOptions'),
      },
      {
        name: 'owner_ID',
        height: 25,
        map_to: 'owner_ID',
        type: 'select',
        options: gantt.serverList('userOptions'),
      },
      // {
      //   name: 'time',
      //   type: 'time',
      //   map_to: 'auto',
      // },
      {
        name: 'activity_type',
        type: 'text',
        map_to: 'activity_type',
        height: 30,
      },
      {
        name: 'start_date',
        height: 38,
        map_to: 'start_date',
        type: 'duration',
        focus: true,
      },
      {
        name: 'end_date',
        height: 38,
        map_to: 'end_date',
        type: 'textarea',
        focus: true,
      },
      // {
      //   name: 'project_ID',
      //   height: 38,
      //   map_to: 'project_ID',
      //   type: 'textarea',
      //   focus: true,
      // },
    ];

    gantt.init(ganttContainer.current);
    initGanttDataProcessor();
    gantt.config.autosize = 'y';
    gantt.clearAll();
    console.log(props.task);
    gantt.parse({
      data: props.task,
      links: links,
    });
    // Marker

    // var dateToStr = gantt.date.date_to_str(gantt.config.task_date);
    // let markersData = [];
    // let markerStartData = [];

    // if (props.projectId) {
    //   props.task.map(d => {
    //     if (d.stage_ID) {
    //       markersData.push(d);
    //       console.log(d);
    //       console.log(
    //         markersData.sort((a, b) => {
    //           // Turn your strings into dates, and then subtract them
    //           // to get a value that is either negative, positive, or zero.
    //           return new Date(a.start_date) - new Date(b.start_date);
    //         })
    //       );
    //     }
    //   });

    //   props.stages.map(s => {
    //     let count = 0;
    //     markersData.map(m => {
    //       if (count === 0) {
    //         if (s.stage_ID === m.stage_ID) {
    //           var markerId = gantt.addMarker({
    //             start_date: new Date(m.start_date), //a Date object that sets the marker's date
    //             css: m.stage_ID, //a CSS class applied to the marker
    //             text: s.name, //the marker title
    //             title: dateToStr(new Date(m.start_date)), // the marker's tooltip
    //           });

    //           count++;
    //         }
    //       }
    //     });
    //   });
    //   console.log(props.stages);

    // }
  }, [props]);

  useEffect(() => {
    if (dataProcessor) {
      console.log(dataProcessor);
      dataProcessor.destructor();
      dataProcessor = null;
    }
  }, [dataProcessor]);

  const {zoom} = props;

  setZoom(zoom);

  const handleClose = () => {
    var task = gantt.getTask(lightBoxData.id);

    if (task.$new) gantt.deleteTask(task.id);
    // gantt.hideLightbox();
    setOpen(false);
    setLightBoxData({});
  };
  const handleSave = data => {
    console.log(data);

    var task = gantt.getTask(lightBoxData.id);

    task.name = data.name;
    task.start_date = new Date(data.start_date);
    task.end_date = new Date(data.end_date);
    task.status = data.status;
    task.owner_ID = data.assignee;
    task.owner_name = props.users.find(d => d.id === task.assignee).fullName;
    task.text = data.name;
    task.description = data.description;
    task.activity_type = data.activity_type;
    task.stage_ID = data.stage;
    // task.sequence = task.sequence
    //   ? task.sequence
    //   : props.task.filter(d => d.project_ID === task.project_ID).length;

    console.log(task);

    if (task.$new) {
      gantt.addTask(task, task.parent);
      task.$new = false;
    } else {
      // task.parent = task.parent === task.project_ID ? 0 : task.parent;

      gantt.updateTask(task.id);
    }
    setOpen(false);

    gantt.hideLightbox();
    console.log(data);
  };
  const handleUpdate = data => {
    console.log(data);
  };
  const handleDelete = data => {
    gantt.deleteTask(lightBoxData.id);
    gantt.hideLightbox();
    setOpen(false);
    console.log(data);
  };

  return (
    <div style={{width: '100%', height: '100%'}}>
      {props.projectId && (
        <Grid justifyContent={'end'} sx={{mb: 0.5, display: 'flex'}}>
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            onClick={() => addTaskData()}
          >
            Add Task
          </Button>
        </Grid>
      )}
      <div ref={ganttContainer} style={{width: '100%', height: '100%'}}></div>
      {console.log(lightBoxData)}

      {lightBoxData && user && props.stages && props.task && (
        <LightBox
          open={open}
          handleClose={handleClose}
          lightBoxData={lightBoxData}
          title={lightBoxTitle}
          assignee={props.users}
          user={user}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          activityType={props.activityType}
          stages={props.stages}
          task={props.task}
        />
      )}

      <div id="lightbox1" class="lightbox1"></div>
    </div>
  );
};

export default Gantt;
