import {DeleteOutline, DragIndicator} from '@mui/icons-material';
import {Card, Grid, IconButton, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

const propertyNameMapping = {
  name: 'name',
  description: 'description',
  duration: 'duration_in_days',
};

const getItems = count =>
  Array.from({length: count}, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#E8EDF2',
  padding: grid,
  width: '100%',
});

const EditTasks = ({tasks, setTasks, setDeletedIds}) => {
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(tasks, result.source.index, result.destination.index);

    setTasks(items);
  };

  const handleUpdateName = (e, propertyName, indexValue) => {    let tempTasks = tasks.map((item, index) =>
      index === indexValue ? {...item, [propertyName]: e.target.value} : item
    );
    setTasks(tempTasks);
  };

  const handleDeleteItem = id => {
    let oldTask = tasks.filter((item, innerIndex) => item.id === id);
    let newTasks = tasks.filter((item, innerIndex) => item.id !== id);
    setTasks(newTasks);
    if (oldTask.length > 0 && oldTask[0].activity_ID)
      setDeletedIds(prevState => [...prevState, oldTask[0].activity_ID]);
  };

  return (
    <Grid container sx={{width: 1}}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" style={{padding: 0}}>
          {(provided, snapshot) => (
            <Grid
              container
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              sx={{width: 1}}
            >
              {tasks &&
                tasks.length > 0 &&
                tasks.map((item, index) => (
                  <Grid item xs={12} key={item.id} sx={{py: 1}}>
                    <Card sx={{width: 1}}>
                      <Draggable draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <Grid
                            container
                            sx={{width: 1}}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent="flex-start"
                              alignItems={'center'}
                            >
                              <Grid item container xs={10} alignItems="center">
                                <Grid item xs={1} sx={{mr: 1}}>
                                  <Typography>{`Item no: ${
                                    index + 1
                                  }`}</Typography>
                                </Grid>

                                <Grid item xs={3} sx={{mr: 1}}>
                                  <TextField
                                    label="Name"
                                    value={item[propertyNameMapping.name]}
                                    size="small"
                                    onChange={e =>
                                      handleUpdateName(
                                        e,
                                        propertyNameMapping.name,
                                        index
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={2} sx={{mr: 1}}>
                                  <TextField
                                    label="Duration in days"
                                    value={item[propertyNameMapping.duration]}
                                    size="small"
                                    type='number'
                                    onChange={e =>
                                      handleUpdateName(
                                        e,
                                        propertyNameMapping.duration,
                                        index
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={5} sx={{mr: 1}}>
                                  <TextField
                                    label="Description"
                                    value={
                                      item[propertyNameMapping.description]
                                        ? item[propertyNameMapping.description]
                                        : ''
                                    }
                                    size="small"
                                    onChange={e =>
                                      handleUpdateName(
                                        e,
                                        propertyNameMapping.description,
                                        index
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={2}
                                alignItems="center"
                                justifyContent={'space-between'}
                              >
                                <Grid item>
                                  <IconButton
                                    onClick={() => handleDeleteItem(item.id)}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </Grid>
                                <Grid item>
                                  <DragIndicator />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    </Card>
                  </Grid>
                ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};

export default EditTasks;
