import {createEntityAdapter} from '@reduxjs/toolkit';
import {json} from 'express';
import AppState from '../__types__/AppState.interface';
import SalesforceDataType from '../__types__/SalesforceData.interface';

//@ts-ignore
import cookie from 'react-cookie';
import envConfig from '../../config/envConfig';

export const getUser = () => {
  console.log(process.env.REACT_APP_ARKA_COOKIE_KEY, envConfig.env);
  let arkaProfile = localStorage.getItem(envConfig.env.ARKA_KEY);

  if (arkaProfile) arkaProfile = JSON.parse(arkaProfile);

  // let tslProfile =  localStorage.getItem(envConfig.env.TSL_KEY);
  // if(tslProfile) tslProfile=JSON.parse(tslProfile)

  const tslProfile = {
    jwt_encoded_data:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjE0NzMsInVzZXJfb3JnIjo3NDgsInVzZXJfcm9sX2lkIjoiUk9MRTAwMDQzNyIsImFwcF9pZCI6IkFQUDAwMDAwMiJ9.m6lubS5i8YZwOnb2yStFF96nSsPCgcGMmYCxuTZSS50',
    token: 'd2c7a25ee607a8c4d2ab752db266f468025454f3',
    user_id: 1473,
  };

  if (!arkaProfile && !tslProfile) return null;

  let user: {arka?: any; tsl?: any} = {};

  if (arkaProfile) user = {arka: arkaProfile};
  if (tslProfile) user = {...user, tsl: tslProfile};

  console.log('profile', user);
  return user;
};

export const getLHS = () => {
  let lhs = localStorage.getItem(envConfig.env.SIDEBAR);

  if (lhs) {
    return JSON.parse(lhs);
  }

  return null;
};

const selectSalesForceId = (item: SalesforceDataType) => item.Id;
export const dashboardDataAdapter = createEntityAdapter<any>({
  selectId: item => item.id,
});
export const leadDataAdapter = createEntityAdapter<any>({
  selectId: selectSalesForceId,
});
export const quoteDataAdapter = createEntityAdapter<any>({
  selectId: item => item.OpportunityId,
});
export const opportunityDataAdapter = createEntityAdapter<any>({
  selectId: selectSalesForceId,
});
export const contactDataAdapter = createEntityAdapter<any>({
  selectId: selectSalesForceId,
});

export const projectDataAdapter = createEntityAdapter<any>({
  selectId: item => item.projectId,
});

export const initialState: AppState = {
  loading: null,
  error: null,
  user: getUser(),

  rules: null,
  menuItems: [],
  dashboardData: null,
  dashboardDataAdapter: dashboardDataAdapter.getInitialState(),
  leadDataAdapter: leadDataAdapter.getInitialState(),
  opportunityDataAdapter: opportunityDataAdapter.getInitialState(),
  contactDataAdapter: contactDataAdapter.getInitialState(),
  quoteDataAdapter: quoteDataAdapter.getInitialState(),
  l2c2Stages: [],
  profileData: {},
  projects: [],
  TSLProjects: null,
  documentTypes: [],
  organisations: null,
  adminUsers: [],
  projectStages: [
    {value: null, stage_ID: 'STG000001', label: 'Unassigned'},
    {value: 'assigned', stage_ID: 'STG000002', label: 'Assigned'},
    {value: 'accepted', stage_ID: 'STG000004', label: 'Accepted'},
    {value: 'rejected', stage_ID: 'STG000003', label: 'Rejected'},

    {value: 'won', stage_ID: 'STG000005', label: 'Won'},

    {value: 'lost', stage_ID: 'STG000006', label: 'Lost'},
  ],
  twinDevices: {
    devices: [],
    progress: [],
  },

  userPrefs: {
    prefs: {
      mode: 'light',
      wishlist: [],
    },
  },
  org: null,
  lhs: getLHS(),
  env: null,
  notifications: [],
};
