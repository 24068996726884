import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Slide from '@mui/material/Slide';
import appService from '../../../core/service/app.service';
import { makeStyles } from '@mui/styles';

import {useSnackbar} from 'notistack';

const useStyle = makeStyles(theme => ({
  dialogPaper: {
    maxWidth: 'calc(100vw - 20%)',
    width: 'calc(100vw - 20%)',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stageMapping = {
  created: 'Created',
  inProgress: 'In Progress',
  completed: 'Completed',
};

const EditOrderModal = ({
  openDialog,
  setOpenDialog,
  selectedOrder,
  getOrderHistory,
  page,
}) => {
  const classes = useStyle();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [selectedMode, setSelectedMode] = useState();

  useEffect(() => {
    setSelectedMode(selectedOrder.status);
  }, [selectedOrder]);

  const editOrderHandler = async () => {
    enqueueSnackbar('Edit in progress')
    try {
      if (selectedOrder) {
        const updateOrderResponse = await appService.editOrder({
          order_ID: selectedOrder.order_ID,
          project_ID: selectedOrder.project_ID,
          status: selectedMode,
        });
if(page!=='productsOrder'){
  setOpenDialog(false);
}
setSelectedMode(null);
        getOrderHistory();
        console.log({updateOrderResponse});
        enqueueSnackbar('Edit successful',{variant:'success'})
       

    } else {
        throw new Error('No selected order');
      }
    } catch (err) {
      console.log({err});
      enqueueSnackbar('Edit failed',{variant:'error'})

    }
  };
  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={() => setOpenDialog(false)}
      sx={{minWidth: {xs: '100%', sm: '80', md: '50%'}, margin: 'auto'}}
      classes={{paper: classes.dialogPaper}}
    >
      <DialogTitle>Edit Order</DialogTitle>
      <DialogContent>
        <Grid container sd={{width: 1}}>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Grid item>
              <Typography variant="body1">Order Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <Select
                size="small"
                value={selectedMode}
                onChange={e => setSelectedMode(e.target.value)}
                fullWidth
              >
                {Object.keys(stageMapping).map((item, index) => {
                  return (
                    <MenuItem key={index} value={stageMapping[item]}>
                      {stageMapping[item]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setOpenDialog(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={editOrderHandler}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderModal;
