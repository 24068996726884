import {
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import DialogHeading from '../../components/DialogHeading';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import envConfig from '../../config/envConfig';
import {DateTime} from 'luxon';

const SupportTicketDetails = ({handleClose, data, open}) => {
  const handleDownload = file => {
    if (!file) return;
    fetch(`${envConfig.env.DOCUMENTSERVICE_URI}?name=${file}`)
      .then(resp => resp.text())

      .then(resp => {
        console.log(resp);
        window.location.assign(resp);
      })

      .catch(err => console.log(err));
  };
  console.log(data);
  return (
    <Dialog open={open} handleClose={handleClose} maxWidth={'xl'}>
      <DialogHeading
        title={'Support Tickets Details '}
        handleClose={handleClose}
      />
      <DialogContent>
        {console.log(data)}
        {data && (
          <Grid container>
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                Support Ticket Id
              </Typography>
              <Typography variant="h5" sx={{wordWrap: 'break-word'}}>
                {data.support_ticket_ID}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                Subject
              </Typography>
              <Typography variant="h5" sx={{wordWrap: 'break-word'}}>
                {data.subject}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                Description
              </Typography>
              <Typography variant="h5" sx={{wordWrap: 'break-word'}}>
                {data.description}
              </Typography>
            </Grid>{' '}
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                Type of Issue
              </Typography>
              <Typography variant="h5" sx={{wordWrap: 'break-word'}}>
                {data.type_of_issue}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                Status
              </Typography>
              <Typography variant="h5" sx={{wordWrap: 'break-word'}}>
                {data.status}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                Created Date
              </Typography>
              <Typography variant="h5" sx={{wordWrap: 'break-word'}}>
                {DateTime.fromISO(data.created_date)
                  .toFormat('dd/MM/yyyy')
                  .toString()}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 1}}>
              <Typography variant="h6" sx={{wordWrap: 'break-word'}}>
                File
              </Typography>
              {console.log(data.documents)}
              {data.documents.length > 0 &&
                data.documents.map(d => (
                  <Chip
                    label={d.file_display_name}
                    variant={'outlined'}
                    deleteIcon={<FileDownloadIcon />}
                    onClick={() => handleDownload(d.file_name)}
                    onDelete={() => handleDownload(d.file_name)}
                  />
                ))}
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SupportTicketDetails;
