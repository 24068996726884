import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import MapAutocomplete from './../../components/MapAutocomplete';
import {GoogleApiWrapper} from 'google-maps-react';
// import Map, {Ilatlng} from '../../components/Map';
import {Box} from '@mui/system';
import {Formik} from 'formik';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as Yup from 'yup';
import ILead from './__types__/lead.interface';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Map from '../Projects/Map';
import {Ilatlng} from '../../components/Map';
import DialogHeading from '../../components/DialogHeading';

import {DesktopDatePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime, Settings} from 'luxon';
import appService from '../../core/service/app.service';
import IActivity, {
  IDefaultActivity,
} from '../Projects/__types__/Activity.interface';
import {makeStyles} from '@mui/styles';
import {leadDataAdapter} from '../../core/redux/initialState';
import {useSnackbar} from 'notistack';
import {ITemplate} from './__types__/templates.interface';
import Gantt from '../TaskManagment/Gantt';
import {AppSettingsAltRounded} from '@mui/icons-material';
import CustomNoRowsOverlay from '../../components/NoRowsOverlay';
import {selectLoading} from '../../core/redux/AppSlice';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import envConfig from '../../config/envConfig';
import IStage from '../Stages/__types__/Stages.interface';

const statusTypes: {toDo: string; inProgress: string; complete: string} = {
  toDo: 'To do',

  inProgress: 'In-progress',

  complete: 'Complete',
};

interface ITemplateForm {
  onCreateProject: any;
  goBack: any;

  templates: {
    activityTemplates: any[];
    paymentTemplates: any[];
    surveyTemplates: any[];
  };
}

function TemplateForm({onCreateProject, goBack, templates}: ITemplateForm) {
  const [templateActivities, setTemplateActivities] = useState<any | null>(
    null
  );

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [initValues, setInitValues] = useState({
    activity_template_ID: '',
    // payment_template_ID: '',
    survey_template_ID: '',
  });

  const baseUrl = envConfig.env.SURVEY_URL;

  const updateGanttChart = async (template_ID: string) => {
    setLoading(true);
    try {
      const result = await appService.getTemplateActivities(template_ID);

      let stages: IStage[] = result.message;

      const ganttData: any[] = [];
      let startDate = new Date();
      stages.forEach(stage => {
        const tempStage = {...stage};
        //@ts-ignore
        tempStage.id = tempStage.stage_ID;
        //@ts-ignore
        tempStage.parent = null;
        //@ts-ignore
        tempStage.text = tempStage.name;
        //@ts-ignore
        tempStage.start_date = new Date();
        //@ts-ignore
        tempStage.end_date = new Date();
        ganttData.push(tempStage);

        const activities = stage.activities;

        activities?.forEach((ts: any) => {
          const tempActivity = {...ts};
          tempActivity.id = tempActivity.activity_ID;
          tempActivity.color = handleColor(
            tempActivity.status,
            tempActivity.end_date
          );

          const st = startDate;
          const et = DateTime.fromJSDate(st)
            .plus({days: Number(tempActivity.duration_in_days) || 10})
            .toJSDate();

          tempActivity.open = false;

          tempActivity.text = tempActivity.name;
      

          tempActivity.parent = tempStage.stage_ID;

          tempActivity.start_date = st;
          tempActivity.end_date = et;

          tempActivity.readonly = true;

          startDate = tempActivity.end_date;

          ganttData.push(tempActivity);
        });
      });

      console.log(ganttData);

      setTemplateActivities(ganttData);
      setLoading(false);

      return ganttData;
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  const handleColor = (status: any, endDate: any) => {
    const now = new Date().toISOString();

    if (status === statusTypes.complete) {
      return '#00C853';
    } else if (now > endDate) {
      return '#FF0000';
    } else if (status === statusTypes.toDo) {
      return '#FFC35E';
    } else if (status === statusTypes.inProgress) {
      return '#409eff';
    } else {
      return '#FFC35E';
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        activity_template_ID: Yup.string().required('Required'),
        survey_template_ID: Yup.string().required('Required'),
        // payment_template_ID: Yup.string().required('Required'),
      })}
      onSubmit={values => {
        onCreateProject(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container xs={12} spacing={3} mt={1}>
              <Grid item container alignItems={'center'} xs={12}>
                <Grid item xs={4}>
                  <Typography> Task Template</Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    value={values.activity_template_ID}
                    size="small"
                    name="activity_template_ID"
                    onChange={async e => {
                      handleChange(e);

                      const tasks = await updateGanttChart(e.target.value);

                      const projectDurationDays = tasks?.reduce(
                        (pv: any, cv: any) => pv + Number(cv.duration_in_days),
                        0
                      );
                    }}
                    select
                    fullWidth
                    error={Boolean(errors.activity_template_ID)}
                    helperText={errors.activity_template_ID}
                  >
                    {templates.activityTemplates.map(template => (
                      <MenuItem value={template.activity_template_ID}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item container>
                {templateActivities &&
                  (templateActivities.length > 0 ? (
                    <Grid item container width={'100%'} rowSpacing={2}>
                      <Grid item width={'100%'}>
                        <Gantt
                          //@ts-ignore
                          tasks={templateActivities}
                          //@ts-ignore
                          zoom={'Days'}
                          projectId={null}
                          task={templateActivities}
                          links={[]}
                          onZoomChange={() => {}}
                          ganttView={true}
                          setGanttView={() => {}}
                          setTaskDatas={() => {}}
                          users={[]}
                          handleTaskUpdateFlag={() => {}}
                          handleColor={handleColor}
                          setLoading={() => {}}
                          preview={true}

                          // onDataUpdated={this.logDataUpdate}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{width: 1}}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Card sx={{width: 1, py: 4}}>
                        <CustomNoRowsOverlay text={'No Activities Available'} />
                      </Card>
                    </Grid>
                  ))}

                {loading && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height={300}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                )}
              </Grid>
              {/* <Grid item container alignItems={'center'} xs={12}>
                <Grid item xs={4}>
                  <Typography> Payment Template</Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    value={values.payment_template_ID}
                    size="small"
                    name="payment_template_ID"
                    onChange={handleChange}
                    select
                    fullWidth
                    error={Boolean(errors.payment_template_ID)}
                    helperText={errors.payment_template_ID}
                  >
                    {templates.paymentTemplates.map(template => (
                      <MenuItem value={template.payment_template_ID}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid> */}

              <Grid item container alignItems={'center'} xs={12}>
                <Grid item xs={4}>
                  <Typography> Survey Template</Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    value={values.survey_template_ID}
                    size="small"
                    name="survey_template_ID"
                    onChange={handleChange}
                    select
                    fullWidth
                    error={Boolean(errors.survey_template_ID)}
                    helperText={errors.survey_template_ID}
                  >
                    {templates.surveyTemplates.map(template => (
                      <MenuItem value={template.template_ID}>
                        {template.template_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      window.open(
                        `${baseUrl}/template/${values.survey_template_ID}/tsl`
                      )
                    }
                  >
                    Preview
                  </Button>
                </Grid>
              </Grid>

              <Grid
                item
                container
                alignItems={'center'}
                xs={12}
                mt={2}
                justifyContent="space-between"
              >
                {' '}
                <Grid item>
                  <Button autoFocus variant="contained" onClick={goBack}>
                    Back
                  </Button>
                </Grid>{' '}
                <Grid item>
                  <Button autoFocus variant="contained" type="submit">
                    Create
                  </Button>
                </Grid>{' '}
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}

export default TemplateForm;
