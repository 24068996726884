import React, {useEffect, useState} from 'react';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';

import Grid from '@mui/material/Grid';

import LinearProgress from '@mui/material/LinearProgress';

import loginStyle from './login.style';
import arkaLogo from '../../assets/images/logo.svg';

import microsoft from '../../assets/images/microsoft.png';

import {
  Alert,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ArkaLogo from '../../assets/images/SolarLas360md.png';
import * as Yup from 'yup';
import {Formik} from 'formik';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {Theme} from '@mui/system';
import {Link} from 'react-router-dom';
import {RootState} from '../../core/redux/store';
import {useDispatch, useSelector} from 'react-redux';
import {setUser} from '../../core/redux/AppSlice';
import TSLLogo from '../../assets/images/tsl_logo.png';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import appService from '../../core/service/app.service';
import envConfig from '../../config/envConfig';

import {loginRequest} from '../../config/authConfig';

import {useHistory} from 'react-router';

//@ts-ignore
import cookie from 'react-cookie';
import {getDomain} from '../../utils/utils';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',

    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

const Login: React.FC<any> = () => {
  const {instance} = useMsal();
  const history = useHistory();
  const classes = useStyle();

  const [login, setLogin] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>('');

  const dispatch = useDispatch();

  const handleLogin = (values: any) => {
    const {email, password} = values;
    setLogin(true);
    const body = {
      email,
      password,
      appId: String(envConfig.env.APPID),
      orgId: String(envConfig.env.ORGID),
    };
    appService
      .loginUser(body)
      .then(res => {
        console.log(res);


        localStorage.setItem(envConfig.env.ARKA_KEY , JSON.stringify(res.message))
        const user = {arka: res.message};
        // eslint-disable-next-line no-restricted-globals
        const domain = getDomain(location.origin);

        console.log('loggin in on domain', domain);


        dispatch(setUser(user));
      })
      .catch(err => {
        console.log(err);
        if (typeof err === 'object') {
          setLoginError(err.message);
        } else {
          setLoginError(err);
        }

        setLogin(false);
      });
  };

  const microsoftLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      console.log(response);
      const loginresponse = await appService.socialSignInsso({
        appId: envConfig.env.APPID,
        orgId: envConfig.env.ORGID,
        email: response.account?.username,
        socialPlatform: 'microsoft',
        socialProfile: response,
      });
      console.log(loginresponse);

      const user = {arka: loginresponse.message};

      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setUser(user));
    } catch (error) {
      console.log(error);
    }
  };

  const TSLLogin = async () => {
    history.push('/tslLogin');
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={11} sm={6} md={7} lg={4}>
        <Card>
          <Grid
            container
            justifyContent={'center'}
            flexDirection="column"
            alignItems={'center'}
            px={2}
            py={5}
          >
            <Grid item xs={12} my={1}>
              <img src={ArkaLogo} alt="arkalogo" height="60px" />
            </Grid>

            <Grid item xs={12}>
              <Typography>Please enter your credentials</Typography>
            </Grid>

            <Grid item xs={12} width="100%">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  visible: false,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Email must be formatted correctly.')
                    .max(255)
                    .required('Email is required.'),
                  password: Yup.string().required('Please Enter your Password'),
                })}
                onSubmit={handleLogin}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => {
                  return (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid
                        item
                        xs={12}
                        container
                        flexDirection="column"
                        justifyContent="center"
                        alignItems={'center'}
                      >
                        <Grid item xs={9} width="100%">
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={9} width="100%">
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={values.visible ? 'text' : 'password'}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'visible',
                                        !values.visible,
                                        false
                                      )
                                    }
                                  >
                                    {values.visible ? (
                                      <VisibilityOffIcon color="primary" />
                                    ) : (
                                      <VisibilityIcon color="primary" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        {loginError && (
                          <Grid item xs={9} width="100%" my={1}>
                            <Alert
                              severity="error"
                              onClose={() => {
                                setLoginError(null);
                              }}
                            >
                              {loginError}
                            </Alert>
                          </Grid>
                        )}

                        <Grid item xs={4} sm={3} width="100%" my={1}>
                          <LoadingButton
                            loading={login}
                            variant="contained"
                            color="secondary"
                            type="submit"
                            fullWidth
                          >
                            Sign In
                          </LoadingButton>
                        </Grid>

                        <Grid
                          item
                          container
                          xs={9}
                          mt={2}
                          justifyContent="center"
                        >
                          <Grid item>
                            <Button
                              onClick={microsoftLogin}
                              sx={{
                                textTransform: 'none',
                              }}
                              startIcon={
                                <img
                                  src={microsoft}
                                  alt="arkalogo"
                                  height="16px"
                                />
                              }
                            >
                              Sign in with Microsoft
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              onClick={TSLLogin}
                              sx={{
                                textTransform: 'none',
                              }}
                              startIcon={
                                <img
                                  src={TSLLogo}
                                  alt="tsllogo"
                                  height="20px"
                                />
                              }
                            >
                              Sign in with TSL
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item mt={1}>
                          <Typography variant="body2">
                            <Link to="/forgotpassword" className={classes.link}>
                              Forgot Your Password?
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item mt={1}>
                          <Typography variant="body1">
                            {' '}
                            Don't have an account?{' '}
                            <Link to="" className={classes.link}>
                              {' '}
                              Contact Arka
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
