/* eslint-disable no-restricted-globals */
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
//
import {BrowserRouter} from 'react-router-dom';
import {Provider as ReduxProvider, useDispatch} from 'react-redux';
import {store} from './core/redux/store';
import axios from 'axios';
import envConfig from './config/envConfig';
import App from './App';

import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';

import {SnackbarProvider} from 'notistack';
import {setEnv} from './core/redux/AppSlice';
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders


/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */


const getOrigin=()=>{


  const basePath = envConfig.env.BUILD_PATH

  if(basePath){
    return `${location.origin}${envConfig.env.BUILD_PATH}`
  }else return location.origin

}
(async function () {
  try {
    const backendURL =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : getOrigin();
    const res = await axios.get(String(`${backendURL}/api/authURL`));
    const envtype = res.data.message;

    console.log(envtype, 'sdddddddd');

    envConfig.setEnvironment(envtype);
  } catch (error) {
    console.log(error);
  }
  // import {msalConfig} from './config/authConfig';

  const {msalConfig} = require('./config/authConfig');
  console.log(msalConfig, envConfig.env);
  const msalInstance = new PublicClientApplication(msalConfig);

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <SnackbarProvider maxSnack={3}>
          <ReduxProvider store={store}>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </ReduxProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
