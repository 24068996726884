import React, {FC, useEffect} from 'react';
import Box from '@mui/material/Box';
import IndexSwitch from './core/router/IndexRouter';
import MiniDrawer from './components/AppBar/MiniDrawer';
import {Theme, createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/system';
import {blue, pink} from '@mui/material/colors';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from './core/redux/store';
import {getl2c2cStages, getUserPrefs} from './core/redux/AppThunk';
import {updateTheme} from './core/redux/AppSlice';
import appService from './core/service/app.service';
import {useIsAuthenticated} from '@azure/msal-react';
import Login from './Pages/Login/Login';
import {FontDownloadRounded} from '@mui/icons-material';

const defaultTheme = createTheme({
  typography: {
    fontFamily: `"Helvetica Neue","Roboto","sans-serif"`,
    subtitle2: {
      color: '#1c3366',
      fontSize: '1rem',
      fontWeight: '500',
    },
    subtitle1: {
      color: '#1c3366',
      fontSize: '1.5rem',
      fontWeight: '500',
    },

    body1: {
      color: '#222',
      fontSize: '1rem',
      fontWeight: '500',
    },
    body2: {
      color: '#263342',
      fontSize: '0.875rem',
      fontWeight: 400,
    },

    h6: {
      color: '#777777',
      fontSize: '0.75rem',
      fontWeight: 'normal',
    },
    h4: {
      color: '#222',
      fontSize: '0.75rem',
      fontWeight: 'normal',
    },
    h5: {
      color: '#222',
      fontSize: '0.875rem',
      fontWeight: 'normal',
    },
  },

  components: {
    //@ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // cursor: 'pointer',
          background: 'white',
          '& .MuiButtonBase-root': {
            color: 'black',
          },
          '& .MuiDataGrid-columnHeaders': {
            background: 'linear-gradient(#FFFFFF, #E8EDF2)',
            color: '#1c3366',
            textTransform: 'uppercase',
            fontSize: '0.875rem',
            minHeight: '35px !important',
            maxHeight: '35px !important',
            lineHeight: '35px !important',
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '35px !important',
          },

          '& .MuiDataGrid-cell': {
            fontSize: '0.875rem',
            fontWeight: 400,
            // color:"#222"
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: '#EBEDF2',
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-root': {
            background: 'linear-gradient(to bottom, #f5f7fa, #e8edf2)',
          },
          '.Mui-expanded': {
            '&.MuiAccordionSummary-root': {
              background: 'linear-gradient(to bottom, #f5f7fa, #e8edf2)',
            },
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#e8edf2',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: 'break-word',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: '3px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#E8EDF2',
          fontSize: '0.875rem',
          '&  .MuiFormHelperText-root.Mui-error': {
            //<--- here
            backgroundColor: 'white',
            margin: 0,
            paddingLeft: 10,
          },
          '& .MuiSelect-select': {
            background: '#E8EDF2 ',
          },
          '& .css-1vktcis-MuiFormControl-root-MuiTextField-root': {
            background: 'red',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          background: '#E8EDF2',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#E8EDF2',
          '& .MuiInputBase-adornedEnd': {
            // ,& . MuiInputBase-sizeSmall,& . MuiInputBase-multiline,& . MuiInputBase-adornedEnd,& . css-voqpi6-MuiInputBase-root-MuiOutlinedInput-root":{
            background: 'red',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            // background:"#E8EDF2 "
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          '&.Mui-selected': {
            color: '#1c3366',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#1c3366',
          },
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: '#E8EDF2',
          padding: '5px',
          marginBottom: '5px',
          color: '#1c3366',
          fontSize: '1rem',
          fontWeight: 600,
        },
      },
    },

    // MuiStepLabel: {
    //   styleOverrides: {
    //     label: {
    //       color: 'white',
    //       [`&.Mui-active`]: {
    //         color: 'white',
    //       },
    //       [`&.Mui-completed`]: {
    //         color: 'white',
    //       },
    //     },

    //     iconContainer: {
    //       '& .MuiSvgIcon-root': {
    //         '& .MuiStepIcon-text': {
    //           fill: '#000000de',
    //         },

    //         color: 'white',
    //         [`&.Mui-active`]: {
    //           color: '#f4cd2a',
    //         },
    //         [`&.Mui-completed`]: {
    //           color: '#f4cd2a',
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       background: 'white',
    //     },
    //     icon: {
    //       color: 'black',
    //     },
    //   },
    // },

    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       ':hover': {
    //         background: '#0000000a',
    //       },
    //       '&.Mui-selected': {
    //         background: '#0000000a',
    //       },
    //     },
    //   },
    // },

    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       background: 'white',
    //     },
    //   },
    // },

    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       color: 'white',
    //       '&.Mui-selected': {
    //         color: '#f4cd2a',
    //       },
    //     },
    //   },
    // },

    // MuiTabs: {
    //   styleOverrides: {
    //     indicator: {
    //       backgroundColor: '#f4cd2a',
    //     },
    //   },
    // },

    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-root': {
    //         '&:hover fieldset': {
    //           borderColor: '#0f2439',
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: '#0f2439',
    //         },

    //         '& .MuiInputAdornment-root': {
    //           color: 'black',
    //         },
    //       },
    //     },
    //   },
    // },
  },

  palette: {
    primary: {
      main: '#409eff', //  blue
      contrastText: '#fff',
      dark: '#66b1ff',
      light: '#66b1ff',
    },
    secondary: {
      main: '#409eff', // light blue
      contrastText: '#fff',
    },
    background: {
      default: '#E8EDF2', //gray,
    },

    // @ts-ignore
    appBar: '#fff',

    // action: {
    //   active: '#000',

    //   // hover: '#0f2439',
    // },
  },
});

const darkTheme: Theme = createTheme({
  palette: {
    mode: 'dark',

    primary: {
      main: '#ffffff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#90caf9', //orange
      contrastText: '#000000',
    },
  },
});

const App: FC = props => {
  const dispatch = useDispatch();
  let userPrefs = useSelector((state: RootState) => state.appState.userPrefs);

  const themeSetter = (theme: 'light' | 'dark') => {
    dispatch(updateTheme(theme));

    // make a deep clone of updated userPres and send it.
    let wishList = [...userPrefs.prefs.wishlist];
    const newPrefs = {mode: theme, wishlist: wishList};
    appService
      .updateUserPreferance(userPrefs.individualId, {
        ...userPrefs,
        prefs: newPrefs,
      })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };

  return (
    <ThemeProvider
      theme={userPrefs.prefs.mode === 'light' ? defaultTheme : darkTheme}
    >
      <IndexSwitch themeSetter={themeSetter} />
    </ThemeProvider>
  );
};

export default App;
