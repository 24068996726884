import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import appService from '../../core/service/app.service';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IDocument from './__types__/Document.interface';
import UploadIcon from '@mui/icons-material/Upload';
import {styled} from '@mui/system';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import cookieParser from 'cookie-parser';
import envConfig from '../../config/envConfig';
import IDocumentTypes from './__types__/DocumentTypes.interface';
import {v4 as uuidv4} from 'uuid';
import {AppSettingsAltRounded, DoubleArrow} from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadDocumentDialog from './Modals/UploadDocumentDialog';
import DocumentUpload from './Modals/DocumentUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';

import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Input = styled('input')({
  display: 'none',
});

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="100"
        height="80"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box textAlign={'center'}>No Documents Available</Box>
    </StyledGridOverlay>
  );
}

const StyledGridOverlay = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));
const DocUploadedType = [
  {id: 0, name: 'All'},
  {id: 1, name: 'Document Uploaded by You'},
  {id: 2, name: 'Document Shared by Us'},
];

interface IDocuments {
  project_ID: string | undefined;
  user_ID: string | undefined;

  handleSend: any;
  handleDocSend: any;
}
function Documents({
  project_ID,
  handleSend,
  user_ID,
  handleDocSend,
}: IDocuments) {
  const [type, setType] = React.useState('');
  const [backDropOpen, setBackDropOpen] = useState({state: false, message: ''});

  const user = useSelector((state: RootState) => state.appState.user);

  const [documentTypes, setDocumentTypes] = useState<null | IDocumentTypes[]>(
    null
  );

  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [status, setStatus] = useState<string>('');
  const [value, setValue] = React.useState(0);

  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [docFilterData, setdocfilterData] = useState<any>([]);
  const [chipData, setChipData] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const myOrg = useSelector((state: RootState) => state.appState.org);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = (e: any) => {
    console.log(e.target.innerText);
    handleDocumnetsUploaded(e.target.innerText);
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const getDocumentData = () => {
    if (project_ID)
      appService
        .getDocuments(project_ID)
        .then(res => {
          console.log(res);
          setDocuments(res.message);
          setdocfilterData(res.message);
        })
        .catch(err => {
          console.log(err);
        });
  };

  useEffect(() => {
    if (!project_ID) return;
    getDocumentData();

    appService
      .getDocumentTypes()
      .then(res => {
        console.log(res);
        setDocumentTypes(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleDownload = (file: string | undefined) => {
    if (!file) return;
    fetch(`${envConfig.env.DOCUMENTSERVICE_URI}?name=${file}`)
      .then(resp => resp.text())

      .then(resp => {
        console.log(resp);
        window.location.assign(resp);
      })

      .catch(err => console.log(err));
  };

  const deleteDocument = async (fileName: any) => {
    setBackDropOpen({state: true, message: 'Deleting...'});

    try {
      const data = {file_name: fileName};

      const res = await appService.deleteDocument(data);

      const tempDocs = documents?.filter(doc => doc.file_name !== fileName);

      if (tempDocs) setDocuments(tempDocs);
      console.log(tempDocs);
    } catch (err) {
      console.log(err);

      setBackDropOpen({state: true, message: 'Error Occured'});
    }

    setBackDropOpen({state: false, message: 'Deleted Successfully'});
    getDocumentData();
  };

  const handleChangeFilters = (id: string) => {
    setdocfilterData('');
    const index = selectedFilters.indexOf(id);
    console.log(id);
    let tempSelectedFilters: string[] = selectedFilters;
    if (index > -1) {
      tempSelectedFilters = tempSelectedFilters.filter(
        (item: string) => item !== id
      );
    } else {
      tempSelectedFilters = [...tempSelectedFilters, id];
    }
    console.log(tempSelectedFilters);
    setSelectedFilters(tempSelectedFilters);
  };

  useEffect(() => {
    if (documentTypes && documentTypes.length > 0) {
      setType(String(documentTypes[0].document_type_ID));
    }
  }, [documentTypes]);

  const filteredDocuments =
    documents && documents.length > 0
      ? documents.filter(doc =>
          selectedFilters.length > 0
            ? selectedFilters.includes(doc.document_type_ID + '')
            : true
        )
      : [];

  const handleDocumnetsUploaded = (data: any) => {
    if (data === DocUploadedType[0].name) {
      setdocfilterData(documents);
      documents?.map(data => renderDocumentRow(data));
    }
    if (data === DocUploadedType[1].name) {
      const docData: any = documents?.filter(data => data.owner_ID === user_ID);
      setdocfilterData(docData);

      docData.map((document: any) => renderDocumentRow(document));
    }
    if (data === DocUploadedType[2].name) {
      const docData = documents?.filter(data => data.owner_ID !== user_ID);
      setdocfilterData(docData);
      docData?.map(doc => renderDocumentRow(doc));
    }
    getDocumentData();
  };

  const handleVisibility = (documentID: any) => {
    if (!documents) return;

    const document = documents?.find(
      document => document.document_ID === documentID
    );

    if (document) {
      const visibility = !document.visibility;
      document.visibility = visibility;

      setDocuments([...documents]);

      appService
        .updateDocument(documentID, {visibility: visibility})
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  const renderDocumentRow = (document: any) => {
    return (
      <TableRow
        hover
        key={document.ID}
        sx={{'&:last-child td, &:last-child th': {border: 0}}}
      >
        <TableCell component="th" scope="row">
          {document.file_display_name}
        </TableCell>
        <TableCell align="left">{document.status}</TableCell>

        <TableCell align="left">
          {new Date(String(document.created_at)).toLocaleDateString()}
        </TableCell>

        <TableCell align="left">
          <Box>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() =>
                handleDocSend(document.file_name, document.document_ID)
              }
              size="small"
              sx={{mr: 1}}
            >
              <SendIcon sx={{border: '1px solid ', borderRadius: '4px'}} />
            </IconButton>
            {/* When contact is uplaod */}
            {/* {type === envConfig.env.CONTRACT_DOCUMENT_ID && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() =>
                  handleSend(document.file_name, document.document_ID)
                }
                size="small"
                sx={{mr: 1}}
              >
                <SendIcon sx={{border: '1px solid ', borderRadius: '4px'}} />
              </IconButton>
            )} */}

            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDownload(document.file_name)}
              size="small"
            >
              <FileDownloadIcon
                titleAccess="Download document"
                sx={{border: '1px solid ', borderRadius: '4px'}}
              />
            </IconButton>

            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => deleteDocument(document.file_name)}
              size="small"
            >
              <DeleteIcon
                titleAccess="Delete document"
                sx={{border: '1px solid ', borderRadius: '4px'}}
              />
            </IconButton>

            <IconButton
              edge="end"
              aria-label="visibility"
              onClick={() => handleVisibility(document.document_ID)}
              size="small"
            >
              {document.visibility ? (
                <VisibilityIcon
                  titleAccess="Toggle visibility to the customer"
                  sx={{border: '1px solid ', borderRadius: '4px'}}
                />
              ) : (
                <VisibilityOffIcon
                  titleAccess="Toggle visibility to the customer"
                  sx={{border: '1px solid ', borderRadius: '4px'}}
                />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  //
  return (
    <Box>
      <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.modal + 1}}
        open={backDropOpen.state}
      >
        <Box
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <CircularProgress color="inherit" />
          <Typography m={2}>{backDropOpen.message}</Typography>
        </Box>
      </Backdrop>

      {uploadDialogOpen && (
        <UploadDocumentDialog
          open={uploadDialogOpen}
          setOpen={setUploadDialogOpen}
          documentTypes={documentTypes}
          projectId={project_ID}
          getDocumentData={getDocumentData}
        />
      )}

      {documentTypes && documents ? (
        <Grid container flexDirection={'column'}>
          <Grid container>
            <Grid item container alignItems={'center'} spacing={3} xs={3}>
              <Grid item></Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={'space-between'}>
              <Grid container item xs={2}>
                <Grid item xs={3}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <FilterListIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={e => handleCloses(e)}
                    onChange={e => console.log(e)}
                    PaperProps={{
                      style: {
                        maxHeight: 40 * 4.5,
                        minWidth: '20ch',
                      },
                    }}
                  >
                    {DocUploadedType.map(option => (
                      <MenuItem
                        key={option.id}
                        value={option.name}
                        onClick={e => handleCloses(e)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
                <Grid item xs={9} sx={{pt: 1}}>
                  <Typography variant="body1">Document Types</Typography>
                </Grid>
              </Grid>

              {console.log(chipData)}
              <Grid item xs={10}>
                {documentTypes &&
                  documentTypes.map(type => (
                    <Chip
                      sx={{
                        p: 2,
                        m: 0.5,
                        '&:hover': {
                          background: '#e8edf2',
                        },
                        color: selectedFilters.includes(type.document_type_ID)
                          ? '#1c3366'
                          : '#222',
                        background: selectedFilters.includes(
                          type.document_type_ID
                        )
                          ? '#e8edf2'
                          : 'none',
                      }}
                      label={type.subject}
                      variant={
                        selectedFilters.includes(type.document_type_ID)
                          ? 'filled'
                          : 'outlined'
                      }
                      onClick={() => handleChangeFilters(type.document_type_ID)}
                    />
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item my={2}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Status</TableCell>

                    <TableCell align="left">Uploaded On</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDocuments.map((documnet: any) =>
                    renderDocumentRow(documnet)
                  )}
                </TableBody>
              </Table>
              {filteredDocuments.length === 0 && documents.length === 0 ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  marginY={3}
                >
                  <CustomNoRowsOverlay />
                </Box>
              ) : null}
            </TableContainer>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={<UploadIcon />}
              component="span"
              variant="contained"
              size="small"
              style={{marginBottom: 8}}
              color="secondary"
              onClick={() => setUploadDialogOpen(true)}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Box>
  );
}

export default Documents;
