import { Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Headings from '../../components/Headings';
import appService from '../../core/service/app.service';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateProductCategory from './CreateProductCategory';
import EditProductCategory from './EditProductCategory';
import { useDispatch } from 'react-redux';
import CircularProgressDialog from '../../components/CircularProgressDialog';
import DeleteDialogOrg from '../Organisation/DeleteDialogOrg';
import CreateDialog from './CreateDialog';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSnackbar } from 'notistack';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';

const modes = {
  category: 'Category',
  product: 'Product',
  sku: 'SKU'
}

const ProductCategory = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [productCategory, setProductCategory] = useState<any>();
  const [product, setProduct] = useState<boolean>(false);
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editableData, setEditableData] = useState<any>(false);
  // const [deleteDialog, setDeleteDialog] = useState<any>(false);
  const [deleteId, setDeleteId] = useState<any | null>(null);
  const [deleteAllowed, setDeleteAllowed] = useState<boolean>(false);
  const [deleteMsg, setDeleteMsg] = useState<string | null>(null)
  const [selectedDeleteData, setSelectDeleteData] = useState<any>();

  const [loading, setLoading] = useState(false)

  const [modeValue, setModeValue] = React.useState<string>(modes.category);


  const handleChangeMode = (event: React.SyntheticEvent, newValue: string) => {
    setModeValue(newValue);
  };

  const getAllProductData = () => {
    appService
      .getProductCategory()
      .then(res => {
        let newData = res.message.map((data: any, index: any) => {
          data.ID = index;

        });
        setProductCategory(res.message);
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    getAllProductData()
    // setProduct(false)
  }, []);
  const handleDeleteProduct = async () => {

    try {

      enqueueSnackbar('Deleting SKU  ')
      setLoading(true)
      if (modeValue === modes.category) {
        await appService.deleteProductCategory(deleteId)
        setLoading(false)
        handleClearDeleteData()
      } else if (modeValue === modes.product) {
        await appService.deleteProduct(deleteId)
        setLoading(false)
        handleClearDeleteData()
      } else
        if (modeValue === modes.sku) {
          await appService.deleteSku(deleteId)
          setLoading(false)
          handleClearDeleteData()

        }
      getAllProductData()
      enqueueSnackbar(`Deleted ${modeValue}  `, { variant: 'success' })
    } catch (err) {
      console.log(err)
    }



    // console.log(selectedDeleteData);
    // // setDeleteData(true);
    // appService
    //   .deleteProductCategory(selectedDeleteData)
    //   .then(res => {
    //     setProduct(res.sucess);
    //     setDeleteId(null)
    //     // setDeleteData(false);
    //     // setDeleteDialog(false);
    //     handleStatus();
    //   })
    //   .catch(err => console.log(err));
  };

  const CategoryColumns = [
    { field: 'id', headerName: 'ID', flex: 1.5 },
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'description', headerName: 'Description', flex: 3 },
    { field: 'is_gazebo', headerName: 'Is a gazebo', flex: 3 },
    // { field: 'productname', headerName: 'Product Name', flex: 1.5 },

    // { field: 'sku', headerName: 'SKU', flex: 1.5 },
    {
      field: 'action',
      heaserName: 'Action',
      renderCell: (params: any) => (
        <div>
          <Tooltip arrow title={'Edit Product'}>
            <IconButton
              color="primary"
              component="span"
              size="small"
              onClick={() => {
                console.log(params);
                const categoryId = params.id;


                productCategory.map((category: any) => {
                  if (category.product_category_ID === params.id)
                    setEditableData(category)
                })

                setCreateOpen(true)

                // const cat = productCategory.find(
                //   (category: any) => category.ID === category
                // );
                // if (product) {
                //   const product_category_ID = product.product_category_ID;
                //   const product_Id = product.product_ID;
                //   const sku_ID = product.sku_ID;
                //   const data = { product_category_ID, product_Id, sku_ID };
                //   console.log(product);
                //   setEditableData(product);
                // }
              }}
            >
              <EditOutlinedIcon
                sx={{
                  color: '#999999',
                  border: '1px solid',
                  borderRadius: '6px',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title={'Delete Product'}>
            <IconButton
              color="primary"
              component="span"
              size="small"
              onClick={() => {
                console.log(params);

                let productCount = 0
                productCategory.map((category: any) => {
                  if (category.product && category.product.length > 0) {

                    if (category.product_category_ID === params.id) {
                      if (category.product && category.product.length > 0) {
                        productCount = category.product.length
                      }
                    }

                  }
                })

                setDeleteId(params.id)
                if (productCount > 0) {
                  setDeleteAllowed(false)
                  setDeleteMsg(`Category with ID ${params.id} can not be deleted as there are ${productCount} Product(s) assigned to it`)
                } else {
                  setDeleteAllowed(true)
                  setDeleteMsg(`Are you sure you would like to delete the Category with ID : ${params.id}`)
                }

              }}
            >
              <DeleteOutlineIcon
                sx={{
                  color: '#999999',
                  border: '1px solid',
                  borderRadius: '6px',
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const ProductColumns = [
    { field: 'product_category_name', headerName: 'Category Name', flex: 1.5 },
    { field: 'id', headerName: 'ID', flex: 1.5 },
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'description', headerName: 'Description', flex: 1.5 },
    { field: 'short_description', headerName: 'Short Description', flex: 1.5 },
    { field: 'cover_image', headerName: 'Cover Image URL', flex: 1.5 },
    {
      field: 'action',
      heaserName: 'Action',
      renderCell: (params: any) => (
        <div>
          <Tooltip arrow title={'Edit Product'}>
            <IconButton
              color="primary"
              component="span"
              size="small"
              onClick={() => {
                console.log(params);
                const productID = params.id;

                let productCategoryId: string = ''

                productCategory.map((category: any) => {
                  category.product.map((product: any) => {
                    if (product.product_ID === params.id)
                      setEditableData({
                        ...product,
                        productCategoryId: category.product_category_ID,
                      })
                  })
                })


                setCreateOpen(true)



              }}
            >
              <EditOutlinedIcon
                sx={{
                  color: '#999999',
                  border: '1px solid',
                  borderRadius: '6px',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title={'Delete Product'}>
            <IconButton
              color="primary"
              component="span"
              size="small"
              onClick={() => {
                console.log(params);
                const productID = params.id;

                let skuCount = 0
                productCategory.map((category: any) => {
                  if (category.product && category.product.length > 0) {
                    let result = category.product.filter((product: any) => product.product_ID === params.id)
                    if (result.length > 0) {
                      console.log(result)
                      if (result[0].sku && result[0].sku.length > 0) {
                        skuCount = result[0].sku.length
                      }
                    }

                  }
                })

                setDeleteId(params.id)
                if (skuCount > 0) {
                  setDeleteAllowed(false)
                  setDeleteMsg(`Product with ID ${params.id} can not be deleted as there are ${skuCount} SKU(s) assigned to it`)
                } else {
                  setDeleteAllowed(true)
                  setDeleteMsg(`Are you sure you would like to delete the Product with ID : ${params.id}`)

                }


              }}
            >
              <DeleteOutlineIcon
                sx={{
                  color: '#999999',
                  border: '1px solid',
                  borderRadius: '6px',
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  const SKUColumns = [
    { field: 'product_category_name', headerName: 'Category Name', flex: 1.5 },
    { field: 'product_name', headerName: 'Product Name', flex: 1.5 },
    { field: 'id', headerName: 'ID', flex: 1.5 },
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'description', headerName: 'Description', flex: 1.5 },
    { field: 'data_3d', headerName: '3D data', flex: 1.5 },
    { field: 'image_count', headerName: 'Image Count', flex: 1.5 },
    {
      field: 'action',
      heaserName: 'Action',
      renderCell: (params: any) => (
        <div>
          <Tooltip arrow title={'Edit Product'}>
            <IconButton
              color="primary"
              component="span"
              size="small"
              onClick={() => {
                console.log(params);
                // const productID = params.id;

                let productCategoryId: string = ''
                let productId: string = ''

                productCategory.map((category: any) => {
                  category.product.map((product: any) => {
                    product.sku && product.sku.length > 0 && product.sku.map((sku: any) => {
                      if (sku.sku_ID === params.id) {
                        setEditableData({
                          ...sku,
                          productCategoryId: category.product_category_ID,
                          productId: product.product_ID,
                        })

                      }
                    })
                  })
                })


                setCreateOpen(true)

              }}
            >
              <EditOutlinedIcon
                sx={{
                  color: '#999999',
                  border: '1px solid',
                  borderRadius: '6px',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title={'Delete Product'}>
            <IconButton
              color="primary"
              component="span"
              size="small"
              onClick={async () => {
                try {
                  setDeleteId(params.id)
                  setDeleteMsg(`Are you sure you would like to delete the SKU with ID : ${params.id}`)
                  setDeleteAllowed(true)

                } catch (err) {
                  enqueueSnackbar('Error occured while deleting SKU : ' + params.id, { variant: 'error' })
                  console.log(err)

                }

                // const productID = params.id;

                // const product = productCategory.find(
                //   (product: any) => product.ID === productID
                // );

              }}
            >
              <DeleteOutlineIcon
                sx={{
                  color: '#999999',
                  border: '1px solid',
                  borderRadius: '6px',
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleStatus = () => {
    setProduct(!product);
  };


  const handleClearDeleteData = () => {
    setDeleteId(null)
    setDeleteMsg(null)
    setDeleteAllowed(false)
  }

  const getFormattedRows = (productCategory: any) => {
    console.log(productCategory);
    if (productCategory) {
      let rows
      if (modeValue === modes.category) {

        rows = productCategory.map((product: any) => ({
          id: product.product_category_ID,
          name: product.name,
          description: product.description,
          is_gazebo: product.is_gazebo ? 'Yes': 'No'
        }));
      } else if (modeValue === modes.product) {
        let products: any = []
        productCategory.map((category: any) => {
          if (category.product.length > 0)
            category.product.map((item: any) => {
              products = [...products, { ...item, product_category_name: category.name }]
            })
        })
        rows = products.map((product: any) => ({
          id: product.product_ID,
          name: product.name,
          description: product.description,
          short_description: product.short_description,
          cover_image: product.cover_image,
          product_category_name: product.product_category_name
        }));

      } else {
        let skus: any = []
        productCategory.map((category: any) => {
          if (category.product.length > 0) {
            category.product.map((product: any) => {
              if (product.sku && product.sku.length > 0) {
                product.sku.map((sku: any) => {
                  skus = [...skus, {
                    ...sku, product_category_name: category.name,
                    product_name: product.name
                  }]
                })
              }
            })
          }
        })
        console.log({ skus })
        rows = skus.map((sku: any, index: number) => ({
          id: sku.sku_ID,
          product_category_name: sku.product_category_name,
          product_name: sku.product_name,
          name: sku.sku_name,
          description:sku.description,
          data_3d: sku.data_3d ? 'Yes' : 'No',
          image_count: sku.image.length
        }));

      }


      return rows;
    }
  };
  return (
    <Grid container sx={{ width: 1 }}>
      <BackDropCircularProgress open={loading} />
      <DeleteDialogOrg
        open={deleteId ? true : false}
        handleClose={handleClearDeleteData}
        handleDeleteOrgId={deleteAllowed ? handleDeleteProduct : null}
        info={deleteMsg}
      />
      <Headings
        heading={'View'}
      // btntitle={'Create Product Category'}
      // handleClick={() => setCreateOpen(modes.category)}
      // btntitle2={'Create Product '}
      // handleClick2={() => setCreateOpen(modes.product)}
      // btntitle3={'Create SKU'}
      // handleClick3={() => setCreateOpen(modes.sku)}
      />
      <Grid container sx={{ width: 1 }} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <TabContext value={modeValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeMode} aria-label="lab API tabs example">
                {Object.keys(modes).map((item: string) => {
                  return (
                    // @ts-ignore
                    <Tab label={modes[item]} value={modes[item]} />
                  )
                })}
              </TabList>
            </Box>

          </TabContext>

        </Grid>
        <Grid item>
          <Button variant='contained' color='secondary' onClick={() => setCreateOpen(true)}>Create</Button>
        </Grid>
      </Grid>



      {productCategory ? (

        // <Box sx={{ height: '70vh', width: '100%', mt: 2 }}>
        //   {console.log(productCategory)}

        //   {/* <EditProductCategory
        //     open={editOpen}
        //     handleClose={() => setEditOpen(false)}
        //     data={editableData}
        //     handleStatus={handleStatus}
        //     listData={productCategory}
        //   /> */}

        //   <DataGrid
        //     getRowId={(productCategory: any) => productCategory.ID}
        //     rows={productCategory ? getFormattedRows(productCategory) : []}
        //     columns={tableColumns}

        //   />
        // </Box>
        <Box sx={{ height: '70vh', width: '100%', mt: 2 }}>
          {createOpen &&
            <CreateDialog
              editableData={editableData}
              modes={modes}
              mode={modeValue}
              open={createOpen}
              handleClose={() => {
                setEditableData(null)
                setCreateOpen(false)
              }}
              data={productCategory}
              getAllProductData={getAllProductData}
            />
          }

          {modeValue === modes.category &&
            < DataGrid
              getRowId={(productCategory: any) => productCategory.id}
              rows={productCategory ? getFormattedRows(productCategory) : []}
              columns={CategoryColumns}

            />
          }
          {modeValue === modes.product &&
            < DataGrid
              getRowId={(productCategory: any) => productCategory.id}
              rows={productCategory ? getFormattedRows(productCategory) : []}
              columns={ProductColumns}

            />
          }
          {modeValue === modes.sku &&
            < DataGrid
              getRowId={(productCategory: any) => productCategory.id}
              rows={productCategory ? getFormattedRows(productCategory) : []}
              columns={SKUColumns}

            />
          }
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Grid>
  );
};

export default ProductCategory;
