import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import appService from '../../core/service/app.service';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import {useSnackbar} from 'notistack';
import {currencyFormatter} from '../../core/service/utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';

interface IRegisterPayment {
  open: boolean;
  handleClose: any;
  orderId: any;
  installmentId: any;
  projectId: any;
  amount_due: string;
}

const initData = {
  amount: '',
  cheque_number: '',
  bank_name: '',
  bank_transaction_number: '',
  amount_due: '0',
};
function RegisterPayment({
  open,
  handleClose,
  orderId,
  installmentId,
  projectId,
  amount_due,
}: IRegisterPayment) {
  const [paymentMode, setPaymentMode] = useState<any>(null);

  const [data, setData] = useState(initData);

  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const myOrg = useSelector((state: RootState) => state.appState.org);
  const registerPayment = async () => {
    setLoading(true);
    try {
      const body = {
        payment_mode: paymentMode,
        order_ID: orderId,
        payment_installment_ID: installmentId,
        amount: String(amount_due),
        bank_name: data.bank_name,
        cheque_number: data.cheque_number,
        bank_transaction_number: data.bank_transaction_number,
        amount_due: String(amount_due),
      };

      console.log(body);
      const res = await appService.registerPayment(projectId, body);

      console.log(res);

      handleClose();
      clear();

      enqueueSnackbar(`Payment Registered Successfully`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } catch (error) {
      enqueueSnackbar(`Error Occured`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.log(error);
    }

    setLoading(false);
  };

  const clear = () => {
    setData(initData);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <BackDropCircularProgress open={loading} />
      <DialogTitle>{'Record Payment'}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container alignItems={'center'}>
            <Grid item xs={3}>
              {' '}
              <Typography> Amount Due:</Typography>
            </Grid>

            <Grid item xs>
              {' '}
              <Typography>
                {' '}
                {currencyFormatter(amount_due, myOrg?.currency)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container alignItems={'center'} xs={12} mb={3}>
            <Grid item xs={3}>
              {' '}
              <Typography>Payment Mode:</Typography>
            </Grid>

            <Grid item xs={6}>
              {' '}
              <Select
                value={paymentMode}
                onChange={e => {
                  clear();
                  setPaymentMode(e.target.value);
                }}
                // sx={{minWidth: 210}}
                size="small"
                fullWidth
              >
                <MenuItem value={'cash'}>Cash</MenuItem>
                <MenuItem value={'cheque'}>Cheque</MenuItem>
                <MenuItem value={'bank_transfer'}>Bank Transfer</MenuItem>
              </Select>
            </Grid>
          </Grid>

          {
            paymentMode === 'cash'
            // &&
            // <Grid item container alignItems={'center'}>
            //   <Grid item xs={3}>
            //     {' '}
            //     <Typography> Amount:</Typography>
            //   </Grid>

            //   <Grid item xs={6}>
            //     {' '}
            //     <TextField
            //       required
            //       fullWidth
            //       type="number"
            //       size="small"
            //       value={data.amount}
            //       name="name"
            //       onChange={e => {
            //         setData({
            //           ...data,
            //           amount: e.target.value,
            //         });
            //       }}
            //       InputProps={{
            //         startAdornment: (
            //           <InputAdornment position="start">
            //             {myOrg?.currency === 'IN' ? '₹' : '$'}
            //           </InputAdornment>
            //         ),
            //       }}
            //     />
            //   </Grid>
            // </Grid>
          }

          {paymentMode === 'cheque' && (
            <Grid item container spacing={1}>
              {' '}
              {/* <Grid item container alignItems={'center'}>
                <Grid item xs={3}>
                  {' '}
                  <Typography> Amount:</Typography>
                </Grid>

                <Grid item xs={6}>
                  {' '}
                  <TextField
                    required
                    fullWidth
                    type="number"
                    size="small"
                    value={data.amount}
                    name="name"
                    onChange={e => {
                      setData({
                        ...data,
                        amount: e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {myOrg?.currency === 'IN' ? '₹' : '$'}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid> */}
              <Grid item container alignItems={'center'}>
                <Grid item xs={3}>
                  {' '}
                  <Typography>Cheque Number:</Typography>
                </Grid>

                <Grid item xs={6}>
                  {' '}
                  <TextField
                    required
                    fullWidth
                    type="number"
                    size="small"
                    value={data.cheque_number}
                    name="name"
                    onChange={e => {
                      setData({
                        ...data,
                        cheque_number: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'}>
                <Grid item xs={3}>
                  {' '}
                  <Typography> Bank Name:</Typography>
                </Grid>

                <Grid item xs={6}>
                  {' '}
                  <TextField
                    required
                    fullWidth
                    size="small"
                    value={data.bank_name}
                    name="name"
                    onChange={e => {
                      setData({...data, bank_name: e.target.value});
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {paymentMode === 'bank_transfer' && (
            <Grid item container spacing={1}>
              {' '}
              {/* <Grid item container alignItems={'center'}>
                <Grid item xs={3}>
                  {' '}
                  <Typography> Amount:</Typography>
                </Grid>

                <Grid item xs={6}>
                  {' '}
                  <TextField
                    required
                    fullWidth
                    size="small"
                    type="number"
                    value={data.amount}
                    name="name"
                    onChange={e => {
                      setData({
                        ...data,
                        amount: e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {myOrg?.currency === 'IN' ? '₹' : '$'}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid> */}
              <Grid item container alignItems={'center'}>
                <Grid item xs={3}>
                  {' '}
                  <Typography> Bank Name:</Typography>
                </Grid>

                <Grid item xs={6}>
                  {' '}
                  <TextField
                    required
                    fullWidth
                    size="small"
                    value={data.bank_name}
                    name="name"
                    onChange={e => {
                      setData({...data, bank_name: e.target.value});
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'}>
                <Grid item xs={3}>
                  {' '}
                  <Typography>Bank Transaction Number:</Typography>
                </Grid>

                <Grid item xs={6}>
                  {' '}
                  <TextField
                    required
                    fullWidth
                    size="small"
                    value={data.bank_transaction_number}
                    name="name"
                    onChange={e => {
                      setData({
                        ...data,
                        bank_transaction_number: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            clear();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={registerPayment} autoFocus variant="contained">
          Record
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RegisterPayment;
