import {Grid} from '@mui/material';
import {Box} from '@mui/system';
import {DataGrid} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react';
import Headings from '../../components/Headings';
import appService from '../../core/service/app.service';

const Inverter = () => {
  const [inverterData, setInverterData] = useState<any>();

  useEffect(() => {
    appService
      .getInverter()
      .then(res => setInverterData(res.message))
      .catch(err => console.log(err));
  }, []);

  const tableColumns = [
    {field: 'inverter_id', headerName: 'Inverter ID', flex: 1},
    {field: 'name', headerName: 'Inverter Name', flex: 1.5},
    {field: 'deviceType', headerName: 'Device Type', flex: 1.5},
    {field: 'gateway_id', headerName: 'GateWay ID', flex: 1},
    {field: 'modbus_address', headerName: 'Modbus Address', flex: 1},
  ];

  const getFormattedRows = (inverterData: any) => {
    if (inverterData) {
      const rows = inverterData.map((inverter: any) => ({
        ID: inverter.ID,
        inverter_id: inverter.inverter_ID,
        name: inverter.name,
        gateway_id: inverter.GatewayId,

        deviceType: inverter.deviceType,
        modbus_address: inverter.modbus_address,
      }));

      return rows;
    }
  };

  return (
    <Grid container>
      <Headings heading={'Device Information'} />

      {inverterData && (
        <Box sx={{height: '70vh', width: '100%', mt: 2}}>
          <DataGrid
            getRowId={(inverterData: any) => inverterData.ID}
            rows={inverterData ? getFormattedRows(inverterData) : []}
            columns={tableColumns}
          />
        </Box>
      )}
    </Grid>
  );
};

export default Inverter;
