import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import IStage, {Stage} from './__types__/Stages.interface';
import {Button, Grid, ListItem, Paper, Typography} from '@mui/material';

export type DraggableListProps = {
  onDragEnd: OnDragEndResponder;
  setStages: any;

  stages: Stage | null;
  categories: {label: string; value: string}[];
  addNewStage: any;
  onStageDelete: any;
  onActivityDelete: any;
};

const DraggableList = React.memo(
  ({
    onDragEnd,
    setStages,
    stages,
    categories,
    addNewStage,
    onStageDelete,
    onActivityDelete,
  }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container rowSpacing={1}>
          {categories.map(({label, value}, index) => {
            if (!stages) return null;
            const categoryStages = stages[value];

            return (
              <Grid
                item
                container
                xs={12}
                sx={{border: '1px solid', p: 1, m: 1}}
              >
                <Grid item sx={{margin: 'auto', marginRight: '0px'}}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      addNewStage(value);
                    }}
                  >
                    Add Stage
                  </Button>
                </Grid>

                <Grid item sx={{textAlign: 'center'}} xs={12}>
                  <Typography>{label}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Droppable droppableId={value}>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {categoryStages.map((item, index) => (
                          <DraggableListItem
                            stage={item}
                            index={index + 1}
                            setStages={setStages}
                            stages={stages}
                            key={item.stage_ID}
                            stageCategory={value}
                            onStageDelete={onStageDelete}
                            onActivityDelete={onActivityDelete}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </DragDropContext>
    );
  }
);

export default DraggableList;
