import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import appService from '../../../core/service/app.service';
import { IPaymentInstallment, IPayment } from '../__types__/Payment.interface';
import { Box } from '@mui/system';
import { currencyFormatter } from '../../../core/service/utils';
import { Button, Card, Grid, Skeleton } from '@mui/material';
import CustomNoRowsOverlay from '../../../components/NoRowsOverlay';

import { useSelector } from 'react-redux';
import { RootState } from '../../../core/redux/store';
import RegisterPayment from '../RegisterPayment';
import BackDropCircularProgress from '../../../components/BackDropCircularProgress';
import { useSnackbar } from 'notistack';
import {
  IOrder,
  IOrderInstallments,
} from '../__types__/OrderInstallments.interface';
import OrderPaymentInstallments from './OrderPaymentInstallments';

interface IPaymentHistory {
  getProposalData: any;
  paymentInstallmentData: IOrder[];
  projectId: string;
}

const PaymentHistory: FC<IPaymentHistory> = ({
  getProposalData,
  paymentInstallmentData,
  projectId,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState();
  const [registerPaymentOpen, setRegisterPaymentOpen] = useState<{
    state: boolean;
    quote: null | any;
    installment: null | IOrderInstallments;
  }>({
    state: false,
    quote: null,
    installment: null,
  });
  const myOrg = useSelector((state: RootState) => state.appState.org);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendPaymentLink = async (installmentID: string, amount: number) => {
    setLoading(true);
    try {
      let body = {
        projectId: projectId,
        paymentInstallmentId: installmentID,
        amount: amount,
      };
      // @ts-ignore
      const result = await appService.sendPaymentLink(body);
      console.log(result);
      enqueueSnackbar(`Link Sent Successfully`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      getProposalData();
    } catch (err) {
      console.log(err);

      enqueueSnackbar(`Error Occured`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    setLoading(false);
  };

  const isAbsolute = (installments: IOrderInstallments[] | undefined | null) =>
    installments?.some(installment => Boolean(Number(installment.amount)));

  const getTotalAmount = (order: IOrder) => {
    const isAbsoluteMode = isAbsolute(order.installments);
    if (!isAbsoluteMode) {
      return Number(order.amount);
    } else
      return order.installments.reduce(
        (sum, installment) => Number(sum) + Number(installment.amount),
        0
      );
  };

  const calculateInstallmentAmount = (
    installment: IOrderInstallments | null,
    amount: string | number | undefined
  ) => {
    console.log({ installment, amount });
    if (!installment || !amount) return 0;
    return Number(Number(installment.percentage_value) * Number(amount));
  };
  return (
    <Grid container sx={{ width: 1 }}>
      <BackDropCircularProgress open={loading} />
      {selectedQuoteId && (
        <OrderPaymentInstallments
          selectedQuoteId={selectedQuoteId}
          handleMoveBackToProposal={() => console.log('called')}
          handleCreateProposal={() => console.log('called')}
          totalQuoteCost={0}
        />
      )}
      <RegisterPayment
        open={registerPaymentOpen.state}
        handleClose={() => {
          setRegisterPaymentOpen({ ...registerPaymentOpen, state: false });
          getProposalData();
        }}
        orderId={registerPaymentOpen.quote?.order_ID}
        installmentId={registerPaymentOpen.installment?.payment_installment_ID}
        projectId={projectId}
        //@ts-ignore
        amount_due={
          isAbsolute(registerPaymentOpen?.quote?.installments)
            ? Number(registerPaymentOpen?.installment?.amount)
            : calculateInstallmentAmount(
              registerPaymentOpen.installment,
              registerPaymentOpen?.quote?.lineItems.reduce(
                (sum: any, item: any) =>
                  sum +
                  (item.amount -
                    item.amount * item.discount +
                    item.amount * item.tax),
                0
              )
            )
        }
      />
      {paymentInstallmentData.length !== 0 ? (
        paymentInstallmentData.map((quote: any, orderIndex: number) => {
          return (
            <Grid container sx={{ width: 1 }}>
              <Accordion
                key={orderIndex}
                sx={{ width: 1 }}
                defaultExpanded={orderIndex === 0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                >
                  <Grid
                    container
                    sx={{ width: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">
                      {`Total Amount: ${currencyFormatter(
                        getTotalAmount(quote),
                        myOrg?.currency
                      )}`}
                    </Typography>
                    {/* <Button variant='outlined' size='small' onClick={() => setSelectedQuoteId(quote.price_quote_ID)}>
                      {'Edit installments'}
                    </Button> */}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ width: 1 }}>
                  <Grid container sx={{ width: 1 }}>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Date&nbsp;(mm/dd/yy)</TableCell>
                            <TableCell align="right">
                              Amount&nbsp;(INR)
                            </TableCell>
                            {quote.order_ID && (
                              <TableCell align="right">Status</TableCell>
                            )}
                            <TableCell align="right">Payment Mode</TableCell>
                            {quote.order_ID && (
                              <TableCell align="right">Actions</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quote && quote.installments && quote.installments.length > 0 && quote.installments.map(
                            (installment: any, index: number) => {
                              return (
                                <TableRow
                                  key={installment.payment_installment_ID}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {new Date(
                                      installment.created_at
                                    ).toLocaleDateString()}
                                  </TableCell>
                                  <TableCell align="right">
                                    {/* {console.log(
                                      quote.lineItems.reduce(
                                        (sum: any, item: any) =>
                                          sum +
                                          (item.amount -
                                            item.amount * item.discount +
                                            item.amount * item.tax)
                                      )
                                    )} */}
                                    {currencyFormatter(
                                      isAbsolute(quote.installments)
                                        ? Number(installment?.amount)
                                        : calculateInstallmentAmount(
                                          installment,
                                          quote?.lineItems?.reduce(
                                            (sum: any, item: any) =>
                                              sum +
                                              (item.amount -
                                                item.amount * item.discount +
                                                item.amount * item.tax),
                                            0
                                          )
                                        ),
                                      myOrg?.currency
                                    )}
                                  </TableCell>
                                  {quote.order_ID && (
                                    <TableCell align="right">
                                      {installment.status ? (
                                        <Chip
                                          size="small"
                                          label={
                                            installment.status === 'captured'
                                              ? 'Paid'
                                              : 'Pending'
                                          }
                                          color={
                                            installment.status === ''
                                              ? 'error'
                                              : 'success'
                                          }
                                        />
                                      ) : (
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          size="small"
                                          onClick={() =>
                                            sendPaymentLink(
                                              installment.payment_installment_ID,
                                              isAbsolute(quote.installments)
                                                ? Number(installment.amount)
                                                : calculateInstallmentAmount(
                                                  installment,
                                                  quote.lineItems.reduce(
                                                    (sum: any, item: any) =>
                                                      sum +
                                                      (item.amount -
                                                        item.amount *
                                                        item.discount +
                                                        item.amount *
                                                        item.tax),
                                                    0
                                                  )
                                                )
                                            )
                                          }
                                        >
                                          Send Payment Link
                                        </Button>
                                      )}
                                    </TableCell>
                                  )}

                                  <TableCell align="right">
                                    {installment.paymentMode}
                                  </TableCell>
                                  {quote.order_ID && (
                                    <TableCell align="right">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        disabled={
                                          installment.status === 'captured'
                                        }
                                        onClick={() => {
                                          setRegisterPaymentOpen({
                                            quote: quote,
                                            installment: installment,
                                            state: true,
                                          });
                                        }}
                                      >
                                        Record Payment
                                      </Button>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })
      ) : (
        <Grid container xs={12} style={{ padding: 20 }}>
          {/* <Card style={{width: '100%', padding: 20}}> */}
          <CustomNoRowsOverlay text={'No Payments History Available'} />
          {/* </Card> */}
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentHistory;
