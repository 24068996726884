import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import ConstructionIcon from '@mui/icons-material/Construction';
import {checkPermisssion} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
import appService from '../../core/service/app.service';
import LoadingButton from '@mui/lab/LoadingButton';
import IOrder from './__types__/order.interface';
import {useSnackbar} from 'notistack';

interface IMiniOrderCard {
  orderData: IOrder;
  onProcess: any;
}

function MiniMyOrderCard({orderData, onProcess}: IMiniOrderCard) {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const history = useHistory();

  const viewProject = () => {
    history.push(`/designs/${orderData.design?.design_project_ID}`);
  };

  return (
    <Card elevation={2}>
      <CardContent
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
          position: 'relative',
        }}
        onClick={() => {}}
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle1" gutterBottom>
              {orderData.order_ID}
            </Typography>
          </Grid>
          <Grid item container flexDirection="row" mb={2} wrap="nowrap">
            <Grid item xs={4}>
              <Typography gutterBottom variant="body2">
                {`${orderData.user?.first_name} ${orderData.user?.surname}`}
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap gutterBottom variant="body2">
                {orderData.status}
              </Typography>
            </Grid>
          </Grid>

          {orderData.design && (
            <Button onClick={viewProject} color="secondary" variant="contained">
              View
            </Button>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MiniMyOrderCard;
