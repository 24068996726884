import LoadingButton from '@mui/lab/LoadingButton';
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
  Tooltip,
  Checkbox,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import appService, {appServiceTSL} from '../../../core/service/app.service';
import {v4 as uuidv4} from 'uuid';
import CreateLineItemForm from './CreateLineItemForm';
import {useSnackbar} from 'notistack';
import DialogHeading from '../../../components/DialogHeading';
import {useSelector} from 'react-redux';
import envConfig from '../../../config/envConfig';
import UploadIcon from '@mui/icons-material/Upload';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import {makeStyles, useTheme} from '@mui/styles';
import CreateProposalFromDesign from './CreateProposalFromDesign';

const useStyles = makeStyles(theme => ({
  tooltipPopper: {
    color: 'black !important',
  },
  tooltipArrow: {
    background: 'black !important',
  },
}));

// const creationColumns = [
//     "product_variant_id",
//     "design_ID",
//     "system_size",
//     "amount",
//     "annual_energy_production",
//     "coverage",
//     "estimated_annual_savings",
//     "discount",
//     "tax"
// ]
const columnMapping = {
  Lead_ID: 'Lead ID',
  product_category_name: 'Product Variant',
  product_name: 'Product',
  sku_name: 'SKU',
  system_size: 'System Size (kWp)',
  discount: 'Discount %',
  tax: 'Tax %',
  annual_energy_production: 'Annual Energy Production (kWh)',
  estimated_annual_savings: 'Estimated Annual Savings',
  created_at: 'Created At',
  //columns for conventional PV
  design_ID: 'TSL Design ID',
  dcCapacity: 'System DC Capacity',
  tsl_proposal_link: 'Proposal Link',
  // shared columns
  amount: 'Total Cost',
  coverage: 'Energy Offset %',
};

const CreateQuoteDialog = ({
  projectId,
  handleMoveToInstallments,
  formModes,
  existingQuotes,
}) => {
  const styles = useStyles();
  const Input = styled('input')({
    display: 'none',
  });
  const [forms, setForms] = useState([
    {
      title: uuidv4(),
      data: {
        product_variant_id: '',
        design_ID: null,
        system_size: null,
        amount: null,
        annual_energy_production: null,
        coverage: null,
        estimated_annual_savings: null,
        discount: null,
        tax: null,
      },
      saved: false,
    },
  ]);
  const myOrg = useSelector(state => state.appState.org);

  const user = useSelector(state => state.appState.user);

  const productVariants =
    user.arka.orgId !== 'ORG005'
      ? [
          {id: '2PRCT00000051', name: 'Power Roof™'},
          {id: '2PRCT00000055', name: 'Power Pergola™'},
          // {id: '2PRCT00000003', name: 'Power Solaire™'},
        ]
      : [
          // {id: '2PRCT00000051', name: 'Power Roof™'},
          {id: '2PRCT00000013', name: 'Power Gazebo'},
          // {id: '2PRCT00000003', name: 'Power Solaire™'},
        ];

  const [loading, setLoading] = useState(false);

  const [formMode, setFormMode] = useState(formModes.pv);

  const [designs, setDesigns] = useState([]);

  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);
  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [isPreliminary, setIsPreliminary] = useState(true);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  // set form mode based on role
  // useEffect(() => {
  //   if (myOrg) {
  //     if (myOrg.org_type === 'gazebo_installer') setFormMode(formModes.pv);
  //     else if (myOrg.org_type === 'installer') setFormMode(formModes.pv);
  //     console.log(myOrg);
  //   }
  // }, [myOrg]);

  const addForm = () => {
    const newForms = [
      ...forms,
      {
        title: uuidv4(),
        data: {
          product_variant_id: '',
          design_ID: null,
          system_size: null,
          amount: '',
          annual_energy_production: null,
          coverage: null,
          estimated_annual_savings: null,
          discount: null,
          tax: null,
          files: [],
          uploadFiles: [],
        },
        saved: false,
      },
    ];
    setForms(newForms);
  };

  const removeForm = title => {
    const newForms = forms.filter(form => form.title !== title);
    setForms([...newForms]);
  };

  const updateLineItem = (title, data) => {
    const newForms = forms.map(form => {
      if (form.title === title) {
        let tempForm = {
          ...form,
          data: data,
          saved: true,
        };
        return tempForm;
      } else return form;
    });
    console.log({newForms});
    setForms([...newForms]);
  };

  const setLineItemEditable = title => {
    const newForms = forms.map(form => {
      if (form.title === title) {
        let tempForm = {
          ...form,
          saved: false,
        };
        return tempForm;
      } else return form;
    });
    console.log({newForms});
    setForms([...newForms]);
  };

  const handleCreateQuote = async () => {
    let data = [];
    forms.map((item, index) => {
      data = [
        ...data,
        {
          ...item.data,
          project_ID: projectId,
        },
      ];
    });

    handleMoveToInstallments(data, uploadFiles, isPreliminary, formMode);
  };

  useEffect(
    (() => async () => {
      if (!projectId) return;

      try {
        const tslProjectID = await appService.getTSLProject(projectId);

        const tslProject = await appServiceTSL.getProjectById(
          tslProjectID.message.tsl_project_ID
        );
        console.log({tslProject});
        setDesigns(tslProject?.designs || []);
      } catch (error) {
        console.log(error);
      }
    })(),
    []
  );

  const handleAddFile = newFile => {
    console.log(files);
    setFiles([...files, ...newFile]);
    setFilesSrc([
      ...filesSrc,
      window.URL.createObjectURL(newFile[0]),
      newFile[0],
    ]);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3)
        enqueueSnackbar('Maximum of 3 files can be uploaded', {
          variant: 'info',
        });
      else {
        setFiles([...files, ...newFile]);
        setFilesSrc([
          ...filesSrc,
          window.URL.createObjectURL(newFile[0]),
          newFile[0],
        ]);
      }
    }
  };

  const handleRemoveImage = index => {
    console.log(index);
    console.log(files);
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles([]);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc([]);
    forceUpdate();
  };

  return (
    <Grid container sx={{width: 1, px: 3, pb: 3}}>
      {/* header */}
      {/* <Grid container item xs={12} justifyContent="center">
        <Typography variant="h4" sx={{my: 0}}>
          Create a Quote
        </Typography>
      </Grid> */}

      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        spacing={2}
        sx={{mb: 1}}
        alignItems="center"
      >
        {/* if type available then show label */}
        <Grid item>
          {/* {myOrg &&
          (myOrg.org_type === 'gazebo_installer' ||
            (myOrg.org_type === 'installer' && false)) ? (
            <Typography>{formMode}</Typography>
          ) : ( */}
          <FormControl>
            <FormLabel>Pick Type</FormLabel>
            <RadioGroup
              value={formMode}
              onChange={e => setFormMode(e.target.value)}
            >
              <Grid container>
                {Object.keys(formModes).map(item => {
                  return (
                    <FormControlLabel
                      label={formModes[item]}
                      value={formModes[item]}
                      control={<Radio />}
                    />
                  );
                })}
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            label="Is this a preliminary proposal"
            control={
              <Checkbox
                color="secondary"
                name="isGazebo"
                checked={isPreliminary}
                sx={{pl: 0}}
                onChange={() => setIsPreliminary(!isPreliminary)}
              />
            }
          />
        </Grid> */}
        <Grid item>
          <Grid container spacing={1}>
            {formMode && formMode === formModes.pg && (
              <>
                <Grid item>
                  <Grid item>
                    <label htmlFor="upload-file">
                      <Input
                        accept="application/pdf"
                        id="upload-file"
                        type="file"
                        multiple
                        onChange={e => {
                          console.log(e.target.files);

                          // setUploadFiles(e.target.files);
                          for (let i = 0; i < e.target.files.length; i++) {
                            console.log(uploadFiles);

                            setUploadFiles(e.target.files[i]);
                          }
                          handleAddFile(e.target.files);
                          // handleAddFile(e.target.files);

                          // setUploadImg([...e.target.files, ...uploadImg]);
                        }}
                      />
                      <Tooltip
                        arrow={true}
                        classes={{
                          arrow: styles.tooltipPopper,
                          tooltip: styles.tooltipArrow,
                        }}
                        title="Upload a signable contract. This will be sent to customer mail via Docusign"
                      >
                        <Button
                          startIcon={<UploadIcon />}
                          component="span"
                          variant="outlined"
                          // style={{marginBottom: 8}}
                          color="secondary"
                          sx={{ml: 2}}
                        >
                          Upload
                        </Button>
                      </Tooltip>
                      <Tooltip
                        arrow={true}
                        classes={{
                          arrow: styles.tooltipPopper,
                          tooltip: styles.tooltipArrow,
                        }}
                        title="Upload a signable contract. This will be sent to customer mail via Docusign"
                      >
                        <IconButton
                          edge="end"
                          aria-label="visibility"
                          size="small"
                        >
                          <InfoIcon
                            color="primary"
                            titleAccess="Upload a signable contract. This will be sent to customer mail via Docusign"
                            sx={{border: '1px solid ', borderRadius: '4px'}}
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </Grid>
                </Grid>

                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={addForm}
                    fullWidth
                  >
                    Add line item
                  </Button>
                </Grid>

                <Grid item>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    color="secondary"
                    onClick={handleCreateQuote}
                    loadingPosition="start"
                    fullWidth
                    disabled={
                      forms.map(item => item.saved === false)[0] ||
                      forms.length === 0 ||
                      files.length === 0
                    }
                  >
                    Create Proposal
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {files && files.length > 0 && filesSrc && filesSrc.length > 0 && (
        <Grid container sx={{width: 1}}>
          {console.log(filesSrc[1].name)}
          <Grid item sx={{mr: 8, mt: 2, width: '100%', height: '20%'}}>
            <Box
              component={'div'}
              // className={classes.imageContainer}
            >
              <Typography>File Uploaded:</Typography>

              <Typography>
                {filesSrc[1].name}
                <span>
                  <a
                    href={filesSrc[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <IconButton>
                      <i className="fas fa-download" />

                      <FileDownloadIcon />
                    </IconButton>
                  </a>
                  <IconButton onClick={() => handleRemoveImage()}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}

      {formMode === formModes.pv ? (
        <CreateProposalFromDesign
          designs={designs}
          handleMoveToInstallments={handleMoveToInstallments}
          formMode={formMode}
          existingQuotes={existingQuotes && existingQuotes.designQuotes}
        />
      ) : (
        forms.map((item, index) => {
          return (
            <CreateLineItemForm
              key={item.title}
              form={item}
              index={index}
              removeForm={removeForm}
              productVariants={productVariants}
              columnMapping={columnMapping}
              updateLineItem={updateLineItem}
              setLineItemEditable={setLineItemEditable}
              designs={designs}
              projectId={projectId}
              formMode={formMode}
              formModes={formModes}
            />
          );
        })
      )}
    </Grid>
  );
};

export default CreateQuoteDialog;
