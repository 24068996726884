import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Button,
} from '@mui/material';
import DialogHeading from '../../components/DialogHeading';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {DateTime} from 'luxon';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {Task} from '@mui/icons-material';
import envConfig from '../../config/envConfig';
import {light} from '@mui/material/styles/createPalette';
import appService from '../../core/service/app.service';
import Documents from '../Projects/Documents';
import UploadDocumentDialog from '../Projects/Modals/UploadDocumentDialog';
import UploadIcon from '@mui/icons-material/Upload';
import Proposal from '../Projects/Proposals/Proposal';
import AddGatewayDialog from '../Projects/Performance/AddGatewayDialog';
import {useSnackbar} from 'notistack';
import CustomerFeedback from '../Projects/tabs/CustomerFeedback';

const LightBox = ({
  open,
  handleClose,
  lightBoxData,
  title,
  assignee,
  user,
  handleSave,
  handleUpdate,
  handleDelete,
  activityType,
  stages,
  task,
}) => {
  console.log(lightBoxData);
  const [initValues, setInitValues] = React.useState({
    name: '',
    status: '',
    start_date: '',
    end_date: '',
    assignee: '',
    description: '',
    activity_type: '',
    stage: '',
  });
  const [btn, setBtn] = useState();
  const [clickAction, setClickAction] = useState();
  const [surveyData, setSurveyData] = useState();
  const [tslproject, setTslProject] = useState();
  const [url, setUrl] = useState();
  const [uploadDialogOpen, setUploadDialogOpen] = useState();
  const [documentTypes, setDocumentTypes] = useState();
  const [openAddDialog, setOpenAddDialog] = useState();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [selectedSystemIndex, setSelectedSystemIndex] = useState();
  const [userSystems, setUserSystems] = useState();
  const [remainingSystems, setRemainingSystems] = useState();
  const [projectData, setProjectData] = useState();

  const statusOption = [
    {key: 'To do', label: 'To do'},
    {key: 'In-progress', label: 'In Progress'},
    {key: 'Complete', label: 'Complete'},
  ];

  const setInitialState = async lightBoxData => {
    setInitValues({
      name: lightBoxData.name ? lightBoxData.name : 'New Task',
      status: lightBoxData.status ? lightBoxData.status : 'To do',
      start_date: lightBoxData.start_date ? lightBoxData.start_date : null,
      end_date: lightBoxData.end_date ? lightBoxData.end_date : null,
      assignee: lightBoxData.owner
        ? lightBoxData.owner.user_ID
        : user.arka.user_Id,
      description: lightBoxData.description ? lightBoxData.description : null,
      activity_type: lightBoxData.activity_type
        ? lightBoxData.activity_type
        : null,
      stage: lightBoxData.stage_ID ? lightBoxData.stage_ID : null,
    });
  };

  useEffect(() => {
    if (lightBoxData.project_ID) {
      appService
        .getSurveyDetails(lightBoxData.project_ID)
        .then(res => {
          console.log(res);
          setSurveyData(res.message);
        })
        .catch(err => {
          console.log(err);
        });

      appService
        .getTSLProject(lightBoxData.project_ID)
        .then(res => setTslProject(res.message))
        .catch(err => console.log(err));

      appService
        .getDocumentTypes()
        .then(res => {
          console.log(res);
          setDocumentTypes(res.message);
        })
        .catch(err => {
          console.log(err);
        });
      appService
        .getProjectById(lightBoxData.project_ID)
        .then(res => setProjectData(res.message))
        .catch(err => console.log(err));
    }
  }, [lightBoxData]);

  console.log(projectData);

  const getDocumentData = () => {
    if (lightBoxData.project_ID)
      appService
        .getDocuments(lightBoxData.project_ID)
        .then(res => {
          console.log(res);
          // setDocuments(res.message);
          // setdocfilterData(res.message);
        })
        .catch(err => {
          console.log(err);
        });
  };

  useEffect(() => {
    console.log(lightBoxData);
    setInitialState(lightBoxData);
    getAllData();
  }, [lightBoxData]);
  const handleCloses = () => {
    handleClose();
    setInitValues({
      name: '',
      status: '',
      start_date: '',
      end_date: '',
      assignee: '',
      description: '',
      activity_type: '',
      stage: '',
    });
  };

  const handleDeleteTask = values => {
    handleDelete(values);
    setInitValues({
      name: '',
      status: '',
      start_date: '',
      end_date: '',
      assignee: '',
      description: '',
      activity_type: '',
      stage: '',
    });
  };

  const handleDates = e => {
    task.map(tsk => {
      if (lightBoxData.parent === tsk.project_ID) {
        tsk.stage_ID === e.target.value
          ? setInitValues({
              name: '',
              status: '',
              start_date: tsk.start_date,
              end_date: '',
              assignee: '',
              description: '',
              activity_type: '',
              stage: '',
            })
          : console.log('NONE');
      }
    });
  };

  const handleClicks = async () => {
    console.log('design');
    try {
      const tslURL = envConfig.production.TSL_URL;
      if (tslproject) {
        //@ts-ignore
        window.location.href = `${tslURL}/projectSummary/${tslproject.tsl_project_ID}`;
      } else {
        if (!projectData) return;

        const {Lead} = projectData;
        const tslBody = {
          tsl_token: user?.tsl?.token,

          name: projectData.name,
          latitude: Lead.latitude,
          longitude: Lead.longitude,
          zoom: 21,
          client_name: Lead.first_name,
          client_email_id: Lead.email,
          client_address: Lead.gmaps_address_string,
          projectId: lightBoxData.project_ID,
        };

        const tslprojectresponse = await appService.createTSLProject(tslBody);

        const tslProjectId = tslprojectresponse.message.id;
        setTslProject({tsl_project_ID: tslProjectId});
        window.location.href = `${tslURL}/projectSummary/${tslProjectId}`;
      }
    } catch (error) {
      enqueueSnackbar(`Design creation Error: ${error}`, {
        variant: 'error',
        autoHideDuration: 5000,
      });

      console.log(error);
    }
  };

  const handleAddSystem = async systemId => {
    enqueueSnackbar('Linking system to project');
    // setLoading(true);
    const projectId = lightBoxData.project_ID;
    try {
      const addSystemForProject = await appService.addSystemToProject({
        systemId,
        projectId,
      });
      console.log(addSystemForProject);
      setOpenAddDialog(false);
      await getAllData();
      // setLoading(false);
      enqueueSnackbar('System has been linked', {variant: 'success'});
    } catch (error) {
      // enqueueSnackbar(error + '', {variant: 'error'});

      // setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(surveyData);
    switch (lightBoxData.activity_type) {
      case 'site_survey':
        setBtn('Site survey');
        setUrl(
          surveyData
            ? `${envConfig.env.SURVEY_URL}/sitesurvey/${surveyData.survey_ID}/tsl`
            : `https://tsl-survey-tool.azurewebsites.net/api/sitesurvey`
        );
        break;
      case 'check_list':
        setBtn('Check List');
        setUrl(
          surveyData
            ? `${envConfig.env.SURVEY_URL}/sitesurvey/${surveyData.survey_ID}/tsl`
            : `https://tsl-survey-tool.azurewebsites.net/api/sitesurvey`
        );
        break;
      case 'design_package':
        setBtn('View Design');
        // setClickAction(handleClicks);
        setUrl();

        break;
      case 'document_upload':
        setBtn('upload');
        setClickAction();
        setUrl();
        break;

      case 'payments':
        setBtn('payments');
        setClickAction();
        setUrl();
        break;

      case 'send_proposal':
        setBtn('sendproposal');
        setClickAction();
        setUrl();
        break;
      case 'commissioning':
        setBtn('Add System');
        setClickAction();
        setUrl();
        break;
      case 'nps':
        setBtn('Customer Feedback');
        setClickAction();
        setUrl();
        break;

      default:
        setBtn();
        setUrl();
        setClickAction();

        break;
    }
  }, [lightBoxData.activity_type]);
  // const updateActions = data => {
  //   setBtn(data.btn);
  //   console.log(data);
  // };

  console.log(lightBoxData.activity_type);

  const getAllSystemDetails = async () => {
    console.log('da');
    try {
      return await appService.getSystemDetails();
    } catch (error) {
      console.log(error);
    }
  };
  //   getProjectSystemDetailsAndStatus
  const getUserSystems = async () => {
    try {
      return await appService.getProjectSystemDetailsAndStatus(
        lightBoxData.project_ID
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    enqueueSnackbar('Getting all systems');
    // setLoading(true);
    try {
      console.log('sys');

      const allSystemDetails = await getAllSystemDetails();
      const getUserSystemsData = await getUserSystems(lightBoxData.project_ID);
      console.log({allSystemDetails, getUserSystemsData});
      if (getUserSystemsData.message.length > 0) setSelectedSystemIndex(0);
      setUserSystems(getUserSystemsData.message);
      if (allSystemDetails.message.length > 0) {
        let remainingSystems = [];

        allSystemDetails.message.map(item => {
          if (
            !getUserSystemsData.message.find(
              userSystem => userSystem.system_ID === item.system_ID
            )
          )
            remainingSystems.push(item);
        });

        setRemainingSystems(remainingSystems);
      }
      enqueueSnackbar('Received data', {variant: 'success'});
      // setLoading(false);
    } catch (error) {
      // enqueueSnackbar(error + '', {variant: 'error'});
      console.log(error);
      // setLoading(false);
    }
  };

  const handleActions = () => {
    switch (btn) {
      case 'Add System':
        setOpenAddDialog(true);
        break;
      case 'View Design':
        handleClicks();
        break;
    }
  };

  return (
    <Dialog
      id="lightbox1"
      class="lightbox1"
      open={open}
      onClose={handleCloses}
      sx={{minwidth: '200px', minHeight: '200px'}}
    >
      <DialogHeading title={title} handleClose={handleCloses} />
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Task name is required.'),
            status: Yup.string().required('Status is required.'),

            start_date: Yup.string().required('Start Date is required.'),

            end_date: Yup.string().required('End Date is required.'),
            assignee: Yup.string().required('Assignee is required.'),
            description: Yup.string().required('Description is required.'),
            activity_type: Yup.string().required('Activity Type is required.'),
            stage: Yup.string().required('Stage is required.'),
          })}
          onSubmit={async values => {
            handleSave(values);
            handleCloses();
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                  {console.log(values)}
                  <Grid item container xs={12} spacing={2} mt={1}>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Task Name
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Task Type
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        defaultValue={values.activity_type}
                        value={
                          values.activity_type ? values.activity_type : 'NA'
                        }
                        onChange={handleChange}
                        select
                        name="activity_type"
                      >
                        {activityType?.map(act => (
                          <MenuItem value={act.type}>{act.name}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Start Date
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          inputFormat="dd/MM/yyyy"
                          value={
                            values.start_date
                              ? new Date(values.start_date).toISOString()
                              : DateTime.now()
                          }
                          //@ts-ignore
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue(
                                'start_date',
                                newValue.endOf('day').toString()
                              );
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              size="small"
                              {...params}
                              placeholder={'dd/MM/yyyy'}
                              fullWidth
                              name="end_date"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        End Date
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          inputFormat="dd/MM/yyyy"
                          value={
                            values.end_date
                              ? new Date(values.end_date).toISOString()
                              : DateTime.now()
                          }
                          //@ts-ignore
                          onChange={newValue => {
                            if (newValue) {
                              setFieldValue(
                                'end_date',
                                newValue.endOf('day').toString()
                              );
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              size="small"
                              {...params}
                              placeholder={'dd/MM/yyyy'}
                              fullWidth
                              name="end_date"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Assign To
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        defaultValue={values.assignee}
                        value={values.assignee}
                        onChange={handleChange}
                        select
                        name="assignee"
                      >
                        {assignee?.map(assignee => (
                          <MenuItem value={assignee.user_ID}>
                            {assignee.fullName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Status
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        defaultValue={values.status}
                        value={values.status}
                        onChange={handleChange}
                        name="status"
                        select
                      >
                        {statusOption?.map(status => (
                          <MenuItem value={status.key}>{status.label}</MenuItem>
                        ))}{' '}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      {stages.map(d => console.log(d.stage_ID))}
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Stage
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        defaultValue={values.stage ? values.stage : 'NA'}
                        value={values.stage}
                        onChange={e => {
                          handleChange(e);
                          // Date changes based on the stages
                          // task.map(tsk => {
                          //   if (lightBoxData.parent === tsk.project_ID) {
                          //     tsk.stage_ID === e.target.value
                          //       ? setInitValues({
                          //           name: lightBoxData.name
                          //             ? lightBoxData.name
                          //             : 'New Task',
                          //           status: lightBoxData.status
                          //             ? lightBoxData.status
                          //             : 'To do',
                          //           start_date: tsk.start_date,
                          //           end_date: lightBoxData.end_date
                          //             ? lightBoxData.end_date
                          //             : null,
                          //           assignee: lightBoxData.owner
                          //             ? lightBoxData.owner.user_ID
                          //             : user.arka.user_Id,
                          //           description: 'jasdkhsa',
                          //           activity_type: lightBoxData.activity_type
                          //             ? lightBoxData.activity_type
                          //             : null,
                          //           stage: e.target.value
                          //             ? e.target.value
                          //             : null,
                          //         })
                          //       : console.log('NONE');
                          //   }
                          // });
                        }}
                        name="stage"
                        select
                      >
                        <MenuItem value={'NA'}>{'NA'}</MenuItem>
                        {stages.map(stg => (
                          <MenuItem value={stg.stage_ID}>{stg.name}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      {console.log(values)}
                      <Typography variant="h5" sx={{mb: 0.5}}>
                        Description
                      </Typography>
                      <TextField
                        multiline
                        rows={5}
                        fullWidth
                        size="small"
                        onChange={handleChange}
                        // defaultValue={values.description}
                        value={values.description}
                        name="description"
                        inputProps={{maxLength: 400}}
                      />
                    </Grid>
                  </Grid>

                  {/* {uploadDialogOpen && (
                    <UploadDocumentDialog
                      open={uploadDialogOpen}
                      setOpen={setUploadDialogOpen}
                      documentTypes={documentTypes}
                      projectId={lightBoxData.project_ID}
                      getDocumentData={getDocumentData}
                    />
                  )}

                  <Button
                    startIcon={<UploadIcon />}
                    component="span"
                    variant="contained"
                    size="small"
                    style={{marginBottom: 8}}
                    color="secondary"
                    onClick={() => setUploadDialogOpen(true)}
                  >
                    Upload
                  </Button> */}
                  {/* Documents tab */}
                  {lightBoxData.project_ID && btn === 'upload' && (
                    <Grid sx={{mt: 1.5}}>
                      <Documents
                        project_ID={lightBoxData.project_ID}
                        // handleSend={handleSend}
                        // user_ID={projectData.owner_ID}
                        // handleDocSend={handleDocSend}
                      />
                    </Grid>
                  )}

                  {/* Payments tab */}
                  {lightBoxData.project_ID &&
                    (btn === 'payments' || btn === 'sendproposal') && (
                      <Grid sx={{overflow: 'auto', mt: 1.5, width: '100%'}}>
                        <Proposal
                          projectId={lightBoxData.project_ID}
                          // paymentTemplateId={projectData?.payment_template_ID}
                        />
                      </Grid>
                    )}

                  {/* Add Systems for commissioning */}
                  {openAddDialog && (
                    <AddGatewayDialog
                      setOpenDialog={setOpenAddDialog}
                      handleAddSystem={handleAddSystem}
                      systems={remainingSystems}
                    />
                  )}

                  {/* Customer Feedback activity type NPS */}
                  {btn === 'Customer Feedback' && projectData && (
                    <CustomerFeedback lead={projectData.Lead} />
                  )}
                  <Grid
                    item
                    xs={12}
                    justifyContent={'right'}
                    sx={{display: 'flex', mt: 1.5}}
                  >
                    {console.log(clickAction)}
                    {/* buttons for activity */}
                    {btn &&
                      btn !== 'upload' &&
                      btn !== 'payments' &&
                      btn !== 'sendproposal' &&
                      btn !== 'Customer Feedback' && (
                        <Button
                          autoFocus
                          variant="contained"
                          sx={{mr: 1}}
                          onClick={() =>
                            url ? window.open(url) : handleActions()
                          }
                        >
                          {btn}
                        </Button>
                      )}

                    {title === 'Create Task' ? (
                      <Button autoFocus variant="contained" type="submit">
                        Create Task
                      </Button>
                    ) : (
                      <Grid>
                        <Button
                          autoFocus
                          variant="contained"
                          type="submit"
                          sx={{mr: 1}}
                        >
                          Update
                        </Button>
                        <Button
                          autoFocus
                          variant="contained"
                          color="error"
                          sx={{mr: 1}}
                          onClick={() => handleDeleteTask(values)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default LightBox;
