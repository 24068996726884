import {CircularProgress, Backdrop, Button} from '@mui/material';
import {Box} from '@mui/system';
import * as React from 'react';

interface ICirularProgress {
  height?: number;
}
function CirularProgress({height = 300}: ICirularProgress) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={height}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}

export default CirularProgress;
