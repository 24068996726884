import React from 'react';
import {
  Button,
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
const DeleteDialogOrg = ({ open, handleClose, handleDeleteOrgId, info }: any) => {
  return (
    <Grid>
      <Dialog open={open} maxWidth="sm" onClose={handleClose}>
        <Grid container item justifyContent={'end'}>
          <IconButton onClick={handleClose}>
            <CloseRoundedIcon sx={{ color: 'black' }} />
          </IconButton>
        </Grid>

        <DialogTitle sx={{ background: 'none !important' }}>
          <Grid container item justifyContent={'center'}>
            <WarningAmberRoundedIcon color="error" sx={{ fontSize: '12vh' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container item justifyContent={'center'}>
              <Typography> {info}</Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid item container justifyContent={'center'} sx={{ mb: 2 }}>
            <LoadingButton
              // loading={loading}
              color="secondary"
              variant="contained"
              onClick={handleDeleteOrgId}
              autoFocus
              disabled={!handleDeleteOrgId}
            >
              Yes
            </LoadingButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DeleteDialogOrg;
