import {Button, Card, CardContent, Grid, Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useEffect, useRef, useState} from 'react';
import {DropResult} from 'react-beautiful-dnd';
import appService from '../../core/service/app.service';
import DraggableList from './DraggableList';

import IStage, {Stage} from './__types__/Stages.interface';
import {v4 as uuidv4} from 'uuid';

import {v4 as uuid4} from 'uuid';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import {useSnackbar} from 'notistack';
import CirularProgress from '../../components/CirularProgress';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
import SetUpDialogue from './SetUpDialogue';

const categories = [
  {label: 'Markerting Qualified Lead', value: 'marketing_qualified_lead'},
  {label: 'Sales Qualified Lead', value: 'sales_qualified_lead'},
  {label: 'Converted', value: 'converted'},
];

function Stages() {
  const [stages, setStages] = useState<null | Stage>(null);

  const deletedStagesRef = useRef<string[]>([]);

  const deletedActivitiesRef = useRef<string[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const [setUpOpen, setSetUpOpen] = useState<boolean>(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const myOrg = useSelector((state: RootState) => state.appState.org);

  const getStages = () => {
    appService
      .getStages()
      .then(res => {
        console.log(res);
        const tempStages: IStage[] = res.message.filter(
          (item: IStage) => item.sequence !== 0
        );

        const x: Stage = {};
        categories.forEach(category => {
          const {label, value} = category;
          const filteredStages = tempStages
            .filter((stage: IStage) => stage?.stage_category === value)
            .sort(
              //@ts-ignore
              (a: IStage, b: IStage) => a?.sequence - b?.sequence
            );

          x[value] = filteredStages;
        });

        console.log(x);

        setStages(x);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!myOrg) return;

    if (!myOrg.stages_setup) {
      setSetUpOpen(true);
    } else {
      getStages();
    }
  }, [myOrg]);

  const removeFromList = (list: IStage[], index: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list: IStage[], index: number, element: IStage) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const onDragEnd = (result: any) => {
    console.log(result);

    const body = {
      source: {
        droppableId: result.source.droppableId,
        sequence: result.source.index,
      },
      destination: {
        droppableId: result.destination.droppableId,
        sequence: result.destination.index,
      },
      source_stage_ID: result.draggableId,
    };

    if (!stages || !result.destination) return;

    const listCopy = {...stages};

    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index - 1
    );

    //@ts-ignore
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index - 1,

      //@ts-ignore
      removedElement
    );

    setStages(listCopy);

    console.log(listCopy);
    // appService
    //   .stageSequenceChange(body)
    //   .then(res => {
    //     console.log(res);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  };

  const addNewStage = (stageCategory: string) => {



    if (!stages) return;
    //@ts-ignore
    const newStage: IStage = {
      //@ts-ignore
      Id: uuidv4(),
      name: '',
      stage_category: stageCategory,

      activities: [],
    };

    const tempStages = {
      ...stages,

      [stageCategory]: [...stages[stageCategory], {...newStage}],
    };



  
     setStages(tempStages);
  };

  const handleSave = async () => {
    setOpen(true);
    try {
      if (!stages) return;

      console.log(stages);

      const onlyStages: any[] = [];

      Object.keys(stages).forEach(category => {
        const categoryStages = stages[category];

        categoryStages.forEach((stage, index) => {
          const stageActivities = stage.activities;
          onlyStages.push({
            id: stage.Id,
            stage_ID: stage.stage_ID,
            name: stage.name,
            sequence: index + 1,
            stage_category: category,
            activities: stageActivities?.map((activity, index) => ({
              id: activity.Id,
              activity_ID: activity.activity_ID,
              activity_type: activity.activity_type || null,
              sequence: index + 1,
              stage_ID: activity.stage_ID,
              document_type: activity.document_type || null,
              duration_in_days: activity.duration_in_days || null,
              name: activity.name,
              description: activity.description,
            })),
          });
        });
      });

      console.log(onlyStages);

      const body = {
        stages: onlyStages,
        deletedStages: deletedStagesRef.current,
        deletedActivities: deletedActivitiesRef.current,
      };

      await appService.updateStages(body);

      enqueueSnackbar(`Updated Sucessfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });

      deletedStagesRef.current = [];
      deletedActivitiesRef.current = [];
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Error Occured`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setOpen(false);

    // getStages();
  };

  const onStageDelete = (id: any) => {
    deletedStagesRef.current = [...deletedStagesRef.current, id];
  };

  const onActivityDelete = (id: any) => {
    deletedActivitiesRef.current = [...deletedActivitiesRef.current, id];
  };

  const handleSetUp = async () => {
    setOpen(true);
    try {
      const result = await appService.linkStageActivity();

      setSetUpOpen(false);

      getStages();

      enqueueSnackbar(`Stages Setup Successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar(`Error occurred!`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      console.log(error);
    }

    setOpen(false);
  };
  return (
    <Card elevation={6} sx={{minHeight: '40vh'}}>
      <CardContent>
        <BackDropCircularProgress open={open} />

        <SetUpDialogue
          open={setUpOpen}
          handleClose={() => setSetUpOpen(false)}
          handleSetUp={handleSetUp}
        />

        {stages ? (
          <Grid container justifyContent={'center'} spacing={2}>
            <Grid item>
              {' '}
              <Button size="small" variant="contained" onClick={handleSave}>
                {' '}
                Save
              </Button>{' '}
            </Grid>
            <Grid item xs={12}>
              <DraggableList
                onDragEnd={onDragEnd}
                setStages={setStages}
                stages={stages}
                categories={categories}
                addNewStage={addNewStage}
                onStageDelete={onStageDelete}
                onActivityDelete={onActivityDelete}
              />
            </Grid>
          </Grid>
        ) : (
          <CirularProgress />
        )}
      </CardContent>
    </Card>
  );
}

export default Stages;
