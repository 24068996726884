import React, {useEffect, useState} from 'react';
import {
  Accordion,
  InputAdornment,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  Typography,
  Input,
  IconButton,
  Badge,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSnackbar} from 'notistack';
import appService, {appServiceTSL} from '../../../core/service/app.service';
import UploadIcon from '@mui/icons-material/Upload';
import {styled} from '@mui/system';
import UploadDocumentDialog from '../Modals/UploadDocumentDialog';
import {FileUploader} from 'react-drag-drop-files';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useSelector} from 'react-redux';
import {RootState} from '../../../core/redux/store';
import {currencyFormatter, numericFormatter} from '../../../core/service/utils';

const CreateLineItemForm = ({
  form,
  index,
  removeForm,
  productVariants,
  columnMapping,
  updateLineItem,
  setLineItemEditable,
  projectId,
  designs,
  formMode,
  formModes,
}) => {
  const Input = styled('input')({
    display: 'none',
  });

  const SystemSize = [2.69, 3.46, 5.39];
  const TotalCost = [479000, 647000, 1036000];
  const EnergyProduction = [4100, 5300, 8300];
  const AnnualSavings = [33000, 42000, 66000];

  const [selectedProductVariant, setSelectedProductVariant] = useState(
    form.data.product_variant_id
  );
  const [selectedSizeValue, setSelectedSizeValue] = useState(
    form.data.system_size
  );
  const [systemSizes, setSysytemSizes] = useState(form.data.system_size);
  console.log(selectedSizeValue);
  const [selectAmount, setSelectAmount] = useState(form.data.amount);
  const [selectedEnergy, setSelectedEnergy] = useState(
    form.data.annual_energy_production
  );
  const [selectedSavings, setSelectedSavings] = useState(
    form.data.estimated_annual_savings
  );

  const [designId, setDesignId] = useState(form.data.design_ID);

  const [selectedDesignSize, setSelectedDesignSize] = useState();
  const [selectedDesignCost, setSelectedDesignCost] = useState();
  const [selectedDesignAnnualSavings, setSelectedDesignAnnualSavings] =
    useState();
  const [selectedDesignAnnualProduction, setSelectedDesignAnnualProduction] =
    useState();
  const [selectedDesignProposalLink, setSelectedDesignProposalLink] =
    useState();

  const [systemSize, setSystemSize] = useState(form.data.system_size);
  const [amount, setAmount] = useState(form.data.amount);
  const [discount, setDiscount] = useState(form.data.discount);
  const [tax, setTax] = useState(form.data.tax);
  const [annualEnergyProduction, setAnnualEnergyProduction] = useState(
    form.data.annual_energy_production
  );
  const [coverage, setCoverage] = useState(form.data.coverage);
  const [estimatedAnnualSavings, setEstimatedAnnualSavings] = useState(
    form.data.estimated_annual_savings
  );

  const [designIdError, setDesignIdError] = useState(null);
  const [systemSizeError, setSystemSizeError] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [discountError, setDiscountError] = useState(null);
  const [taxError, setTaxError] = useState(null);
  const [annualEnergyProductionError, setAnnualEnergyProductionError] =
    useState(null);
  const [coverageError, setCoverageError] = useState(null);
  const [estimatedAnnualSavingsError, setEstimatedAnnualSavingsError] =
    useState(null);

  const [errorMessage, setErrorMessage] = useState();

  const [expandedAccordion, setExpandedAccordion] = useState(true);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [productCat, setProductCat] = useState([]);
  const [productsCat, setProductsCat] = useState([]);
  const [product, setProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [sku, setSku] = useState([]);
  const [skus, setSkus] = useState([]);
  const [selectProduct, setSelectProduct] = useState();
  const [selectSku, setSelectSku] = useState();
  const [category, setCategory] = useState();
  const [filetrProduct, setFilterProduct] = useState([]);
  const [filetrSku, setFilterSku] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);
  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [bomLineItems, setBomLineItems] = useState();
  const myOrg = useSelector(state => state.appState.org);
  useEffect(() => {
    if (myOrg?.org_type === 'gazebo_installer') {
      appService
        .getParentProductCategory()
        .then(res => {
          console.log(res.message);
          console.log([
            ...new Map(
              res.message.map(item => [item['product_category_ID'], item])
            ).values(),
          ]);
          setProductCat(res.message);
          setProductsCat(res.message);
        })
        .catch(err => console.log(err));
    } else {
      appService
        .getProductCategory()
        .then(res => {
          console.log(res.message);
          console.log([
            ...new Map(
              res.message.map(item => [item['product_category_ID'], item])
            ).values(),
          ]);
          setProductCat(res.message);
          setProductsCat(res.message);
        })
        .catch(err => console.log(err));
    }

    appService
      .getProduct()
      .then(res => {
        setProduct([
          ...new Map(
            res.message.map(item => [item['product_ID'], item])
          ).values(),
        ]);
        setProducts(res.message);
      })
      .catch(err => console.log(err));

    if (myOrg?.org_type === 'gazebo_installer') {
      appService
        .getParentSKU()
        .then(res => {
          setSku([
            ...new Map(
              res.message.map(item => [item['SKU_ID'], item])
            ).values(),
          ]);
          setSkus(res.message);
        })
        .catch(err => console.log(err));
    } else {
      appService
        .getSKU()
        .then(res => {
          setSku([
            ...new Map(
              res.message.map(item => [item['SKU_ID'], item])
            ).values(),
          ]);
          setSkus(res.message);
        })
        .catch(err => console.log(err));
    }
  }, []);

  const updateSystemSize = value => {
    if (value < 0.1 || value >= 9.9) {
      setSystemSizeError('Invalid System Size (0.1 - 9.9)');
    } else {
      setSystemSizeError(null);
    }
    setSystemSize(value);
  };

  const updateAmount = value => {
    console.log(value === 450000);
    if (value < 450000 || value >= 1300000) {
      setAmountError('Invalid amount (4,50,000 - 13,00,000)');
    } else {
      setAmountError(null);
    }
    setAmount(value);
  };
  const updateDiscount = value => {
    if (value < 0 || value > 20) {
      setDiscountError('Invalid discount % (0.0% - 20.0%)');
    } else {
      setDiscountError(null);
    }
    setDiscount(value);
  };
  const updateTax = value => {
    if (value < 5 || value > 20) {
      setTaxError('Invalid tax % (5.0% - 20.0%)');
    } else {
      setTaxError(null);
    }
    setTax(value);
  };
  const updateAnnualEnergyProduction = value => {
    if (value < 3000 || value > 10000) {
      setAnnualEnergyProductionError(
        'Invalid Annual Production (3,000 - 10,000)'
      );
    } else {
      setAnnualEnergyProductionError(null);
    }
    setAnnualEnergyProduction(value);
  };
  const updateCoverage = value => {
    if (value < 0 || value > 150) {
      setCoverageError('Invalid coverage % (0% - 150.0%)');
    } else {
      setCoverageError(null);
    }
    setCoverage(value);
  };
  const updateEstimatedAnnualSavings = value => {
    if (value < 5000 || value > 60000) {
      setEstimatedAnnualSavingsError(
        'Invalid estimated savings (5,000 - 60,000)'
      );
    } else {
      setEstimatedAnnualSavingsError(null);
    }
    setEstimatedAnnualSavings(value);
  };

  const handleSaveFormData = () => {
    let data;
    if (formMode === formModes.pg) {
      data = {
        product_variant_id: selectedProductVariant,
        product_ID: selectProduct,
        SKU_ID: selectSku,
        // design_ID: designId,
        system_size: systemSize,
        // system_size: category === 'Gazebo' ? systemSizes : systemSize,
        amount: amount,
        annual_energy_production: annualEnergyProduction,
        // annual_energy_production:
        //   category === 'Gazebo' ? selectedEnergy : annualEnergyProduction,
        coverage: coverage / 100,
        estimated_annual_savings: estimatedAnnualSavings,
        // estimated_annual_savings:
        //   category === 'Gazebo' ? selectedSavings : estimatedAnnualSavings,
        discount: discount / 100,
        tax: tax / 100,
      };
    } else {
      data = {
        product_variant_id: selectedProductVariant,
        design_ID: designId,
        amount: selectedDesignCost,
        system_size: selectedDesignSize,
        annual_energy_production: selectedDesignAnnualProduction,
        estimated_annual_savings: selectedDesignAnnualSavings,
        tsl_proposal_link: selectedDesignProposalLink,
      };
    }

    updateLineItem(form.title, data);
    setExpandedAccordion(false);
    setUploadFiles([]);
    enqueueSnackbar('Quote saved Successfully', {variant: 'success'});
  };
  const handleChange = e => {
    console.log(e);
    setSelectedSizeValue(e.target.value);
    setSysytemSizes(2.3);
    setSelectAmount(450000);
    setSelectedEnergy(5000);
    setSelectedSavings(6000);
  };

  const handleAddFile = newFile => {
    console.log(files);
    setFiles([...files, ...newFile]);
    setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3)
        enqueueSnackbar('Maximum of 3 files can be uploaded', {
          variant: 'info',
        });
      else {
        setFiles([...files, ...newFile]);
        setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
      }
    }
  };

  const handleRemoveImage = index => {
    console.log(index);
    console.log(files);
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };

  return (
    <Grid container sx={{width: 1}}>
      <UploadDocumentDialog
        open={uploadDialogOpen}
        setOpen={setUploadDialogOpen}
        // documentTypes={documentTypes}
        projectId={projectId}

        // getDocumentData={getDocumentData}
      />{' '}
      {/* input fields */}
      {productCat && sku && product && (
        <Accordion
          defaultExpanded
          expanded={expandedAccordion}
          elevation={2}
          onChange={() => setExpandedAccordion(!expandedAccordion)}
          sx={{width: 1}}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Line Item {index + 1}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12} justifyContent="center">
              <Grid container item xs={6} sx={{p: 1}}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color:
                        designs.length > 0 ? 'rgba(0, 0, 0, 0.38)' : 'inherit',
                    }}
                  >
                    Select Category
                  </InputLabel>
                  <Select
                    value={selectedProductVariant}
                    label="Select a product"
                    size="small"
                    onChange={e => {
                      setSelectedProductVariant(e.target.value);

                      const productcatname = productsCat.filter(
                        d => d.product_category_ID === e.target.value
                      )[0].name;

                      const prdct = product.filter(
                        d => d.product_category_ID === e.target.value
                      );
                      setFilterProduct(prdct);

                      setCategory(productcatname);
                    }}
                    disabled={form.saved}
                  >
                    {productCat.map(item => {
                      if (formMode === formModes.pv) {
                        if (!item.is_gazebo) {
                          return (
                            <MenuItem
                              value={item.product_category_ID}
                              name={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        }
                      } else {
                        if (item.is_gazebo) {
                          return (
                            <MenuItem
                              value={item.product_category_ID}
                              name={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        }
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {formMode === formModes.pv ? (
                <>
                  <Grid container item xs={6} sx={{p: 1}}></Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.dcCapacity}
                      variant="outlined"
                      error={false}
                      value={selectedDesignSize}
                      type="number"
                      fullWidth
                      size="small"
                      disabled={true}
                      InputLabelProps={{shrink: true}}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">kW</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.amount}
                      InputLabelProps={{shrink: true}}
                      variant="outlined"
                      error={false}
                      type="number"
                      fullWidth
                      size="small"
                      disabled={true}
                      value={selectedDesignCost}
                    />
                  </Grid>

                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.annual_energy_production}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">kWh</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={selectedDesignAnnualProduction}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // onChange={e => updateDiscount(parseFloat(e.target.value))}
                      fullWidth
                      disabled
                      size="small"
                    />
                  </Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.estimated_annual_savings}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {myOrg?.currency === 'IN' ? '₹' : '$'}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={numericFormatter(
                        selectedDesignAnnualSavings,
                        myOrg?.currency
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // onChange={e => updateDiscount(parseFloat(e.target.value))}
                      fullWidth
                      disabled
                      size="small"
                    />
                  </Grid>
                  <Grid container item xs={12} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.proposalLink}
                      InputLabelProps={{shrink: true}}
                      value={selectedDesignProposalLink}
                      variant="outlined"
                      error={false}
                      fullWidth
                      size="small"
                      disabled={true}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          color: !selectedProductVariant
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'inherit',
                        }}
                      >
                        Select a product
                      </InputLabel>
                      <Select
                        value={selectProduct}
                        label="Select a product"
                        size="small"
                        onChange={e => {
                          setSelectProduct(e.target.value);
                          setFilterSku(
                            sku.filter(
                              d =>
                                d.product_category_ID ===
                                  selectedProductVariant &&
                                d.product_ID === e.target.value
                            )
                          );
                        }}
                        disabled={!selectedProductVariant || form.saved}
                      >
                        {filetrProduct.map(item => (
                          <MenuItem value={item.product_ID}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          color: !selectProduct
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'inherit',
                        }}
                      >
                        Select SKU
                      </InputLabel>
                      <Select
                        value={selectSku}
                        label="Select a product"
                        size="small"
                        onChange={e => setSelectSku(e.target.value)}
                        disabled={!selectProduct || form.saved}
                      >
                        {filetrSku.map(item => (
                          <MenuItem value={item.SKU_ID}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.system_size}
                      variant="outlined"
                      value={systemSize}
                      onChange={e =>
                        updateSystemSize(parseFloat(e.target.value))
                      }
                      error={systemSizeError}
                      placeholder={'0.1-9.9'}
                      helperText={systemSizeError ? systemSizeError : ''}
                      type="number"
                      fullWidth
                      size="small"
                      disabled={form.saved}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">kWp</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.amount}
                      variant="outlined"
                      // InputLabelProps={{shrink: amount ? true : false}}
                      // focused={selectAmount ? true : false}
                      value={amount}
                      placeholder={'4,50,000-13,00,000'}
                      onChange={e => updateAmount(parseFloat(e.target.value))}
                      error={amountError}
                      helperText={amountError ? amountError : ''}
                      type="number"
                      size="small"
                      fullWidth
                      disabled={form.saved}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {myOrg?.currency === 'IN' ? '₹' : '$'}
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >
                {columnMapping.amount}                
                </InputLabel>
                <Select
                  value={selectAmount}
                  label={selectAmount}
                  onChange={e => handleChange(e)}
                  disabled={form.saved}
                  renderValue={(selectAmount) => {
                    
        
                    return selectAmount;
                  }}
                >
                  {TotalCost.map((item,index) => (
                    <MenuItem value={index}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
                  </Grid>
                  {/* <Grid container item xs={6} sx={{p: 2}}>
              <TextField
                label={columnMapping.amount}
                variant="outlined"
                value={amount}
                onChange={e => updateAmount(parseFloat(e.target.value))}
                error={amountError}
                helperText={amountError ? amountError : ''}
                type="number"
                fullWidth
                disabled={form.saved}
              />
            </Grid> */}
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.discount}
                      placeholder={'0% - 20%'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={discount === null ? '' : discount}
                      onChange={e => updateDiscount(parseFloat(e.target.value))}
                      error={discountError}
                      helperText={discountError ? discountError : ''}
                      type="number"
                      fullWidth
                      size="small"
                      disabled={form.saved}
                    />
                  </Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.tax}
                      placeholder={'5% - 20%'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={tax}
                      onChange={e => updateTax(parseFloat(e.target.value))}
                      error={taxError}
                      helperText={taxError ? taxError : ''}
                      type="number"
                      fullWidth
                      size="small"
                      disabled={form.saved}
                    />
                  </Grid>
                  <Grid container item xs={6} sx={{p: 1}}>
                    {console.log(annualEnergyProduction)}
                    <TextField
                      label={columnMapping.annual_energy_production}
                      variant="outlined"
                      value={
                        annualEnergyProduction === null
                          ? ''
                          : annualEnergyProduction
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">kWh</InputAdornment>
                        ),
                      }}
                      onChange={e =>
                        updateAnnualEnergyProduction(parseFloat(e.target.value))
                      }
                      error={annualEnergyProductionError}
                      helperText={
                        annualEnergyProductionError
                          ? annualEnergyProductionError
                          : ''
                      }
                      type="number"
                      fullWidth
                      placeholder={'3,000 - 10,000'}
                      disabled={form.saved}
                      size="small"
                    />
                  </Grid>
                  {/* <Grid container item xs={6} sx={{p: 2}}>
              <TextField
                label={columnMapping.annual_energy_production}
                variant="outlined"
                value={annualEnergyProduction}
                onChange={e =>
                  updateAnnualEnergyProduction(parseFloat(e.target.value))
                }
                error={annualEnergyProductionError}
                helperText={
                  annualEnergyProductionError ? annualEnergyProductionError : ''
                }
                type="number"
                fullWidth
                disabled={form.saved}
              />
            </Grid> */}
                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.coverage}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={coverage}
                      onChange={e => updateCoverage(parseFloat(e.target.value))}
                      error={coverageError}
                      helperText={coverageError ? coverageError : ''}
                      type="number"
                      fullWidth
                      placeholder={'0% - 150%'}
                      disabled={form.saved}
                      size="small"
                    />
                  </Grid>

                  <Grid container item xs={6} sx={{p: 1}}>
                    <TextField
                      label={columnMapping.estimated_annual_savings}
                      variant="outlined"
                      value={
                        estimatedAnnualSavings === null
                          ? ''
                          : estimatedAnnualSavings
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {myOrg?.currency === 'IN' ? '₹' : '$'}
                          </InputAdornment>
                        ),
                      }}
                      onChange={e =>
                        updateEstimatedAnnualSavings(parseFloat(e.target.value))
                      }
                      error={estimatedAnnualSavingsError}
                      placeholder={'5,000 - 60,000'}
                      helperText={
                        estimatedAnnualSavingsError
                          ? estimatedAnnualSavingsError
                          : ''
                      }
                      type="number"
                      fullWidth
                      disabled={form.saved}
                      size="small"
                    />
                  </Grid>
                </>
              )}

              {/* <Grid item xs={12} sx={{display: 'flex', justifyContent: 'left'}}>
                <Grid item my={2}>
                  <label htmlFor="upload-file">
                    <Input
                      accept="application/pdf"
                      id="upload-file"
                      type="file"
                      multiple
                      onChange={e => {
                        console.log(e.target.files);

                        // setUploadFiles(e.target.files);
                        for (let i = 0; i < e.target.files.length; i++) {
                          console.log(uploadFiles);
                          uploadFiles.push(e.target.files[i]);

                          setUploadFiles(e.target.files[i]);
                          setFiles(e.target.files);
                        }
                        handleAddFile(e.target.files);
                        // handleAddFile(e.target.files);

                        // setUploadImg([...e.target.files, ...uploadImg]);
                      }}
                    />
                    <Button
                      startIcon={<UploadIcon />}
                      component="span"
                      variant="outlined"
                      size="small"
                      style={{marginBottom: 8}}
                      color="secondary"
                      sx={{ml: 2}}
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid> */}

              {/* <Grid container item xs={6} sx={{p: 2}}>
                <Button
                  startIcon={<UploadIcon />}
                  component="span"
                  variant="outlined"
                  size="small"
                  style={{marginBottom: 10}}
                  color="secondary"
                  onClick={() => setUploadDialogOpen(true)}
                >
                  Upload
                </Button>
              </Grid> */}

              {/* <Grid container item xs={6} sx={{p: 2}}>
              <TextField
                label={columnMapping.estimated_annual_savings}
                variant="outlined"
                value={estimatedAnnualSavings}
                onChange={e =>
                  updateEstimatedAnnualSavings(parseFloat(e.target.value))
                }
                error={estimatedAnnualSavingsError}
                helperText={
                  estimatedAnnualSavingsError ? estimatedAnnualSavingsError : ''
                }
                type="number"
                fullWidth
                disabled={form.saved}
              />
            </Grid> */}
            </Grid>
          </AccordionDetails>
          {console.log(coverage)}
          <AccordionActions>
            {form.saved ? (
              <Button
                color="success"
                variant="contained"
                onClick={() => setLineItemEditable(form.title)}
              >
                Edit
              </Button>
            ) : (
              <Button
                color="success"
                onClick={handleSaveFormData}
                variant="contained"
                disabled={
                  !(discount >= 0) ||
                  !(tax >= 5) ||
                  !(annualEnergyProduction >= 3000) ||
                  !(estimatedAnnualSavings >= 5000) ||
                  !(coverage >= 0) ||
                  !(amount >= 450000) ||
                  !(systemSize >= 0.1) 
                }
                >
                Save
              </Button>
            )}

            <Button
              color="error"
              onClick={() => removeForm(form.title)}
              variant="contained"
            >
              Remove
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Grid>
  );
};

export default CreateLineItemForm;
