import React, {useState, useEffect} from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {styled} from '@mui/system';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import ILead from './__types__/lead.interface';

import appService from '../../core/service/app.service';
import {Button, Checkbox, MenuItem, Select, Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';
import CreateLeads from '../Lead/createLead';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../core/redux/store';
import {batch, useSelector} from 'react-redux';
import {checkPermisssion} from '../../utils/utils';
import SlideDrawer from './SlideDrawer';
import {useSnackbar} from 'notistack';
import SearchFilter from '../../components/SearchFilter';
import ImportLeadTable from './ImportLeadTable.jsx';
import LeadView from './LeadView';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Headings from '../../components/Headings';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime, Settings} from 'luxon';
import {makeStyles} from '@mui/styles';
import UploadErrorLogDialog from './UploadErrorLogDialog';
import SearchComponent from '../../components/SearchComponent';

const useStyle = makeStyles(theme => ({
  pickerInput: {
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: 500,
    marginLeft: 10,
    textAlign: 'center',
  },
  rootInput: {
    justifyContent: 'center',
  },
}));
const Input = styled('input')({
  display: 'none',
});
Settings.defaultZone = 'Asia/Kolkata';

const uploadSourceMapping: {
  HubSpot: string;
  Facebook: string;
  SalesForce: string;
  CSV: string;
} = {
  HubSpot: 'HubSpot',
  Facebook: 'Facebook',
  SalesForce: 'SalesForce',
  CSV: 'CSV',
};

function Leads() {
  const classes = useStyle();

  const [view, setView] = useState<'list' | 'grid'>('list');
  const [leads, setLeads] = useState<null | ILead[]>(null);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [batchUploadData, setBatchUploadData] = useState<any[]>([]);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [uploadLeadsDialogOpen, setUploadLeadsDialogOpen] =
    useState<boolean>(false);

  const [uploadSource, setUploadSource] = useState<string | null>(null);

  const [showDataTable, setShowDataTable] = useState<boolean>(false);

  const [leadDialogOpen, setLeadDialogOpen] = React.useState(false);

  const [hasHsKey, setHasHsKey] = useState(false);
  const [hasSfdcKey, setHasSfdcKey] = useState(false);

  const [hsLastSync, setHsLastSync] = useState(null);
  const [sfdcLastSync, setSfdcLastSync] = useState(null);

  // for updating mapping
  const [editConfigIndex, setEditConfigIndex] = useState<number | null>(null);
  const [selectedSoruceColumn, setSelectedSoruceColumn] = useState<
    number | null
  >(null);

  const [mappingData, setMappingData] = useState<object | null>(null);

  const [newMappingData, setNewMappingData] = useState<[] | null>(null);

  const [csvSourceColumns, setCsvSourceColumns] = useState<null | string[]>();

  const [errorLogData, setErrorLogData] = useState<null | []>();

  const [rows, setRows] = useState<any>();
  const [searched, setSearched] = useState<string>('');
  const history = useHistory();

  const rules = useSelector((state: RootState) => state.appState.rules);

  const setdopen = () => {
    setLeadDialogOpen(true);
  };

  const getLeads = async () => {
    let result;
    let tempLeads: any[] = [];

    if (checkPermisssion(rules, 'leads/', 'GET')) {
      result = await appService.getLeads();
      tempLeads = [...result.message];
    } else {
      if (checkPermisssion(rules, 'leads/assigned', 'GET')) {
        result = await appService.getAssignedLeads();

        tempLeads = [...result.message];
      }
    }

    setLeads(tempLeads);
    setRows(tempLeads);
  };

  useEffect(() => {
    getLeads();
  }, [rules]);

  const getHsLastSyncHandler = async () => {
    try {
      const sync = await appService.getHsLastSync();
      // @ts-ignore
      setHsLastSync(DateTime.fromISO(sync.message.lastSyncData.last_sync))(
        sync.message.lastSyncData
      );
    } catch (err) {
      console.log(err);
    }
  };

  const hsKey = async () => {
    try {
      const result = await appService.getHsSecret();
      setHasHsKey(result.message);

      if (result.message) {
        getHsLastSyncHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sfdcKey = async () => {
    try {
      const result = await appService.getSfdcSecret();
      setHasSfdcKey(result.message);

      if (result.message) {
        getSfdcLastSyncHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSfdcLastSyncHandler = async () => {
    try {
      const sync = await appService.getSfdcLastSync();
      // @ts-ignore
      setSfdcLastSync(DateTime.fromISO(sync.message.lastSyncData.last_sync));
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (d: any) => {
    const date = DateTime.fromJSDate(new Date(d));

    let returnStatement;

    returnStatement = `${date.toFormat('MMMM dd, yyyy')}`;

    return returnStatement;
  };

  // useEffect(() => {
  //   hsKey();
  //   sfdcKey();
  // }, []);

  const handleCreateLead = () => {
    history.push('/createlead');
  };

  const closeDialog = () => {
    setUploadLeadsDialogOpen(false);
    setUploadSource(null);
    setSelectedRows([]);
    setBatchUploadData([]);
    setShowDataTable(false);
    setEditConfigIndex(null);
    setSelectedSoruceColumn(null);
    setMappingData(null);
    setNewMappingData([]);
  };

  const uploadLeads = async () => {
    enqueueSnackbar(`Uploading Data`);
    try {
      let selectedRowsFromBatch: object[] = [];
      selectedRows.map(item => {
        selectedRowsFromBatch = [
          ...selectedRowsFromBatch,
          batchUploadData[item],
        ];
      });

      let body = {};

      let tempMappingData = {
        ...mappingData,
        mapping: newMappingData,
      };

      if (uploadSource === uploadSourceMapping.CSV) {
        body = {...body, imported_from: 'csv'};
      } else if (uploadSource === uploadSourceMapping.HubSpot) {
        body = {...body, imported_from: 'hubspot'};
      } else if (uploadSource === uploadSourceMapping.SalesForce) {
        body = {...body, imported_from: 'salesforce'};
      }
      body = {
        ...body,
        leads: selectedRowsFromBatch,
        import: true,
        mappingData: tempMappingData,
      };

      console.log({body});

      /* @ts-ignore */
      const result = await appService.addLeads(body);

      if (!result.success) {
        throw new Error('Error from /leads');
      }

      let message = 'Leads added ';
      if (result.message.errorLog) {
        setErrorLogData(result.message.errorLog);
        if (result.message.errorLog.success.length > 0) {
          message =
            message + ' Success: ' + result.message.errorLog.success.length;
        }
        if (result.message.errorLog.failure.length > 0) {
          message =
            message + ' Failure : ' + result.message.errorLog.failure.length;
        }
      }
      enqueueSnackbar(message, {
        variant: 'success',
      });
      // closeDialog();
      getLeads();

      if (uploadSource === uploadSourceMapping.HubSpot) {
        getHsLastSyncHandler();
      } else if (uploadSource === uploadSourceMapping.SalesForce) {
        getSfdcLastSyncHandler();
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error Adding Leads`, {
        variant: 'error',
      });
    }
  };

  const editNewMapping = (index: number) => {
    setEditConfigIndex(index);
    let currentValue: number;

    if (mappingData && newMappingData) {
      // @ts-ignore
      mappingData.source_columns.map((column: string, i: number) => {
        // @ts-ignore
        if (column === newMappingData[index].source) currentValue = i;
      });

      // @ts-ignore
      console.log({currentValue});

      // @ts-ignore
      setSelectedSoruceColumn(currentValue);
    }
  };

  const proccedToMappingConfig = () => {
    setShowDataTable(true);
  };

  const changeView = (view: 'list' | 'grid') => {
    setView(view);
  };

  const selectUploadSource = async (source: string, data: object[] | null) => {
    setUploadSource(source);

    if (source === uploadSourceMapping.HubSpot) {
      try {
        let allLeads: React.SetStateAction<any[]> = [];
        //set to 0.5 as it will be true and at the same time server would never respond with such a value
        let skipCount = 0.5;

        //recursive pull for paginated api
        while (skipCount !== 0) {
          console.log('skip count start', skipCount);

          const hubspotContactsData = await appService.getHubSpotContacts(
            skipCount === 0.5 ? null : skipCount,
            {lastSync: hsLastSync}
          );
          allLeads = [...allLeads, ...hubspotContactsData.message.results];
          if (hubspotContactsData.message.mapping && !mappingData) {
            setMappingData(hubspotContactsData.message.mapping);
            setNewMappingData(hubspotContactsData.message.mapping.mapping);
          }

          if (
            hubspotContactsData.message.paging &&
            hubspotContactsData.message.paging.next
          ) {
            skipCount = hubspotContactsData.message.paging.next.after;
          } else {
            skipCount = 0;
          }
          console.log('skip count end', skipCount);
        }

        setBatchUploadData(allLeads);
      } catch (err) {
        console.log(err);

        enqueueSnackbar('Error Found ', {
          variant: 'error',
        });
        setUploadLeadsDialogOpen(false);
      }
    }
    if (source === uploadSourceMapping.SalesForce) {
      try {
        const salesforceContactsData = await appService.getSalesForceData({
          lastSync: sfdcLastSync,
        });
        setMappingData(salesforceContactsData.message.mapping);
        setNewMappingData(salesforceContactsData.message.mapping.mapping);
        setBatchUploadData(salesforceContactsData.message.results);
      } catch (err) {
        console.log('Error Found', err);
      }
    }
    if (source === uploadSourceMapping.CSV) {
      const getCsvLeadData = await appService.getCsvData();

      if (data) {
        let matchingFlag = true;

        let tempSourceColumns: string[] = [];
        Object.keys(data[0]).map(key => {
          tempSourceColumns = [...tempSourceColumns, key];
        });
        console.log({tempSourceColumns});

        if (
          getCsvLeadData.message.mapping.source_columns.length ===
          tempSourceColumns.length
        ) {
          tempSourceColumns.map((item, index) => {
            if (item !== getCsvLeadData.message.mapping.source_columns[index]) {
              matchingFlag = false;
            }
          });
        } else {
          matchingFlag = false;
        }

        let tempMappingData = getCsvLeadData.message.mapping;
        if (!matchingFlag) {
          tempMappingData = {
            ...tempMappingData,
            source_columns: tempSourceColumns,
          };
        }

        if (!matchingFlag) {
          let tempMapping: object[] = [];
          getCsvLeadData.message.mapping.destination_columns.map(
            (item: string) => {
              tempMapping = [
                ...tempMapping,
                {
                  type: 'property',
                  destination: item,
                  source: null,
                },
              ];
            }
          );
          // @ts-ignore
          setNewMappingData(tempMapping);
          tempMappingData = {
            ...tempMappingData,
            mapping: tempMapping,
          };
        } else {
          setNewMappingData(tempMappingData.mapping);
        }
        setMappingData(tempMappingData);

        //if matching send mapping otherwise dont

        setBatchUploadData(data);
      }
    }
  };

  const updateNewMapping = () => {
    if ((editConfigIndex || editConfigIndex === 0) && newMappingData) {
      let tempData = newMappingData;

      console.log({tempData, editConfigIndex, selectedSoruceColumn});

      // @ts-ignore
      tempData[editConfigIndex] = {
        // @ts-ignore
        ...tempData[editConfigIndex],
        // @ts-ignore
        source: mappingData.source_columns[selectedSoruceColumn],
      };

      setNewMappingData(tempData);

      //clean up
      setSelectedSoruceColumn(null);
      setEditConfigIndex(null);
    }
  };

  const handleDateChange = (date: any, item: string) => {
    if (item === uploadSourceMapping.HubSpot) setHsLastSync(date);
    else setSfdcLastSync(date);
  };

  const handleCloseDialog = (date: any, item: string) => {
    if (item === uploadSourceMapping.HubSpot) setHsLastSync(date);
    else setSfdcLastSync(date);
  };

  const requestSearch = (searchedVal: string) => {
    if (leads) {
      const filteredRows = leads.filter(
        row =>
          row.lead_ID
            ?.toLowerCase()
            ?.includes(searchedVal.toLowerCase().trim()) ||
          row.fullname
            ?.toLowerCase()
            ?.includes(searchedVal.toLowerCase().trim())

        // return row.project_ID.toLowerCase().includes(searchedVal.toLowerCase());
      );
      // console.log(filteredRows)
      setRows(filteredRows);
      setSearched(searchedVal);
    }
  };
  const cancelSearch = () => {
    setSearched('');
    console.log(searched);

    requestSearch('');
  };

  return (
    <Box minWidth={'420px'}>
      <CreateLeads
        getLeads={getLeads}
        open={leadDialogOpen}
        handleClose={() => setLeadDialogOpen(false)}
      />

      {errorLogData && uploadSource && (
        <SlideDrawer
          open={errorLogData}
          handleClose={closeDialog}
          width={'100%'}
        >
          <UploadErrorLogDialog
            data={errorLogData}
            uploadSource={uploadSource}
            uploadSourceMapping={uploadSourceMapping}
            mappingData={mappingData}
          />
        </SlideDrawer>
      )}

      <SlideDrawer
        open={uploadLeadsDialogOpen}
        handleClose={closeDialog}
        width={!uploadSource || !showDataTable ? '60%' : '100%'}
      >
        {/* Pick source screen */}
        {!uploadSource && (
          <>
            <Grid
              container
              item
              alignItems={'center'}
              sx={{background: '#E8EDF2'}}
            >
              <Grid
                container
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                justifyContent="left"
              >
                <Typography variant="h6" sx={{fontWeight: 500, ml: 1}}>
                  Import Lead
                </Typography>
              </Grid>
              <Grid
                container
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                justifyContent={'end'}
              >
                <IconButton onClick={() => closeDialog()}>
                  <CloseRoundedIcon
                    sx={{color: 'black', fontSize: 'inherit', fontWeight: 500}}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                paddingLeft: 4,
                paddingRight: 4,
                paddingBottom: 4,
                paddingTop: 1,
              }}
            >
              {Object.keys(uploadSourceMapping).map(item => {
                if (item === uploadSourceMapping.HubSpot && !hasHsKey) {
                  return null;
                } else if (
                  item === uploadSourceMapping.SalesForce &&
                  !hasSfdcKey
                ) {
                  return null;
                } else {
                  return (
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{py: 1, borderBottom: '1px solid #CCCCCC'}}
                      justifyContent="space-between"
                      alignItems="center"
                      key={item}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {/* @ts-ignore */}
                        <Typography variant="h6">{`Import from ${uploadSourceMapping[item]}`}</Typography>
                        {(item === uploadSourceMapping.HubSpot ||
                          item === uploadSourceMapping.SalesForce) && (
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <MobileDatePicker
                              disableFuture
                              // @ts-ignore
                              style={{justifyContent: 'center'}}
                              showToolbar={false}
                              variant="dialog"
                              margin="none"
                              id="date-picker-inline"
                              openTo="day"
                              //@ts-ignore
                              views={['day']}
                              value={
                                item === uploadSourceMapping.HubSpot
                                  ? hsLastSync
                                  : sfdcLastSync
                              }
                              onChange={(d: any) => handleDateChange(d, item)}
                              onAccept={(d: any) => handleCloseDialog(d, item)}
                              // minDate={props.minDate}
                              // error={props.errorText !== ''}
                              // helperText={props.errorText}
                              label="Last sync. date"
                              renderInput={params => (
                                <TextField
                                  style={{fontSize: 20}}
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                              OpenPickerButtonProps={{color: 'primary'}}
                              InputProps={{
                                classes: {input: classes.pickerInput},
                                disableUnderline: true,
                              }}
                              //@ts-ignore
                              rifmFormatter={formatDate}
                            />
                          </LocalizationProvider>
                        )}
                      </Grid>

                      {/* if CSV then input element to upload file */}
                      <Grid
                        item
                        xs={4}
                        sx={{display: 'flex', justifyContent: 'end'}}
                      >
                        {item === uploadSourceMapping.CSV ? (
                          <label htmlFor="upload-file">
                            <Input
                              accept=".csv, text/csv"
                              id="upload-file"
                              type="file"
                              onChange={async e => {
                                try {
                                  const file =
                                    e.target.files && e.target.files[0];
                                  if (file) {
                                    console.log(file);
                                    let res = Papa.parse(file, {
                                      header: true,
                                      complete: (res: any) => {
                                        if (res.errors.length === 0) {
                                          selectUploadSource(item, res.data);
                                        }
                                      },
                                    });
                                    console.log({res});
                                  }
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                            />
                            <Button
                              startIcon={<FileDownloadIcon />}
                              component="span"
                              variant="contained"
                              // size=""
                              style={{marginBottom: 8}}
                              color="secondary"
                            >
                              Import
                            </Button>
                          </label>
                        ) : (
                          <Button
                            startIcon={<FileDownloadIcon />}
                            component="span"
                            variant="contained"
                            onClick={() => selectUploadSource(item, null)}
                            style={{marginBottom: 8}}
                            color="secondary"
                            disabled={item === uploadSourceMapping.Facebook}
                          >
                            {/* @ts-ignore */}
                            Import
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  );
                }
              })}
              <Grid item xs={12}></Grid>
            </Grid>
          </>
        )}

        {/* Data Table with all leads */}
        {uploadSource && showDataTable && (
          <Grid container sx={{p: 4}}>
            <Grid item container xs={12} sx={{pb: 2}} justifyContent="center">
              <Typography variant="h4">Contacts from {uploadSource}</Typography>
            </Grid>

            {batchUploadData && batchUploadData.length !== 0 ? (
              <ImportLeadTable
                data={batchUploadData}
                setSelectedRows={setSelectedRows}
                uploadSource={uploadSource}
                uploadSourceMapping={uploadSourceMapping}
                mappingData={mappingData}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height={300}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}

            <Grid item container xs={12} justifyContent="center" sx={{mt: 2}}>
              <Button
                variant="contained"
                color="secondary"
                onClick={uploadLeads}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        )}

        {/* column mapping */}
        {uploadSource && !showDataTable && (
          <Grid container sx={{p: 4, pb: 2}}>
            <Grid item container xs={12}>
              {/* headers */}
              <Grid
                item
                container
                xs={12}
                sx={{borderBottom: '3px solid black'}}
              >
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {`${uploadSource} Columns`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">{'SL360 Columns'}</Typography>
                </Grid>
              </Grid>

              {batchUploadData && batchUploadData.length !== 0 ? (
                <Grid container item xs={12}>
                  {/* if not editing a config show all */}
                  {!editConfigIndex && editConfigIndex !== 0 ? (
                    <Grid item container xs={12} sx={{mt: 1}}>
                      {newMappingData &&
                        newMappingData.map(
                          (
                            item: {
                              type: string;
                              destination: string;
                              source: string;
                            },
                            index: number
                          ) => {
                            return (
                              <Grid
                                item
                                container
                                xs={12}
                                sx={{p: 1, borderBottom: '1px solid black'}}
                                justifyContent="space-between"
                              >
                                <Grid item xs={5}>
                                  <Typography variant="h6">
                                    {item.source}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography variant="h6">
                                    {item.destination}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => editNewMapping(index)}
                                  >
                                    Edit
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                    </Grid>
                  ) : (
                    <Grid item container xs={12} sx={{mt: 1}}>
                      {/* show 1 while editing */}
                      {newMappingData && (
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{p: 2}}
                          alignItems="center"
                        >
                          <Grid item xs={5}>
                            <Select
                              value={selectedSoruceColumn}
                              //  @ts-ignore
                              onChange={e =>
                                setSelectedSoruceColumn(
                                  //  @ts-ignore
                                  parseInt(e.target.value)
                                )
                              }
                              fullWidth
                            >
                              {/* @ts-ignore */}
                              {mappingData.source_columns.map(
                                (item: string, index: number) => {
                                  return (
                                    <MenuItem key={item} value={index}>
                                      {item}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="h6">
                              {/* @ts-ignore */}
                              {newMappingData[editConfigIndex].destination}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-end"
                            sx={{mt: 5}}
                          >
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => setEditConfigIndex(null)}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => updateNewMapping()}
                              sx={{ml: 2}}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height={300}
                >
                  <CircularProgress color="inherit" />
                </Box>
              )}
              {batchUploadData && batchUploadData.length !== 0
                ? !editConfigIndex &&
                  editConfigIndex !== 0 && (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      sx={{mt: 2}}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => proccedToMappingConfig()}
                      >
                        Next
                      </Button>
                    </Grid>
                  )
                : ''}
            </Grid>
          </Grid>
        )}
      </SlideDrawer>

      <Grid container flexDirection={'column'} spacing={2}>
        {checkPermisssion(rules, 'leads', 'POST') && (
          <Headings
            heading={'Lead Management'}
            btntitle2={'Import Lead'}
            btntitle={'Create Lead'}
            searchBar={
              <SearchComponent
                searched={searched}
                cancelSearch={cancelSearch}
                requestSearch={requestSearch}
                placeholdertext={'Enter Lead ID'}
              />
            }
            handleClick={setdopen}
            handleClick2={() => setUploadLeadsDialogOpen(true)}
          />
        )}

        {/* <Grid item>
          <SearchFilter changeView={changeView} view={view} />
        </Grid> */}

        <Grid item>
          {leads ? (
            <LeadView
              setLeads={setLeads}
              leads={leads}
              view={view}
              row={rows}
              setRows={setRows}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={300}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Leads;
