import {Typography} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';

function NotAuthroized() {
  return (
    <Box>
      <Typography>Not Authorized To Access This Page</Typography>
    </Box>
  );
}

export default NotAuthroized;
