import {Box, Card, Grid, Typography} from '@mui/material';
import Reac, {FC, useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import SearchFilter from '../../components/SearchFilter';
import appService from '../../core/service/app.service';
import MiniPermitCard from './MiniPermitCard';
import IPermit from './__types__/permit.interface';

const Permits: React.FC<any> = () => {
  const [permits, setPermits] = useState<Array<IPermit>>([]);

  useEffect(() => {
    appService
      .getAllPermits()
      .then(resp => setPermits(resp.message))
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <Box minWidth={'420px'}>
      <SearchFilter />
      <Grid container spacing={2} p={3}>
        {permits.length > 0 ? (
          permits.map(permit => (
            <Grid item xs={12} md={6} xl={4}>
              <MiniPermitCard permit={permit} />
            </Grid>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={300}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default Permits;
