import {
  handleAxiosError,
  handleAxiosResponse,
  handleResponse,
  RequestTypes,
  setHeaders,
  setIfAuthHeader,
} from './utils';
// import axios from 'axios';
import envConfig from '../../config/envConfig';
import axios from 'axios';
import {token} from 'morgan';
import {AnyIfEmpty} from 'react-redux';
import {getUser} from '../redux/initialState';

class AppService {
  loginUser(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(`${envConfig.env.AUTH_SERVER}/api/auth/signin`, {
      ...requestOptions,
    }).then(handleResponse);
  }

  getSL360UserProfile(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(`${envConfig.env.AUTH_SERVER}/api/auth/enrichtoken`, {
      ...requestOptions,
    }).then(handleResponse);
  }

  // loginUser(body: any) {
  //   // const requestOptions: RequestInit = {
  //   //   ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
  //   //   body: JSON.stringify(body),
  //   // };
  //   // return fetch(`${envConfig.env.AUTH_SERVER}/api/auth/signin`, {
  //   //   ...requestOptions,
  //   // }).then(handleResponse);

  //   return axios
  //     .post(`${envConfig.env.AUTH_SERVER}/api/auth/signin`, body)
  //     .then(handleAxiosResponse)
  //     .catch(handleAxiosError);
  // }
  getTSLUserProfile(userID: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.TSL_SERVER}/api/users/${userID}/`,
      requestOptions
    ).then(handleResponse);
  }

  ADPTSLSignIn(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/auth/signin/tsl`,
      requestOptions
    ).then(handleResponse);
  }

  TSLloginUser(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.TSL_SERVER}/api-token-auth/`,
      requestOptions
    ).then(handleResponse);
  }

  sendOTP(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/auth/sendCode`,
      requestOptions
    ).then(handleResponse);
  }

  updatePassword(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/auth/updatePassword`,
      requestOptions
    ).then(handleResponse);
  }

  getACL(roleId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/acl/${roleId}`,
      requestOptions
    ).then(handleResponse);
  }
  getAppsData() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/apps`,
      requestOptions
    ).then(handleResponse);
  }

  getQuotesById(projectId: string | null) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({projectId}),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/price/priceQuotes`,
      requestOptions
    ).then(handleResponse);
  }

  resendProposal(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/price/resendQuote`,
      requestOptions
    ).then(handleResponse);
  }

  createOrderOnQuote(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/customerOrder/createOrder`,
      requestOptions
    ).then(handleResponse);
  }

  updateAndSendProposal = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/price/createQuote`,
      requestOptions
    ).then(handleResponse);
  }

  createQuote(formData: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: formData,
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/price/createQuote`,
      requestOptions
    ).then(handleResponse);
  }

  getHubSpotContactData() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/HubspotContact`,
      requestOptions
    ).then(handleResponse);
  }
  getSalesforceData(type: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/SalesforceData?type=${type}`,
      requestOptions
    ).then(handleResponse);
  }

  getProfileData(id: string | null) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/profile/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  getProjects() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects`,
      requestOptions
    ).then(handleResponse);
  }

  editOrder(body: object) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/customerOrder/updateOrder`,
      requestOptions
    ).then(handleResponse);
  }

  getMyProjects() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/type/myprojects`,
      requestOptions
    ).then(handleResponse);
  }

  getAssignedProjects() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/type/assigned`,
      requestOptions
    ).then(handleResponse);
  }

  updateProjectData(projectId: string, body: any) {
    console.log(projectId, body);

    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/${projectId}`,
      requestOptions
    ).then(handleResponse);
  }

  getProjectById(id: string | null) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  getUserPreferance(id: string | null) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/profile/${id}/prefs`,
      requestOptions
    ).then(handleResponse);
  }

  addUserPreferance(id: string | null, userPrefs: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(userPrefs),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/profile/${id}/prefs`,
      requestOptions
    ).then(handleResponse);
  }

  updateUserPreferance(id: string, userPrefs: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(userPrefs),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/profile/${id}/prefs`,
      requestOptions
    ).then(handleResponse);
  }

  sendContract(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),

      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/docuSign`,
      requestOptions
    ).then(handleResponse);
  }

  getCurrentCustomerStep(projectId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({projectId}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/spotlight/currentStepId`,
      requestOptions
    ).then(handleResponse);
  }
  getAllCustomerSteps() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/spotlight/customerSteps`,
      requestOptions
    ).then(handleResponse);
  }
  getAllInstallationSteps() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/spotlight/installationSteps`,
      requestOptions
    ).then(handleResponse);
  }

  getCurrentInstallationStep(projectId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({projectId}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/spotlight/currentInstallationStepId`,
      requestOptions
    ).then(handleResponse);
  }

  changeCustomerStep(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/spotlight/changeStepId`,
      requestOptions
    ).then(handleResponse);
  }

  changeInstallationStep(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/spotlight/changeInstallationStepId`,
      requestOptions
    ).then(handleResponse);
  }

  getProjectManagers() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projectManagers/v2`,
      requestOptions
    ).then(handleResponse);
  }

  sendStepChangeNotification(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/notification/step_change`,
      requestOptions
    ).then(handleResponse);
  }

  getProjectDocuments(id: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents?individual_ID=${id}`,
      requestOptions
    ).then(handleResponse);
  }

  uploadFile(data: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };

    return fetch(
      `https://arkafunction.azurewebsites.net/api/FileUploadsAPI?container=documents&code=bsSDWMalCRsn8CTuOBi9zQd/JmxZ5ohjkQnbkZpEYq6i3rLVY2l25w==`,
      requestOptions
    ).then(handleResponse);
  }

  getPayments(projectId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({projectId}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/paymentHistoryByProjectId`,
      requestOptions
    ).then(handleResponse);
  }

  sendPaymentLink(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/createPaymentLink`,
      requestOptions
    ).then(handleResponse);
  }

  postPowerData(individual_ID: string, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),

      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/powerData?individual_ID=${individual_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  getFirmware() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/firmwares`,
      requestOptions
    ).then(handleResponse);
  }

  deleteFirmware(id: string, fileName: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
      body: JSON.stringify({fileName: 'firmware1.txt'}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/firmwares/${id}?fileName=${fileName}`,
      requestOptions
    ).then(handleResponse);
  }

  uploadFirmware(data: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/firmwares`,
      requestOptions
    ).then(handleResponse);
  }

  getDeviceTags() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/deviceTags`,
      requestOptions
    ).then(handleResponse);
  }

  getDevices(data: {type: string; filter: any}) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/devices`,
      requestOptions
    ).then(handleResponse);
  }

  getconfigcosmos(deviceId: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET, 'application/json; charset=utf-8'),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/devices/config/${deviceId.deviceId}`,
      requestOptions
    ).then(handleResponse);
  }
  updateConfigCosmos(json: any, deviceId: any) {
    const device = deviceId.deviceId;
    const data = json;
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/devices/config/${deviceId.deviceId}`,
      requestOptions
    ).then(handleResponse);
  }
  updateConfig(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/devices/config`,
      requestOptions
    ).then(handleResponse);
  }

  updateDevicesConfig(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/devices/config`,
      requestOptions
    ).then(handleResponse);
  }

  updateDevicesTags(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/devices/tag`,
      requestOptions
    ).then(handleResponse);
  }

  deleteTag(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/deviceTags`,
      requestOptions
    ).then(handleResponse);
  }

  insertTag(data: {data: any}) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/deviceTags`,
      requestOptions
    ).then(handleResponse);
  }

  insertFirmwareType(data: {fmType: string}) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/firmwares/type`,
      requestOptions
    ).then(handleResponse);
  }

  deleteFirmwareType(fmTypeId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/firmwares/type/${fmTypeId}`,
      requestOptions
    ).then(handleResponse);
  }

  getl2c2cStages() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(`${envConfig.env.BACKEND_URL}/api/l2c2c`, requestOptions).then(
      handleResponse
    );
  }

  getDocumentTypes() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents/types`,
      requestOptions
    ).then(handleResponse);
  }

  getParentDocumentTypes() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents/parenttypes`,
      requestOptions
    ).then(handleResponse);
  }

  getDocuments(project_ID: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents?project_ID=${project_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  updateDocument(document_ID: string, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents/${document_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  getOrderHistory(project_ID: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({project_ID}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/customerOrder/orderHistory`,
      requestOptions
    ).then(handleResponse);
  }

  getProductOrders() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/orders2`,
      requestOptions
    ).then(handleResponse);
  }

  getHubspotDetails(project_ID: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({project_ID}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/hubspot/hubspotDetails`,
      requestOptions
    ).then(handleResponse);
  }

  getSalesforceDetails(project_ID: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({project_ID}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/sfdc/sfdcDetails`,
      requestOptions
    ).then(handleResponse);
  }

  getProjectComments(type: string, id: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/comments?${type}=${id}`,
      requestOptions
    ).then(handleResponse);
  }

  addProjectComment(type: string, id: string, message: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({
        // scope: 'project',
        body: message,
        [type]: id,
      }),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/comments`,
      requestOptions
    ).then(handleResponse);
  }

  updateProjectComment(commentId: string, message: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify({
        body: message,
      }),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/comments/${commentId}`,
      requestOptions
    ).then(handleResponse);
  }

  deleteProjectComment(commentId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE, 'application/json; charset=utf-8'),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/comments/${commentId}`,
      requestOptions
    ).then(handleResponse);
  }

  uploadDocument(data: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents`,
      requestOptions
    ).then(handleResponse);
  }

  deleteDocument(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents/delete`,
      requestOptions
    ).then(handleResponse);
  }

  getDocument(order_ID: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents?order_Id=${order_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  sendProposal(data: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/documents/sendProposal`,
      requestOptions
    ).then(handleResponse);
  }

  getQuoteInstallments(quoteId: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/installments/quote${
        quoteId ? '/' + quoteId : ''
      }`,
      requestOptions
    ).then(handleResponse);
  }

  getInstallmentsByTemplateId(payment_template_ID: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/installments/quote?payment_template_ID=${payment_template_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  getOrderInstallments(orderId: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/installments/order/${orderId}`,
      requestOptions
    ).then(handleResponse);
  }

  updateOrderInstallments(orderId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/installments/order/${orderId}`,
      requestOptions
    ).then(handleResponse);
  }

  registerPayment(projectId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/cash/${projectId}`,
      requestOptions
    ).then(handleResponse);
  }

  getInstallments(projectId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify({projectId}),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/payments/paymentInstallmentsByProjectId`,
      requestOptions
    ).then(handleResponse);
  }

  getAllPermits() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/permits`,
      requestOptions
    ).then(handleResponse);
  }

  getPermitById(permit_project_ID: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/permits/${permit_project_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  uploadPermitDocument(files: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: files,
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/permits/uploadDocument`,
      requestOptions
    ).then(handleResponse);
  }

  getAllPermitDocuments(permit_project_ID: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/permits/permitDocuments/${permit_project_ID}`,
      requestOptions
    ).then(handleResponse);
  }

  socialSignInsso = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/auth/signin/sso`,
      requestOptions
    ).then(handleResponse);
  };

  socialSignIn = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/auth/signin`,
      requestOptions
    ).then(handleResponse);
  };

  createLead = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/`,
      requestOptions
    ).then(handleResponse);
  };

  deleteLead = (leadId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/${leadId}`,
      requestOptions
    ).then(handleResponse);
  };

  getHubSpotContacts = (skipCount: null | number, body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    let addQueryParams = '';
    if (skipCount) {
      addQueryParams = `/${skipCount}`;
    }

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/import/hubspot${addQueryParams}`,
      requestOptions
    ).then(handleResponse);
  };

  getSalesForceData = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/import/salesforce`,
      requestOptions
    ).then(handleResponse);
  };

  getCsvData = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/import/CSV`,
      requestOptions
    ).then(handleResponse);
  };

  getLeads = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(`${envConfig.env.BACKEND_URL}/api/leads`, requestOptions).then(
      handleResponse
    );
  };

  getHsSecret = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET, 'application/json; charset=utf-8'),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/hubspot/hasKey`,
      requestOptions
    ).then(handleResponse);
  };

  getHsLastSync = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET, 'application/json; charset=utf-8'),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/lastSync/hubspot`,
      requestOptions
    ).then(handleResponse);
  };

  getSfdcSecret = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET, 'application/json; charset=utf-8'),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/sfdc/hasKey`,
      requestOptions
    ).then(handleResponse);
  };

  getSfdcLastSync = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET, 'application/json; charset=utf-8'),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/lastSync/salesforce`,
      requestOptions
    ).then(handleResponse);
  };

  updateLead = (leadId: any, body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/${leadId}`,
      requestOptions
    ).then(handleResponse);
  };

  getAssignedLeads = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/assigned`,
      requestOptions
    ).then(handleResponse);
  };

  addLeads = (data: any[]) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(`${envConfig.env.BACKEND_URL}/api/leads`, requestOptions).then(
      handleResponse
    );
  };

  getLeadById = (leadId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/${leadId}`,
      requestOptions
    ).then(handleResponse);
  };

  getUserInterests = (userId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/customer/interest/${userId}`,
      requestOptions
    ).then(handleResponse);
  };

  assignLead = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/assign`,
      requestOptions
    ).then(handleResponse);
  };
  getAssignLead = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/assign`,
      requestOptions
    ).then(handleResponse);
  };

  convertLeadToProject = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/convert`,
      requestOptions
    ).then(handleResponse);
  };

  rejectLead = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/leads/reject`,
      requestOptions
    ).then(handleResponse);
  };
  getOrganisations = (userType: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/childorg`,
      requestOptions
    ).then(handleResponse);
  };

  sendSpotlightInvite = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/sendInvite/userinvite/spotlight`,
      requestOptions
    ).then(handleResponse);
  };

  deleteOrganisationsSettings(orgId: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
      body: orgId,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/organisation/${orgId.orgId}`,
      requestOptions
    ).then(handleResponse);
  }

  getMyOrders = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/orders/myorders`,
      requestOptions
    ).then(handleResponse);
  };

  getDesignOrders = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/orders/serviceType/design`,
      requestOptions
    ).then(handleResponse);
  };
  getPermitOrders = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/orders/serviceType/permit`,
      requestOptions
    ).then(handleResponse);
  };

  createOrder = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/orders`,
      requestOptions
    ).then(handleResponse);
  };

  getSurveyDetails = (projectId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/survey/project/${projectId}`,
      requestOptions
    ).then(handleResponse);
  };

  createSurvey = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/survey`,
      requestOptions
    ).then(handleResponse);
  };

  getSupportTickets = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/supportTicket/getSupportTicket`,
      requestOptions
    ).then(handleResponse);
  };

  updateSupportTicket = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/supportTicket/updateSupportTicket`,
      requestOptions
    ).then(handleResponse);
  };

  deleteSupportTicket = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/supportTicket/deleteSupportTicket`,
      requestOptions
    ).then(handleResponse);
  };

  getStages = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages`,
      requestOptions
    ).then(handleResponse);
  };

  linkStageActivity = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/linkstages`,
      requestOptions
    ).then(handleResponse);
  };

  stageSequenceChange = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/sequencechange`,
      requestOptions
    ).then(handleResponse);
  };

  updateStage = (stageId: string, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/${stageId}`,
      requestOptions
    ).then(handleResponse);
  };

  updateStages = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/`,
      requestOptions
    ).then(handleResponse);
  };

  deleteStage = (stageId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/${stageId}`,
      requestOptions
    ).then(handleResponse);
  };

  addNewStage = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/`,
      requestOptions
    ).then(handleResponse);
  };

  getActivities = (url: string | null) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}${
        url ? url : '/api/activity/projectwise/all'
      }`,
      requestOptions
    ).then(handleResponse);
  };

  getTasks = (url: string | null) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}${
        url ? url : '/api/task/projectwise/all'
      }`,
      requestOptions
    ).then(handleResponse);
  };
  getTaskById = (taskId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/task/${taskId}`,
      requestOptions
    ).then(handleResponse);
  };

  addTask = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/task/`,
      requestOptions
    ).then(handleResponse);
  };

  updateTask = (activityId: string, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/task/${activityId}`,
      requestOptions
    ).then(handleResponse);
  };

  sendMailOnTaskUpdate = (data: any) => {
    console.log(data);
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/notification/`,
      requestOptions
    ).then(handleResponse);
  };

  getNotifications = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/notification/`,
      requestOptions
    ).then(handleResponse);
  };

  dismissNotificationRequest = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/notification/dismissedNotification`,
      requestOptions
    ).then(handleResponse);
  };

  deleteTask = (activityId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/task/${activityId}`,
      requestOptions
    ).then(handleResponse);
  };

  //Task Links

  getTasksLink = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/tasklink`,
      requestOptions
    ).then(handleResponse);
  };

  addTaskLink = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/tasklink`,
      requestOptions
    ).then(handleResponse);
  };

  updateTaskLink = (activityLinkId: string, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/tasklink${activityLinkId}`,
      requestOptions
    ).then(handleResponse);
  };

  deleteTaskLink = (activityLinkId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.TASKS_BACKEND_URL}/api/tasklink/${activityLinkId}`,
      requestOptions
    ).then(handleResponse);
  };

  getDefaultActivities = (activity_template_ID: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/defaultActivity?activity_template_ID=${activity_template_ID}`,
      requestOptions
    ).then(handleResponse);
  };

  getDefaultTasks = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/defaultActivity`,
      requestOptions
    ).then(handleResponse);
  };

  updateDefaultTasks = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/defaultActivity`,
      requestOptions
    ).then(handleResponse);
  };

  getAllActivities = (url: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}${
        url ? url : '/api/activity/projectwise/all'
      }`,
      requestOptions
    ).then(handleResponse);
  };

  getAllActivities2 = (url: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}${
        url ? url : '/api/activity/projectwise/all'
      }`,
      requestOptions
    ).then(handleResponse);
  };

  createActivity = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/`,
      requestOptions
    ).then(handleResponse);
  };

  getActivityTypes = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/types`,
      requestOptions
    ).then(handleResponse);
  };

  getFormDetails = () => {
    // const tempID=cosmotemplateId.template_ID
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/form/template`,
      requestOptions
    ).then(handleResponse);
  };

  updateCheckList(surveyId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/form/${data.projectId}/update`,
      requestOptions
    ).then(handleResponse);
  }

  createCheckList = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET, 'application/json; charset=utf-8'),
      // body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/template/${data.projectId}`,
      requestOptions
    ).then(handleResponse);
  };

  getSurveyTemplete = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/checklist/${data.projectId}`,
      requestOptions
    ).then(handleResponse);
  };

  assignActivity = (activityId: any, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/${activityId}/assign`,
      requestOptions
    ).then(handleResponse);
  };

  updateActivityStatus = (activityId: any, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/${activityId}/status`,
      requestOptions
    ).then(handleResponse);
  };

  updateActivity = (activityId: any, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/${activityId}`,
      requestOptions
    ).then(handleResponse);
  };

  getDesignProjects = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/designprojects`,
      requestOptions
    ).then(handleResponse);
  };

  getDesignProjectsInstaller = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/designprojects/installer`,
      requestOptions
    ).then(handleResponse);
  };

  getDesignProjectsSentInstaller = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/designprojects/installer/sent`,
      requestOptions
    ).then(handleResponse);
  };

  changeStage = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/project/stageChange`,
      requestOptions
    ).then(handleResponse);
  };

  convertOrder = (data: any, serviceType: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/orders/convert/${serviceType}`,
      requestOptions
    ).then(handleResponse);
  };

  getDesignById = (designId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/designprojects/${designId}`,
      requestOptions
    ).then(handleResponse);
  };

  createTSLProject = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/TSL/createTSLProject`,
      requestOptions
    ).then(handleResponse);
  };

  createTSLProjectAction = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/integration/actions`,
      requestOptions
    ).then(handleResponse);
  };

  createTSLDesign = (projectId: string, body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),

      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/TSL/projects/${projectId}/designs`,
      requestOptions
    ).then(handleResponse);
  };

  completeActivity = (activityId: string, projectId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/activity/complete/${activityId}?projectId=${projectId}`,
      requestOptions
    ).then(handleResponse);
  };

  sendTSLDesign = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),

      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/TSL/sendTSLDesign`,
      requestOptions
    ).then(handleResponse);
  };

  getTSLDesigns = (projectId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/TSL/designs?tsl_project_ID=${projectId}`,
      requestOptions
    ).then(handleResponse);
  };

  getTSLDesignsInstaller = (projectId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/TSL/designs/installer?tsl_project_ID=${projectId}`,
      requestOptions
    ).then(handleResponse);
  };

  getChecklistTemplate() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/checklist`,
      requestOptions
    ).then(handleResponse);
  }
  getChecklist(projectId: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/checklist/${projectId}`,
      requestOptions
    ).then(handleResponse);
  }

  updateChecklist(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/checklist`,
      requestOptions
    ).then(handleResponse);
  }

  createSupportTicket(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/supportTicket/createSupportTicket`,
      requestOptions
    ).then(handleResponse);
    // return axios
    //   .post(
    //     `${envConfig.env.BACKEND_URL}/api/supportTicket/createSupportTicket`,
    //     data,
    //     {
    //       headers: setIfAuthHeader('application/json; charset=utf-8')
    //     }
    //   )
    //   .then(handleAxiosResponse)
    //   .catch(handleAxiosError);
  }

  getNPS(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(`${envConfig.env.BACKEND_URL}/api/nps/`, requestOptions).then(
      handleResponse
    );
  }

  resendNPSLink(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/nps/resend`,
      requestOptions
    ).then(handleResponse);
  }

  sendNPSLink(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/integration/actions`,
      requestOptions
    ).then(handleResponse);
  }

  getLeadStagewise(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/stages/leadstages`,
      requestOptions
    ).then(handleResponse);
  }

  getAggregateNPS() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/nps/aggregatenps`,
      requestOptions
    ).then(handleResponse);
  }

  getTopInstallers(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/dashboard/topInstallers`,
      requestOptions
    ).then(handleResponse);
  }

  getProductCategory() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2`,
      requestOptions
    ).then(handleResponse);
  }

  getParentProductCategory() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/parent/productcategories`,
      requestOptions
    ).then(handleResponse);
  }

  getProposalData(projectId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/price/proposalData/${projectId}`,
      requestOptions
    ).then(handleResponse);
  }

  createProductCategory(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/createProductCategory`,
      requestOptions
    ).then(handleResponse);
  }

  createProduct(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/createProduct`,
      requestOptions
    ).then(handleResponse);
  }

  createSku(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: data,
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/createSku`,
      requestOptions
    ).then(handleResponse);
  }

  updateProductCategory = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/updateProductCategory`,
      requestOptions
    ).then(handleResponse);
  };

  updateProduct = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH),
      body: body,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/updateProduct`,
      requestOptions
    ).then(handleResponse);
  };

  updateSku = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH),
      body: body,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/updateSku`,
      requestOptions
    ).then(handleResponse);
  };

  deleteProductCategory = (productCategoryId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/productCategoryId/${productCategoryId}`,
      requestOptions
    ).then(handleResponse);
  };
  deleteProduct = (productId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/productId/${productId}`,
      requestOptions
    ).then(handleResponse);
  };
  deleteSku = (skuId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productCategory2/sku/${skuId}`,
      requestOptions
    ).then(handleResponse);
  };

  getImageGallery() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/imageGallery/`,
      requestOptions
    ).then(handleResponse);
  }

  uploadImagetoGallery(files: FormData) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST),
      body: files,
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/imageGallery/`,
      requestOptions
    ).then(handleResponse);
  }

  updateImageName = (ImageId: any, body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/imageGallery/${ImageId}`,
      requestOptions
    ).then(handleResponse);
  };

  deleteImageFromGallery = (imageId: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/imageGallery/${imageId}`,
      requestOptions
    ).then(handleResponse);
  };

  getSystemAlert() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/systemAlert/`,
      requestOptions
    ).then(handleResponse);
  }

  getSKU() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(`${envConfig.env.BACKEND_URL}/api/sku`, requestOptions).then(
      handleResponse
    );
  }

  getParentSKU() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/sku/parent/sku`,
      requestOptions
    ).then(handleResponse);
  }

  getProduct() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/products2`,
      requestOptions
    ).then(handleResponse);
  }

  createProductImage(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productImage/
      `,
      requestOptions
    ).then(handleResponse);
  }

  getProductImage() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/productImage/`,
      requestOptions
    ).then(handleResponse);
  }
  getInverter() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/inverter/`,
      requestOptions
    ).then(handleResponse);
  }
  // getPowerData(projectID: any, data: any) {
  //   const requestOptions: RequestInit = {
  //     ...setHeaders(RequestTypes.POST),
  //     body: JSON.stringify(data),
  //   };
  //   console.log(requestOptions);
  //   return fetch(
  //     `${envConfig.env.BACKEND_URL}/api/powerData/${projectID}`,
  //     requestOptions
  //   ).then(handleResponse);
  // }

  getPowerData = (projectID: string, systemID: string, data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/powerData/${projectID}/${systemID}`,
      requestOptions
    ).then(handleResponse);
  };

  getSystemStatus(projectID: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/systemDetails/status/${projectID}`,
      requestOptions
    ).then(handleResponse);
  }

  getSystemDetails() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/systemDetails`,
      requestOptions
    ).then(handleResponse);
  }

  getProjectSystemDetailsAndStatus(projectId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/systemDetails/status${
        projectId ? '/' + projectId : ''
      }`,
      requestOptions
    ).then(handleResponse);
  }

  addSystemToProject(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/systemDetails/addSystem`,
      requestOptions
    ).then(handleResponse);
  }

  sendEvent = (body: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };

    return fetch(`${envConfig.env.BACKEND_URL}/api/event`, requestOptions).then(
      handleResponse
    );
  };

  getEvents = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(`${envConfig.env.BACKEND_URL}/api/event`, requestOptions).then(
      handleResponse
    );
  };

  getWorkFlows = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/workflow`,
      requestOptions
    ).then(handleResponse);
  };

  getActions = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/workflow/actions`,
      requestOptions
    ).then(handleResponse);
  };

  getOutcomes = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/workflow/outcomes`,
      requestOptions
    ).then(handleResponse);
  };

  outcomeSequenceChange = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/workflow/sequencechange`,
      requestOptions
    ).then(handleResponse);
  };

  changeEventWorkflowMapping = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/event/evenworkflowmapping`,
      requestOptions
    ).then(handleResponse);
  };

  deleteWorkflow = (actionId: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/workflow/${actionId}`,
      requestOptions
    ).then(handleResponse);
  };

  createWorkflow = (data: any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/workflow/createworkflow`,
      requestOptions
    ).then(handleResponse);
  };

  getTSLProject = (project_id: string) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projects/${project_id}/tslproject`,
      requestOptions
    ).then(handleResponse);
  };

  getTemplates = () => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/templates`,
      requestOptions
    ).then(handleResponse);
  };


  getTemplateActivities = (activityTemplateId:any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/templates/activities?activity_template_ID=${activityTemplateId}`,
      requestOptions
    ).then(handleResponse);
  };

  getTemplateStages = (activityTemplateId:any) => {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };

    return fetch(
      `${envConfig.env.BACKEND_URL}/api/templates/stages?activity_template_ID=${activityTemplateId}`,
      requestOptions
    ).then(handleResponse);
  };
  
}

class AppServiceTSL {
  getTSLToken = () => {
    // return '46b1ccdba0a065b3a0e0203bcb81fb629dff43c1';
    let token = '';

    const user = getUser();

    if (user) {
      const {tsl} = user;
      token = tsl?.token;
    }

    return token;
  };

  loginUser(body: {email: string; password: string}) {
    return axios
      .post(`${envConfig.env.TSL_SERVER}/api-token-auth/`, body)
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getProjects(pageNumber: string | number) {
    return axios
      .get(`${envConfig.env.TSL_SERVER}/api/projects/?page=${pageNumber}`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getChatMessages(projectId: any) {
    console.log(projectId);
    return axios
      .get(
        `${envConfig.env.TSL_SERVER}/api/chat/messages/?project_id=${projectId.projectId}`,
        {
          headers: {
            Authorization: `Token ${this.getTSLToken()}`,
          },
        }
      )
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getProjectById(projectId: string) {
    return axios
      .get(`${envConfig.env.TSL_SERVER}/api/projects/${projectId}/`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getDesignReportDataById(designId: string) {
    return axios
      .get(`${envConfig.env.TSL_SERVER}/api/designs/${designId}/report_data/`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getOrders(queryString: string | number) {
    return axios
      .get(
        `${envConfig.env.TSL_SERVER}/api/request-expert-services/?${queryString}`,
        {
          headers: {
            Authorization: `Token ${this.getTSLToken()}`,
          },
        }
      )
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getOrdersById(orderid: string | number) {
    return axios
      .get(
        `${envConfig.env.TSL_SERVER}/api/request-expert-services/${orderid}/`,
        {
          headers: {
            Authorization: `Token ${this.getTSLToken()}`,
          },
        }
      )
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  updateProject(projectId: string, body: any) {
    return axios
      .patch(
        `${envConfig.env.TSL_SERVER}/api/projects/${projectId}/`,

        body,

        {
          headers: {
            Authorization: `Token ${this.getTSLToken()}`,
          },
        }
      )
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  updateOrder(orderId: string | number, body: any) {
    return axios
      .patch(
        `${envConfig.env.TSL_SERVER}/api/request-expert-services/${orderId}/`,
        body,
        {
          headers: {
            Authorization: `Token ${this.getTSLToken()}`,
          },
        }
      )
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  createProject(project: any) {
    return axios
      .post(`${envConfig.env.TSL_SERVER}/api/projects/`, project, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  }

  getUsers = (queryString: string | number) => {
    return axios
      .get(`${envConfig.env.TSL_SERVER}/api/users/?${queryString}`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  };

  // getUsers2 = (url: string | null) => {
  //   return axios
  //     .get(`${url ? url : `${envConfig.env.TSL_SERVER}/api/users/`}`, {
  //       headers: {
  //         Authorization: `Token 7fd1a48580308dae1305c96ff2270eb1070c5c30`,
  //       },
  //     })
  //     .then(handleAxiosResponse)
  //     .catch(handleAxiosError);
  // };

  getUserById = (userId: string | number) => {
    return axios
      .get(`${envConfig.env.TSL_SERVER}/api/users/${userId}/`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  };

  getTSLDocument = (id: string, actor: string) => {
    return axios
      .get(
        `${envConfig.env.TSL_SERVER}/api/documents/?project=${id}&uploaded_by=${actor}`,
        {
          headers: {
            Authorization: `Token ${this.getTSLToken()}`,
          },
        }
      )
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  };

  deleteTSLDocument = (documentID: string) => {
    return axios
      .delete(`${envConfig.env.TSL_SERVER}/api/documents/${documentID}/`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  };

  downloadTSLDocument = (documentID: string) => {
    const requestOptions: RequestInit = {
      headers: {
        Authorization: `Token ${this.getTSLToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        document_ids: [documentID],
      }),
    };

    return fetch(
      `${envConfig.env.TSL_SERVER}/api/documents/download/`,
      requestOptions
    ).then(handleResponse);
  };

  uploadTSLDocument = (data: FormData) => {
    return axios
      .post(`${envConfig.env.TSL_SERVER}/api/documents/`, data, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  };

  // uploadDocument(data: FormData) {
  //   const requestOptions: RequestInit = {
  //     ...setHeaders(RequestTypes.POST),
  //     body: data,
  //   };

  //   return fetch(
  //     `${envConfig.env.BACKEND_URL}/api/documents`,
  //     requestOptions
  //   ).then(handleResponse);
  // }

  getWorkFlows1 = (orgId: string) => {
    return axios
      .get(`${envConfig.env.TSL_SERVER}/api/workflow/?orgId=${orgId}`, {
        headers: {
          Authorization: `Token ${this.getTSLToken()}`,
        },
      })
      .then(handleAxiosResponse)
      .catch(handleAxiosError);
  };
}

const appService = new AppService();
export default appService;

const appServiceTSL = new AppServiceTSL();
export {appServiceTSL};
