import {CardContent, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import IPermit from './__types__/permit.interface';
import {DateTime} from 'luxon';
import {useHistory} from 'react-router-dom';

interface IMiniPermitCard {
  permit: IPermit;
}

function MiniPermitCard({permit}: IMiniPermitCard) {
  const history = useHistory();

  return (
    <Paper sx={{minWidth: 350, minHeight: 160}} elevation={2}>
      <CardContent
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
          position: 'relative',
          p: '16px',
        }}
        onClick={() => {
          console.log(permit);
          
          history.push(`/permits/${permit.permit_project_ID}`);
        }}
      >
        <Grid container direction="row">
          <Grid
            item
            xs
            container
            direction="column"
            //   sx={{borderRight: ' 1px solid rgba(0, 0, 0, 0.12)'}}
          >
            <Typography variant="subtitle1" gutterBottom>
              {permit.permit_project_ID}
            </Typography>

            <Grid
              item
              container
              flexDirection="row"
              alignItems="baseline"
              mb={1}
            >
              <Grid item container>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="body2">
                    Project ID :
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography gutterBottom variant="body2">
                    {permit.project_ID}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="body2">
                    Status :
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography gutterBottom variant="body2">
                    {permit.status}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="body2">
                    Created On :
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography gutterBottom variant="body2">
                    {DateTime.fromISO(permit.created_at).toFormat(
                      'dd MMM yyyy'
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
}

export default MiniPermitCard;
