import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UploadErrorLogDialog = props => {
  const getFormattedRows = leads => {
    const getAddressFromSalesforce = lead => {
      let address = '';
      if (props.uploadSource === props.uploadSourceMapping.SalesForce) {
        if (lead.Address) {
          address = `${lead.Address.street}, ${lead.Address.city}, ${lead.Address.postalCode}, ${lead.Address.country}`;
        }
      }

      return address;
    };

    const rows = leads.map((lead, index) => ({
      id: index,
      ...lead,
    }));

    return rows;
  };

  const getColumns = leads => {
    console.log({leads});
    let columns = [];

    console.log('BP1 ', props.mappingData);

    if (props.uploadSource === props.uploadSourceMapping.HubSpot) {
      props.mappingData.source_columns.map(item => {
        columns = [
          ...columns,
          {
            field: item,
            headerName: item,
            flex: 3,
          },
        ];
      });
    } else if (props.uploadSource === props.uploadSourceMapping.SalesForce) {
      props.mappingData.source_columns.map(item => {
        columns = [
          ...columns,
          {
            field: item,
            headerName: item,
            flex: 3,
          },
        ];
      });
    } else if (props.uploadSource === props.uploadSourceMapping.CSV) {
      props.mappingData.source_columns.map(item => {
        columns = [
          ...columns,
          {
            field: item,
            headerName: item,
            flex: 3,
          },
        ];
      });
    } else {
      throw new Error('No Matching Upload Source found');
    }

    return columns;
  };

  return (
    <Grid container sx={{width: '100%', p: 3, height: '100%'}}>
      <Typography variant="">{`Upload Report :  ${
        'Success : ' + props.data.success.length
      } ${',    Failure : ' + props.data.failure.length}`}</Typography>
      {props.data.success.length > 0 && (
        <Box sx={{width: '100%'}}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
              <Typography>Leads created successfully</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{height: 300}}>
              <DataGrid
                columns={getColumns(props.data.success)}
                rows={getFormattedRows(props.data.success)}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {props.data.failure.length > 0 && (
        <Box sx={{width: '100%'}}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
              <Typography>Leads not created</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{height: 300}}>
              <DataGrid
                columns={getColumns(props.data.failure)}
                rows={getFormattedRows(props.data.failure)}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Grid>
  );
};

export default UploadErrorLogDialog;
