import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import DialogHeading from '../../components/DialogHeading';
import '@google/model-viewer';
import {Height} from '@mui/icons-material';
import {DateTime, Settings} from 'luxon';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
    }
  }
}

const ViewImageGallery = ({open, handleClose, data}: any) => {
  console.log(data);
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogHeading title={'View Image'} handleClose={handleClose} />

      <DialogContent>
        {console.log(data)}
        <Grid container sx={{minHeight: '200px'}} justifyContent="center">
          {data && data.name.split('.')[1] === 'glb' ? (
            <model-viewer
              src={data ? data.storage_url : ''}
              camera-controls
              //@ts-ignore
              alt="A 3D model carousel"
              shadow-intensity="1"
              ar
              ar-modes="quick-look"
              camera-orbit="90deg "
              style={{width: '600px', height: '300px'}}
            ></model-viewer>
          ) : (
            <img
              src={data ? data.storage_url : ''}
              width={'100%'}
              height={'100%'}
            />
          )}

          {data && (
            <Grid container sx={{mt: 2}}>
              <Grid item xs={6} sx={{p: 1}}>
                <Typography variant="h5">Image Name</Typography>
                <Typography>{data.name ? data.name : 'NA'}</Typography>
              </Grid>
              <Grid item xs={6} sx={{p: 1}}>
                <Typography variant="h5">Product Name</Typography>
                <Typography>
                  {data.product_name ? data.product_name : 'NA'}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{p: 1}}>
                <Typography variant="h5">Category Name</Typography>
                <Typography>
                  {data.product_category_name
                    ? data.product_category_name
                    : 'NA'}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{p: 1}}>
                <Typography variant="h5">SKU Name</Typography>
                <Typography>{data.SKU_name ? data.SKU_name : 'NA'}</Typography>
              </Grid>
              <Grid item xs={6} sx={{p: 1}}>
                <Typography variant="h5">Last Modified Date</Typography>
                <Typography>
                  {data.last_modified
                    ? DateTime.fromISO(data.last_modified).toFormat('dd/MM/y ')
                    : 'NA'}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewImageGallery;
