import {Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import appService from '../../core/service/app.service';
import {useSnackbar} from 'notistack';
import EditTasks from './EditTasks';
import Headings from '../../components/Headings';
import {v4 as uuidv4} from 'uuid';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';

const DefaultTasks = () => {
  const [tasks, setTasks] = useState();
  const [deletedIds, setDeletedIds] = useState([]);

  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const getTasks = async () => {
    try {
      setLoading(true);
      enqueueSnackbar('Requesting for data');
      const tasksData = await appService.getDefaultTasks();
      let tempTasks =
        tasksData &&
        tasksData.message.length > 0 &&
        tasksData.message.map((item, index) => ({
          ...item,
          id: uuidv4(),
          sequence: parseInt(item.sequence),
          duration_in_days: parseInt(item.duration_in_days),
        }));
      enqueueSnackbar('Successful ', {variant: 'success'});
      setTasks(tempTasks);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching default activities');
      enqueueSnackbar('Failure ', {variant: 'failure'});
      setLoading(false);
    }
  };

  const updateTasks = async () => {
    try {
      setLoading(true);
      enqueueSnackbar('Updating data');
      const updateTaskData = await appService.updateDefaultTasks({
        tasks,
        deletedIds,
      });
      enqueueSnackbar('Successful ', {variant: 'success'});
      setLoading(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Failure ', {variant: 'failure'});
      setLoading(false);
    }
  };

  const addNewTask = () => {
    setTasks([
      ...tasks,
      {id: uuidv4(), name: '', description: '', duration_in_days: 0},
    ]);
  };
  useEffect(() => {
    getTasks();
  }, []);
  return (
    <Grid container sx={{width: 1}}>
      <BackDropCircularProgress open={loading} />

      <Headings
        heading="Default Company Tasks"
        btntitle={'Save'}
        handleClick={updateTasks}
        btntitle2={'Add'}
        handleClick2={addNewTask}
      />
      <EditTasks
        tasks={tasks}
        setTasks={setTasks}
        setDeletedIds={setDeletedIds}
      />
    </Grid>
  );
};

export default DefaultTasks;
