import {AxiosError, AxiosResponse} from 'axios';
import {getUser} from '../redux/initialState';

export const RequestTypes = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
export const setHeaders = (
  method: string,
  contentType?: string
): RequestInit => {
  return {
    method: method,
    mode: 'cors',
    cache: 'no-cache',

    //@ts-ignore
    //  'Access-Control-Allow-Headers': 'POST,GET,PATCH,DELETE,OPTIONS,PUT',
    headers: setIfAuthHeader(contentType),
  };
};
export const handleResponse = (response: any) => {
  return response.json().then((json: any) => {
    if (response.status === 401) {
    } else if (response.status >= 300) {
      const error = (json && json.message) || 'Something went wrong';
      return Promise.reject(error);
    } else {
      return json;
    }
  });
};

export const handleAxiosResponse = (response: AxiosResponse<any, any>) => {
  return response.data;
};

export const handleAxiosError = (error: AxiosError<any, any>) => {
  if (error.response?.status === 401) {
  }
  return error.response;
};

const getAppVersion = (): string => '0.21.1';

export const setIfAuthHeader = (contentType?: string): HeadersInit => {
  const user = getUser();
  const header: HeadersInit = {};
  if (user?.arka) {
    const {arka} = user;

    header.Authorization = arka.token;
  }
  if (contentType) {
    header['Content-Type'] = contentType;
    header['tsl_token'] = user?.tsl?.token;

    // header['tsl_token'] = 'f557384a3fff216cfb75186df5cd1af391fe3a8d';
  }
  header['X-Client-Version'] = getAppVersion();
  return header;
};

export const numericFormatter = (
  value: number | string,
  country: any
): string => {
  if (!country) return '0';
  if (!value) return '0';

  let options = {locale: 'en-IN', currency: 'INR'};

  //@ts-ignore
  if (country === 'IN') {
    options = {locale: 'en-IN', currency: 'INR'};
  } else if (country === 'US') {
    options = {locale: 'en-US', currency: 'USD'};
  }

  const formatter = new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.currency,
  });

  let result = formatter.format(Number(value || 0));

  result = result.replace('₹', '');

  result = result.replace('$', '');

  return result;
};

export const currencyFormatter = (
  value: number | string,
  country: any
): string => {
  if (!country) return '0';
  if (!value) return '0';

  let options = {locale: 'en-IN', currency: 'INR'};

  //@ts-ignore
  if (country === 'IN') {
    options = {locale: 'en-IN', currency: 'INR'};
  } else if (country === 'US') {
    options = {locale: 'en-US', currency: 'USD'};
  }

  const formatter = new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.currency,
  });

  const result = formatter.format(Number(value || 0));

  return result;
};

export const dateFormatter = (value: string): string => {
  if (value) {
    let date = new Date(value);
    return date.toLocaleDateString();
  }
  return 'N/A';
};
