import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DataGrid} from '@mui/x-data-grid';
import {DateTime} from 'luxon';

const proposalColumns = [
  {key: 'systemSize', label: 'System Size'},
  {key: 'annualEnergyProduction', label: 'Annual Energy Production'},
  {key: 'annualSavings', label: 'Annual Savings'},
  {key: 'totalCost', label: 'Total'},
];

const DesignAccordion = ({data, handleMoveToInstallments, formMode}) => {
  const tableColumns = [
    {field: 'component', headerName: 'Component', flex: 4},
    {field: 'make', headerName: 'Make', flex: 4},
    {
      field: 'quantity',
      headerName: ' Quantity',
      flex: 4,
    },
  ];

  const getFormattedRows = items => {
    let rows;
    if (items)
      rows = items.map((item, index) => ({
        component: item.component,
        make: item.make,
        quantity: item.quantity,
        id: index,
      }));

    return rows;
  };

  const handleSendProposal = async () => {
    try {
      console.log(data);
      handleMoveToInstallments(data, null, true, formMode);
    } catch (error) {}
  };
  return (
    <Accordion sx={{width: 1}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justifyContent="space-between" alignItems={'center'}>
          <Typography variant="h5">{`${data.name} (${data.id})`}</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSendProposal}
          >
            Send
          </Button>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container sx={{width: 1}}>
          {proposalColumns.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Typography>{`${item.label} : ${data[item.key]}`}</Typography>
            </Grid>
          ))}
          <Grid item xs={12}>
            {data.bomItems && data.bomItems.length > 0 && (
              <DataGrid
                hideFooter
                hideFooterPagination
                rows={data.bomItems ? getFormattedRows(data.bomItems) : []}
                columns={tableColumns}
                sx={{height: 300}}
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{mt: 1}}>
            <Grid container justifyContent={'flex-end'}>
              <Typography>
                Last modified by:{' '}
                {data.modified_by.first_name +
                  ' ' +
                  data.modified_by.last_name +
                  ' at ' +
                  DateTime.fromISO(data.versions.modified_at)
                    .toFormat('dd/MM/yyyy')
                    .toString()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DesignAccordion;
