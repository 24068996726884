import { Grid, IconButton, TextField } from '@mui/material';
import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
const SearchComponent = ({searched,cancelSearch,requestSearch,placeholdertext}:any) => {
    return (
        <Grid sx={{pl:1,pr:1}}>
            <TextField id="outlined-basic"
        placeholder={placeholdertext}          
        variant="outlined" 
        defaultValue={searched}
        value={searched}
        onChange={(e:any) =>    requestSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton
            onClick={()=>cancelSearch()}
            >
           {searched? <CloseRoundedIcon />:<SearchOutlinedIcon />}
            </IconButton>
          ),
         }}
         sx={{
          "& .MuiInputBase-root":{
            background:'white',
            height:'40px'
          }
         }}
        />
        </Grid>
    );
};

export default SearchComponent;