import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import SearchIcon from '@mui/icons-material/Search';
import React, {FC} from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Typography from '@mui/material/Typography';
import {makeStyles,useTheme} from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import SelectUser from '../Pages/Organisation/Modals/Tabs/SelectUser';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

const useStyles = makeStyles(theme => ({
  tooltipArrow: {
    background: 'black !important',
  },
  tooltipPopper: {
    color: 'black !important',
  },
}));

const SearchFilter: FC<any> = ({changeView, view, flag}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <Box
      component="div"
      sx={{
        bgcolor: 'background.default',
        position: 'sticky',
        // pb: 1,
        top: 48,
        zIndex: 5,
      }}
    >
      <Grid container justifyContent="flex-end">
        {/* <Grid item xs={9}>
          <TextField
            size="small"
            id="outlined-start-adornment"
            placeholder="Search by Project Id, Installer, Location etc,"
            sx={{width: '100%'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent={'flex-end'}>
            {/* <Select
              value={'All'}
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
              size="small"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value={'Leads'}>Leads</MenuItem>
              <MenuItem value={'Contacts'}>Contacts</MenuItem>
            </Select>
            <FilterAltIcon sx={{margin: '0 0.5rem'}} /> */}
            {/* <Typography
              sx={{
                display: 'inline-block',
                fontSize: '2rem',
                lineHeight: '1rem',
                fontWeight: 'lighter',
              }}
            >
              |
            </Typography> */}
            <Tooltip 
        arrow={true}
        classes={{
          arrow: styles.tooltipPopper,
          tooltip: styles.tooltipArrow,
        }}
        title="List View"
        >
            <ViewListOutlinedIcon
              onClick={() => {
                changeView('list');
              }}
              sx={{
                margin: '0 0.5rem',
                width:'36px',
                height:'36px',
                padding:"2px",
                // @ts-ignore
                background: view === 'list' ? '#1c3366' : 'white',
                color:view === 'list' ? 'white' : 'inherit',
                // @ts-ignore
                // color:
                //   view === 'list'
                //     ? theme => theme.palette.secondary.contrastText
                //     : theme => theme.palette.primary.contrastText,
                borderRadius: '3px',
                opacity: '0.8',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                ':hover': {
                  opacity: '1 !important',
                  // background: 'white !important',
                  // @ts-ignore
                  // color: theme.palette.secondary.contrastText,
                },
              }}
            />
            </Tooltip>
         { flag ===1 && <Tooltip 
        arrow={true}
        classes={{
          arrow: styles.tooltipPopper,
          tooltip: styles.tooltipArrow,
        }}
        title="Tree View"
        >
            <AccountTreeOutlinedIcon
              onClick={() => {
                changeView('grid');
              }}
              sx={{
                margin: '0 0.5rem',
                width:'36px',
                height:'36px',
                padding:"2px",
                // @ts-ignore
                background: view === 'grid' ? '#1c3366' : 'white',
                color:view === 'grid' ? 'white' : 'inherit',
                // // @ts-ignore
                // color:
                //   view === 'grid'
                //     ? theme => theme.palette.secondary.contrastText
                //     : theme => theme.palette.primary.contrastText,
                borderRadius: '3px',
                opacity: '0.8',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                ':hover': {
                  opacity: '1 !important',
                  // background: 'white !important',
                  // @ts-ignore
                  //  color: theme.palette.secondary.contrastText,
                },
              }}
            />
            </Tooltip>
        }
        { flag!==1 && <Tooltip 
        arrow={true}
        classes={{
          arrow: styles.tooltipPopper,
          tooltip: styles.tooltipArrow,
        }}
        title="Grid View"
        >
            <GridViewIcon
              onClick={() => {
                changeView('grid');
              }}
              sx={{
                margin: '0 0.5rem',
                width:'36px',
                height:'36px',
                padding:"2px",
                // @ts-ignore
                background: view === 'grid' ? '#1c3366' : 'white',
                color:view === 'grid' ? 'white' : 'inherit',
                // // @ts-ignore
                // color:
                //   view === 'grid'
                //     ? theme => theme.palette.secondary.contrastText
                //     : theme => theme.palette.primary.contrastText,
                borderRadius: '3px',
                opacity: '0.8',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                ':hover': {
                  opacity: '1 !important',
                  // background: 'white !important',
                  // @ts-ignore
                  //  color: theme.palette.secondary.contrastText,
                },
              }}
            />
            </Tooltip>
        }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchFilter;
