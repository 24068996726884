import {
  Autocomplete,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import DialogHeading from '../../components/DialogHeading';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import appService from '../../core/service/app.service';
import UploadIcon from '@mui/icons-material/Upload';
import {styled} from '@mui/system';
import CircularProgressDialog from '../../components/CircularProgressDialog';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import {setupMaster} from 'cluster';

const ImageUploader = ({open, handleClose, handleStatus, type}: any) => {
  const Input = styled('input')({
    display: 'none',
  });
  const [files, setFiles] = useState<any>([]);
  const [filesSrc, setFilesSrc] = useState<any>([]);
  const [matchingFileIndex, setMatchingFileIndex] = useState<any>(null);
  const [tempNewFile, setTempNewFile] = useState<any>(null);
  const [, updateState] = React.useState<any>();
  const [uploadImg, setUploadImg] = useState<any>([]);
  const [loading, setloading] = useState<any>();
  const [productcat, setProductcat] = useState<any>();
  const [selectedProductVariant, setSelectedProductVariant] = useState<any>();
  const [productscat, setProductscat] = useState<any>();
  const [sku, setSku] = useState<any>();
  const [selectedSKUVariant, setSelectedSKUVariant] = useState<any>();
  const [productcatID, setProductcatID] = useState<any>();
  const [productCatname, setProductCatname] = useState<any>();
  const [skuname, setSkuName] = useState<any>();
  const [product, setProduct] = useState<any>();
  const [productID, setProductID] = useState<any>();
  const [productName, setProductName] = useState<any>();

  const [skuID, setSkuID] = useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    appService
      .getProductCategory()
      .then(res => {
        setProductcat([
          ...new Set(res.message.map((d: any) => d.productcategory_name)),
        ]);
        setProductscat(res.message);
      })
      .catch(err => console.log(err));

    appService
      .getSKU()
      .then(res => setSku(res.message))
      .catch(err => console.log(err));
    appService
      .getProduct()
      .then(res => setProduct(res.message))
      .catch(err => console.log(err));
  }, []);
  console.log(productcat);
  const handleAddFile = (newFile: any) => {
    console.log(newFile);

    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file: any, index: any) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }
    console.log(newFile[1]);
    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3) console.log('lenth>3');
      else {
        setFiles([...files, ...newFile]);
        let rrr: any = [];

        uploadImg.map((item: any) => {
          rrr.push(window.URL.createObjectURL(item));
          console.log(rrr);
          setFilesSrc(rrr);
        });
      }
    }
  };

  const handleRemoveImage = (index: any) => {
    console.log(index);
    console.log(uploadImg);
    let filteredFiles = uploadImg;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };
  const cancelOverWrite = () => {
    setMatchingFileIndex(null);
    setTempNewFile(null);
  };

  const handleSubmit = (uploadImg: any) => {
    console.log(uploadImg);

    // const formData = new FormData();
    // formData.append("file", files[0].name);
    setloading(true);

    const file = uploadImg;
    if (file) {
      const product_category_ID = productcatID;
      const product_category_name = productCatname;
      const SKU_ID = skuID;
      const SKU_name = skuname;
      const data: any = {
        product_category_ID,
        product_category_name,
        SKU_ID,
        SKU_name,
      };
      console.log(data);
      const formData = new FormData();
      formData.append('product_category_ID', product_category_ID);
      formData.append('product_category_name', product_category_name);
      formData.append('SKU_ID', SKU_ID);
      formData.append('SKU_name', SKU_name);
      formData.append('product_ID', productID);
      formData.append('product_name', productName);

      if (uploadImg.length > 0) {
        uploadImg.map((item: any, index: any) =>
          type === 'img'
            ? formData.append('file', item)
            : item.name.includes('.glb')
            ? formData.append('file', item)
            : formData.append('poster', item)
        );
      }
      console.log(formData);
      // formData.append('file', file);
      // formData.append('permit_project_ID', param.permitId);

      appService
        .uploadImagetoGallery(formData)
        .then(res => {
          setloading(false);
          handleStatus();
          setFiles('');
          setFilesSrc('');
          setUploadImg(['']);
          handleClose();
          const uploads = res.message;
          {
            uploads &&
              uploads.map((data: any, index: any) => {
                const image_ID = data.image_ID;
                const product_ID = productcatID;
                const sku_ID = skuID;
                const sequence = index + 1;
                const newdata = {image_ID, product_ID, sku_ID, sequence};
                console.log(newdata);
                appService
                  .createProductImage(newdata)
                  .then(resp => console.log(resp))
                  .catch(err => console.log(err));
              });
          }
        })
        .catch(err => err);
    }
  };

  const handleSelectChange = (value: any) => {
    setSelectedProductVariant(value);
    const productcatID = productscat.filter(
      (d: any) => d.productcategory_name === value
    );
    console.log(productcatID[0]);
    setProductcatID(productcatID[0].product_category_ID);
    setProductCatname(productcatID[0].productcategory_name);
  };

  const handleSelectSKU = (value: any) => {
    console.log(value);
    setSkuID(value.SKU_ID);
    setSkuName(value.name);
  };

  const handleSelectProduct = (value: any) => {
    console.log(value);
    setProductID(value.product_ID);
    setProductName(value.name);
  };
  console.log(filesSrc);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setFiles('');
        setFilesSrc('');
        setUploadImg([]);
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogHeading
        title={'Upload Image'}
        handleClose={() => {
          setFiles('');
          setFilesSrc('');
          setUploadImg([]);
          handleClose();
        }}
      />
      <BackDropCircularProgress open={loading} />

      <DialogContent>
        <Grid container sx={{mt: 1}}>
          <Grid container item xs={6} sx={{p: 2}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select a product Category
              </InputLabel>
              <Select
                value={selectedProductVariant}
                label="Select a product"
                onChange={(e: any) => handleSelectChange(e.target.value)}
                // disabled={form.saved}
              >
                {productcat &&
                  productcat.map((item: any) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={6} sx={{p: 2}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select a SKU
              </InputLabel>
              <Select
                value={selectedSKUVariant}
                label="Select a product"
                onChange={(e: any) => handleSelectSKU(e.target.value)}
                // disabled={form.saved}
              >
                {sku &&
                  sku.map((item: any) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={6} sx={{p: 2}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select a Product
              </InputLabel>
              <Select
                value={selectedSKUVariant}
                label="Select a product"
                onChange={(e: any) => handleSelectProduct(e.target.value)}
                // disabled={form.saved}
              >
                {product &&
                  product.map((item: any) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography
              // className={classes.marginTop}
              variant="body2"
              sx={{mb: 1}}
            >
              {'Upload image '}
            </Typography>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'left'}}>
              <Grid item my={2}>
                <label htmlFor="upload-file">
                  <Input
                    accept=".png,.jpg"
                    id="upload-file"
                    type="file"
                    multiple={type === 'img' ? true : false}
                    onChange={async (e: any) => {
                      console.log(files);
                      for (let i = 0; i < e.target.files.length; i++) {
                        uploadImg.push(e.target.files[i]);
                        setUploadImg(uploadImg);
                        console.log(uploadImg);
                      }
                      handleAddFile(e.target.files);

                      // setUploadImg([...e.target.files, ...uploadImg]);
                    }}
                  />
                  <Button
                    startIcon={<UploadIcon />}
                    component="span"
                    variant="outlined"
                    size="small"
                    style={{marginBottom: 8}}
                    color="secondary"
                    sx={{ml: 2}}
                  >
                    Upload
                  </Button>
                </label>
                {type === '3d' && (
                  <label htmlFor="upload-files" style={{padding: '5px'}}>
                    <Input
                      accept=" .glb"
                      id="upload-files"
                      type="file"
                      onChange={async (e: any) => {
                        console.log(e.target.files[0]);
                        for (let i = 0; i < e.target.files.length; i++) {
                          uploadImg.push(e.target.files[i]);
                          setUploadImg(uploadImg);
                          console.log(uploadImg);
                        }
                        handleAddFile(e.target.files);

                        // setUploadImg([e.target.files[0], ...uploadImg]);
                      }}
                    />
                    <Button
                      startIcon={<UploadIcon />}
                      component="span"
                      variant="outlined"
                      size="small"
                      style={{marginBottom: 8}}
                      color="secondary"
                    >
                      Upload 3d
                    </Button>
                  </label>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {console.log(uploadImg)}

        <Grid item my={2}>
          {files && files.length > 0 && filesSrc && filesSrc.length > 0 && (
            <Grid container sx={{width: 1}}>
              {filesSrc.map((image: any, index: any) => (
                <Grid item sx={{mr: 5, mt: 2, width: '10%'}}>
                  {console.log(image)}
                  <Badge
                    badgeContent={
                      <IconButton
                        size="small"
                        style={{padding: 0}}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <CloseRoundedIcon sx={{fontSize: '1rem'}} />
                      </IconButton>
                    }
                    color="primary"
                    sx={{
                      '& .MuiBadge-badge': {
                        padding: 0,
                      },
                    }}
                  >
                    <Box
                      component={'div'}
                      // className={classes.imageContainer}
                    >
                      {' '}
                      <img
                        src={image}
                        alt="uploaded"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  </Badge>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          // onClick={() => createCustomerSupportRequest()}
          onClick={() => handleSubmit(uploadImg)}
          color="secondary"
          sx={{mr: 4}}
        >
          {'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploader;
