import React, {useEffect, useState, useRef} from 'react';
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {Box} from '@mui/system';
import {DataGrid, GridCellParams, MuiEvent} from '@mui/x-data-grid';

import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import appService from '../../core/service/app.service';

import EditIcon from '@mui/icons-material/Edit';

import LoadingButton from '@mui/lab/LoadingButton';

import DeleteIcon from '@mui/icons-material/Delete';
import {useSnackbar} from 'notistack';
import {checkPermisssion} from '../../utils/utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';

interface IDesignTable {
  tslProjectId: string | undefined;
}

function DesignsTable({tslProjectId}: IDesignTable) {
  const [open, setOpen] = useState<boolean>(false);
  const [designs, setDesigns] = useState<null | any[]>(null);

  const [sending, setSending] = useState<string>('');

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const designName = useRef<string>();
  const [creating, setCreating] = useState<boolean>(false);
  const handleViewDesign = (designId: string | undefined) => {
    window.open(`https://beta.thesolarlabs.com/studio/${designId}`);
  };

  const rules = useSelector((state: RootState) => state.appState.rules);

  const tableColumns: GridColDef[] = [
    {field: 'id', headerName: 'Design ID', flex: 1},
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'created_at',
      headerName: 'Created On',
      flex: 2,
      renderCell: params => {
        return new Date(String(params.row.created_at)).toLocaleDateString();
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',

      flex: 2,
      renderCell: params => {
        const is_sent = designs?.find(
          design => design.tsl_design_ID === params.row.id
        ).is_sent;
        return (
          <Grid item>
            <IconButton
              size="small"
              sx={{mx: 1}}
              onClick={e => handleViewDesign(params.row.id)}
              title="View/Edit"
            >
              <EditIcon />
            </IconButton>

            {checkPermisssion(rules, 'TSL/sendTSLDesign', 'POST') && (
              <LoadingButton
                loading={params.row.id === sending}
                onClick={() => handleSend(params.row.id)}
                color="error"
                title="Delete"
                disabled={is_sent}
              >
                {is_sent ? 'Sent' : 'Send'}
              </LoadingButton>
            )}
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    if (!tslProjectId) return;

    console.log(checkPermisssion(rules, 'TSL/designs/installer', 'GET'));
    if (checkPermisssion(rules, 'TSL/designs/installer', 'GET')) {
      appService
        .getTSLDesignsInstaller(tslProjectId)
        .then(res => {
          console.log(res);

          console.log(res.message);
          setDesigns(res.message);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (checkPermisssion(rules, 'TSL/designs', 'GET')) {
      appService
        .getTSLDesigns(tslProjectId)
        .then(res => {
          console.log(res);

          console.log(res.message);
          setDesigns(res.message);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  const handleSend = (tsl_design_ID: string) => {
    if (!designs) return;

    const body = {tsl_design_ID: tsl_design_ID};

    setSending(tsl_design_ID);
    appService
      .sendTSLDesign(body)
      .then(res => {
        console.log(res);

        const design = designs?.find(
          design => design.tsl_design_ID === tsl_design_ID
        );

        design.is_sent = true;

        setDesigns([...designs]);
        setSending('');
      })
      .catch(err => {
        setSending('');
        console.log(err);
      });
  };

  const getFormattedRows = (designs: any[] | null) => {
    if (!designs) return [];

    const tempDesigns: any[] = [];

    designs.forEach(design =>
      tempDesigns.push({id: design.tsl_design_ID, ...design})
    );
    return tempDesigns;
  };

  const createNewDesign = async () => {
    if (!tslProjectId) return;
    try {
      setCreating(true);
      const res = await appService.createTSLDesign(tslProjectId, {
        name: designName.current,
      });

      console.log(res);

      const designData = res.message;

      if (!designs) {
        setDesigns([designData]);
      } else {
        setDesigns([designData, ...designs]);
      }

      setCreating(false);
      setOpen(false);
      designName.current = '';

      enqueueSnackbar(`design is created successfully`, {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
      setCreating(false);
      designName.current = '';

      enqueueSnackbar(`Failed to create new design`, {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        // sx={{minWidth: 200}}
        onKeyUp={key => {
          if (key.code === 'Enter') {
            createNewDesign();
          }
        }}
      >
        <DialogTitle>Enter Design Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            variant="standard"
            onChange={e => {
              designName.current = e.target.value;
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <LoadingButton
            loading={creating}
            onClick={createNewDesign}
            size="small"
            variant="contained"
            color="secondary"
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}
        >
          {' '}
          Create New Design
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{height: '400px', width: '100%', overflow: 'auto'}}>
          <DataGrid
            rows={getFormattedRows(designs)}
            columns={tableColumns}
            // hideFooter
            hideFooterPagination
            hideFooterSelectedRowCount
            loading={!designs}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default DesignsTable;
