import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import MapAutocomplete from './../../components/MapAutocomplete';
import {GoogleApiWrapper} from 'google-maps-react';
// import Map, {Ilatlng} from '../../components/Map';
import {Box} from '@mui/system';
import {Formik} from 'formik';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as Yup from 'yup';
import ILead from './__types__/lead.interface';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Map from '../Projects/Map';
import {Ilatlng} from '../../components/Map';
import DialogHeading from '../../components/DialogHeading';

import {DesktopDatePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime, Settings} from 'luxon';
import appService from '../../core/service/app.service';
import IActivity, {
  IDefaultActivity,
} from '../Projects/__types__/Activity.interface';
import {makeStyles} from '@mui/styles';
import {leadDataAdapter} from '../../core/redux/initialState';
import {useSnackbar} from 'notistack';
import {ITemplate} from './__types__/templates.interface';
import TemplateForm from './TemplateForm';
const steps = ['Project Details', 'Template Details'];
// eslint-disable-next-line import/first
import {IProject1} from '../Projects/__types__/Project.interface';

interface ICreateProjectDialogue {
  open: boolean;
  handleClose: any;
  leadData: ILead | null;
  google: any;
  loaded: boolean;
  convertToProject?: any;
  createProject?: any;
}

const useStyles = makeStyles(theme => ({
  dialog: {
    // the dropdown is next to the dialog root, not inside
    '& + .pac-container': {
      //@ts-ignore
      zIndex: theme.zIndex.modal + 1,
    },
  },
}));

function CreateProject({
  open,
  handleClose,
  leadData,
  convertToProject,
  createProject,
  ...rest
}: ICreateProjectDialogue) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const geocoder = new rest.google.maps.Geocoder();
  const [value, setValue] = React.useState('0');
  const [initValues, setInitValues] = React.useState<any>({
    location: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    address: '',
    project_name: '',
  });

  const [addressString, setAddressString] = React.useState<string>('');
  const [addressLatLng, setAddressLatLng] = React.useState<Ilatlng>({
    lat: null,
    lng: null,
  });

  const [center, setCenter] = React.useState<Ilatlng>({
    lat: null,
    lng: null,
  });

  const [defaultActivities, setDefaultActivities] = React.useState<
    null | IDefaultActivity[]
  >(null);

  const projectData = React.useRef({
    projectDetails: {},
    templates: {},
  });

  const [templates, setTemplates] = React.useState<ITemplate>({
    activityTemplates: [],
    paymentTemplates: [],
    surveyTemplates: [],
  });

  const updateAddressFromAutocomplete = (addressObject: any) => {
    if (typeof addressObject !== 'string') {
      const latlng = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      };
      setAddressString(addressObject.formatted_address);

      setAddressLatLng(latlng);

      setCenter(latlng);
    }
  };

  const getaddressToLocation = async (geocoder: any, address: any) => {
    try {
      const {results} = await geocoder.geocode({address: address});

      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();

      return {lat, lng};
    } catch (error) {
      console.log(error);
      throw new Error('Geocoder failed due to: ' + error);
    }
  };

  const handleMapClick = (coords: any, setFieldValue: any) => {
    // set lat lng , set address string
    setAddressLatLng(coords);

    geocoder
      .geocode({location: coords})
      .then((response: any) => {
        const address = response.results[0].formatted_address;
        setAddressString(address);

        setFieldValue('location', address);
      })
      .catch((e: any) => console.log('Geocoder failed due to: ' + e));
  };

  const setInitialState = async (
    leadData: ILead | null,
    defaultActivities: null | IDefaultActivity[]
  ) => {
    console.log(leadData, defaultActivities);

    if (!leadData) return;

    setAddressString(leadData?.gmaps_address_string || '');

    let {latitude, longitude} = leadData;

    if (!latitude || !longitude) {
      const {lat, lng} = await getaddressToLocation(
        geocoder,
        leadData?.gmaps_address_string
      );

      latitude = lat;
      longitude = lng;
    }

    setAddressLatLng({lat: latitude, lng: longitude});

    const projectDurationDays = defaultActivities?.reduce(
      (pv, cv) => pv + Number(cv.duration_in_days),
      0
    );

    setInitValues({
      location: leadData?.gmaps_address_string,
      first_name: leadData?.first_name,
      last_name: leadData?.last_name,

      phone_number: leadData?.phone_number,
      email: leadData?.email,
      address: leadData?.gmaps_address_string,
      project_name: '',
    });
  };
  React.useEffect(() => {
    setInitialState(leadData, defaultActivities);
  }, [leadData, defaultActivities]);

  React.useEffect(() => {
    appService
      .getDefaultTasks()
      .then(res => {
        console.log(res);
        setDefaultActivities(res.message);
      })
      .catch(err => {
        console.log(err);
      });

    appService
      .getTemplates()
      .then(res => {
        console.log(res);

        setTemplates(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const goBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onCreateProject = async (values: any) => {
    projectData.current = {...projectData.current, templates: values};

    const {templates, projectDetails} = projectData.current;

    const data: any = {...projectDetails, ...templates};

    

    try {
      const result = await getaddressToLocation(geocoder, data.location);
      data.lat = result.lat;
      data.lng = result.lng;
      console.log(data, addressLatLng);

      if (!leadData) {
        createProject(data);
      } else {
        convertToProject(leadData?.lead_ID, leadData?.user_ID, data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Unable to resolve location`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogHeading title="Enter Project Details" handleClose={handleClose} />

      <DialogContent>
        <TabContext value={value}>
          {/* <Box sx={{borderBottom: 1, borderColor: 'divider', }}  > */}
          <Stepper activeStep={activeStep} alternativeLabel connector={null}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottom:
                      activeStep === index
                        ? '2px solid #1c3366'
                        : '2px solid #999999',
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <StepLabel
                      {...labelProps}
                      sx={{
                        '.MuiSvgIcon-root': {
                          color:
                            activeStep === index
                              ? '#1c3366 !important'
                              : '#999999 !important',
                        },
                      }}
                    >
                      <Tab
                        sx={{
                          color: activeStep === index ? '#1c3366' : '',
                          opacity: activeStep === index ? 1 : '',
                        }}
                        label={label}
                        value={index + ''}
                      />
                      <Grid></Grid>
                    </StepLabel>

                    {/* <Tab label="Change" value="2" /> */}
                  </TabList>
                </Step>
              );
            })}
          </Stepper>
          {/* </Box> */}
          <Grid
            container
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems="center"
            mt={3}
            height="100%"
          >
            <Grid item xs={12} width="100%">
              {activeStep === 0 && (
                <Formik
                  enableReinitialize
                  initialValues={initValues}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Email must be formatted correctly.')
                      .max(255)
                      .required('Email is required.'),

                    first_name: Yup.string().required(
                      'First name is required.'
                    ),
                    last_name: Yup.string().required('Last name is required.'),

                    location: Yup.string().required('Location is required.'),

                    phone_number: Yup.string().required(
                      'Phone number is required.'
                    ),
                    address: Yup.string().required('Address is required.'),
                    project_name: Yup.string().required(
                      'Project name is required.'
                    ),
                  })}
                  onSubmit={async values => {
                    console.log(values);
                    const result = await getaddressToLocation(
                      geocoder,
                      values.location
                    );

                    values.lat = result.lat;
                    values.lng = result.lng;

                    projectData.current = {
                      ...projectData.current,
                      projectDetails: {...values},
                    };

                    setActiveStep(activeStep + 1);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <form noValidate onSubmit={handleSubmit}>
                        <Grid container>
                          <Grid item container xs={8} spacing={1} mt={1}>
                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Location*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                {/* ts-ignore */}
                                <MapAutocomplete
                                  // ts-ignore
                                  addressString={addressString}
                                  onChange={(e: any) => {
                                    setAddressString(e.target.value);

                                    handleChange(e);
                                  }}
                                  updateAddressFromAutocomplete={(
                                    data: any
                                  ) => {
                                    setFieldValue(
                                      'location',
                                      data.formatted_address
                                    );
                                    updateAddressFromAutocomplete(data);
                                  }}
                                  name="location"
                                  size="small"
                                  error={Boolean(errors.location)}
                                  helperText={errors.location}
                                />
                              </Grid>
                            </Grid>

                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Project Name*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                <TextField
                                  required
                                  value={values.project_name}
                                  size="small"
                                  fullWidth
                                  name="project_name"
                                  error={Boolean(errors.project_name)}
                                  helperText={errors.project_name}
                                  onChange={handleChange}
                                />
                              </Grid>{' '}
                            </Grid>

                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Client First Name*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                <TextField
                                  required
                                  value={values.first_name}
                                  size="small"
                                  fullWidth
                                  onChange={handleChange}
                                  name="first_name"
                                  error={Boolean(errors.first_name)}
                                  helperText={errors.first_name}
                                />
                              </Grid>{' '}
                            </Grid>

                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Client Last Name*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                <TextField
                                  required
                                  value={values.last_name}
                                  size="small"
                                  fullWidth
                                  onChange={handleChange}
                                  name="last_name"
                                  error={Boolean(errors.last_name)}
                                  helperText={errors.last_name}
                                />
                              </Grid>{' '}
                            </Grid>

                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Contact Number*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                <TextField
                                  required
                                  value={values.phone_number}
                                  size="small"
                                  fullWidth
                                  onChange={handleChange}
                                  name="phone_number"
                                  error={Boolean(errors.phone_number)}
                                  helperText={errors.phone_number}
                                />
                              </Grid>{' '}
                            </Grid>

                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Email Id*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                <TextField
                                  required
                                  value={values.email}
                                  size="small"
                                  fullWidth
                                  onChange={handleChange}
                                  name="email"
                                  error={Boolean(errors.email)}
                                  helperText={errors.email}
                                />
                              </Grid>{' '}
                            </Grid>

                            <Grid item container alignItems={'center'}>
                              {' '}
                              <Grid item xs={3}>
                                <Typography> Address*</Typography>{' '}
                              </Grid>{' '}
                              <Grid item xs={8}>
                                <TextField
                                  required
                                  value={values.address}
                                  size="small"
                                  fullWidth
                                  onChange={handleChange}
                                  name="address"
                                  error={Boolean(errors.address)}
                                  helperText={errors.address}
                                />
                              </Grid>{' '}
                            </Grid>
                          </Grid>

                          <Grid item xs={4}>
                            <Map
                              latlng={addressLatLng}
                              handleClick={cords => {
                                handleMapClick(cords, setFieldValue);
                              }}
                              address={addressString}
                              minheight={'300px'}
                            />
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems={'center'}
                            xs={12}
                            mt={2}
                            justifyContent="end"
                          >
                            {' '}
                            <Grid item>
                              <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                              >
                                Next
                              </Button>
                            </Grid>{' '}
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              )}

              {activeStep === 1 && (
                <TemplateForm
                  onCreateProject={onCreateProject}
                  goBack={goBack}
                  templates={templates}
                />
              )}
            </Grid>
          </Grid>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}

const CreateProjectDialogue = GoogleApiWrapper({
  apiKey: String(process.env.REACT_APP_GMAPS_KEY),
  //@ts-ignore
  options: {mapTypeId: 'satellite', componentRestrictions: {country: ['US']}},
})(CreateProject);

export default CreateProjectDialogue;
