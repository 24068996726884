import {Box, Breadcrumbs, Button, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useParams, Link} from 'react-router-dom';
import appService from '../../core/service/app.service';
import {makeStyles} from '@mui/styles';

import {checkPermisssion} from '../../utils/utils';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallIcon from '@mui/icons-material/Call';

import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ConstructionIcon from '@mui/icons-material/Construction';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import Map from '../Projects/Map';
import IDesign from './__types__/design.interface';
import DesignsTable from './DesignsTable';
import {useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';

const NA = '-- : --';
const useStyles = makeStyles(theme => ({
  linkDecorationReset: {
    textDecoration: 'none',

    // @ts-ignore
    color: theme.palette.secondary.main,
  },
  mainSeparator: {
    margin: '0.5rem 0 1rem',
    height: '4px',
    backgroundColor: '#0060b0 !important',
    borderColor: '#0060b0 !important',
  },
  sectionSeparator: {
    margin: '2px 0 5px 0',
    padding: '0',
  },
}));

function Design() {
  const rules = useSelector((state: RootState) => state.appState.rules);

  const styles = useStyles();

  const param = useParams<{designId: string}>();
  const [design, setDesign] = useState<null | IDesign>(null);

  const breadcrumbs = [
    <Link
      className={styles.linkDecorationReset}
      to={
        checkPermisssion(rules, 'designprojects/installer', 'GET')
          ? '/designs/installer'
          : '/designs'
      }
    >
      Designs
    </Link>,
    <Typography color="HighlightText" sx={{cursor: 'pointer'}}>
      {param.designId}
    </Typography>,
  ];

  useEffect(() => {
    appService
      .getDesignById(param.designId)
      .then(res => {
        console.log(res);

        setDesign(res.message);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const formatCustomerName = () => {
    if (design) {
      try {
        const {project} = design;
        const {Lead} = project;
        const {first_name, salutation, last_name} = Lead;

        return `${salutation} ${first_name} ${last_name}`;
      } catch (error) {
        return '-- : --';
      }
    }
  };

  const formatManagerName = () => {
    if (design) {
      try {
        const {project} = design;
        const {owner} = project;
        const {first_name, salutation, surname, middle_name} = owner;

        return `${salutation} ${first_name} ${middle_name} ${surname}`;
      } catch (error) {
        return '-- : --';
      }
    }
  };

  const handleViewDesign = (designId: string | undefined) => {
    window.open(`https://beta.thesolarlabs.com/studio/${designId}`);
  };

  return (
    <Box>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb "
        sx={{
          color: 'secondary.main',
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      {design ? (
        <Grid container>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Grid item>
              <Typography> {param.designId} </Typography>{' '}
            </Grid>
          </Grid>

          <Grid
            item
            container
            p={1}
            pt={3}
            mt={1}
            sx={{
              borderTop: theme => `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            {/*manager name */}
            <Grid item container xs={12} mt={2}>
              <Grid item xs={6} md={2}>
                <Typography>Project Manager :</Typography>
              </Grid>

              <Grid item xs>
                <Typography color={'secondary'}>
                  {formatManagerName()}
                </Typography>
              </Grid>
            </Grid>
            {/*Project start and end date*/}
            <Grid item container xs={12} md={6} my={1}>
              <Grid item xs={6} md={4}>
                <Typography>Project Started :</Typography>
              </Grid>

              <Grid item xs>
                <Typography>
                  {design.project?.start_date || '-- : --'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={6} md={4}>
                <Typography>Expected Completion :</Typography>
              </Grid>

              <Grid item xs>
                <Typography>
                  {design.project?.projected_close_date || '-- : --'}
                </Typography>
              </Grid>
            </Grid>
            {/*cutomer details*/}
            <Grid
              item
              container
              flexDirection="column"
              pl={3}
              rowSpacing={1.5}
              my={2}
              xs={12}
              md={6}
            >
              <Grid item container>
                <AccountCircleIcon />
                <Typography ml={2} color={'secondary'}>
                  {formatCustomerName()}
                </Typography>
              </Grid>

              <Grid item container>
                <CallIcon />
                <Typography ml={2} color={'secondary'}>
                  {design.project?.Lead.phone_number || NA}
                </Typography>
              </Grid>
              <Grid item container>
                <EmailIcon />
                <Typography ml={2} color={'secondary'}>
                  {design.project?.Lead.email || NA}
                </Typography>
              </Grid>
              <Grid item container>
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item xs>
                  <Typography ml={2}>
                    {design.project?.Lead.address || NA}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*Geo map*/}
            <Grid item xs={12} md={6} height={250} my={2}>
              <Map
                address={design.project?.Lead.gmaps_address_string}
                latlng={{
                  lat: design.project?.Lead.latitude,
                  lng: design.project?.Lead.longitude,
                }}
                minheight={'250px'}
              />
            </Grid>

            <Grid item xs={12} my={2}>
              <DesignsTable tslProjectId={design.tsl_project_ID} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={300}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Box>
  );
}

export default Design;
