import envConfig from '../../config/envConfig';
import {handleResponse, RequestTypes, setHeaders} from './utils';

class OrganisationService {
  createOrganisation(createOrgBody: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(createOrgBody),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/childorg/`,
      requestOptions
    ).then(handleResponse);
  }

  createIndependentOrganisation(createOrgBody: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(createOrgBody),
    };
    return fetch(`${envConfig.env.AUTH_SERVER}/api/org/`, requestOptions).then(
      handleResponse
    );
  }
  updateOrganisation(adminUser: any, orgId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(adminUser),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/${orgId}`,
      requestOptions
    ).then(handleResponse);
  }
  getOrganisations() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/childorg`,
      requestOptions
    ).then(handleResponse);
  }
  getMyOrganisations() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/myorg`,
      requestOptions
    ).then(handleResponse);
  }
  getAdminUsers() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.BACKEND_URL}/api/projectManagers/v2/`,
      requestOptions
    ).then(handleResponse);
  }

  getPermissionByRoleId(roleId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/accessRules/permission/${roleId}`,
      requestOptions
    ).then(handleResponse);
  }

  getAllRoles() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/roles/`,
      requestOptions
    ).then(handleResponse);
  }

  sendUserInvite(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/sendinvite/userinvite`,
      requestOptions
    ).then(handleResponse);
  }

  getOrgUsers() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/users`,
      requestOptions
    ).then(handleResponse);
  }

  getRules() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/accessRules/rule/`,
      requestOptions
    ).then(handleResponse);
  }

  getPermission(roleId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/org/accessRules/permission/${roleId}`,
      requestOptions
    ).then(handleResponse);
  }

  getLHS(roleId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/roles/${roleId}/lhs`,
      requestOptions
    ).then(handleResponse);
  }

  createRole(data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/roles`,
      requestOptions
    ).then(handleResponse);
  }

  deleteRole(roleId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.DELETE),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/roles/${roleId}`,
      requestOptions
    ).then(handleResponse);
  }

  createPermission(roleId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/accessRules/permission/${roleId}`,
      requestOptions
    ).then(handleResponse);
  }

  updatePermission(cosmosId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PUT, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/accessRules/permission/${cosmosId}`,
      requestOptions
    ).then(handleResponse);
  }

  updateRole(roleId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/roles/${roleId}`,
      requestOptions
    ).then(handleResponse);
  }

  updateOrgDetails(orgId: any, data: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(data),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/childorg/updateorg/${orgId}`,
      requestOptions
    ).then(handleResponse);
  }

  getchildOrgUsers(orgId: string) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/childorg/orgusers/${orgId}`,
      requestOptions
    ).then(handleResponse);
  }

  getOrgTypes() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/orgtypes`,
      requestOptions
    ).then(handleResponse);
  }

  getRoleTypes() {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.GET),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/roles/types`,
      requestOptions
    ).then(handleResponse);
  }

  changeChildOrgAdmin(orgId: string, body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/childorg/changeadmin/${orgId}`,
      requestOptions
    ).then(handleResponse);
  }

  updateUser(userId: any, body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.PATCH, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/users/${userId}`,
      requestOptions
    ).then(handleResponse);
  }
  addUser(body: any) {
    const requestOptions: RequestInit = {
      ...setHeaders(RequestTypes.POST, 'application/json; charset=utf-8'),
      body: JSON.stringify(body),
    };
    return fetch(
      `${envConfig.env.AUTH_SERVER}/api/org/users/`,
      requestOptions
    ).then(handleResponse);
  }
}
const organisationService = new OrganisationService();
export default organisationService;
