import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import appService from '../../core/service/app.service';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/system';
import DialogHeading from '../../components/DialogHeading';
import BackDropCircularProgress from '../../components/BackDropCircularProgress';
import { loadingButtonClasses } from '@mui/lab/LoadingButton';


const EditImageGallery = ({open,handleClose,data,handleStatus}:any) => {


      const [imgName,setImgName]=useState<any>()
      const [loading,setLoading]= useState<boolean>(false)

      const handleSubmit =(imgName:any)=>{
        setLoading(true)
console.log(imgName)
let name=imgName
appService.updateImageName(data.image_ID,{name})
.then(res=>{
    setLoading(false)
    handleStatus()
    handleClose()
})
.catch(err=>console.log(err))
      }


    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
           <DialogHeading 
           title={'Edit Image Details'}
           handleClose={handleClose}
           />
           <BackDropCircularProgress 
           open={loading}
           />
  
        <DialogContent>
        <Grid item my={2}>
        <Grid item xs={6}>
                    <Typography variant="h5" sx={{pb: 0.5}}>Image Name</Typography>
                    <TextField
                       
                          // inputProps={{backgroundColor: 'white'}}
                          //placeholder="First Name"
                          name="name"
                          defaultValue={data?data.name:'NA'}
                          onChange={(e:any)=>setImgName(e.target.value)}
                          type="text"
                          size="small"
                          variant="outlined"
                          fullWidth
                          sx={{backgroundColor: '#E8EDF2'}}
                        />

                   </Grid>
      </Grid>
            </DialogContent>
            <DialogActions>
         
          <Button
            variant="contained"
            // onClick={() => createCustomerSupportRequest()}
            onClick={()=>handleSubmit(imgName)}
            color="secondary"
          >
            {'Submit'}
          </Button>
        </DialogActions>
            </Dialog>

      
    );
};

export default EditImageGallery;