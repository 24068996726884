import {
  DialogTitle,
  Typography,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Box,
  Badge,
  IconButton,
  DialogActions,
  Button,
  Dialog,
  Grid,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {makeStyles} from '@mui/styles';

import React, {useState} from 'react';
import OverwriteDialog from './OverwriteDialog';
import {FileUploader} from 'react-drag-drop-files';
import {useSnackbar} from 'notistack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DialogHeading from '../../../components/DialogHeading';
import appService from '../../../core/service/app.service';

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG', 'PDF', 'CSV', 'XLSX', 'XLX'];

const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  cardRoot: {
    width: '100%',
    padding: 10,
  },
  greyText: {
    color: 'grey',
  },
  mainPaddedContainers: {
    padding: '50px 100px 50px 100px',
  },
  paddedTextContainers: {
    padding: 20,
  },
  subPaddedContainers: {
    padding: 10,
  },
  marginTop: {
    marginTop: '5px !important',
  },
  dialogPaper: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    paddingBottom: 8,
  },
  imageContainer: {
    width: '80px',
    height: '80px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
}));
const UploadDocumentDialog = ({
  open,
  setOpen,
  documentTypes,
  projectId,
  getDocumentData,
}) => {
  const classes = useStyle();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [files, setFiles] = useState([]);
  const [filesSrc, setFilesSrc] = useState([]);

  const [customerVisibiiity, setCustomerVisibility] = useState(true);

  const [matchingFileIndex, setMatchingFileIndex] = useState(null);
  const [tempNewFile, setTempNewFile] = useState(null);

  const [selectedDocumentType, setSelectedDocumentType] = useState(
    documentTypes && documentTypes[0].document_type_ID
  );
  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleAddFile = newFile => {
    console.log(newFile[0], files);
    let fileExists = null;
    if (files && files.length > 0) {
      files.map((file, index) => {
        console.log(file, newFile[0]);
        if (file.name === newFile[0].name) {
          setMatchingFileIndex(index);
          setTempNewFile(newFile[0]);
          fileExists = file;
        }
      });
    }

    if (fileExists || fileExists === 0) {
      console.log('overwrite?');
    } else {
      if (files.length === 3)
        enqueueSnackbar('Maximum of 3 files can be uploaded', {
          variant: 'info',
        });
      else {
        setFiles([...files, ...newFile]);
        setFilesSrc([...filesSrc, window.URL.createObjectURL(newFile[0])]);
      }
    }
  };

  const handleRemoveImage = index => {
    console.log(index);
    console.log(files);
    let filteredFiles = files;
    filteredFiles.splice(index, 1);
    setFiles(filteredFiles);
    let filteredFilesSrc = filesSrc;
    filteredFilesSrc.splice(index, 1);
    setFilesSrc(filteredFilesSrc);
    forceUpdate();
  };
  const cancelOverWrite = () => {
    setMatchingFileIndex(null);
    setTempNewFile(null);
  };

  const initiateOverWrite = () => {
    let temp = files;
    let tempSrc = filesSrc;

    console.log(temp, tempSrc, tempNewFile);

    temp[matchingFileIndex] = tempNewFile;
    tempSrc[matchingFileIndex] = window.URL.createObjectURL(tempNewFile);

    console.log(temp, tempSrc);

    setFiles(temp);
    setFilesSrc(tempSrc);

    setMatchingFileIndex(null);
    setTempNewFile(null);
    forceUpdate();
  };

  const handleChangeDocumentType = event => {
    setSelectedDocumentType(event.target.value);
  };

  const clearSupportCard = () => {
    setSelectedDocumentType(null);
    setFiles([]);
    setFilesSrc([]);
  };

  const uploadDocuments = async () => {
    setOpenProgressDialog(true);
    enqueueSnackbar('Uploading document');

    try {
      const formData = new FormData();
      if (files.length > 0) {
        files.map(item => formData.append('file', item));
      }
      formData.append('documentTypeId', selectedDocumentType);
      formData.append('customerVisibility', customerVisibiiity);
      formData.append('projectId', projectId);
      console.log(formData);

      const uploadDocumentResponse = await appService.uploadDocument(formData);
      getDocumentData();
      setOpenProgressDialog(false);
      enqueueSnackbar('Document successfully Uploaded', {variant: 'success'});
      setOpen(false);
      clearSupportCard();
    } catch (err) {
      console.log(err);
      setOpenProgressDialog(false);
      enqueueSnackbar('Error creating ticket');
    }
  };
  return (
    <Grid container sx={{width: 12}}>
      <OverwriteDialog
        matchingFileIndex={matchingFileIndex}
        fileName={tempNewFile ? tempNewFile.name : ''}
        cancelOverWrite={cancelOverWrite}
        initiateOverWrite={initiateOverWrite}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={'xl'}
        style={{width: '100vw'}}
        PaperProps={{classes: {root: classes.dialogPaper}}}
      >
        <DialogHeading
          title="Upload Documents by category"
          handleClose={() => setOpen(false)}
        />

        <DialogContent>
          <Grid container style={{width: '100%'}} justifyContent="space-around">
            <Grid item container xs={12} sx={{mt: 3}}>
              <InputLabel id="demo-simple-select-label">
                Select document type
              </InputLabel>

              <Select
                size="small"
                fullWidth
                value={selectedDocumentType}
                onChange={handleChangeDocumentType}
                error={!selectedDocumentType}
                label="Select document type"
              >
                {documentTypes &&
                  documentTypes.map(item => (
                    <MenuItem value={item.document_type_ID}>
                      {item.subject}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            {/* <Grid item xs={12}>
              {' '}
              <FormControlLabel
                value="Internal"
                control={
                  <Switch color="primary" checked={!customerVisibiiity} />
                }
                label="Internal "
                labelPlacement="start"
                onChange={() => setCustomerVisibility(!customerVisibiiity)}
              />
            </Grid> */}
            <Grid container sx={{mt: 1}}>
              {files && files.length > 0 && filesSrc && filesSrc.length > 0 && (
                <Grid container sx={{width: 1}}>
                  {filesSrc.map((image, index) => (
                    <Grid item sx={{mr: 5, mt: 2}}>
                      <Badge
                        badgeContent={
                          <IconButton
                            size="small"
                            style={{padding: 0}}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <CloseRoundedIcon sx={{fontSize: '1rem'}} />
                          </IconButton>
                        }
                        color="primary"
                        sx={{
                          '& .MuiBadge-badge': {
                            padding: 0,
                          },
                        }}
                      >
                        <Box
                          component={'div'}
                          className={classes.imageContainer}
                        >
                          {' '}
                          <img
                            src={image}
                            alt="uploaded"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </Box>
                      </Badge>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  className={classes.marginTop}
                  variant="body2"
                  sx={{mb: 1}}
                >
                  {'Upload image - (optional - maximum 3)'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FileUploader
                  multiple={true}
                  handleChange={handleAddFile}
                  name="file"
                  types={fileTypes}
                  children={
                    <Grid
                      container
                      sx={{
                        height: 100,
                        width: 1,
                        border: `2px dashed`,
                        borderColor: 'secondary.main',
                      }}
                      justifyContent="center"
                    >
                      <Typography variant="body2" sx={{mt: 2}}>
                        Click here to upload or drag and drop
                      </Typography>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              clearSupportCard();
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            onClick={() => uploadDocuments()}
            disabled={!selectedDocumentType && FileList.length > 0}
            color="secondary"
          >
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UploadDocumentDialog;
