import React, {useState} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Button, Grid, Typography} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {IOutcome} from './__types__/Outcome';

interface IOutcomesTable {
  open: boolean;

  handleClose: any;

  outcomes: IOutcome[];
  handleOutcomeSelect: any;
}

function OutcomesTable({
  open,
  handleClose,
  outcomes,
  handleOutcomeSelect,
}: IOutcomesTable) {
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const columns: GridColDef[] = [
    {field: 'id', headerName: 'ID', flex: 2},
    {field: 'system', headerName: 'System', flex: 2},
    {field: 'outcome', headerName: 'Outcome', flex: 2},
  ];

  const formatRows = (rows: IOutcome[]) => {
    const x = rows.map(row => ({
      id: row.outcomes_ID,
      system: row.system,
      outcome: row.subject,
    }));
    return x;
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle> Outcomes</DialogTitle>
      <DialogContent>
        <Box sx={{height: 400}}>
          <DataGrid
            rows={formatRows(outcomes)}
            columns={columns}
            hideFooter
            hideFooterPagination
            checkboxSelection
            onSelectionModelChange={newSelectionModel => {
              console.log(newSelectionModel);

              //@ts-ignore
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleOutcomeSelect(selectionModel)}
          autoFocus
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OutcomesTable;
