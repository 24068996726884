import {createAsyncThunk} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import appService from '../service/app.service';
import organisationService from '../service/organisation.service';
import {
  profileDataResponse,
  ProjectManagementResponse,
  SaleforceDataResponse,
} from '../__types__/Response.interface';

export const getHubSpotContactData = createAsyncThunk(
  'arka/getAccountsHubSpot',
  async () => {
    const response: ProjectManagementResponse =
      await appService.getHubSpotContactData();
    return response.message;
  }
);
export const getSalesforceData = createAsyncThunk(
  'arka/getDataFromSalesForce',
  async (type: string) => {
    const response: SaleforceDataResponse = await appService.getSalesforceData(
      type
    );
    return response.message;
  }
);

export const getProfileData = createAsyncThunk(
  'arka/getProfileData',
  async (id: string | null) => {
    const response: profileDataResponse = await appService.getProfileData(id);
    return response.message;
  }
);

export const getProjects = createAsyncThunk('arka/getProjects', async () => {
  const response = await appService.getProjects();

  return response.message;
});

export const getUserPrefs = createAsyncThunk(
  'arka/getUserPrefs',
  async (id: string | null, {dispatch}) => {
    const response = await appService.getUserPreferance(id);
    const {message} = response;
    if (!message) {
      // if document is not created for this individual id then dispath action to create new user pref document
      dispatch(
        addUserPrefs({
          id,
          userPrefs: {
            individualId: id,
            prefs: {mode: 'light', wishlist: []},
          },
        })
      );
    } else {
      return message;
    }
  }
);

export const addUserPrefs = createAsyncThunk(
  'arka/addUserPrefs',
  async ({id, userPrefs}: any) => {
    const {message} = await appService.addUserPreferance(id, userPrefs);

    return message;
  }
);

export const getl2c2cStages = createAsyncThunk(
  'arka/getl2c2cStages',
  async () => {
    const {message} = await appService.getl2c2cStages();

    return message;
  }
);

export const getDocumentTypes = createAsyncThunk(
  'arka/getDocumentTypes',
  async () => {
    const {message} = await appService.getDocumentTypes();

    return message;
  }
);

export const getOrganisations = createAsyncThunk(
  'arka-authentication/getOrganisations',
  async()=> {
    const {message} = await organisationService.getOrganisations();
    return message
  }
)
export const updateOrganisationThunk = createAsyncThunk(
  'arka-authentication/updateOrganisation',
  async({adminUser,orgId}:any)=> {
    const message = await organisationService.updateOrganisation(adminUser,orgId)
    return message
  }
)
export const createOrganisationThunk = createAsyncThunk(
  'arka-authentication/createOrganisation',
  async({createOrgBody}:any)=> {    
    const message = await organisationService.createOrganisation(createOrgBody)
    return message
    
  }
)
export const getAdminUsers = createAsyncThunk(
  'arka-users/getAdminUsers',
  async() => {
    const {message} = await organisationService.getAdminUsers()
    return message.managers
  }
)

export const getACL = createAsyncThunk(
  'arka/getACL',
  async (roleId: string) => {
    const {message} = await appService.getACL(roleId);

    return message;
  }
);
